import { useEffect, useState, useRef, Fragment } from 'react';
import './Comentarios.css';
import Comentario from './Comentario';
import { Formik } from 'formik';
import { Form, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField } from '../TextField';
import Spinner from '../Loadig';

const ListadoComentarios = ({ modalComments, setModalComments, comentarios, handleSubmit, loading, setLoading }) => {
    const refFormik = useRef();
    const { id } = useParams();
    const initial = {
        cierreContratoId: id,
        comentario: '',
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [comentariosAgrupados, setComentariosAgrupados] = useState([]);

    const validate = Yup.object().shape({
        // comentario: Yup.string()
        //     .required('Campo requerido')
    });

    useEffect(() => {
        sortComments();
    }, []);

    useEffect(() => {
        sortComments();
    }, [comentarios]);

    const sortComments = () => {
        if(comentarios?.data){
            comentarios?.data.sort((a, b) => new Date(a.fechaAlta) - new Date(b.fechaAlta));
            const listComentarios = comentarios.reduce((acc, comentario) => {
                const fecha = comentario.fechaAlta.split('T')[0]; // Obtener solo la fecha
                if (!acc[fecha]) {
                    acc[fecha] = [];
                }
                acc[fecha].push(comentario);
                return acc;
            }, {});
            setComentariosAgrupados(listComentarios);
        }else{
            setComentariosAgrupados([]);
        }
        setLoading(false);
    }


    /*
    Explicación:
    Ordenación: Utilizamos sort() para ordenar los objetos en el arreglo por la fecha en fechaAlta, convirtiendo cada fecha a un objeto Date para compararlas.
    Agrupación: Utilizamos reduce() para crear un nuevo objeto donde cada clave es una fecha y su valor es un arreglo de comentarios de esa fecha. Usamos split('T')[0] para obtener solo la parte de la fecha, ignorando la hora.
    */


    const formatDate = (fechaStr) => {
        const fecha = new Date(fechaStr + "T00:00:00");
        const hoy = new Date();

        // Comparar solo las fechas sin horas
        if (fecha.toDateString() === hoy.toDateString()) {
            return 'Hoy';
        } else {
            const opciones = { day: '2-digit', month: 'long', year: 'numeric' };
            return fecha.toLocaleDateString('es-ES', opciones);
        }
    }

    return (
        <>
            <div className="row comments-header d-flex justify-content-between">
                <div className="col-auto">
                    <h5 className="fw-bold">Comentarios</h5>
                </div>
                <div className="col-auto">
                    <button style={{ border: 'none', backgroundColor: '#E8EAF6', color: '#1351A5' }} onClick={() => setModalComments({ isOpen: false })}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
            </div>
            <div className={`row comments-content ${Object.keys(comentariosAgrupados).length > 0 ? 'bg-gray' : 'bg-white'} my-3 ${loading ? 'd-flex align-items-center justify-content-center' : ''}`}>
                {
                    loading &&
                    <Spinner message={""} light={true} />
                }
                {
                    (!loading && Object.keys(comentariosAgrupados).length > 0) &&
                    <div className='col-12'>
                        {
                            Object.keys(comentariosAgrupados).map(item => {
                                return <Fragment key={`fecha_${item}`}>
                                    <div className="row my-1">
                                        <div className="col-12">
                                            <span className='fw-bold content-date'>{formatDate(item)}</span>
                                        </div>
                                    </div>
                                    {
                                        comentariosAgrupados[item].map(comentario => {
                                            return <Fragment key={`comentario_${comentario?.folioId}`}><Comentario comentario={comentario} /></Fragment>
                                        })
                                    }
                                </Fragment>
                            })
                        }
                    </div>
                }
                {
                    (!loading && Object.keys(comentariosAgrupados).length === 0) &&
                    <div className='col-12'>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-12 text-center icon-no-comments">
                                <i className="ri-discuss-fill"></i>
                            </div>
                            <div className="col-12 text-center">
                                <span>No hay comentarios aún</span>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
            >
                {({ isValid, values, resetForm, setFieldValue, errors }) => (
                    <Form>
                        <div className="row comments-bar d-flex justify-content-between">
                            <div className="col-12 col-md-10">
                                <TextField name="comentario" type="text" holder="Escribir comentario"
                                    onChange={(event) => {
                                        setFieldValue("comentario", event.target.value);
                                    }} />
                            </div>
                            <div className="col-12 col-md-2 d-flex justify-content-end">
                                <button className="btn btn-send d-flex justify-content-center" disabled={values.comentario.trim().length === 0} onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(values)
                                    resetForm();
                                }}>
                                    <i className="ri-send-plane-fill"></i>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default ListadoComentarios;