import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguroSiniestrosService from "../../../Services/Seguros/SeguroSiniestrosService";
import toast from "react-hot-toast";
import { message } from "../../../Utils/functions";
import Llamada from "../../../Services/Cartera/Llamadas";
import Catalogo from '../../../Services/Catalogos/Catalogo';

const ResguardoUnidadHook = ({ handleConcluido, stepId }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const TipoAseguradoras = [{ value: 'Taller aseguradora', label: 'Taller aseguradora' }, { value: 'Patio aseguradora', label: 'Patio aseguradora' }];
    const tipoUbicacion = [{ value: 'Interno', label: 'Interno' }, { value: 'Externo', label: 'Externo' }];

    let initial = {
        origenTipoUbicacion: "",
        origenNombreUbicacion: "",
        siniestroId: id,
        siniestroResguardoUnidadId: "",
        origenTipoUbicacionRecoleccion: "",
        origenTipoAseguradora: "",
        destiboUbicacionStr: "",
        fechaCitaRecoleccion: "",
        formaArriboId: "",
        estadoRecoleccion: "",
        municipioRecoleccion: "",
        colonicaRecoleccion: "",
        calleRecoleccion: false,
        numeroRecoleccion: "",
        referenciaUbicacionRecoleccion: false,
        fechaResguardo: "",
        horaResguardo: "",
        quienEntrega: "",
        quienRecibe: "",
        calleNumero: "",
        codigoPostal: "",
        edoMexicoIdE: 0,
        municipioIdE: 0,
        coloniaIdE: 0,
        referenciaUbicacion: "",
        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        paso: stepId,
        porcentaje: 75,
        status: 2,
        procesoId: state?.procesoId,
        fechaCita: "",
        horaCita: "",
        subidoResguardo: false,
        edoMexicoId: 0,
        municipioId: 0,
        coloniaId: 0,
        origenUbicacion: "",
        comentarios: "",
        calle: "",
        codigoPostalRecoleccion: null
    }

    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const [formasArribo, setFormasArribo] = useState([]);
    const [dataMotivoResguardo, setDataMotivoResguardo] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);

    // const [tipoUbicacion, setTipoUbicacion] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getEstados();
        getEstadosDos();
        getFormasArribo();
        handleMotivoResguardo();
        // handleGetTipoUbicacion();
    }, [])

    const getData = async () => {
        setLoading(true)
        await SeguroSiniestrosService.getResguardoVehiculoSiniestros({ id, step: stepId })
            .then(resp => {
                let item = resp.data

                //item.concluido = false
                item.origenTipoUbicacion = 'Externo'

                let newData = {
                    //Detalle de Resguardo
                    siniestroId: item?.siniestroId ?? id,
                    siniestroResguardoUnidadId: item?.siniestroResguardoUnidadId ?? "",
                    origenTipoUbicacionRecoleccion: item?.origenTipoUbicacionRecoleccion ?? "",
                    origenTipoAseguradora: item?.origenTipoAseguradora ?? "",
                    origenTipoUbicacion: item?.origenTipoUbicacion ?? "",
                    destiboUbicacionStr: item?.destiboUbicacionStr ?? "",
                    fechaCitaRecoleccion: item?.fechaCitaRecoleccion ?? "",
                    origenNombreUbicacion: item?.origenNombreUbicacion ?? "",
                    formaArriboId: item?.formaArriboId ?? "",
                    motivoResguardoId: item?.motivoResguardoId ?? "",
                    estadoRecoleccion: item?.estadoRecoleccion ?? "",
                    municipioRecoleccion: item?.municipioRecoleccion ?? "",
                    colonicaRecoleccion: item?.colonicaRecoleccion ?? "",
                    calleRecoleccion: item?.calleRecoleccion ?? "",
                    numeroRecoleccion: item?.numeroRecoleccion ?? "",
                    referenciaUbicacionRecoleccion: item?.referenciaUbicacionRecoleccion ?? "",
                    //Detalles de la entrega
                    fechaResguardo: item?.fechaResguardo !== "" ? item?.fechaResguardo?.split("T")[0] : null,

                    horaResguardo: item?.horaResguardo ?? null,
                    fechaCita: item?.fechaCita?.split("T")[0] ?? null,
                    horaCita: item?.horaCita ?? "",
                    quienEntrega: item?.quienEntrega ?? "",
                    quienRecibe: item?.quienRecibe ?? "",
                    calleNumero: item?.calleNumero ?? "",
                    codigoPostal: item?.codigoPostal ?? "",

                    edoMexicoIdE: item?.edoMexicoIdE ?? 0,
                    municipioIdE: item?.municipioIdE ?? 0,
                    coloniaIdE: item?.coloniaIdE ?? 0,

                    edoMexicoId: item?.edoMexicoId ?? 0,
                    municipioId: item?.municipioId ?? 0,
                    coloniaId: item?.coloniaId ?? 0,

                    referenciaUbicacion: item?.referenciaUbicacion ?? "",

                    concluido: state?.concluido ? true : (item?.concluido ?? false),
                    fechaInicio: item.fechaInicio ?? "",
                    fechaFin: item.fechaFin ?? "",
                    //Stepper Update        
                    paso: stepId,
                    porcentaje: 75,
                    status: 2,
                    procesoId: state?.procesoId,
                    subidoResguardo: item.subidoResguardo ?? false,
                    origenUbicacion: item.origenUbicacion ?? "",
                    comentarios: item.comentarios ?? "",
                    calle: item.calle ?? "",
                    codigoPostalRecoleccion: item.codigoPostalRecoleccion ?? null
                }
                setInitialValue(newData);
                getMunicipios(item?.edoMexicoId);
                getColonias(item?.municipioId);
                getMunicipiosDos(item?.edoMexicoIdE);
                getColoniasDos(item?.municipioIdE);
                handleConcluido({ concluido: item.concluido });

            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const handleMotivoResguardo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Cobranza_MotivoResguardo')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({
                        value: item.id.toLowerCase(),
                        label: item.valor
                    });
                });
                setDataMotivoResguardo(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const getFormasArribo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ResguardoFormaArribo')
            .then(resp => {
                let list = [];
                resp.data.forEach(item => {
                    list.push({ value: item.ResguardoFormaArriboId, label: item.ResguardoFormaArribo });
                });
                setFormasArribo(list);
            })
            .catch(err => {
                toast.error(message('Error al cargar el catálogo de Formas de Arribo'));
            });
    };

    const submitForm = async (values) => {
        values.fechaCita = values.fechaCita ?? null
        setLoading(true);
        await SeguroSiniestrosService.UpdateResguardoPercance(values)
            .then(res => {
                console.log("res", res)
                if (res.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.mensaje));
                    handleConcluido({ concluido: values.concluido })
                    getData()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.mensaje));
                }

            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }

    // const handleGetTipoUbicacion = async () => {
    //     await Catalogo.getDropdownsByTable('Catalogos.Tipo_Ubicacion_Datamovil')
    //         .then(resp => {
    //             let newData = [];
    //             resp.data.forEach(item => {
    //                 newData.push({
    //                     value: item.TipoUbicacionId,
    //                     label: item.TipoUbicacion
    //                 });
    //             });

    //             setTipoUbicacion(newData);
    //         })
    //         .catch(err => {
    //         });
    // }

    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipios = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }

    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCntPRes/${state?.folio}/${initialValue.siniestroResguardoUnidadId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroResguardoUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }

    return {
        initialValue,
        estados,
        municipios,
        colonias,
        formasArribo,
        tipoUbicacion,
        setLoading,
        loading,
        submitForm,
        handleUploadFile,
        setMunicipios,
        setColonias,
        getMunicipios,
        getColonias,
        estadosDos,
        municipiosDos,
        coloniasDos,
        dataMotivoResguardo,
        getMunicipiosDos,
        getColoniasDos,
        setMunicipiosDos,
        setColoniasDos,
        TipoAseguradoras
    }
}

export default ResguardoUnidadHook;