import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { message } from "../../../Utils/functions";
import DataTable from "../../../Components/datatable/DataTable";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import AmpliarSolicitudExpedienteModal from "./AmpliarSolicitudExpedienteModal";
import HistorialDetalleSolicitudesModal from"./HistorialDetalleSolicitudesModal";

const DetalleSolicitudExpediente = ({actualizarCardTramite}) => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, width: '' });
    const [openmodalMessage, setOpenModalMessage] = useState(false);
    const[isOpenModalHistorialDetalle,setIsOpenModalHistorialDetalle]=useState(false);
    const [listaEstatus, setListaEstatus] = useState([]);

    useEffect(() => {
        getDetalle();
        getEstatus()
    }, [actualizarCardTramite]);

    const getEstatus = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoExpedienteStatus')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.CierreContratoExpedienteStatusId,
                        label: item.CierreContratoExpedienteStatus
                    }
                });
                setListaEstatus(items);
            })
            .catch(err => {
                toast.error(message('Error al obtener el listado'));
            });
    }

    const getDetalle = async () => {
        await ServicioExpedientes.getDetalleExpediente(id)
            .then(resp => {
                const items = [];
                items.push({
                    ...resp.data,
                    fechaSolicitud: getOnlyDateFormat(resp.data.fechaSolicitud),
                    selectFieldPlaceholder: resp.data.cierreContratoExpedienteStatus,
                    EstatusUI: {
                        text: resp.data.cierreContratoExpedienteStatus,
                        backgroundColor: resp.data.fondoHex,
                        textColor: resp.data.textoHex
                    },
                });
                setData(items);
            })
            .catch(err => {
                toast.error(message('Erro al obtener el detalle del expediente'));
            });
    };

    const columns = [
        { field: 'folio', headerName: 'Folio' },
        { field: 'usuarioSolicito', headerName: 'Solicitado por' },
        { field: 'fechaSolicitud', headerName: 'Fecha de Solicitud' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'vigencia', headerName: 'Días de Vigencia' },
        { field: 'EstatusUI', headerName: 'Estatus' },
        // { field: 'selectField', headerName: 'Estatus', width: 200 },
        { field: 'accionesExpediente', headerName: 'Acciones' },
        { field: 'historialdesistema', headerName: 'Historial' },
    ];

    const handleAmpliar = (obj) => {
        setModalMessage({ isOpen: true, type: 1, data: obj, title: 'Ampliar solicitud de expediente' });
    }

    const handleHistorial = (obj) => {
        setIsOpenModalHistorialDetalle(true);
    }

    const handleDocuments = (obj) => {
        
        navigate(`/procesocierre/expediente/datadocs/fExpedient/eExpedient/${state.folio}/${state?.cierreContratoExpedienteId}`, {
            state: { ...state, registroId: state?.cierreContratoExpedienteId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }

    const handleSelectedField = async (obj, selectData) => {
        let data = {
            generalId: state.generalId,
            expedienteStatusId: obj.valueOption
        }

        setLoading(true)
        await ServicioExpedientes.ActualizarEstatusCierreExpediente(data)
            .then(resp => {
            })
            .catch(err => {
                setLoading(false)
                toast.error(message('Erro al obtener el detalle del expediente'));
            });
            await getDetalle()
        setLoading(false)


    }

    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={520}>
                {
                    modalMessage.type === 1 &&
                    <AmpliarSolicitudExpedienteModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        info={data}
                    />
                }
             
            </Modal>
            <Modal isOpen={isOpenModalHistorialDetalle} color='#fff' width={520}>
                    <HistorialDetalleSolicitudesModal
                        generalid={state?.generalId}
                        isOpen={isOpenModalHistorialDetalle}
                        setIsOpen={setIsOpenModalHistorialDetalle}
                        actualizarCardTramite={actualizarCardTramite}
                    />
            </Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseDetalleSolicitudExpediente" role="button" aria-expanded="false" aria-controls="collapseDetalleSolicitudExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Detalle solicitud de expediente</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDetalleSolicitudExpediente">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12" style={{ overflowX: "auto" }}>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            striped={false}
                                            handleAmpliar={handleAmpliar}
                                            handleDocuments={handleDocuments}
                                            handleHistorial={handleHistorial}

                                            dataSelectField={listaEstatus}
                                            funcSelectField={handleSelectedField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetalleSolicitudExpediente;