import React from "react";
import SeguimientoStepper from "../../PasosSeguimiento/Componentes/SeguimientoStepper";
import useProcesoTramite from "../../../../Hooks/Gestoria/seguimiento/useProcesoTramite";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import SeguimientoProcesoDatamovil from "../../PasosSeguimiento/Componentes/SeguimientoProcesoDatamovil";
import GestoriaProcesoDeTramiteCard from "./GestoriaProcesoDeTramiteCard";
import GestoriaRegistroTramiteCard from "./GestoriaRegistroTramiteCard";
import ModalConfirm from "../../PasosSeguimiento/Componentes/ModalConfirm";
import ContratoCard from "../../../../Components/ContratoCard";

const GestoriaProcesoDeTramite = () => {
  let {
    stepe,
    handleClickTerminar,
    activebtncontinuar,
    loading,
    handleChangeStatusBtn,
    modalConfirm,
    setModalConfirm,
    handleClickTerminarProceso,
    state,
    reload,
  } = useProcesoTramite();
  return (
    <>
      <SeguimientoStepper
        dataStep={stepe}
        handleClickContinuar={handleClickTerminar}
        activeBtn={activebtncontinuar}
      />
      <div
        className="d-flex flex-column background-grey"
        style={{ height: "100vh" }}
      >
        <div className="row mx-md-4 my-4">
          <div className="col-6 px-md-3">
            <VehiculoInfoCard VIN={state?.vin} verClaves={true} />
          </div>
          <div className="col-6 px-md-3">
            <ContratoCard vin={state?.vin} />
          </div>
        </div>
        <SeguimientoProcesoDatamovil />
        <GestoriaProcesoDeTramiteCard handleConcluido={handleChangeStatusBtn} />
        <GestoriaRegistroTramiteCard reload={reload} />
      </div>
      {modalConfirm && (
        <ModalConfirm
          isOpen={modalConfirm}
          setIsOpen={setModalConfirm}
          title={"Seguimiento Gestoria"}
          handleConfirm={handleClickTerminarProceso}
        />
      )}
    </>
  );
};
export default GestoriaProcesoDeTramite;
