import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';
import GeneracionExpedientesService from '../../../../Services/Cartera/GeneracionExpedientesService';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { getDateNow, message, FormatFecha, formatearFecha, IsNullOrEmpty,FechaParaSQL } from '../../../../Utils/functions';

const EntregaUnidad = ({
    dataEstado,
    toast,
    state
}) => {
    // const { state } = useLocation();
    // const { id } = useParams();
    const { refFormik } = useRef();
    const navigate = useNavigate();
    const userId = sessionStorage.getItem('userId')
    const refbtnDownload = useRef();

    let initial = {
        contrato: state.contrato,
        recibe_id: "",
        parentesco_id: "",
        fechaentrega: "",
        horaentrega: "",
        bloqueoid: false,
        unidad_entregada: 0,
        concluido:false,
        documento:false
    }

    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);

    const [relacionSolicitante, setRelacionSolicitante] = useState([]);
    const [tipoPersona, setTipoPersona] = useState([]);
    const [showOtroField, setShowOtroField] = useState(false);

    useEffect(() => {
        HandleGetRelacionSolicitante()
        HandleTipoPersona()
        getAllData()
    }, [])

    const getAllData = async () => {
        setLoading(true)
        try {
            await handleGetCita()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleGetCita = async () => {
        await UnidadesRecuperadasService.getAllEntregaUnidad()
            .then(res => {
                let entrega = []
                res.data?.map(item => {
                    if (item.contrato === state.contrato) {
                        entrega.push(item)
                    }
                });
                
                if (entrega[0]) {
                    state.valuacionInicioId=entrega[0].id
                    setInitialValue((prevValues) => ({
                        ...prevValues,
                        id: entrega[0].id,
                        recibe_id: entrega[0].recibe_id,
                        parentesco_id: entrega[0].parentesco_id,
                        fechaentrega: (entrega[0]?.fechaentrega && entrega[0]?.fechaentrega !== "" && entrega[0]?.fechaentrega !== "1900-01-01T00:00:00"&& entrega[0]?.fechaentrega !=="1990-01-01T00:00:00" ) ? getDateNow(entrega[0]?.fechaentrega) : '',
                        horaentrega: entrega[0].horaentrega,
                        fechainicioproceso: entrega[0].fechainicioproceso,
                        fechafinproceso: entrega[0].fechafinproceso,
                        bloqueoid: entrega[0].unidad_entregada === 1 ? true : false,
                        concluido: entrega[0].unidad_entregada === 1,
                        documento: entrega[0].subidoEntregaUnidad === 1
                        
                    }));
                    
                }

            })
    }

    const HandleGetRelacionSolicitante = async (id) => {
        setRelacionSolicitante([]);
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relacion = []
                res.data?.responseData?.map(item => {
                    relacion.push({
                        value: item.id,
                        label: item.nombre
                    });
                });
                setRelacionSolicitante(relacion);
            })
    }

    const HandleTipoPersona = async (id) => {
        setTipoPersona([])
        await UnidadesRecuperadasService.getAllTipoPersona().then(res => {
            let tipo = []
            res.data?.responseData?.map(item => {
                tipo.push({
                    value: item.id,
                    label: item.nombre
                });
            });
            setTipoPersona(tipo);
        })
    }

    const validationSchema = Yup.object({
        //recibe_id: Yup.string().required('Requerido'),
        //parentesco_id: Yup.string().required('Requerido'),
        //fechaentrega: Yup.string().required('Requerido'),
        //horaentrega: Yup.string().required('Requerido'),
        //concluido: Yup.boolean()
          //  .oneOf([true])
    })

    const downloadPDF = async (num) => {

        refbtnDownload.current.disabled = true;
        setLoading(true)
        if (num === 1) {

            let value = {
                "FIELD": "nombreDocumentoKey",
                "VALUE": "checklist_de_recepcion"
            }

            let tipoDocumentoValue = await GeneracionExpedientesService.GetDocumentoVisitaByField(value).then(res => {
                return res.data.responseData[0].id
            }).catch(err => {
                console.error(err);
            });

            let IdsDescargar = [
                {
                    "sol_id": state?.ruta_item?.contrato?.sol_id,
                    "contrato": state?.ruta_item?.contrato?.contrato_id,
                    "vin": state?.ruta_item?.contrato?.vin
                }
            ]

            if (!IsNullOrEmpty(tipoDocumentoValue)) {

                await GeneracionExpedientesService.GenerarPlantillaExpediente({
                    contratos: IdsDescargar,
                    tipodocumentos: tipoDocumentoValue,
                    comentarios: '',
                    numero_telefono: '',
                    nombre_cobrador: '',
                })
                    .then(res => {
                        var FileSaver = require('file-saver');
                        let today = FormatFecha(new Date())
                        FileSaver.saveAs(res.data, `Checklist de entrega_${state?.ruta_item?.contrato?.contrato_id}_${today}.pdf`);
                        setLoading(false);
                        refbtnDownload.current.disabled = false;
                        return GeneracionExpedientesService.ActualizaDocumentoCita({
                            contrato: state?.ruta_item?.contrato?.contrato_id
                          });
                    }).catch(e => {
                        setLoading(false);
                        refbtnDownload.current.disabled = false;
                        toast.custom(<div className='warnin-toast'>{message("Error.!", "Algo ocurrio al intentar generar el PDF.")}</div>);
                    })
            } else {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No se encuentra el id del documento.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                setLoading(false);
                refbtnDownload.current.disabled = false;
            }

        } else if (num === 2) {
            let value = {
                "FIELD": "nombreDocumentoKey",
                "VALUE": "carta_recepcion_unidad"
            }

            let tipoDocumentoValue = await GeneracionExpedientesService.GetDocumentoVisitaByField(value).then(res => {
                return res.data.responseData[0].id
            }).catch(err => {
                console.error(err);
            });

            let IdsDescargar = [
                {
                    "sol_id": state?.ruta_item?.contrato?.sol_id,
                    "contrato": state?.ruta_item?.contrato?.contrato_id,
                    "vin": state?.ruta_item?.contrato?.vin
                }
            ]

            if (!IsNullOrEmpty(tipoDocumentoValue)) {

                await GeneracionExpedientesService.GenerarPlantillaExpediente({
                    contratos: IdsDescargar,
                    tipodocumentos: tipoDocumentoValue,
                    comentarios: '',
                    numero_telefono: '',
                    nombre_cobrador: '',
                })
                    .then(res => {
                        var FileSaver = require('file-saver');
                        let today = FormatFecha(new Date())
                        FileSaver.saveAs(res.data, `Carta de recepción de unidad_${state?.ruta_item?.contrato?.contrato_id}_${today}.pdf`);
                        setLoading(false);
                        refbtnDownload.current.disabled = false;
                        return GeneracionExpedientesService.ActualizaDocumentoCita({
                            contrato: state?.ruta_item?.contrato?.contrato_id
                          });
                    }).catch(e => {
                        setLoading(false);
                        refbtnDownload.current.disabled = false;
                        toast.custom(<div className='warnin-toast'>{message("Error.!", "Algo ocurrio al intentar generar el PDF.")}</div>);
                    })
            } else {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No se encuentra el id del documento.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                setLoading(false);
                refbtnDownload.current.disabled = false;
            }
        }
    }


    const submitForm = async (values) => {

        const fechaHoy = new Date()

        if (values.fechainicioproceso) {

        } else {
            values.fechainicioproceso = FechaParaSQL(fechaHoy)
        }
        
        if(values.concluido){
            values.fechafinproceso = FechaParaSQL(fechaHoy)
            values.unidad_entregada=1
        }
        const data = { anyObject: values }

        const notificacion = {
            anyObject: {
                usuarioid: userId, //El usuario que inicio sesión
                modulo: "pago_unidad", //pantalla origen
                tipocorreo: "Autorización de Cita Entrega de Unidad",
                data: JSON.stringify(values), //Objeto JSON que se va a usar en la notificacion 
                enviado: 0 //Cuando se crea va en 0
            }
        }

        try {
            setLoading(true)
            await UnidadesRecuperadasService.upsertEntregaUnidad(data)
            await UnidadesRecuperadasService.createNotificacionesCobranza(notificacion)
            setLoading(false)
            toast.success(message("¡Operación exitosa!"));

        } catch (error) {
            setLoading(false)
            toast.error(message("¡Error!"));
        }
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={validationSchema}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({  values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div>
                                <div className="row mb-2">
                                    <div className='col-4'>
                                        <h6><strong>Generar documento</strong></h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    className='btn btn-gray'
                                                    onClick={() => {
                                                        navigate(`/cobranza/datadocs/fRecupera/eEntregaRc/${state.contrato}/${state.id}`, {
                                                            state: {
                                                                ...state,
                                                                registroId: state.valuacionInicioId,
                                                                vin: state.vin,
                                                                nombreMarca: state.ruta_item.contrato.vehiculo.nombreMarca,
                                                                nombreModelo: state.ruta_item.contrato.vehiculo.nombreModelo,
                                                                nombreVersion: state.ruta_item.contrato.vehiculo.nombreVersion,
                                                                nombreYear: state.ruta_item.contrato.vehiculo.nombreYear
                                                            }
                                                        });
                                                    }}>
                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.bloqueoid ?
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                        : <button
                                                            className='btn'
                                                            //disabled={!(isValid)}
                                                            type='submit'>
                                                            Guardar
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <small>Checklist de entrega</small>
                                    </div>
                                    <div className="col-3">
                                        <button className='btn btn-gray' ref={refbtnDownload} onClick={() => downloadPDF(1)}>
                                            <i className="icon-blue bx bx-download"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <small>Carta de recepción de unidad</small>
                                    </div>
                                    <div className="col-3">
                                        <button className='btn btn-gray' ref={refbtnDownload} onClick={() => downloadPDF(2)}>
                                            <i className="icon-blue bx bx-download"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-12 mt-3 '>
                                    <h6><strong>Detalle de entrega</strong></h6>
                                    
                                </div>
                                <div className='row mt-4 mb-2'>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <SelectField
                                            id="recibe_id"
                                            label="¿Quién recibe?"
                                            name="recibe_id"
                                            items={tipoPersona}
                                            disabled={initialValue.bloqueoid}
                                            onChange={(event) => {
                                                setFieldValue("recibe_id", event.value);
                                                const selectedOption = tipoPersona.find(option => option.value === event.value);    
                                                setShowOtroField(selectedOption && selectedOption.label === 'Otros'); 
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <SelectField
                                            id="parentesco_id"
                                            label="Parentesco"
                                            name="parentesco_id"
                                            items={relacionSolicitante}
                                            disabled={initialValue.bloqueoid}
                                            onChange={(event) => {
                                                setFieldValue("parentesco_id", event.value);
                                                
                                               
                                            }}
                                        />
                                    </div>
                                    {showOtroField && (
                                        <>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                        <TextField label="Otro" name="Otro" type="text"  value={values.Otro || ''} holder="Escribe" onChange={(event) => {
                                            setFieldValue("Otro", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                        </div>
                                        </>
                                    )}
                                </div>
                                <div className='d-flex row'>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <TextField label="Fecha de entrega" name="fechaentrega" type="date" holder="Escribe" onChange={(event) => {
                                            setFieldValue("fechaentrega", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 px-3'>
                                        <TextField label="Hora de entrega" name="horaentrega" type="time" holder="Escribe" onChange={(event) => {
                                            setFieldValue("horaentrega", event.target.value);
                                        }} disabled={initialValue.bloqueoid} />
                                    </div>
                                </div>
                                <div className="row mt-2 p-4">
                                    {initialValue.documento && (
                                        <div className="col-12 col-lg-3 col-sm-12">
                                            <div className="form-check" style={{ paddingLeft: '0' }}>
                                                <Field 
                                                    className="form-check-input" 
                                                    type="checkbox" 
                                                    name="concluido" 
                                                    onClick={(event) => {
                                                        setFieldValue("concluido", event.target.checked);
                                                    }} 
                                                     
                                                />
                                                <label className="form-check-label" style={{ marginLeft: '10px' }}>
                                                    Unidad entregada
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12 col-lg-9 col-sm-12">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                {
                                                    values.fechainicioproceso &&
                                                    <small className='text-body-tertiary'>Fecha Inicio: {formatearFecha(values.fechainicioproceso)}</small>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.concluido &&
                                                    <small className='text-body-tertiary'>Fecha Fin: {formatearFecha(values.fechafinproceso)}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )
                }
            </Formik >
        </>
    )

}
export default EntregaUnidad