import React from 'react';
import '../Pages/Login/Login.css';

let version = "N/A"; // Valor por defecto en caso de error
try {
  const packageJson = require('../../package.json'); // Importa el package.json
  version = packageJson.version || "N/A"; // Obtén la versión o usa el valor por defecto
} catch (error) {
  console.error("Error al cargar el archivo package.json:", error);
}

const Footer = ({ cls = "" }) => {
  let year = new Date().getFullYear();

  return (
    <>
      <footer className={cls}>
        <div className="row mt-5">
          <div className="col-12 mt-5">
            <h5 className="text-white fs-6">
              Copyright © {year} Dataware - Version {version}
            </h5>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;