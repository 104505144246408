
import { useEffect, useState } from "react";
import { toast, Toaster } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

import DataTable from "../../../Components/datatable/DataTable";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import VisorFile from "../../../Components/VisorFile";

import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import dataDocsServices from "../../../Services/DataDocs/dataDocsServices";

import { FormatFecha, message } from "../../../Utils/functions";
import AuthLogin from "../../../Utils/AuthLogin";

const MovimientosdeSolicitudExpediente = ({ actualizarCardTramite }) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const auth = new AuthLogin();
    let url = auth.UrlDataDocs();

    const [loading, setLoading] = useState(false);
    const [collapsable, setCollapsable] = useState(false);

    const [data, setData] = useState([]);
    
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);

    const columns = [
        { field: 'fecha', headerName: 'Fecha' },
        { field: 'usuario', headerName: 'Usuario' },
        { field: 'operacion', headerName: 'Operacion' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
        { field: 'acciones', headerName: 'Acciones' },
    ];

    const handleGetData = async () => {
        setLoading(true)
        try {
            const res = await ServicioExpedientes.getAllOperacionesExpediente(state?.generalId);
            if (res.data) {
                let datosFormateados = res.data.map(item => ({
                    ...item, 
                    operacion: item.operacion || "N/A",
                    usuario: item.usuarioSolicito || "N/A",
                    motivo: item.area || "N/A",
                    fecha: FormatFecha(item.fechaSolicito) || "N/A",
                    comentariosUI: item.comentarios || "-"
                }));

                setData(datosFormateados);
            } else {
                setData([]); // Manejo de caso sin datos
            }
        } catch (error) {
            toast.error(message('Error al obtener los movimientos.'));
            setLoading(false)
            setData([]);
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        handleGetData()
    }, [actualizarCardTramite])

    const handleDocuments = (obj) => {
            navigate(`/procesocierre/expediente/datadocs/fExpedient/eExpedient/${state?.folio}/${state?.cierreContratoExpedienteId}`, {
            state: { ...state, registroId: obj?.tramiteDetalleId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }

    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item?.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item?.idFolder}/${item?.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {isOpenDocs &&
                <VisorFile
                   isOpen={isOpenDocs}
                   setIsOIpen={setIsOpenDocs}
                   fileName={fileName}
                   doc={SelectedDocs}
                />
            }
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseSolicitudMovimiento" role="button" aria-expanded="false" aria-controls="collapseSolicitudMovimiento" onClick={() => setCollapsable(!collapsable)}>
                                <div className="col-11">
                                    <h6><strong>Movimientos de solicitud</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collapsable ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseSolicitudMovimiento">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12">
                                        <DataTable
                                            column={columns}
                                            data={data}
                                        handleAction={(tramite, idx) => {
                                            if (tramite.subido) {
                                                handleViewdocs(tramite)
                                            }
                                            else {
                                                handleDocuments(tramite)
                                            }
                                        }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MovimientosdeSolicitudExpediente;
