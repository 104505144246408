import axios from '../../Utils/ApiService';

const ValuacionesServices = {
    getAllValuaciones: async ({ page, rows, buscar, estatus, fechaInicio, fechaFin, concluido }, advanced) => {
        let queryParams = `page=${page}&Rows=${rows}`;

        if (buscar) queryParams += `&Buscar=${buscar}`
        if ((!advanced) && (estatus)) queryParams += `&VaulacionStatusId=${estatus}`
        if (fechaInicio) queryParams += `&FechaInicio=${fechaInicio}`
        if (fechaFin) queryParams += `&FechaFin=${fechaFin}`
        if (advanced) queryParams += `&Concluido=${concluido}`

        return await axios.get(`/functions/GetAllValuaciones?${queryParams}`);
    },
    getInfoVehiculo: (generalId, vin) => {
        let params = '';
        if (generalId?.trim().length > 0) params += `GeneralId=${generalId}`;
        if (generalId?.trim().length === 0 && vin?.trim().length > 0) params += `vin=${vin}`;
        if (generalId?.trim().length > 0 && vin?.trim().length > 0) params += `&vin=${vin}`;

        return axios.get(`/functions/GetInfoVehiculo?${params}`);
    },
    getEstatus: (procesoId) => {
        return axios.get(`/functions/Estatus?ProcesoId=${procesoId}`);
    },
    getDataInspeccion: (id) => {
        return axios.get(`/functions/Valuacion?ValuacionId=${id}`);
    },
    putDataInspeccion: (data) => {
        return axios.put(`/functions/Valuacion`, data);
    },
    getCostos: ({ id, page, rows }) => {
        return axios.get(`/functions/CostosInspeccion?ValuacionInspeccionUnidadId=${id}&page=${page}&rows=${rows}`);
    },
    postCostos: (data) => {
        return axios.post(`/functions/CostosInspeccion`, data);
    },
    putCostos: (data) => {
        return axios.put(`/functions/CostosInspeccion`, data);
    },
    deleteCostos: (data) => {
        return axios.post(`/functions/CostosInspeccionDelete`, data);
    },

}
export default ValuacionesServices;