import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { message } from '../../../Utils/functions'
import { Formik, Field } from 'formik';
import { toast } from 'react-hot-toast';
import Select from 'react-select'
import RowPages from '../../../Components/RowPages'
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../Components/datatable/DataTable';
import useSeguimientoCierre from '../../../Hooks/Cierre/useSeguimientoCierre';
import ModalBuscarVin from './SeguimientoCierre/ModalBuscarVin';
import ModalInfo from './SeguimientoCierre/ModalInfo';
import ModalBusquedaAvanzada from "./SeguimientoCierre/ModalBusquedaAvanzada";
import { Proceso } from '../../../Utils/constan';

const SeguimientoCierre = () => {
    const refFormik = useRef();
    const navigate = useNavigate();

    let initial = {
        buscar: "",
        cierreContratoProcesoId: null,
        fechaInicio: null,
        fechaFin: null,
        concluido: null,
        page: 1,
        rows: 10
    }
    const {
        columns,
        dataValue,
        downloadExcel,
        loading,
        cierreContrato,
        setBuscar,
        setCierreContratoProcesoId,
        handlePageClickH,
        currentPage,
        setCurrentPage,
        setpageRows,
        cierreContratoProcesoId,
        buscar,
        ejecutarBusqueda,
        setFechaInicio,
        setFechaFin,
        setConcluido
    } = useSeguimientoCierre(toast);

    const [initialValue, setInitialValue] = useState(initial);
    const [modalBuscar, setModalBuscar] = useState(false);
    const [buscarAvanzado, setBuscarAvanzado] = useState(false);
    const [viewInfo, setViewInfo] = useState(false);
    const [elementSelected, setElementSelected] = useState({});

    const handleInfo = (values) => {
        setElementSelected(values)
        setViewInfo(true)
    }

    const handleDetailData = (values) => {
        console.log(values)
        if (values.paso == null) {
            toast.error(message("El registro seleccionado no contiene un paso!"));
            return;
        }
        navigate(`/procesocierre/seguimientocierre/${values.cierreContratoId}/${values.paso}`, {
            // state: values,
            state: {
                ...values,
                proceso: Object.keys(Proceso).find(key => Proceso[key] === Proceso.SeguimientoCierre),
                procesoId: Proceso.SeguimientoCierre
            },
        })
    }

    const submitBuscar = (values) => {
        values = {
            ...values,
            cierreContratoProcesoId: cierreContratoProcesoId
        };
        ejecutarBusqueda(values)
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ values }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section>
                            <div className='pb-4'>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>Seguimiento de cierre</strong>
                                            </div>
                                            <div className="col-auto">
                                                <button className='btn' onClick={() => downloadExcel(values)}>
                                                    Exportar excel
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button className='btn' onClick={() => { setModalBuscar(true) }}>
                                                    Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-2 inner-addon right-addon  mt-2 mr-1">
                                                <i className="glyphicon fas fa-search"></i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Buscar"
                                                    id="buscar"
                                                    name="buscar"
                                                    value={buscar}
                                                    onChange={
                                                        (e) => {
                                                            setBuscar(e.target.value)
                                                            ejecutarBusqueda({ "buscar": e.target.value, "cierreContratoProcesoId": cierreContratoProcesoId, "page": 1, "rows": 10 });
                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 mt-2 mr-1">
                                                <Select
                                                    options={cierreContrato}
                                                    id='cierreContratoProcesoId'
                                                    name='cierreContratoProcesoId'
                                                    value={cierreContrato.find(option => option.value === cierreContratoProcesoId) || null}
                                                    onChange={(e) => {
                                                        setCierreContratoProcesoId(e.value)
                                                        ejecutarBusqueda({ "buscar": buscar, "cierreContratoProcesoId": e.value, "page": 1, "rows": 10 });
                                                    }}
                                                    placeholder='Proceso'
                                                />

                                            </div>
                                            <div className="col-auto mt-2 mr-1">
                                                <button className='btn btn-blue me-2' onClick={() => setBuscarAvanzado(true)}>
                                                    <i className="glyphicon fas fa-search me-2"></i>
                                                    <span className='mr-4'>Busqueda Avanzada</span>
                                                </button>
                                                <button className='btn btn-blue' onClick={() => {
                                                    setBuscar("");
                                                    setCierreContratoProcesoId(null);
                                                    setCurrentPage(1);
                                                    setpageRows(10);
                                                    setFechaInicio(null);
                                                    setFechaFin(null);
                                                    setConcluido(false);

                                                    if (refFormik.current)
                                                        refFormik.current.resetForm();

                                                    ejecutarBusqueda({ "buscar": "", "cierreContratoProcesoId": null, "page": 1, "rows": 10 });
                                                }}>
                                                    Limpiar filtro
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-end'>
                                        <div className='col-sm-2'>
                                            <RowPages setpageRows={setpageRows} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-12'>
                                            <DataTable
                                                column={columns}
                                                data={dataValue.data}
                                                pageCount={dataValue.totalPages}
                                                currentPage={currentPage - 1}
                                                handlePageClick={handlePageClickH}
                                                infoColumn={true}
                                                handleInfo={handleInfo}
                                                detailable={true}
                                                handleDetail={handleDetailData}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </>
                )}
            </Formik>
            {
                modalBuscar &&
                <ModalBuscarVin
                    isOpen={modalBuscar}
                    setIsOpen={setModalBuscar}
                    cierreContrato={cierreContrato}
                />
            }
            {
                viewInfo &&
                <ModalInfo
                    isOpen={viewInfo}
                    setIsOpen={setViewInfo}
                    elementSelected={elementSelected}
                />
            }
            {
                buscarAvanzado &&
                <ModalBusquedaAvanzada
                    submitBuscar={submitBuscar}
                    isOpen={buscarAvanzado}
                    setIsOpen={setBuscarAvanzado}
                />
            }
        </>
    );
}

export default SeguimientoCierre;
