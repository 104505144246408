import axios from '../../Utils/ApiService';

const SeguimientoGestoriaService = {
    //Stepe
    getAllStepe: (Id, procesoId) => {
        return axios.get(`/functions/GetAllStep?Id=${Id}&ProcesoId=${procesoId}`);
    },
    updStep: (data) => {
        return axios.put(`/functions/UpdStep`, data);
    },
    RegistroTramiteInicialGet: (id) => {
        return axios.get(`/gestoria/RegistroTramiteInicialGet?gestoriaRegistroInicialId=${id}`);
    },
    RegistroTramiteInicialActualizar: (data) => {
        return axios.put(`/gestoria/RegistroTramiteInicialActualizar`, data);
    },
    //Paso 3 
    GetProcesoTramiteById:(id)=>{
        return axios.get(`/gestoria/GetProcesoTramiteById?gestoriaId=${id}`);
    },
    GetProcesoTramiteHistorialById:(id)=>{
        return axios.get(`/gestoria/GetProcesoTramiteHistorialById?gestoriaId=${id}`);
    },
    UpdProcesoTramiteGestoria: (data) => {
        return axios.put(`/gestoria/UpdProcesoTramiteGestoria`, data);
    },
    EstimacionCostosGet: (data) => {
        return axios.get(`/gestoria/EstimacionCostosGet?gestoriaRegistroInicialId=${data.id}&paso=${data.paso}`);
    },
    EstimacionCostosActualizar: (data) => {
        return axios.put(`/gestoria/EstimacionCostosActualizar`, data);
    },
    GetConcluirTramite: (id) => {
        return axios.get(`/gestoria/ConcluirTramite?gestoriaRegistroInicialId=${id}`);
    },
    ActualizarConcluirTramite: (data) => {
        return axios.put(`/gestoria/ConcluirTramite`, data);
    },
}

export default SeguimientoGestoriaService