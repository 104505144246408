import { useEffect, useState } from "react";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";


const DetalleExpedienteHook = (state) => {

    const [data, setData] = useState([])
    const [actualizarCardTramite, setactualizarCardTramite] = useState(0)

    const triggerUpdate = () => {
        setactualizarCardTramite(actualizarCardTramite => actualizarCardTramite + 1)
    }

    const getInfo = async () => {
        await ValuacionesServices.getInfoVehiculo(state.generalId, state.vin)
            .then(resp => {
                state.marca = resp.data.nombreMarca
                state.modelo = resp.data.nombreModelo
                state.version = resp.data.nombreVersion
                state.year = resp.data.nombreYear

                // setData(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    return {
        getInfo, actualizarCardTramite, triggerUpdate
    }

}

export default DetalleExpedienteHook;