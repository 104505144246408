import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import Siniestros from '../../Services/Siniestro/Siniestro';
import Catalogo from '../../Services/Catalogos/Catalogo';
import ValuacionesServices from '../../Services/Inventario/ValuacionesService';
import { setUrlSlice } from '../../Redux/Slice/IncidenciasSlice/urlSlice';
import { useDispatch } from 'react-redux';
import { clearScheduleByTipoCalendario } from '../../Redux/Slice/Calendario/calendarDynamicalSlice';

const useSeguimientosiniestro = (toast) => {
    const navigate = useNavigate();
    const selectRef = useRef();
    const selectRefStatus = useRef({
        select1: "",
        select2: "",
    });
    const dispatch = useDispatch();
    const location = useLocation();

    const columns = [
        { field: 'folio', headerName: 'Folio siniestro Dataware' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'año', headerName: 'Año' },
        { field: 'siniestroTipo', headerName: 'Tipo siniestro' },
        { field: 'fechaStr', headerName: 'Fecha del reporte' },
        { field: 'tiempoEnProceso', headerName: 'Tiempo en proceso' },
        { field: 'siniestroProceso', headerName: 'Proceso' },
        { field: 'pasoDescripcion', headerName: 'Estatus' },
        { field: 'subestatus', headerName: 'sub Estatus' },
        { field: 'fechaUltimaActualizacion', headerName: 'Ultima Actualización' }
    ];
    let dataCondicionPoliza = [
        { label: "Todos", value: "" },
        { label: "Vigente", value: 1 },
        // { label: "Cancelada", value: 0 },
        { label: "Vencida", value: 0 },
    ];

    let dataEstatus = [
        { label: "Todos", value: "" },
        { label: "Completa", value: 1 },
        { label: "Cotización", value: 2 },
        { label: "Cotizada", value: 3 },
        { label: "Emitida", value: 4 },
        { label: "Pagada", value: 5 },
        { label: "Pendiente de comisión", value: 6 },
        { label: "Pendiente de pago", value: 7 },
        { lbel: "Por Renovar", value: 8 }
    ];
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [loading, setLoading] = useState(false);
    //Filtro
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const [dataToShow, setDataToShow] = useState([]);
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);
    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    const [filtroStatus, setFiltroStatus] = useState([]);
    const [dataFiltro, setDataFiltro] = useState({
        page: 1,
        rows: 10,
        buscar: "",
        tipoSiniestroId: "",
        estatus: "",
        //Filtor vanzado
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });
    useEffect(() => {
        if(dataFiltro.buscar!='' 
            || dataFiltro.tipoSiniestroId!=''  
            || dataFiltro.estatus!=''
            || dataFiltro.strAvanzado!=''
            || dataFiltro.fechaInicio!=''
            || dataFiltro.fechaFin!=''
            || dataFiltro.concluido!=false
            || dataFiltro.busquedaAvanzada!=false
        ){
            setCurrenPage(1);
        }
        seguimientoSiniestros();

    }, [dataFiltro,currenPage]); // Se ejecuta SOLO cuando cambia dataFiltro
    useEffect(() => {
        getSiniestroTipo();
        getListadoEstatus();
    }, [])

    const seguimientoSiniestros = async () => {
        setLoading(true);
         await Siniestros.getSeguimientoSiniestro({...dataFiltro, page: currenPage})
            .then(res => {
                setDataToShow(res.data.data || []);
                setPageCount(res.data.totalPages);
            })
            .catch(err => {
                console.error("Error en la solicitud:", err);
                toast.error("Error cargando datos");
            })
            .finally(() => setLoading(false));
    };
    
    
    const getSiniestroTipo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroTipo')
            .then(resp => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.map(item => {
                    NewData.push({ value: item.SiniestroTipoId, label: item.SiniestroTipo });
                });
                setDataTipoSiniestro(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    };
    const getListadoEstatus = async () => {
        await ValuacionesServices.getEstatus(2)
            .then(resp => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.map(item => {
                    NewData.push({
                        value: item.valuacionStatusId,
                        label: item.estatus
                    });
                });
                setFiltroStatus(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        setCurrenPage(newPage);
    };
    
    
    
    const handleDetail = (value) => {
        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 14 }));
        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 13 }));
        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 4 }));
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));

        console.log(value)
        navigate(`/seguro/siniestros/${value.siniestroId}/${value.paso}`, {
            state: value
        });
    }

    return {
        columns,
        handlePageClick,
        currenPage,
        pageCount,
        loading,
        selectRef,
        handleDetail,
        dataToShow,
        setOpenModalAvanzado,
        openModalAvanzado,
        setDataFiltro,
        dataFiltro,
        dataTipoSiniestro,
        filtroStatus,
        setModalMessage,
        modalMessage,
        seguimientoSiniestros,
        selectRefStatus
    }
}

export default useSeguimientosiniestro