import React, {useEffect, useRef, useState} from 'react'
import { Form, Formik, Field } from 'formik';
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { useParams } from 'react-router-dom'
import * as Yup from "yup";
import {TextField} from "../../../../Components/TextField";

const ModalBusquedaAvanzada = ({ isOpen, setIsOpen, submitBuscar }) => {
    const refFormik = useRef();

    let initial = {
        buscar : "",
        fechaInicio : "",
        fechaFin:"",
        concluido : "",
        page: 1,
        rows: 10
    }
    const [loading, setLoading] = useState(false);
    const [initialValue,setInitialValue] = useState(initial);

    const validate = Yup.object({ });
    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-end'>
                    <button
                        className='btn btn-outline d-flex justify-content-center align-items-center'
                        style={{ width: '23px', height: '23px' }}
                        onClick={() => { setIsOpen(false) }}>
                        <i className="ri-close-fill"></i>
                    </button>
                </section>
                <section className='d-flex justify-content-between mx-4'>
                    <span className=''>
                        <strong>Búsqueda Avanzada</strong>
                    </span>
                </section>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        validationSchema={validate}
                        initialValues={initialValue}
                        onSubmit={(values) => {
                            submitBuscar({ ...values });
                            setIsOpen(false);
                        }}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <Form>
                                <div className='row mt-2 mb-2'>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <TextField
                                            label=""
                                            name="buscar"
                                            type="text"
                                            holder="Buscar"
                                            onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }}/>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <TextField
                                            label="Fecha de inicio"
                                            name="fechaInicio"
                                            type="date"
                                            holder=""
                                            onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }}/>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <TextField
                                            label="Fecha de fin"
                                            name="fechaFin"
                                            type="date"
                                            holder="Buscar"
                                            onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }}/>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <Field className="form-check-input"
                                               id="concluido"
                                               type="checkbox" name="concluido"
                                               onClick={(event) => {
                                                   setFieldValue("concluido", event.target.value);
                                               }}/>
                                        <label className="form-check-label"
                                               style={{'marginLeft': '10px'}}>Concluido</label>
                                    </div>
                                </div>
                                <div className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => {
                                        setIsOpen(false)
                                    }}>Cancelar
                                    </button>
                                    &nbsp;&nbsp;
                                    <button id="AT_btnGuardar" className='btn' type='submit'> Confirmar</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""}/></Modal>
        </>
    )
}

export default ModalBusquedaAvanzada