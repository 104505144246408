import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import UnidadesRecuperadasService from '../../Services/Cartera/UnidadesRecuperadas';
import { FormatFecha, FormatNumberMoney, formatearMontoMx } from '../../Utils/functions'
import { clearScheduleByTipoCalendario } from '../../Redux/Slice/Calendario/calendarDynamicalSlice';

const useUnidadesRecuperadas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'resultado', headerName: 'Tipo de recuperación', width: 200 },
        { field: 'montoVencidoTexto', headerName: 'Monto vencido', width: 150 },
        { field: 'montoRecuperadoTexto', headerName: 'Monto recuperado', width: 200 },
        { field: 'fechaVencimiento', headerName: 'Fecha vencimiento', width: 200 },
        { field: 'vigencia', headerName: 'Vigencia', width: 150 },
        { field: 'promesa_status', headerName: 'Estatus', width: 150 }
    ];

    const tipoRecuperacionSelect = [
        // { value: 1, label: "No dan acceso" },
        // { value: 2, label: "Carta visita" },
        // { value: 3, label: "Carta jurídica" },
        // { value: 4, label: "Cita despacho externo", },
        // { value: 5, label: "Promesa de pago" },
        { value: "todos", label: "Todos", },
        { value: "Promesa de auto garantía", label: "Promesa de auto garantía", },
        { value: "Promesa de auto definitiva", label: "Promesa de auto definitiva", }, //ESTATUS en valuacion  MONTO RECUPERADO -, MONTO VENCIMIENTO - VIFENCIA -
    ]

    //Cambiar el select de estatus. 
    // Vigencia es de dia actual a promesa.fecha_compromiso


    const estatusSelect = [
        { value: "todos", label: "Todos" },
        { value: "Vigente", label: "Vigente", },
        { value: "Por Vencer", label: "Por Vencer", },
        { value: "Cumplida", label: "Cumplida", },
        { value: "Rota", label: "Rota", },
        { value: "En Valuación", label: "En Valuación" },
        { value: "En proceso de venta", label: "En proceso de venta" },
        { value: "Pago de unidad", label: "Pago de unidad" },
        { value: "Proceso de Entrega", label: "En proceso de entrega", },

    ]
    const [pageRows, setpageRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [tipoRecuperacion, setTipoRecuperacion] = useState("todos")
    const [estatus, setEstatus] = useState("todos")

    //Filtro
    const [data, setData] = useState([{}]);
    const [currentData, setCurrentData] = useState([]);
    const [textoBusqueda, setTextoBusqueda] = useState("");


    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);

    //Filtro Avanzado
    const [dataFiltro, setDataFiltro] = useState({
        //Filtor vanzado
        estatus: "",
        tipoRecuperacion: "",
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    useEffect(() => {
        handleGetAll();
    }, [])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(item => {
            let fechaFiltrada = true;

            if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {
                const FechaVencimiento = new Date(dataFiltro.fechaFin);
                const FechaInicio = new Date(dataFiltro.fechaInicio);
                const fechaItem = new Date(item.ruta_item.promesa.fecha_vencimiento);
                fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento;
            }

            let concluido = true
            if (dataFiltro.concluido) {
                concluido = item.estatus === "En proceso de venta"
            }

            const nameMatches = textoBusqueda === '' || (item.vin && item.vin.toLowerCase().includes(textoBusqueda.toLowerCase()));
            const contratoMatches = textoBusqueda === '' || (item.contrato && item.contrato.toLowerCase().includes(textoBusqueda.toLowerCase()));

            return (
                (nameMatches || contratoMatches) &&
                (tipoRecuperacion === "todos" || item.resultado === tipoRecuperacion) &&
                (estatus === "todos" || item.promesa_status === estatus) &&
                (!dataFiltro.busquedaAvanzada || fechaFiltrada) &&
                concluido
            );
        });

        const indexOfLastItem = (currentPage + 1) * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)

        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
    }, [currentPage, textoBusqueda, tipoRecuperacion, estatus, pageRows, data, dataFiltro])

    useEffect(() => {
        setCurrentPage(0)
    }, [pageRows, textoBusqueda, tipoRecuperacion, estatus])

    useEffect(() => {
        setTextoBusqueda(dataFiltro.strAvanzado)
        setOpenModalAvanzado(false)
    }, [dataFiltro])

    const handleGetAll = async () => {
        setLoading(true); // Activar indicador de carga

        try {

            const res = await UnidadesRecuperadasService.getAllVisitaDom();
            const filteredData = res.data.responseData.filter(
                item => item.ruta_item !== null && item.ruta_item.contrato && item.ruta_item.promesa
            );

            const contratosWithPagos = await Promise.all(
                filteredData.map(async (item) => {
                    const contratoId = item.ruta_item.contrato.contrato_id;

                    // Construcción de los objetos para las llamadas
                    const datacontrato = {
                        field: "contrato",
                        value: contratoId,
                    };
                    const datacontrato2 = {
                        contrato_id: contratoId,
                    };

                    try {
                        // Llamada al endpoint para obtener pagos
                        const pagosResponse = await UnidadesRecuperadasService.getPagosByContrato(datacontrato);
                        const pagos = pagosResponse.data.responseData;

                        const montoRecuperado = pagos.reduce((total, pago) => {
                            const monto = parseFloat(pago.monto_pagado);
                            return total + (isNaN(monto) ? 0 : monto);
                        }, 0);

                        // Llamada al endpoint para obtener estatus
                        const estatusResponse = await UnidadesRecuperadasService.getValidaUnidadRecuperada(datacontrato2);
                        const estatus = estatusResponse.data?.estatus || "Sin estatus";
                        return {
                            ...item,
                            montoRecuperado: montoRecuperado > 0 ? montoRecuperado : 0,
                            contratoStatus: estatus,
                        };
                    } catch (error) {
                        console.error("Error al obtener datos para el contrato:", contratoId, error);
                        return {
                            ...item,
                            montoRecuperado: "$0.00",
                            contratoStatus: "Error al obtener estatus",
                        };
                    }
                })
            );

            const resultadosFiltrados = contratosWithPagos.filter(item =>
                item.resultado === "Promesa de auto definitiva" || item.resultado === "Promesa de auto garantía"
            );

            const response = resultadosFiltrados.map((item) => {
                let estatus = item.contratoStatus;
                let montoVencido;

                if (item.resultado === "Promesa de auto definitiva") {
                    montoVencido = item.ruta_item.promesa.monto_vencido
                }
                if (item.resultado === "Promesa de auto garantía") {
                    // montoVencido = formatearMontoMx(calcularTotal(item.ruta_item.promesa, item.ruta_item.contrato))
                    montoVencido = item.ruta_item.promesa.total
                    // estatus = item.contratoStatus;
                }

                const today = new Date();
                let colorFondo;
                let colorTexto;

                switch (estatus) {
                    case "Cumplida":
                        colorFondo = "#D8F2CD";
                        colorTexto = "#47A066";
                        break;
                    case "Rota":
                        colorFondo = "#F5CECE";
                        colorTexto = "#CE2222";
                        break;
                    case "En Valuación":
                        colorFondo = "#E8EAF6";
                        colorTexto = "#1A3D7E";
                        break;
                    case "Por Vencer":
                        colorFondo = "#FFE5CB";
                        colorTexto = "#D57200";
                        break;
                    case "En proceso de venta":
                        colorFondo = "#C4B7DD";
                        colorTexto = "#000000";
                        break;
                    case "Pago de unidad":
                        colorFondo = "#C8EABE";
                        colorTexto = "#000000";
                        break;
                    case "En proceso de entrega":
                        colorFondo = "#A2C4C9";
                        colorTexto = "#000000";
                        break;
                    case "Vigente":
                        const fechaPromesa = new Date(item.ruta_item.promesa.fecha_vencimiento);

                        if (fechaPromesa < today) {
                            estatus = "Rota";
                            colorFondo = "#F5CECE";
                            colorTexto = "#CE2222";
                        } else {
                            colorFondo = "#FAE7BC";
                            colorTexto = "#B46B16";
                        }
                        break;
                    default:
                        colorFondo = "#B5B8C8";
                        colorTexto = "#000000";
                        break;
                }

                // const ayer = new Date()
                // ayer.setDate(ayer.getDate() - 1)
                let fechaVencimiento = "-";
                let vigenciaDias = "-";

                if (item.ruta_item.promesa?.fecha_vencimiento) {
                    fechaVencimiento = FormatFecha(item.ruta_item.promesa.fecha_vencimiento);
                    vigenciaDias = item.ruta_item.promesa.dias_de_promesa
                    //Vigencia calculada
                    // const fechaVencimientoDay = new Date(item.ruta_item.promesa.fecha_vencimiento);
                    // const diferenciaDias = fechaVencimientoDay - ayer;
                    // vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24);
                    // vigenciaDias = vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0
                }

                return {
                    ...item,
                    vin: item.ruta_item.contrato.vin,
                    contrato: item.ruta_item.contrato.contrato_id,
                    montoVencido: montoVencido,
                    montoVencidoTexto: formatearMontoMx(montoVencido),
                    montoRecuperado: item.montoRecuperado || "-",
                    montoRecuperadoTexto: formatearMontoMx(item.montoRecuperado),
                    fechaVencimiento: fechaVencimiento,
                    vigencia: vigenciaDias,
                    promesa_status: estatus,
                    colorFondo: colorFondo,
                    colorTexto: colorTexto,
                    sol_id: item.ruta_item.contrato.sol_id,
                };
            });

            setData(response);
        } catch (error) {
            console.error("Error al obtener datos:", error);
        }

        setLoading(false);

    };


    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const handleDetails = (value) => {
        //Movimiento a Detalles del Job
        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 13 }));
        navigate(`/cobranza/unidades-recuperadas/detalle`, {
            state: value
        })
    }

    const calcularTotal = (promesa, contrato) => { // USAR ESTA FUNCION.!!!
        let frecuencia = 7;
        if (contrato?.frecuencia_pago?.toLowerCase() === "quincenal") {
            frecuencia = 15;
        } else if (contrato?.frecuencia_pago?.toLowerCase() === "mensual") {
            frecuencia = 30;
        }
        let dias_promesa = getDiasPromesa(promesa.fecha_vencimiento, promesa);
        let result = dias_promesa / frecuencia;

        let pago_periodo = (contrato?.pago_periodo !== undefined && contrato?.pago_periodo !== null) ? contrato?.pago_periodo : 0
        let monto_adeudo = (contrato?.monto_adeudo !== undefined && contrato?.monto_adeudo !== null) ? contrato?.monto_adeudo : 0

        if (result <= 0) {
            result = 1
        }

        let res = Number((result * pago_periodo + monto_adeudo).toFixed(2))
        //es number no acepta la cadena formateada tipo money
        // setTotalNumerico(res)
        return res;
        // return res;
    }

    const getDiasPromesa = (fecha, promesa) => {
        let dias = 0
        if (promesa !== null && promesa?.fecha_inicio !== null) {
            let fechaInicio = new Date(promesa?.fecha_inicio)
            dias = Math.ceil((new Date(fecha) - fechaInicio) / (1000 * 60 * 60 * 24));
        }
        else {
            dias = Math.ceil((new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24));
        }

        return isNaN(dias) ? 0 : dias
    }

    return {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        zonas,
        setpageRows,
        tipoRecuperacionSelect,
        setTipoRecuperacion,
        estatusSelect,
        setEstatus,
        dataFiltro,
        setDataFiltro,
        openModalAvanzado,
        setOpenModalAvanzado,
        textoBusqueda,
    }
}

export default useUnidadesRecuperadas