import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import UnidadesRecuperadasService from '../../Services/Cartera/UnidadesRecuperadas';
import Catalogo from '../../Services/Catalogos/Catalogo';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { getDateFormat } from '../../Utils/functions'

const CitaEnregaUnidadHook = () => {
    const navigate = useNavigate();

    const columns = [
        { field: 'id_cita', headerName: 'Folio Cita', width: 150 },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'anio', headerName: 'Año' },
        { field: 'names', headerName: 'Cobrador' },
        { field: 'fechacitaFormat', headerName: 'Fecha y hora de cita' },
        { field: 'ubicacion', headerName: 'Tipo de ubicación' },
        { field: 'sucursal', headerName: 'Sucursal' }
    ];

    const [pageRows, setpageRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);

    const [sucursal, setSucursal] = useState("")
    const [sucursales, setSucursales] = useState([])

    const [cobrador, setCobrador] = useState("")
    const [cobradores, setCobradores] = useState([]);

    
    const [ubicacion, setUbicacion] = useState("")
    const [ubicaciones, setUbicaciones] = useState([{ label: "Datamovil", value: "Datamovil" }, { label: "Externo", value: "Externo" }]);

    //Filtro
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [textoBusqueda, setTextoBusqueda] = useState("")

    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    const [modalValue, setModalValue] = useState()

    useEffect(() => {
        handleGetAll();
        handleGetSucursal()
        // handleGetCobradores()
    }, [])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(item => {
            const nameMatches = textoBusqueda === '' || (item.vin && item.vin.toLowerCase().includes(textoBusqueda.toLowerCase()));
            const contratoMatches = textoBusqueda === '' || (item.contrato && item.contrato.toLowerCase().includes(textoBusqueda.toLowerCase()));
            const folioMatches = textoBusqueda === '' || (item.id_cita && item.id_cita.toLowerCase().includes(textoBusqueda.toLowerCase()));

            return (
                (nameMatches || contratoMatches || folioMatches) &&
                (ubicacion === 'Tipo de ubicación' || ubicacion === '' || item.ubicacion === ubicacion) &&
                (sucursal === 'Sucursal' || sucursal === '' || item.sucursal === sucursal) &&
                (cobrador === '' || item.cobradorid === cobrador)
            )
        })

        const indexOfLastItem = (currentPage + 1) * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)

        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
    }, [currentPage, textoBusqueda, ubicacion,sucursal, cobrador, pageRows, data])

    useEffect(() => {
        setCurrentPage(0)
    }, [pageRows, textoBusqueda, sucursal, cobrador])


    const handleGetAll = async () => {
        setLoading(true);

        await UnidadesRecuperadasService.getAllCitaEntregaUnidadxVehiculo()
            .then(res => {
                const filteredData =  res.data.responseData.filter(item => item.estatus === 1);
                const response = filteredData.map((item) => {

                    const fechaFormat = getDateFormat(item.fechacita, "")
                    const names = item.names.names
                    const cobradorid = item.names.userId
                    return {
                        ...item,
                        fechacitaFormat: fechaFormat,
                        names: names,
                        cobradorid: cobradorid
                    }
                })

                const uniqueNames = [
                    ...new Map(
                        response.map(item => [item.names, { value: item.cobradorid, label: item.names }])
                    ).values()
                  ];

                setCobradores(uniqueNames);
                setData(response)
            })
        setLoading(false);
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const handleDetails = (value) => {
        setModalValue(value)
        setOpenModalAvanzado(true)
        // //Movimiento a Detalles del Job
        // navigate(`/cobranza/unidades-recuperadas/detalle`, {
        //     state: value
        // })
    }

    const handleGetSucursal = async () => {
        await Catalogo.getAllUbicacion(1, 100)
            .then(resp => {
                let listSucursales = [];
                resp.data?.data.forEach(item => {
                    listSucursales.push({
                        value: item.ubicacionId,
                        label: item.nombre
                    });
                });
                setSucursales(listSucursales);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const handleGetCobradores = async () => {
        let NewData = [];
        //Se usa el endpoint solo para pruebas, se debe cambiar por el correspondiente
        await Cobranza.getAllUsersNotInCobranza()
            .then(res => {
                if (res.data.callSucceded) {
                    res.data.responseData?.map(item => {
                        NewData.push({ value: item.userId, label: item.names })
                    })
                }
            })
            .catch(e => {
                console.log(`ERROR -- ${e}`)
            })
        setCobradores(NewData);
    };

    return {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        setpageRows,
        sucursales,
        setSucursal,
        cobradores,
        setCobrador,
        ubicaciones,
        setUbicacion,
        openModalAvanzado,
        setOpenModalAvanzado,
        modalValue,
        textoBusqueda,
        handleGetAll,
    }
}

export default CitaEnregaUnidadHook