import React from "react";
import SeguimientoStepper from "../../PasosSeguimiento/Componentes/SeguimientoStepper";
import useProcesoTramite from "../../../../Hooks/Gestoria/seguimiento/useProcesoTramite";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "../../../Seguro/Siniestros/ContratoHeaderCard";
import SeguimientoProcesoDatamovil from "../../PasosSeguimiento/Componentes/SeguimientoProcesoDatamovil";
import ModalConfirm from "../../PasosSeguimiento/Componentes/ModalConfirm";
import GestoriaPreparacionPapeleria from "./GestoriaPreparacionPapeleria";
import { toast, Toaster } from "react-hot-toast";
import ContratoCard from "../../../../Components/ContratoCard";

const GestoriaConcluirTramite = () => {
  let {
    stepe,
    handleClickTerminar,
    activebtncontinuar,
    loading,
    handleChangeStatusBtn,
    modalConfirm,
    setModalConfirm,
    handleClickTerminarProceso,
    state,
    reload,
  } = useProcesoTramite(toast);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      <SeguimientoStepper
        dataStep={stepe}
        handleClickContinuar={handleClickTerminar}
        activeBtn={activebtncontinuar}
      />
      <div
        className="d-flex flex-column background-grey"
        style={{ height: "100vh" }}
      >
        <div className="row mx-md-4 my-4">
          <div className="col-6 px-md-3">
            <VehiculoInfoCard
              VIN={state?.vin}
              verClaves={true}
              dataCalendar={true}
              calendarioType={10}
            />
          </div>
          <div className="col-6 px-md-3">
            <ContratoCard vin={state?.vin} />
          </div>
        </div>
        <SeguimientoProcesoDatamovil />
        <GestoriaPreparacionPapeleria handleConcluido={handleChangeStatusBtn} />
      </div>
      {modalConfirm && (
        <ModalConfirm
          isOpen={modalConfirm}
          setIsOpen={setModalConfirm}
          title={"Seguimiento Gestoria"}
          handleConfirm={handleClickTerminarProceso}
        />
      )}
    </>
  );
};
export default GestoriaConcluirTramite;
