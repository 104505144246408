import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoGestoriaService from '../../../Services/Gestoria/SeguimientoGestoria';
import { message } from '../../../Utils/functions';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const useInicioTramite = ({ toast, handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial = {
        gestoriaRegistroInicialId: "",
        tipoTramiteId: "",
        tipoTramite: "",
        personaSolicitaTramite: null,
        telefonoFijo: null,
        telefonoMovil: "",
        telefonoTrabajo: null,
        concluido: null,
        fechaInicio: "",
        fechaFin: null,
        siniestroId: null,
        proceso: null
    }


    const DepartamentosList = [
        { value: 1, label: "Seguro" },
        { value: 2, label: "Gestoría" },
        { value: 3, label: "Cobranza" },
        { value: 4, label: "Proceso de Cierre" },
        { value: 5, label: "Garantía" }
    ];

    const [loading, setloading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [tipoTramites, setTipoTramites] = useState([]);
    const [departamentos, setDepartamentos] = useState(DepartamentosList);

    useEffect(() => {
        getData();
        getTipoTramite();
    }, []);

    const getData = async () => {
        setloading(true)
        await SeguimientoGestoriaService.RegistroTramiteInicialGet(id)
            .then(resp => {
                let resData = resp.data
                resData.concluido = resData.concluido ?? false;
                resData.proceso = resData.siniestroId ? 'Siniestro' : ''
                resData.departamentoId = resData.departamentoId ? parseInt(resData.departamentoId) : null
                setInitialValue({ ...initialValue, ...resData });
                handleConcluido({ concluido: resp.data.concluido })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setloading(false)
            })
    };

    const getTipoTramite = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.TipoTramiteId,
                        label: item.TipoTramite
                    })
                });

                setTipoTramites(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const submitForm = async (values) => {
        values.procesoId = state.procesoId
        values.paso = 1
        values.porcentaje = 100
        values.status = 2

        setloading(true)
        await SeguimientoGestoriaService.RegistroTramiteInicialActualizar(values)
            .then(resp => {
                toast.success(message("Se Actualizó el Registro Inicial correctamente"));
                getData()
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar Actualizar el Registro Inicial!"));
            })
            .finally(() => {
                setloading(false);
            });
    }

    return {
        loading,
        tipoTramites,
        initialValue,
        submitForm,
        departamentos
    };
};

export default useInicioTramite;
