import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import { message } from '../../../../Utils/functions';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ModalMessage from '../../../../Components/Message/ModalMessage';
import { TextField } from '../../../../Components/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { selectScheduleByTipoCalendario, setFields } from '../../../../Redux/Slice/Calendario/calendarDynamicalSlice';

const SiniestroCitaAcreditacionRoboCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scheduleDataCita = useSelector((state) => selectScheduleByTipoCalendario(state, 14));
    console.log("cita acreditacion:", scheduleDataCita)
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const { refFormik } = useRef();
    // let { state } = props;

    let initial = {
        siniestroId: id,
        seguroSeguimientoSiniestroCitaAcreditacionId: "",
        citaAgendada: false,
        clienteLlegoCita: "",
        ubicacionId: "",
        nombreUbicacion: "",
        folioAseguradora: "",
        bloqueoId: "",
        fechaHoraCita: "",

        estatusCita: "",
        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        codEstatus: "",
        fechaCita: "",
        horaCita: "",
        //Cita
        calendarioId: "",
        day: "",
        hour: "",
        sucursalId: "",
        sucursalName: "",
        //Stepper Update        
        paso: 2,
        porcentaje: 50,
        status: 2,
        procesoId: state?.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleGetAllCitaAcreditacionById();
    }, []);

    const getDataFromCalendar = () => {
        const date = scheduleDataCita?.day.split("-").reverse().join("-");
        const hour = new Date(`1970-01-01T${scheduleDataCita?.hour.replace(/\s?(AM|PM)/, '')}:00`); // La fecha no importa, solo es para formatear la hora
        const opciones = { hour: 'numeric', minute: 'numeric', hour12: true };
        return `${date} ${hour.toLocaleString('en-US', opciones)}`;
    }

    const getMeridianFormat = () => {
        if (scheduleDataCita?.hour === undefined) return false;
        const hour = new Date(`1970-01-01T${scheduleDataCita?.hour.replace(/\s?(AM|PM)/, '')}:00`); // La fecha no importa, solo es para formatear la hora
        const opciones = { hour: 'numeric', minute: 'numeric', hour12: true };
        return `${hour.toLocaleString('en-US', opciones)}`;
    }

    /* CONSULTAS */
    const handleGetAllCitaAcreditacionById = async () => {
        setLoading(true);
        await Siniestros.GetCitaAcreditacionById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: id,
                    seguroSeguimientoSiniestroCitaAcreditacionId: item?.seguroSeguimientoSiniestroCitaAcreditacionId ?? "",
                    citaAgendada: item?.citaAgendada ?? false,
                    clienteLlegoCita: item?.clienteLlegoCita ?? "",
                    ubicacionId: item?.ubicacionId ?? "",
                    nombreUbicacion: item?.nombreUbicacion ?? scheduleDataCita?.sucursalName,
                    folioAseguradora: item?.folioAseguradora ?? "",
                    bloqueoId: item?.bloqueoId ?? "",
                    fechaHoraCita: item?.fechaHoraCita ?? getDataFromCalendar(),
                    estatusCita: item?.estatus ?? "",
                    concluido: item?.concluido ?? false,
                    color: "default",
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    codEstatus: item?.codEstatus ?? "",
                    fechaCita: item?.fechaCita ?? "",
                    horaCita: item?.horaCita ?? "",
                    // AgendarCita
                    calendarioId: scheduleDataCita?.calendarioId ?? "",
                    day: scheduleDataCita?.day ?? "",
                    hour: scheduleDataCita?.hour ?? "",
                    sucursalId: scheduleDataCita?.sucursalId ?? "",
                    sucursalName: scheduleDataCita?.sucursalName ?? "",
                    //Stepper Update        
                    paso: 2,
                    porcentaje: 50,
                    status: 2,
                    procesoId: state?.procesoId,
                    concluido: state?.concluido ? true : (item?.concluido ?? false),
                }
                setInitialValue(newData);

                handleConcluido({ concluido: false, codStatus: item?.codEstatus });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const submitForm = async (values) => {
        if (!scheduleDataCita.calendarioId) {
            toast.error(message("¡Error!", "Es necesario agendar una cita en el calendario."));
            return
        }
        values.fechaHoraCita = scheduleDataCita?.day;
        values.hour = getMeridianFormat();
        setLoading(true);
        await Siniestros.UpdateCitaAcreditacion(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllCitaAcreditacionById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar agendar cita, horario ya ocupado!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        //Detalle de contacto
        // clienteLlegoCita: Yup.string()
        //     .required("El campo es requerido.")
    });
    const verCalendario = async () => {
        // console.log(state.año)
        // return;
        let calendario = {
            tipoCalendario: 14,
            fields: {
                contrato: state?.contrato,
                vin: state?.vin,
                modelo: state?.modelo,
                ano: state?.año,
                marca: state?.marca,
                year: state?.año
            }
        }
        dispatch(setFields(calendario));
        navigate(`/seguro/siniestros/Citas/v2/${14}`, {
            state: {
                title: 'Cita Acreditación',
                tipoCalendario: 14,
                ...state,
                year: state.año

            }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                modalMessage &&
                <ModalMessage
                    setIsOpen={setModalMessage}
                    isOpen={modalMessage}
                />
            }
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseCitaAcreditacion" role="button" aria-expanded="false" aria-controls="collapseCitaAcreditacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Cita de acreditación</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseCitaAcreditacion">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        let newData = {
                                            siniestroId: values.siniestroId,
                                            seguroSeguimientoSiniestroCitaAcreditacionId: values?.seguroSeguimientoSiniestroCitaAcreditacionId ?? "",
                                            concluido: values?.citaAgendada ?? false,
                                            citaAgendada: values.citaAgendada,
                                            nombreUbicacion: values?.nombreUbicacion ?? "",
                                            contrato: state?.contrato ?? "",
                                            fechaCita: values?.fechaCita ?? "",
                                            horaCita: values?.horaCita ?? "",
                                            bloqueoId: values?.bloqueoId,//"9622DDB1-FC01-4F3B-83F9-04D33FDA22B6",//values?.bloqueoId, // 
                                            // AgendarCita
                                            calendarioId: scheduleDataCita?.calendarioId ?? "",
                                            day: scheduleDataCita?.day ?? "",
                                            hour: scheduleDataCita?.hour ?? "",
                                            sucursalId: scheduleDataCita?.sucursalId ?? "",
                                            sucursalName: scheduleDataCita?.sucursalName ?? "",
                                            //Stepper Update        
                                            paso: 2,
                                            porcentaje: 50,
                                            status: 2,
                                            procesoId: state?.procesoId
                                        };
                                        submitForm(newData);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">

                                                    <div className="col-3">
                                                        <small><strong>Indicar ubicación</strong></small>
                                                    </div>

                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {/* <fieldset className='btn d-flex align-items-center mx-2 col-auto' onClick={() => { setModalMessage(true) }}><i className="bx bxs-message-alt me-1" ></i>Comentarios</fieldset> */}
                                                            {/* <div className="col-auto">
                                                                <fieldset
                                                                    disabled={initialValue?.concluido}
                                                                    onClick={handleUploadFile}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div> */}
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !values.citaAgendada}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto d-flex align-items-center">
                                                        <TextField disabled={true} label="Fecha y hora de cita" holder="Escribe" name="fechaHoraCita" type="text" />
                                                        <fieldset disabled={initialValue?.concluido} onClick={() => { verCalendario() }} className='btn d-flex align-items-center mx-2 mt-3'>Ver calendario</fieldset>
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={true} label="Ubicación" holder="Escribe" name="nombreUbicacion" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={true} label="Estatus cita" holder="Escribe" name="estatusCita" type="text" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="citaAgendada" onClick={(event) => {
                                                            setFieldValue("dictamenRegistrado", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cita agendada</label>
                                                    </div>
                                                    <label className='d-flex align-items-center' style={{ fontSize: '13px', color: 'red' }}><i className='icon-red ri-error-warning-fill' style={{ fontSize: "15px" }}></i> Es importante concluir la cita agendada para inicar el proceso de autorización.</label>
                                                </div>
                                                <div className="row mt-2 px-4 d-flex justify-content-end">

                                                    <div className="col-12 col-lg-6">
                                                        <div className="row flex-column align-items-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaInicio !== "" &&
                                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SiniestroCitaAcreditacionRoboCard