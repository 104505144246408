import axios from '../../Utils/ApiService';
import { IsNullOrEmpty } from '../../Utils/functions';

const ServicioExpedientes = {
    getSeguimientoExpedientes: ({ buscar, page, rows, fechaInicio, fechaFin, status , concluido }) => {
        let query = '';
        if(buscar?.trim().length > 0) query += `buscar=${buscar}&`
        if (!IsNullOrEmpty(fechaInicio)) query += '&fechaInicio=' + fechaInicio + '&';
        if (!IsNullOrEmpty(fechaFin)) query += '&fechaFin=' + fechaFin + '&';
        if (!IsNullOrEmpty(status)) query += '&status=' + status + '&';
        if (!IsNullOrEmpty(concluido)) query += '&concluido=' + concluido + '&';
        query += `page=${page}&rows=${rows}`;

        return axios.get(`/cartera/SeguimientoExpedientes?${query}`);
    },
    getDetalleExpediente: (id) => {
        return axios.get(`/cartera/Expediente?CierreContratoExpedienteId=${id}`);
    },
    ActualizarEstatusCierreExpediente: (data) => {
        return axios.post(`/cartera/ActualizarEstatusCierreContrato`, data);
    },
    ActualizarEstatusCierreContratoByOperacion: (data) => {
        return axios.post(`/cartera/ActualizarEstatusCierreContratoByOperacion`, data);
    },
    ampliarExpediente: (data) => {
        return axios.put(`/cartera/AmpliarExpediente`, data);
    },
    addTramite: (data) => {
        return axios.post(`/cartera/AddTramite`, data);
    },
    getTramite: (id) => {
        return axios.get(`/cartera/GetTramite?GeneralId=${id}`);
    },
    getAllTramites: (id) => {
        return axios.get(`/cartera/GetAllTramites?GeneralId=${id}`);
    },
    addSolicitud: (data) => {
        return axios.post(`/cartera/AddSolicitud`, data);
    },
    getSolicitudes: (id) => {
        return axios.get(`/cartera/GetSolicitudesByTramite?GeneralId=${id}`);
    },
    getAllSolicitudesExpediente: (id) => {
        return axios.get(`/cartera/GetAllSolicitudes?GeneralId=${id}`);
    },
    getAllOperacionesExpediente: (id) => {
        return axios.get(`/cartera/GetAllOperaciones?GeneralId=${id}`);
    },
    getHistorial: (id) => {
        return axios.get(`/cartera/GetHistorialSolicitudes?GeneralId=${id}`);
    }
}

export default ServicioExpedientes;