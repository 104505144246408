import { useEffect, useState } from "react";
import { formatearMontoMx, FormatFecha, message } from "../../Utils/functions";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setItemGeneralProperty } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const useSeguimientoVisitaDetalle = (toast) => {
    const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
    const userId = sessionStorage.getItem('userId').trim();
    // const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([{}]);
    const [userDetail, setUserDetail] = useState({ names: '' });
    const [promesasRotas, setPromesasRotas] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const navigate = useNavigate();
    const [allContracts, setAllContracts] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false);
    // const [cierreVisita, setCierreVisita] = useState(false)
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.loginSlice);

    useEffect(() => {
        // handleGetSeguimientoContrato();
        // GetUserStats();

        handleGetAllData();
    }, [])

    const handleGetAllData = async () => {
        setLoading(true)
        // if (itemGeneral?.visitaDoms !== null && itemGeneral?.visitaDoms !== undefined && itemGeneral?.visitaDoms?.[0] !== null) {
        //     if (itemGeneral?.visitaDoms[0]?.resultado.toLowerCase() === "promesa de pago") {
        //         setPromesasRotas(1)  //Promesa de pago
        //     }
        // }

        const [] = await Promise.all([handleGetSeguimientoContrato(), handleGetPromesasdePago()]).finally(() => {
            setLoading(false)
        });
    }

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 1,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        };
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                setAllContracts(res?.data?.responseData);
                let userContracts;
                if (role === "DMCobranza Nivel 2") {
                    userContracts = res?.data?.responseData?.filter(user => user?.userid?.toLowerCase() === userId?.toLowerCase());
                }
                if (role === "DMSupervisordeCobranza" || role === "Administrador") {
                    userContracts = res?.data?.responseData;
                }

                const contratoEncontrado = userContracts
                    ?.flatMap(item => item.contratos || [])
                    .find(contrato => contrato.contrato_id === contratoActual?.contrato_id);

                const userDetail = contratoEncontrado ? res?.data?.responseData?.find(item => item.userid === contratoEncontrado.userid)?.user_detail : null;
                setUserDetail(userDetail ?? { names: '' });
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e?.response?.data?.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e?.message })
            })
    }

    const handleReturnPage = () => {
        navigate(`/cobranza/seguimiento-visita/seguimiento-visita`);
    }

    const handleCerrarVisita = async (visitaDom = null) => {
        // setCierreVisita(true);
        UpsertCobranzaRutaItem(visitaDom);
        setModalConfirm(false);
    };

    const UpsertCobranzaRutaItem = async (visitaDom = null) => {
        setLoading(true);
        let estatus = (visitaDom != null) ? (visitaDom.resultado != null ? (visitaDom.encontraste_cliente == 1 ? visitaDom.resultado : visitaDom.motivo) : "Concluido") :
            (itemGeneral?.visitaDoms[0].resultado != null ? (itemGeneral?.visitaDoms[0].encontraste_cliente == 1 ? itemGeneral?.visitaDoms[0].resultado : itemGeneral?.visitaDoms[0].motivo) : "Concluido");
        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": false,
            "anyObject": {
                "id": itemGeneral.id,
                "ruta_head_id": itemGeneral.ruta_head_id,
                "evento_item_id": itemGeneral.evento_item_id,
                "evento_head_id": itemGeneral.evento_head_id,
                "fecha": itemGeneral.fecha,
                "tipo_visita": itemGeneral.tipo_visita,
                // "estatus": "Concluido",
                "estatus": estatus,
                "resultado": itemGeneral.resultado,
                "userid": contratoActual?.userid,
                "fecha_inicio": itemGeneral.fecha_inicio != null ? itemGeneral.fecha_inicio : getSqlServerDateTime(),
                "fecha_fin": itemGeneral.fecha_fin != null ? itemGeneral.fecha_fin : getSqlServerDateTime(),
                "cierre_visita": true,
                "dias_vencido": contratoActual?.dias_vencido,
                "monto_vencido": contratoActual?.monto_adeudo,
                "es_aval": itemGeneral?.es_aval
            }
        }

        await CobranzaService.UpsertCobranzaRutaItem(data)
            .then(res => {
                setLoading(false)
                toast.success(message("¡Operación exitosa!", "Visita cerrada"));
                dispatch(setItemGeneralProperty({ key: 'cierre_visita', value: true }));
                handleReturnPage();
                // handleGetSeguimientoContrato();
            }).catch(e => {
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }

    // const GetUserStats = async () => {
    //     let data = {
    //         "verb": "string",
    //         "id": userId,
    //         "field": "string",
    //         "value": "string",
    //         "level": 0,
    //         "flag": true,
    //         "anyObject": {},
    //         "kvp": {}
    //     }
    //     await CobranzaService.GetUserStats(data)
    //         .then(res => {
    //             console.log("promesas", res)
    //             const filteredCount = res?.data?.responseData?.filter(item =>
    //                 item.status === "rota" && item.contrato_id === contratoActual?.contrato_id
    //             ).length;
    //             setPromesasRotas(filteredCount);
    //         }).catch(e => {
    //             setPromesasRotas(0);
    //             if (e.response !== undefined)
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
    //             else
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
    //         })
    // }

    const handleGetPromesasdePago = async () => {
        const dataQuery = {
            contrato_id: contratoActual.contrato_id,
            periodo: contratoActual.periodo
        }
        await CobranzaService.getAllPromesa_de_PagoxPeriodo(dataQuery)
            .then(res => {
                let promesasrotas = 0
                const today = new Date()
                const ayer = new Date()
                ayer.setDate(ayer.getDate() - 1)
                const update = res.data;

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === contratoActual.contrato_id) || [{}]

                if (promesasUpdate) {
                    //acomodar de vieja a mas nueva
                    promesasUpdate.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));

                    for (let i = 0; i < promesasUpdate.length; i++) {
                        const promesa = promesasUpdate[i];
                
                        // Si la promesa está cumplida, salimos del ciclo
                        if (promesa.promesa_status_nombre === 'Cumplida') {
                            break; 
                        }
                
                        // Si la promesa está rota o está vigente pero su fecha es anterior a hoy, sumamos 1
                        if ((promesa.promesa_status_nombre === 'Rota' || promesa.promesa_status_id === "66498f01-9033-4be1-9353-ee0fd4217120") && 
                             new Date(promesa.fecha_promesa) < today) {
                                promesasrotas++;
                        }
                    }
                }

                // if (promesasrotas >= 3) {
                //     actualizaVisitabyContrato(contratoActual.contrato_id)
                // }

                setPromesasRotas(promesasrotas)

                // setCurrentDataPromesas(promesasUpdate)

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }


    const actualizaVisitabyContrato = async (contratoId) => {
        let data = {
            contrato: contratoId
        }
        try {
            const response = await CobranzaService.actualizaVisitabyContrato(data)
            return response.data; // Devuelve los datos de la respuesta
        } catch (e) {
            setLoading(false);
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };


    function getSqlServerDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return {
        // currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        handleReturnPage,
        allContracts,
        handleCerrarVisita,
        modalConfirm,
        setModalConfirm
    }

}

export default useSeguimientoVisitaDetalle;