import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DetalleExpedienteHook from "../../../Hooks/ProcesosCierre/DetalleExpedienteHook";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard";
import ContratoCard from "../../../Components/ContratoCard";
import DetalleSolicitudExpediente from "./DetalleSolicitudExpediente";
import AutorizarSolicitudExpediente from "./AutorizarSolicitud";
import SolicitudTramiteExpediente from "./SolicitudTramite";
import HistorialExpediente from "./HistorialPrestamosExpediente";
import MovimientosdeSolicitudExpediente from "./MovimientosdeSolicitud";
const DetalleExpediente = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [solicitudActive, setSolicitudActive] = useState(false);

    const { getInfo,
        actualizarCardTramite,
        triggerUpdate
    } = DetalleExpedienteHook(state)

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <section className='headerTab'>
                <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                    <div>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de incidencias
                    </div>
                </header>


            </section>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-12 col-lg-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
                    </div>
                    <div className="col-12 col-lg-6 px-md-3">
                        <ContratoCard vin={state?.vin} />
                    </div>
                </div>

                <DetalleSolicitudExpediente
                    actualizarCardTramite={actualizarCardTramite}
                />
                <AutorizarSolicitudExpediente
                    actualizarCardTramite={actualizarCardTramite}
                    setactualizarCardTramite={triggerUpdate}
                    solicitudActive={solicitudActive}
                />
                <MovimientosdeSolicitudExpediente
                    actualizarCardTramite={actualizarCardTramite}
                />
                <SolicitudTramiteExpediente
                    actualizarCardTramite={actualizarCardTramite}
                    setSolicitudActive={setSolicitudActive}
                />
                <HistorialExpediente />



            </div>
        </>
    );
}

export default DetalleExpediente;