import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import { message } from '../../../../Utils/functions';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ModalMessage from '../../../../Components/Message/ModalMessage';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import Garantias from '../../../../Services/Cartera/Garantias';
import documentsHelper from '../../../../Utils/documents'
import { useSelector } from 'react-redux';

const SiniestroAcreditacionRoboCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const { refFormik } = useRef();
    const { clienteSiniestro } = useSelector((state) => state.reloadSlice);    
    // let { state } = props;

    let initial = {
        siniestroId: id,
        clienteLlegoCita: "",
        cuentaFolioAseguradora: "",
        folioAseguradora: "",

        realizadoPor: "",
        folioDenuncia: "",
        fechaDenuncia: "",
        acreditacionComentarios: "",
        acreditacionConcluido: false,

        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 2,
        porcentaje: 100,
        status: 2,
        procesoId: state?.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const dataBoolean = [
        { value: true, label: 'Si' },
        { value: false, label: "No" }
    ]
    const realizadoPor = [
        { value: "Representante lega", label: "Representante legal" },
        { value: "Cliente", label: "Cliente" },
    ]

    useEffect(() => {
        // handleGetAllSelects();
        handleGetAllSiniestroById();
    }, []);


    /* CONSULTAS */
    const handleGetAllSiniestroById = async () => {
        setLoading(true);
        await Siniestros.GetSiniestroById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: id,
                    clienteLlegoCita: item?.clienteLlegoCita ?? false,
                    cuentaFolioAseguradora: item?.cuentaFolioAseguradora ? item?.cuentaFolioAseguradora : "",
                    folioAseguradora: item?.folioAseguradora ?? "",
                    comentarios: item?.cuentaFolioAseguradora ? item?.comentarios ?? "": "",

                    realizadoPor: item?.realizadoPor ?? "",
                    folioDenunciaAcreditacion: item?.folioDenunciaAcreditacion ?? "",
                    fechaDenunciaAcreditacion: (item?.fechaDenunciaAcreditacion !== null || item?.fechaDenunciaAcreditacion !== "") ? item?.fechaDenunciaAcreditacion?.split("T")[0] : "",
                    acreditacionComentarios: item?.acreditacionComentarios ?? "",
                    acreditacionConcluido: item?.acreditacionConcluido ?? false,
                    subidoACreditacion: item?.subidoACreditacion ?? false,
                    concluido: true,
                    color: "default",
                    fechaInicio: item?.fechaInicioAcreditacion ?? "",
                    fechaFin: item?.fechaFinAcreditacion ?? "",
                    //Stepper Update        
                    paso: 2,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state.procesoId,
                    concluido: state?.concluido ? true : (item?.concluido ?? false),   
                }
                setInitialValue(newData);

                handleConcluido({ concluido: item?.acreditacionConcluido, codStatus: 2 });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([]).finally(() => {
            setLoading(false)
        });
    }


    const submitForm = async (values) => {
        if (values.acreditacionConcluido) {
            if (!values.subidoACreditacion) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Faltan subir evidencia.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }else if(!values.cuentaFolioAseguradora){
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Favor de ingresar el folio de la aseguradora.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }
        setLoading(true);
        await Siniestros.UpdateSiniestroRobo(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllSiniestroById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        cuentaFolioAseguradora: Yup.bool()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        folioAseguradora: Yup.string()
            .when(["acreditacionConcluido", "cuentaFolioAseguradora"], {
                is: (acreditacionConcluido, cuentaFolioAseguradora) => {
                    return (acreditacionConcluido && cuentaFolioAseguradora);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // folioAseguradora: Yup.string()
        //     .when("cuentaFolioAseguradora", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // comentarios: Yup.string()
        //     .required("El campo es requerido."),
        comentarios: Yup.string()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        //Detalle de contacto
        realizadoPor: Yup.string()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        folioDenunciaAcreditacion: Yup.string()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaDenunciaAcreditacion: Yup.string()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        acreditacionComentarios: Yup.string()
            .when("acreditacionConcluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // realizadoPor: Yup.string()
        //     .required("El campo es requerido."),
        // folioDenuncia: Yup.string()
        //     .required("El campo es requerido."),
        // fechaDenuncia: Yup.string()
        //     .required("El campo es requerido."),
        // acreditacionComentarios: Yup.string()
        //     .required("El campo es requerido.")
    });

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eAcredita/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    const generatePDFChecklist = async () => {
        setLoading(true)
        const date = new Date();
        const formattedDate = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

        const requestBody = {
            nombrePlantilla: "CheckListDocumentosSiniestro",
            parametros: {
                "<<VARIABLE_CONTRATO>>": state?.contrato,
                "<<VARIABLE_VIN>>": state?.vin,
                "<<VARIABLE_DATOS>>": `${state?.marca}, ${state?.modelo}, ${state?.año} ${state?.nombreVersion}, ${state?.colorVehiculo}`,
                "<<VARIABLE_CLIENTE>>": clienteSiniestro,
                "<<VARIABLE_FECHA>>": formattedDate,
                "<<VARIABLE_FOLIO>>": state?.folio,
                "<<VARIABLE_UBICACION>>": state?.nombreUbicacion
            }
        };

        await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            submitForm({
                siniestroId: id,
                clienteLlegoCita: true,
                concluido: true,
                acreditacionConcluido: false
            });
        }).catch(err => {
            setLoading(false)
            console.error(err);
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                modalMessage &&
                <ModalMessage
                    setIsOpen={setModalMessage}
                    isOpen={modalMessage}
                />
            }
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAcreditacion" role="button" aria-expanded="false" aria-controls="collapseAcreditacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.acreditacionConcluido ? "green" : "default"}`}></span> <strong>Acreditación</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue?.acreditacionConcluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAcreditacion">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">

                                                    <div className="col-3">
                                                        <small><strong>Generar documento</strong></small>
                                                    </div>

                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {/* <fieldset className='btn d-flex align-items-center mx-2 col-auto' onClick={() => { setModalMessage(true) }}><i className="bx bxs-message-alt me-1" ></i>Comentarios</fieldset> */}
                                                            <div className="col-auto">
                                                                <fieldset
                                                                    disabled={false}
                                                                    onClick={handleUploadFile}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue?.acreditacionConcluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.acreditacionConcluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2 my-4">
                                                    <div className="col-3 d-flex align-items-center">
                                                        <small>Checklist documentos</small>
                                                        <fieldset disabled={initialValue?.acreditacionConcluido} onClick={generatePDFChecklist} className='btn btn-light d-flex align-items-center mx-2'><i className="icon fs-4 bx bx-download" ></i></fieldset>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small className='fw-bold'>Detalle de la aseguradora</small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Cuenta con folio aseguradora?"
                                                            disabled={initialValue.cuentaFolioAseguradora?true: initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita}
                                                            name="cuentaFolioAseguradora"
                                                            items={dataBoolean}
                                                            onChange={(event) => {
                                                                setFieldValue("cuentaFolioAseguradora", event.value);
                                                            }} />
                                                    </div>
                                                    {
                                                        values.cuentaFolioAseguradora &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField disabled={initialValue.cuentaFolioAseguradora?true:initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita} label="Folio siniestro aseguradora" holder="Escribe" name="folioAseguradora" type="text" />
                                                        </div>
                                                    }

                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue.cuentaFolioAseguradora?true:initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small className='fw-bold'>Detalle acreditación</small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-3">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Realizado por?"
                                                            disabled={initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita}
                                                            name="realizadoPor"
                                                            items={realizadoPor}
                                                            onChange={(event) => {
                                                                setFieldValue("realizadoPor", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita} label="Folio de la denuncia" holder="Escribe" name="folioDenunciaAcreditacion" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita} label="Fecha de la denuncia" holder="Escribe" name="fechaDenunciaAcreditacion" type="date" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="acreditacionComentarios"
                                                            type="text"
                                                            holder="Escribe" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.acreditacionConcluido ? true : !initialValue?.clienteLlegoCita} type="checkbox" name="acreditacionConcluido" onClick={(event) => {
                                                            setFieldValue("acreditacionConcluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4 d-flex justify-content-end">

                                                    <div className="col-12 col-lg-6">
                                                        <div className="row flex-column align-items-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue?.fechaInicio !== "" &&
                                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue?.acreditacionConcluido &&
                                                                    <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SiniestroAcreditacionRoboCard