import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { FormatNumberMoney2, message } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import LiberacionUnidadService from '../../../../Services/Inventario/Valuacion/liberacionUnidadService';
import Llamada from '../../../../Services/Cartera/Llamadas';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../../Services/Siniestro/Siniestro';

const auth = new AuthLogin();
const SiniestroLiberacionUnidadRoboCard = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    let initial = {
        siniestroId: id,
        siniestroLiberacionUnidadId: "",
        tipoUbicacion: "",
        nombreUbicacion: "",
        totalAdeudo: "",
        calleNumero: "",
        codigoPostal: "",
        edoMexicoId: "",
        municipioId: "",
        coloniaId: "",

        requiereGrua: false,

        comentarios: "",
        oficioLiberado: false,

        unidadCorralon: false,
        nombreCorralon: "",
        corralonCalleNumero: "",
        corralonCodigoPostal: "",
        corralonEdoMexicoId: "",
        corralonMunicipioId: "",
        corralonColoniaId: "",

        subido: false,

        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 50,
        status: 2,
        procesoId: state?.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);

    const [tipoUbicacion, setTipoUbicacion] = useState([]);

     //tblAdeudos
     const [adeudosData, setAdeudosData] = useState([]);
     const [totalAdeudos, setTotalAdeudos] = useState(0);
     const [totalPagos, setTotalPagos] = useState(0);

    useEffect(() => {
        getData();
        getPagoTramites();
    }, []);

    useEffect(() => {
        getEstados()
        getEstadosDos()
        handleGetTipoUbicacion()
    }, [])

    const getData = async () => {
        await LiberacionUnidadService.getLiberacionunidadSiniestro(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: item?.siniestroId ?? id,
                    siniestroLiberacionUnidadId: item?.siniestroLiberacionUnidadId ?? "",
                    tipoUbicacion: item?.tipoUbicacionId ?? "",
                    nombreUbicacion: item?.nombreUbicacion ?? "",
                    totalAdeudo: item?.totalAdeudo ?? "",
                    calleNumero: item?.calleNumero ?? "",
                    codigoPostal: item?.codigoPostal ?? "",
                    edoMexicoId: item?.edoMexicoId ?? "",
                    municipioId: item?.municipioId ?? "",
                    coloniaId: item?.coloniaId ?? "",

                    requiereGrua: item?.requiereGrua ?? false,

                    comentarios: item?.comentarios ?? "",
                    oficioLiberado: item?.subido ?? false,
                    // oficioLiberado: item?.oficioLiberado ?? false,

                    unidadCorralon: item?.unidadCorralon ?? false,
                    nombreCorralon: item?.nombreCorralon ?? "",
                    corralonCalleNumero: item?.corralonCalleNumero ?? "",
                    corralonCodigoPostal: item?.corralonCodigoPostal ?? "",
                    corralonEdoMexicoId: item?.corralonEdoMexicoId ?? "",
                    corralonMunicipioId: item?.corralonMunicipioId ?? "",
                    corralonColoniaId: item?.corralonColoniaId ?? "",

                    subido: item?.subido ?? "",

                    concluido: state?.concluido ? true : (item?.concluido ?? false),  
                    fechaInicio: item.fechaInicio ?? "",
                    fechaFin: item.fechaFin ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 50,
                    status: 2,
                    procesoId: state?.procesoId
                }
        
                setInitialValue(newData);
                if (item?.tipoUbicacionId !== "") {
                    getMunicipios(item?.edoMexicoId);
                    getColonias(item?.municipioId);
                }
                if (item?.unidadCorralon) {
                    getMunicipiosDos(item?.corralonEdoMexicoId);
                    getColoniasDos(item?.corralonMunicipioId);
                }
                handleConcluido({ tipo: 2, concluido: false, ubicacionvehiculo: false, requiereGrua: item?.concluido ? item?.requiereGrua : "", recoleccionconcluido: false });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };
    const getPagoTramites = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesGet(id)
            .then((response) => {
                let adeudos = [];
                let totalAdeudos = 0;
                let totalPagos = 0;
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                response.data.adeudos.map((item, index) => {
                    item.orden = index + 1;
                    item.total = (item.subtotal * item.iva) / 100 + item.subtotal;
                    totalAdeudos += item.total;
                    adeudos.push(item);
                });
                //Actualizar los valores a formato moneda en data.adeudos
                response.data.adeudos.map((item) => {
                    item.subtotal = FormatNumberMoney2(item.subtotal);
                    item.iva = FormatNumberMoney2(item.iva);
                    item.total = FormatNumberMoney2(item.total);
                    // item.fechaAlta = getDateFormat(item.fechaAlta, "");
                    // item.fechaAdeudo = getDateFormat(item.fechaAdeudo, "");
                });

                response.data.pagos.map((item, index) => {
                    totalPagos += item.monto;
                });
                setTotalAdeudos(totalAdeudos);
                setTotalPagos(totalPagos);
                setAdeudosData(adeudos);                
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }
    const validate = Yup.object().shape({
        tipoUbicacion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        nombreUbicacion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // totalAdeudo: Yup.string()
        //     .when("concluido", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        calleNumero: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        codigoPostal: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        edoMexicoId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        municipioId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        coloniaId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        requiereGrua: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        oficioLiberado: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        unidadCorralon: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),

        nombreCorralon: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        corralonCalleNumero: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        corralonCodigoPostal: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        corralonEdoMexicoId: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        corralonMunicipioId: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        corralonColoniaId: Yup.string()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        ///////////////////////////////////////
        // tipoUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        // nombreUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        // tipoUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        // totalAdeudo: Yup.string()
        //     .required("El campo es requerido."),
        // calleNumero: Yup.string()
        //     .required("El campo es requerido."),
        // codigoPostal: Yup.string()
        //     .required("El campo es requerido."),
        // edoMexicoId: Yup.string()
        //     .required("El campo es requerido."),
        // municipioId: Yup.string()
        //     .required("El campo es requerido."),
        // coloniaId: Yup.string()
        //     .required("El campo es requerido."),

        // requiereGrua: Yup.bool()
        //     .required("El campo es requerido."),

        // comentarios: Yup.string()
        //     .required("El campo es requerido."),
        // oficioLiberado: Yup.bool()
        //     .required("El campo es requerido."),

        // unidadCorralon: Yup.bool()
        // //     .required("El campo es requerido."),
        // nombreCorralon: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // corralonCalleNumero: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // corralonCodigoPostal: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // corralonEdoMexicoId: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // corralonMunicipioId: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // corralonColoniaId: Yup.string()
        //     .when("unidadCorralon", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
    });

    const submitForm = async (values) => {
        if (values.concluido) {
            if (!values.subido) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir evidencia.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }
        setLoading(true);
        await LiberacionUnidadService.updateLiberacionUnidadSiniestro(values)
            .then(res => {
                if (res.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.mensaje));
                    getData()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.mensaje));
                }

            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleGetTipoUbicacion = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Tipo_Ubicacion_Datamovil')
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.TipoUbicacionId,
                        label: item.TipoUbicacion
                    });
                });

                setTipoUbicacion(newData);
            })
            .catch(err => {
            });
    }
    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipios = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data?.data
            let Municipios = []
            resData?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data?.data
            let Colonias = []
            resData?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }

    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData?.map(item => {
                Estados?.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eDictRbo/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroLiberacionUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseLiberacion" role="button" aria-expanded="false" aria-controls="collapseLiberacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Liberación de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseLiberacion">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-3'>
                                                    <h6>
                                                        <strong>Ubicación de Unidad</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Tipo de ubicación"
                                                        disabled={initialValue?.concluido}
                                                        name="tipoUbicacion"
                                                        items={tipoUbicacion}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoUbicacion", event.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField disabled={initialValue?.concluido} label="Nombre de ubicacion" name="nombreUbicacion" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-3">
                                                    <TextField disabled={true} label="Total adeudo" name="totalAdeudo" type="text" holder="Escribe" value={`$${FormatNumberMoney2(totalAdeudos - totalPagos)}`}/>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField disabled={initialValue?.concluido} label="Calle y numero" name="calleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("calleNumero", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField disabled={initialValue?.concluido} label="Codigo postal" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("codigoPostal", event.target.value);
                                                    }} />
                                                </div>
                                                {/* <div className="col-2">
                                                    <TextField label="Pais" name="paisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("paisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                <div className="col-3">
                                                    <SelectField id="edoMexicoId"
                                                        label="Estado"
                                                        disabled={initialValue?.concluido}
                                                        name="edoMexicoId"
                                                        items={estados}
                                                        onChange={(event) => {
                                                            setFieldValue("edoMexicoId", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("municipioId", "");
                                                                setFieldValue("coloniaId", "");
                                                                setMunicipios([]);
                                                                setColonias([]);
                                                            } else
                                                                getMunicipios(event.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <SelectField id="municipioId"
                                                        label="Municipio"
                                                        disabled={initialValue?.concluido}
                                                        name="municipioId"
                                                        items={municipios}
                                                        onChange={(event) => {
                                                            setFieldValue("municipioId", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("coloniaId", "");
                                                                setColonias([]);
                                                            } else
                                                                getColonias(event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        disabled={initialValue?.concluido}
                                                        label="Colonia"
                                                        name="coloniaId"
                                                        items={colonias}
                                                        onChange={(event) => {
                                                            setFieldValue("coloniaId", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3 px-4">
                                                <small><strong>Traslado de unidad</strong></small>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        disabled={initialValue?.concluido}
                                                        label="¿Se requiere Grua?"
                                                        name="requiereGrua"
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereGrua", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3 px-4">
                                                <small><strong>Detalle de unidad liberada</strong></small>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className='d-flex flex-column'>
                                                    <label htmlFor="">Oficio de liberación</label>
                                                    <fieldset
                                                        disabled={false}
                                                        onClick={handleUploadFile}
                                                        className='btn btn-gray col-2'>
                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                    </fieldset>
                                                </div>

                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField
                                                        label="comentarios"
                                                        disabled={initialValue?.concluido}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentarios"
                                                        id="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input"
                                                        disabled={true}
                                                        // disabled={initialValue?.oficioLiberado} 
                                                        type="checkbox" name="oficioLiberado" onClick={(event) => {
                                                            setFieldValue("oficioLiberado", event.target.value);
                                                        }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Oficio de liberacion registrado en sistema</label>
                                                </div>
                                            </div>
                                            <div className="col-3 px-4 mt-4">
                                                <small><strong>Traslado de unidad</strong></small>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        disabled={initialValue?.concluido}
                                                        label="¿Unidad en Corralon?"
                                                        name="unidadCorralon"
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadCorralon", event.value);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    values.unidadCorralon &&
                                                    <div className="col-3">
                                                        <TextField disabled={initialValue?.concluido} label="Nombre del corralon" name="nombreCorralon" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("nombreCorralon", event.target.value);
                                                        }} />
                                                    </div>
                                                }

                                            </div>
                                            {
                                                values.unidadCorralon &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Calle y numero" name="corralonCalleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("corralonCalleNumero", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Codigo postal" name="corralonCodigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("corralonCodigoPostal", event.target.value);
                                                            }} />
                                                        </div>
                                                        {/* <div className="col-2">
                                                    <TextField label="Pais" name="corralonPaisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("corralonPaisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                        <div className="col-3">
                                                            <SelectField id="corralonEdoMexicoId"
                                                                label="Estado"
                                                                disabled={initialValue?.concluido}
                                                                name="corralonEdoMexicoId"
                                                                items={estadosDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonEdoMexicoId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("corralonMunicipioId", "");
                                                                        setFieldValue("corralonColoniaId", "");
                                                                        setMunicipiosDos([]);
                                                                        setColoniasDos([]);
                                                                    } else
                                                                        getMunicipiosDos(event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="corralonMunicipioId"
                                                                label="Municipio"
                                                                disabled={initialValue?.concluido}
                                                                name="corralonMunicipioId"
                                                                items={municipiosDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonMunicipioId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("corralonColoniaId", event.value);
                                                                        setColoniasDos([]);
                                                                    } else
                                                                        getColoniasDos(event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField
                                                                disabled={initialValue?.concluido}
                                                                label="Colonia"
                                                                name="corralonColoniaId"
                                                                items={coloniasDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonColoniaId", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad liberada</label>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-12 col-lg-6">
                                                    <div className="row flex-column align-items-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaInicio !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.concluido &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default SiniestroLiberacionUnidadRoboCard