import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import AuthLogin from '../../../../../Utils/AuthLogin';
import { SelectField } from '../../../../../Components/SelectField';
import { TextAreaField } from '../../../../../Components/TextAreaField';
import { TextField } from '../../../../../Components/TextField';
import Llamada from '../../../../../Services/Cartera/Llamadas';
import Catalogo from '../../../../../Services/Catalogos/Catalogo';
import SeguroSiniestrosService from '../../../../../Services/Seguros/SeguroSiniestrosService';
import ListadoCostos from '../../../Siniestros/Detalle/Registro/ListadoCostos';
import ResguardoUnidadHook from '../../../../../Hooks/Seguros/Siniestros/ResguardoUnidadHook';

const auth = new AuthLogin();
const ResguardoUnidadCard = ({ handleConcluido, stepId }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    let url = auth.UrlDataDocs();

    const [collpsableGrua, setCollpsableGrua] = useState(false);

    let {
        initialValue,
        estados,
        municipios,
        colonias,
        formasArribo,
        tipoUbicacion,
        setLoading,
        loading,
        submitForm,
        handleUploadFile,
        setMunicipios,
        getMunicipios,
        setColonias,
        getColonias,
        dataMotivoResguardo,
        estadosDos,
        municipiosDos,
        coloniasDos,
        getMunicipiosDos,
        getColoniasDos,
        setMunicipiosDos,
        setColoniasDos,
        TipoAseguradoras
    } = ResguardoUnidadHook({ handleConcluido, stepId })

    const validate = Yup.object().shape({
        motivoResguardoId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .nullable()
            }),
        formaArriboId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        codigoPostalRecoleccion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        edoMexicoId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        municipioId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        coloniaId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        calle: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        fechaCita: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        horaCita: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        referenciaUbicacion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        origenTipoUbicacion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        origenTipoAseguradora: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        origenUbicacion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        fechaResguardo: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        horaResguardo: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        quienEntrega: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        quienRecibe: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        calleNumero: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),

        codigoPostal: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        edoMexicoIdE: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),

        municipioIdE: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        coloniaIdE: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            })

    });


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseResguardo" role="button" aria-expanded="false" aria-controls="collapseResguardo" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Resguardo de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseResguardo">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-3'>
                                                    <h6>
                                                        <strong>Detalle de recolección</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <fieldset
                                                                onClick={handleUploadFile}
                                                                className='btn btn-gray'>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                {/* {
                                                    values.origenTipoUbicacionRecoleccion !== "" &&
                                                } */}


                                                {
                                                    //values.origenTipoUbicacionRecoleccion === "externo" &&
                                                    <>
                                                        {/* <div className="col-12 col-lg-3">
                                                            <TextField
                                                                label="Tipo aseguradora"
                                                                name="origenTipoAseguradora"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={initialValue?.concluido}
                                                            />
                                                        </div> */}
                                                    </>
                                                }
                                                {
                                                    //values.origenTipoUbicacionRecoleccion === "interno" &&
                                                    // <>
                                                    //     <div className="col-12 col-lg-3">
                                                    //         <TextField
                                                    //             id="destiboUbicacionStr"
                                                    //             label="Ubicación"
                                                    //             name="destiboUbicacionStr"
                                                    //             holder="Escribe"
                                                    //             type="text"
                                                    //             disabled={initialValue?.concluido}
                                                    //         />
                                                    //     </div>
                                                    //     <div className="col-12 col-lg-3">
                                                    //         <TextField
                                                    //             id="fechaHoraCitaRecoleccion"
                                                    //             label="Fecha y hora de cita"
                                                    //             name="fechaCitaRecoleccion"
                                                    //             holder="Escribe"
                                                    //             type="text"
                                                    //             disabled={initialValue?.concluido}
                                                    //         />
                                                    //     </div>
                                                    // </>
                                                }

                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="motivoResguardoId"
                                                        label="Motivo resguardo"
                                                        name="motivoResguardoId"
                                                        disabled={initialValue?.concluido}
                                                        items={dataMotivoResguardo}
                                                        onChange={(event) => {
                                                            setFieldValue("motivoResguardoId", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="formaArriboId"
                                                        label="Forma de arribo"
                                                        name="formaArriboId"
                                                        disabled={initialValue?.concluido}
                                                        items={formasArribo}
                                                        onChange={(event) => {
                                                            setFieldValue("formaArriboId", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="codigoPostalRecoleccion"
                                                        disabled={initialValue?.concluido}
                                                        label="Código postal"
                                                        name="codigoPostalRecoleccion"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("codigoPostalRecoleccion", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    //values.origenTipoUbicacionRecoleccion === "externo" &&
                                                    <>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="edoMexicoId"
                                                                disabled={initialValue?.concluido}
                                                                label="Estado"
                                                                name="edoMexicoId"
                                                                items={estados}
                                                                onChange={(event) => {
                                                                    setFieldValue("edoMexicoId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("municipioId", "");
                                                                        setFieldValue("coloniaId", "");
                                                                        setMunicipios([]);
                                                                        setColonias([])
                                                                    } else
                                                                        getMunicipios(event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="municipioId"
                                                                disabled={initialValue?.concluido}
                                                                label="Municipio"
                                                                name="municipioId"
                                                                items={municipios}
                                                                onChange={(event) => {
                                                                    setFieldValue("municipioId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("coloniaId", "");
                                                                        setColonias([])
                                                                    } else
                                                                        getColonias(event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="coloniaId"
                                                                disabled={initialValue?.concluido}
                                                                label="Colonia"
                                                                name="coloniaId"
                                                                items={colonias}
                                                                onChange={(event) => {
                                                                    setFieldValue("coloniaId", event.value);
                                                                }}
                                                            />
                                                        </div>


                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="calle"
                                                                label="Calle y numero"
                                                                name="calle"
                                                                holder="Escribe"
                                                                type="text"
                                                                onChange={(event) => {
                                                                    setFieldValue("calle", event.target.value);
                                                                }}
                                                                disabled={initialValue?.concluido}
                                                            />
                                                        </div>
                                                        {/* <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="numeroRecoleccion"
                                                                label="Número"
                                                                holder="Escribe"
                                                                name="numeroRecoleccion"
                                                                type="text"
                                                                disabled={initialValue?.concluido}
                                                            />
                                                        </div> */}
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="fechaCita"
                                                                label="Fecha de recolección"
                                                                disabled={initialValue?.concluido}
                                                                name="fechaCita"
                                                                type="date"
                                                                onChange={(event) => {
                                                                    setFieldValue("fechaCita", event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="horaCita"
                                                                label="Hora de recolección"
                                                                disabled={initialValue?.concluido}
                                                                name="horaCita"
                                                                holder="Escribe"
                                                                type="time"
                                                                onChange={(event) => {
                                                                    setFieldValue("horaCita", event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            {
                                                //values.origenTipoUbicacionRecoleccion === "externo" &&
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField
                                                            id="referenciaUbicacion"
                                                            label="Referencia de Ubicación"
                                                            className="form-control col-12"
                                                            holder="Escribe"
                                                            rows="3"
                                                            name="referenciaUbicacion"
                                                            onChange={(event) => {
                                                                setFieldValue("referenciaUbicacion", event.target.value);
                                                            }}
                                                            disabled={initialValue?.concluido}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            <ListadoCostos item={initialValue} siniestroResguardoUnidadId={initialValue.siniestroResguardoUnidadId} />

                                            <div className="row mt-2 px-4 align-items-center">
                                                <div className="col-auto">
                                                    <small><strong>Detalles de la entrega</strong></small>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="origenTipoUbicacion"
                                                        label="Tipo de ubicación"
                                                        name="origenTipoUbicacion"
                                                        disabled
                                                        items={tipoUbicacion}
                                                        onChange={(event) => {
                                                            setFieldValue("origenTipoUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField id="origenTipoAseguradora"
                                                        label="Tipo aseguradora"
                                                        name="origenTipoAseguradora"
                                                        items={TipoAseguradoras}
                                                        disabled={initialValue?.concluido}
                                                        onChange={(event) => {
                                                            setFieldValue("origenTipoAseguradora", event.value);
                                                        }} />
                                                </div>


                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="origenUbicacion"
                                                        label="Nombre ubicación"
                                                        name="origenUbicacion"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("origenUbicacion", event.target.value);
                                                        }}
                                                        disabled={initialValue?.concluido}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="fechaResguardo"
                                                        label="Fecha de resguardo"
                                                        disabled={initialValue?.concluido}
                                                        name="fechaResguardo"
                                                        type="date"
                                                        onChange={(event) => {
                                                            setFieldValue("fechaResguardo", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="horaResguardo"
                                                        label="Hora de resguardo"
                                                        disabled={initialValue?.concluido}
                                                        name="horaResguardo"
                                                        holder="Escribe"
                                                        type="time"
                                                        onChange={(event) => {
                                                            setFieldValue("horaResguardo", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="quienEntrega"
                                                        disabled={initialValue?.concluido}
                                                        label="¿Quién entrega?"
                                                        name="quienEntrega"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("quienEntrega", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="quienRecibe"
                                                        disabled={initialValue?.concluido}
                                                        label="¿Quién recibe?"
                                                        holder="Escribe"
                                                        name="quienRecibe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("quienRecibe", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="calleNumero"
                                                        disabled={initialValue?.concluido}
                                                        label="Calle y número"
                                                        name="calleNumero"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("calleNumero", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="codigoPostal"
                                                        disabled={initialValue?.concluido}
                                                        label="Código postal"
                                                        name="codigoPostal"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("codigoPostal", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="edoMexicoIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Estado"
                                                        name="edoMexicoIdE"
                                                        items={estadosDos}
                                                        onChange={(event) => {
                                                            setFieldValue("edoMexicoIdE", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("municipioIdE", "");
                                                                setFieldValue("coloniaIdE", "");
                                                                setMunicipiosDos([]);
                                                                setColoniasDos([]);
                                                            } else
                                                                getMunicipiosDos(event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="municipioIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Municipio"
                                                        name="municipioIdE"
                                                        items={municipiosDos}
                                                        onChange={(event) => {
                                                            setFieldValue("municipioIdE", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("coloniaIdE", "");
                                                                setColoniasDos([])
                                                            } else
                                                                getColoniasDos(event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="coloniaIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Colonia"
                                                        name="coloniaIdE"
                                                        items={coloniasDos}
                                                        onChange={(event) => {
                                                            setFieldValue("coloniaIdE", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <TextAreaField
                                                        id="comentarios"
                                                        label="Referencia de ubicación"
                                                        className="form-control col-12"
                                                        rows="3"
                                                        disabled={initialValue?.concluido}
                                                        holder="Escribe"
                                                        name="comentarios"
                                                        onChange={(event) => {
                                                            setFieldValue("comentarios", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input"
                                                        disabled={initialValue?.concluido || initialValue?.subidoResguardo === false}
                                                        type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Resguardo de unidad concluida</label>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-auto">
                                                    {
                                                        initialValue?.fechaInicio !== "" &&
                                                        <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                    }
                                                </div>
                                                <div className="col-auto">
                                                    {
                                                        initialValue?.concluido &&
                                                        <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                    }
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default ResguardoUnidadCard