import React, { useState, useEffect } from 'react';
import DataTable from '../../Components/datatable/DataTable';
import Spinner from '../../Components/Loadig';
import Modal from '../../Components/Modal/Modal';
import SubMenuParametrosCartera from '../../Components/SubMenuParametrosCartera';
import RowPages from '../../Components/RowPages';
import * as XLSX from 'xlsx';
import ParametrosCarteraHistorialHooks from '../../Hooks/ParametrosCartera/ParametrosCarteraHistorialHooks';

const ParametrosCarteraHistorial = () => {

  const {
    columns,
    loading,
    handlePageClick,
    currentPage,
    setpageRows,
    dateTime,
    setStrFiltro,
    strFiltro,
    setDateTime,
    dataParametrosCarteraLogs,
    pageCount
  } = ParametrosCarteraHistorialHooks()

  // const [filteredData, setFilteredData] = useState([]); // Datos filtrados para la tabla

  const handleExportToExcel = () => {
    if (dataParametrosCarteraLogs.length === 0) { // Ahora usamos los datos filtrados

      return;
    }

    const FilterExcel = dataParametrosCarteraLogs.map(({ parametro, valor_anterior, valor_nuevo, usuario_nombre, last_update }) => ({
      parámetro: parametro,
      valor_anterior,
      valor_nuevo,
      usuario: usuario_nombre,
      fecha_hora: last_update
    }));

    // Convertir los datos filtrados a formato de hoja de Excel
    const ws = XLSX.utils.json_to_sheet(FilterExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "HistorialParametros");

    // Generar archivo Excel
    XLSX.writeFile(wb, "ParametrosCarteraHistorial.xlsx");
  };

  const handleClearFilters = () => {
    setStrFiltro("")
    setDateTime("")
  }

  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

      <SubMenuParametrosCartera />

      <section className='mx-5 my-4 d-flex'>
        <div className='d-grid justify-content-between'>
          <span className='fs-5'><strong>Parámetros cartera</strong></span>
          <span className='mt-2'>A continuación, se presentan los parametros agrupados por proceso</span>
        </div>

        <div className='ms-auto justify-content-between'>
          <button className='btn btn-blue' onClick={handleExportToExcel} >
            Exportar Excel
          </button>
        </div>
      </section>

      <section className='mt-3 mx-4 mb-4'>
        <div className='col-lg-12 col-sm-12 row d-flex'>
          <div className="col-lg-3 col-md-3 col-sm-12 ps-4 mb-4 inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input
              type="text"
              className="form-control"
              placeholder="Buscar"
              value={strFiltro} // This binds the input value to the strFiltro state
              onChange={(e) => setStrFiltro(e.target.value)} // Updates strFiltro on user input
            />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='col-lg-12 col-md-12 col-sm-12 ps-4 mb-4'>
              <input
                type="date"
                className="form-control"
                value={dateTime}
                onChange={(e) => {
                  const newFecha = e.target.value;
                  setDateTime(newFecha);  // Actualiza el estado dateTime
                }}
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12'>
            <div className='col-lg-12 col-md-12 col-sm-12 ps-4 mb-4'>
              <button className='btn btn-blue ' onClick={handleClearFilters}>
                Limpiar filtro
              </button>
            </div>
          </div>
        </div>
      </section>
      <RowPages
        setpageRows={setpageRows}
      />
      <section className='mt-3 mx-4'>
        <DataTable
          column={columns}
          data={dataParametrosCarteraLogs}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage - 1}
        />
      </section>
    </>
  );
};

export default ParametrosCarteraHistorial;
