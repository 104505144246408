import React, { useEffect, useState } from "react"
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard.jsx"
import CarteraInfoCard from "../../../../Components/CarteraInfoCard.jsx"
import RegistroPromesaCards from "../Cards/RegistroPromesaCards.jsx"
import ResguardoUnidadCards from "../Cards/ResguardoUnidadCards.jsx"
import SeguimientoVisitaCards from "../Cards/SeguimientoVisitaCards.jsx"
import ContratoCard from "../../../../Components/ContratoCard";
import useSeguimientoVisitaDetalle from '../../../../Hooks/SeguimientoVisita/useSeguimientoVisitaDetalle.js'
import VisitaDomiciliariaCard from "../Cards/VisitaDomiciliariaCard.jsx"
import RecoleccionUnidadCard from "../Cards/RecoleccionUnidadCard.jsx"
import Modal from "../../../../Components/Modal/Modal.jsx"
import Spinner from "../../../../Components/Loadig.jsx"
import { useSelector } from "react-redux"
import { formatearMontoMx, FormatFecha, FormatFechaHora } from '../../../../Utils/functions';
import ConfirmarCierreVisitaModal from './Modal/ConfirmarCierreVisitaModal.jsx';
import toast, { Toaster } from 'react-hot-toast'
import { Section, SubModule } from '../../../../Auth/Authorization'
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission'

const SeguimientoVisitaDetalle = () => {
   const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [showPromesaPago, setShowPromesaPago] = useState(false);
   const [showRecoleccionResguardo, setShowRecoleccionResguardo] = useState(0);
   const [cerrarVisitaHabilitado, setCerrarVisitaHabilitado] = useState(false);
   const [visitaDom, setVisitaDom] = useState(null);

   const {
      userDetail,
      promesasRotas,
      loading,
      handleReturnPage,
      allContracts,
      handleCerrarVisita,
      modalConfirm,
      setModalConfirm
   } = useSeguimientoVisitaDetalle(toast);

   const formattedData = {
      ...contratoActual,
      monto_adeudo: formatearMontoMx(contratoActual.monto_adeudo),
      fecha_ultima_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave),
      saldo_credito: formatearMontoMx(contratoActual.saldo_credito),
      fecha_ultimo_pago: contratoActual.fecha_ultimo_pago === "1990-01-01T00:00:00" ? "-" : FormatFecha(contratoActual.fecha_ultimo_pago),
      vigencia_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave)
   };

   const cerrarVisita = async () => {
      setModalConfirm(true);
   };

   const handleEjecutarCierreVisita = async () => {
      handleCerrarVisita(visitaDom);
   }

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto' }}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <section className='headerTab'>
                  <header style={{ height: '55px', background: "#FFF" }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                     <div onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2"></i>
                        Regresar a listado de seguimiento de visita
                     </div>
                     <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoVisitaDetalle, SubModule.SeguimientoVisita)?.Agregar}>
                        <div className='d-flex align-items-center'>
                           {!itemGeneral.cierre_visita && (
                              <button disabled={!cerrarVisitaHabilitado} className='btn ms-2' onClick={cerrarVisita}>Cierre de visita</button>
                           )}
                        </div>
                     </ValidatePermission>
                  </header>
               </section>
               <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoVisitaDetalle, SubModule.SeguimientoVisita)?.Ver}>
                  <div className="col-11 col-sm-12 col-md-12 d-flex">
                     <div className="col-6 px-4 py-2">
                        <VehiculoInfoCard
                           GeneralId={contratoActual?.vehiculo?.generalId}
                           VIN={contratoActual?.vin}
                           verClaves={true}
                           solicitarClave={true} />
                     </div>
                     <div className="col-6 px-4 py-2">
                        <ContratoCard vin={contratoActual?.vin} />
                     </div>
                  </div>
                  <div className="col-12 px-4 py-2">
                     <CarteraInfoCard
                        datos={formattedData}
                        user={userDetail}
                        promesasRotas={promesasRotas} />
                  </div>
                  <div className="col-12 px-4 py-2">
                     <SeguimientoVisitaCards />
                  </div>
                  <div className="col-12 px-4 py-2">
                     <VisitaDomiciliariaCard
                        handleNextSteps={showNext => {
                           setShowPromesaPago(showNext);
                           if (showNext === false) {
                              setShowRecoleccionResguardo(0);
                           }
                        }}
                        handleSetCerrarVisita={(cerrar, visitaDom) => {
                           setCerrarVisitaHabilitado(cerrar);
                           setVisitaDom(visitaDom);
                        }} />
                  </div>
                  {showPromesaPago && (
                     <div className="col-12 px-4 py-2">
                        <RegistroPromesaCards
                           show={showPromesaPago}
                           handleNextSteps={(formularioActual) => {
                              setShowRecoleccionResguardo(formularioActual)
                           }}
                           handleSetCerrarVisita={(cerrar) => {
                              setCerrarVisitaHabilitado(cerrar)
                           }}
                           allContracts={allContracts}
                        />
                     </div>
                  )
                  }
                  {
                     showRecoleccionResguardo === 1 && (
                        <>
                           <div className="col-12 px-4 py-2">
                              <RecoleccionUnidadCard
                                 show={true}
                                 handleSetCerrarVisita={(cerrar) => {
                                    setCerrarVisitaHabilitado(cerrar)
                                 }}
                              />
                           </div>
                        </>
                     )
                  }
                  {
                     showRecoleccionResguardo === 2 && (
                        <>
                           <div className="col-12 px-4 py-2">
                              <ResguardoUnidadCards
                                 show={true}
                                 handleSetCerrarVisita={(cerrar) => {
                                    setCerrarVisitaHabilitado(cerrar)
                                 }}
                              />
                           </div>
                        </>
                     )
                  }
               </ValidatePermission>
            </div>
         </div>
         {
            modalConfirm &&
            <ConfirmarCierreVisitaModal
               isOpen={modalConfirm}
               setIsOpen={setModalConfirm}
               handleConfirm={handleEjecutarCierreVisita}
            />
         }
      </>
   )
}

export default SeguimientoVisitaDetalle