import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    reporteAutoridades: false,
    subido: false
}

export const AcreditacionSlice = createSlice({
    name: 'AcreditacionSlice',
    initialState,
    reducers: {
        setAcreditacion: (state, action) => {
            return {
                ...state,
                reporteAutoridades: action.payload,
                subido: action.payload.subido
            }
        }
    }
})

export const { setAcreditacion } = AcreditacionSlice.actions
export default AcreditacionSlice.reducer