import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import ValoracionDeUnidad from '../../../../Services/Inventario/Valuacion/ValoracionDeUnidadService'
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import { TextAreaField } from "../../../../Components/TextAreaField";
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import ValuacionesServices from "../../../../Services/Inventario/ValuacionesService";
import ValuacionRegistroCostoService from '../../../../Services/Inventario/Valuacion/ValuacionRegistroCostoService'
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../../Auth/Authorization';

const ValoracionInicial = ({ handleMostrarTarjetaAutorizacion }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const usuario = sessionStorage.getItem('usuario')
    let initial = {
        valuacionValoracionUnidadId: "",
        valuacionid: "",
        valorMercado: null,
        costoReparacion: null,
        adeudos: null,
        utilidad: null,
        valorUnidad: null,
        unidadParaInventario: null,
        usuario: null,
        fechaOperacion: null,
        horaOperacion: null,
        comentariosValorInicial: null,
        autorizarValorFinalUnidad: null,
        autorizarUnidadParaInventario: null,
        autorizarUsuario: null,
        autorizarFechaOperacion: null,
        autorizarHoraOperacion: null,
        autorizarComentarios: null,
        concluido: null,
        valuacionConcluida: null
    }

    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [pageRows, setpageRows] = useState(10);
    const [boolSelect, setboolSelect] = useState([{ label: "Sí", value: true }, { label: "No", value: false }]);
    const [Politica_compra, setPolitica_compra] = useState(false);
    const userId = sessionStorage.getItem('userId').trim()
    const nombreUsuario = sessionStorage.getItem('nombreUsuario').trim()

    const { refFormik } = useRef();

    useEffect(() => {
        handleGetAllData();
    }, []);

    const handleGetAllData = async () => {
        setLoading(true);
        try {
            await Promise.all([getAll(), handleGetParamById()]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setInitialValue(prevState => ({
            ...prevState,
            valorUnidad: calcularValorDeUnidad(prevState.unidadParaInventario, prevState.valorMercado, prevState.utilidad, prevState.costoReparacion, prevState.adeudos)
        }));
    }, [initialValue.unidadParaInventario]);

    const formatDate = (fecha) => {
        const date = new Date(fecha); // Convierte la fecha al objeto Date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() es 0 indexado, por eso se suma 1
        const day = String(date.getDate()).padStart(2, '0'); // Asegura que siempre haya 2 dígitos

        return `${year}-${month}-${day}`;
    };
    const handleGetParamById = async () => {
        await CobranzaService.getAllParametrosByField({
            FIELD: "ID",
            VALUE: "politica_compra"
        }).then(res => {
            setPolitica_compra(state?.year <= res.data.responseData[0]?.valorpublico)
        }).catch(err => {
            console.log(err)
        })
    }
    const getAll = async () => {
        await ValoracionDeUnidad.getValoracionUnidad(id)
            .then((resp) => {
                let valoracionInicial = resp.data;
                const fechaOperacionFormateada = valoracionInicial.fechaOperacion
                    ? formatDate(valoracionInicial.fechaOperacion)
                    : "";

                const autorizarFechaOperacionFormateada = valoracionInicial.autorizarFechaOperacion
                    ? formatDate(valoracionInicial.autorizarFechaOperacion)
                    : "";

                setInitialValue({
                    ...valoracionInicial,
                    fechaOperacion: fechaOperacionFormateada,
                    autorizarFechaOperacion: autorizarFechaOperacionFormateada,
                });

                if (valoracionInicial.costoReparacion == null)
                    getDataInspeccion();
                if (valoracionInicial.adeudos == null)
                    getAllRegistroCostos();
                if (valoracionInicial.utilidad == null)
                    getAllParametrosByField();
                if (valoracionInicial.fechaOperacion == null)
                    setInitialValue(prevState => ({
                        ...prevState,
                        fechaOperacion: formatDate(new Date())
                    }));
                if (valoracionInicial.horaOperacion == null)
                    setInitialValue(prevState => ({
                        ...prevState,
                        horaOperacion: new Date().toLocaleTimeString('es-MX', { hour12: false })
                    }));
                if (valoracionInicial.usuario == null)
                    setInitialValue(prevState => ({
                        ...prevState,
                        usuario: nombreUsuario
                    }));

                handleMostrarTarjetaAutorizacion({ concluido: resp.data.valuacionConcluida })
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const validate = Yup.object({

    });

    const getDataInspeccion = async () => {
        await ValuacionesServices.getDataInspeccion(id)
            .then(resp => {
                getCostos(resp.data.valuacionInspeccionUnidadId);
            })
            .catch(err => {
            })
            .finally(() => {
            });
    };

    const getCostos = async (id) => {
        await ValuacionesServices.getCostos({ id: id, page: 1, rows: 100 })
            .then(resp => {
                const total = resp.data.data.reduce((previous, current) => {
                    return previous + parseFloat(current.total); // sumar el valor de una propiedad
                }, 0);
                setInitialValue(prevState => ({
                    ...prevState,
                    costoReparacion: total
                }));
            })
            .catch(err => {
                console.error(err);
            })
    }

    const getAllRegistroCostos = async () => {
        await ValuacionRegistroCostoService.getAllRegistroCostos(1, 100, id)
            .then((resp) => {
                setInitialValue(prevState => ({
                    ...prevState,
                    adeudos: handleTotal(resp.data.data.costosAdicionalesTbl)
                }));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    }

    const handleTotal = (array) => {
        const total = array.reduce((previous, current) => {
            return previous + current.total; // sumar el valor de una propiedad
        }, 0);
        return total;
    }

    const getAllParametrosByField = async () => {
        await CobranzaService.getAllParametrosByField({
            FIELD: "ID",
            VALUE: "utilidad"
        }).then(res => {
            setInitialValue(prevState => ({
                ...prevState,
                utilidad: res.data.responseData[0]?.valorpublico
            }));
        }).catch(err => {
            console.log(err)
        })
    }

    const calcularValorDeUnidad = (unidadInventario, valorMercado, utilidad, costoReparacion, adeudos) => {
        //UNIDAD INVENTARIO = SI (Valor de Mercado- Parámetro de utilidad - Costo de Reparaciones – Adeudos Tesorería= Precio de Compra)
        //UNIDAD INVENTARIO = NO (Valor de Mercado- Costo de Reparaciones – Adeudos Tesorería= Precio de Venta)
        return typeof (unidadInventario) == "boolean" ?
            (unidadInventario ? (valorMercado - utilidad - costoReparacion - adeudos) : (valorMercado - costoReparacion - adeudos)) : 0;

    };

    const submitForm = async (values) => {
        values.procesoId = state.procesoId
        values.paso = 4
        values.porcentaje = 100
        values.status = 2
        values.contrato = state.contrato
        values.vin = state.vin
        values.marca = state.marca
        values.modelo = state.modelo
        values.year = state.year
        values.ejecutivo = usuario

        let _values = { ...values, usuario: userId };
        setLoading(true);
        await ValoracionDeUnidad.updateValoracionUnidad(_values)
            .then((resp) => {
                getAll();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div className='row mx-md-4 my-4' >
                                <div className="col-12 px-md-3">
                                    <div className="p-4 white-wrapper mb-2">

                                        <div className="row mt-2" data-bs-toggle="collapse" href="#valoreacionUnidad" role="button" aria-expanded="false" aria-controls="valoreacionUnidad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                            <div className="col-11">
                                                <h6><span></span> <strong>Valoración inicial</strong></h6>
                                            </div>
                                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                            </div>
                                            <div className='col-12'>
                                                <small>{initialValue.siniestroPagoTramitesStatus}</small>
                                            </div>
                                        </div>
                                        <div className="collapse" id="valoreacionUnidad">
                                            {/* <div className="separator"></div> */}
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    <h6><strong>Detalle de valoración</strong></h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.valuacionConcluida ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    :
                                                                    <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.SeguimientoValoracionDeUnidadInicial, SubModule.SeguimientoValuacion))}>
                                                                        <button
                                                                            className='btn'
                                                                            disabled={initialValue.valuacionConcluida}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                    </ValidatePermission>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <TextField disabled={initialValue.valuacionConcluida} isMoney={true} icon={"bi bi-currency-dollar"} label="Valor de mercado" name="valorMercado" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("valorMercado", event.target.value);
                                                        setFieldValue("valorUnidad", calcularValorDeUnidad(values.unidadParaInventario, event.target.value, values.utilidad, values.costoReparacion, values.adeudos));
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled isMoney={true} icon={"bi bi-currency-dollar"} label="Costo de reparación ($)" name="costoReparacion" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("costoReparacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled isMoney={true} icon={"bi bi-currency-dollar"} label="Adeudo ($)" name="adeudos" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("adeudos", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled={initialValue.valuacionConcluida} isMoney={true} icon={"bi bi-currency-dollar"} label="Utilidad ($)" name="utilidad" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("utilidad", event.target.value);
                                                        setFieldValue("valorUnidad", calcularValorDeUnidad(values.unidadParaInventario, values.valorMercado, event.target.value, values.costoReparacion, values.adeudos));
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled isMoney={true} icon={"bi bi-currency-dollar"} label="Valor de la unidad ($)" name="valorUnidad" type="text" holder="" onChange={(event) => {
                                                        setFieldValue("valorUnidad", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            {/* <div className='pt-3'>
                                                <h6><strong>Cambio de ubicación</strong></h6>
                                            </div> */}
                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <SelectField
                                                        id="unidadParaInventario"
                                                        label="Unidad para inventario"
                                                        name="unidadParaInventario"
                                                        items={boolSelect}
                                                        disabled={initialValue.valuacionConcluida ? true : Politica_compra}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadParaInventario", event.value);
                                                            setFieldValue("valorUnidad", calcularValorDeUnidad(event.value, values.valorMercado, values.utilidad, values.costoReparacion, values.adeudos));
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled={initialValue.valuacionConcluida} label="Usuario" name="usuario" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("usuario", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled={initialValue.valuacionConcluida} label="Fecha de la operación" name="fechaOperacion" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaOperacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <TextField disabled={initialValue.valuacionConcluida} label="Hora de la operación" name="horaOperacion" type="time" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("horaOperacion", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>

                                                    <TextAreaField id="Comentarios"
                                                        label="Comentarios"
                                                        disabled={initialValue.valuacionConcluida}
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentariosValorInicial"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("comentariosValorInicial", event.target.value);
                                                        }} />

                                                </div>
                                            </div>

                                            <div className="row mt-2 1">
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue.valuacionConcluida} type="checkbox" name="valuacionConcluida" onClick={(event) => {
                                                        setFieldValue("valuacionConcluida", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Inspección de unidad finalizada</label>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                <small className='text-body-tertiary'>Fecha Inicio: {values.fechaInicioValoracion}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.valuacionConcluida &&
                                                                <small className='text-body-tertiary'>Fecha Fin: {values.fechaFinValoracion}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )
                }
            </Formik>


        </>
    )
}

export default ValoracionInicial;