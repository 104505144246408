import { useState } from "react";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";
import { getOnlyDateFormat } from '../../Utils/functions';
import { useDispatch } from "react-redux";
import { clearDataContrato, setDataContrato } from '../../Redux/Slice/Contrato/ContratoCardSlice';
import { setFields } from "../../Redux/Slice/Calendario/calendarDynamicalSlice";


const useInfoVehiculo = ({dataCalendar, calendarioType}) => {
    const dispatch = useDispatch();

    const initial = {
        vin: '',
        placas: '',
        nombre: '',
        color: '',
        nombreMarca: '',
        kilometraje: '',
        nombreModelo: '',
        procedenciaAuto: '',
        nombreVersion: '',
        numMotor: '',
        nombreYear: '',
        fechaAdquisicion: '',
        claves: '',
        numContrato: '',
        numPlaca: '',
    };

    const [data, setData] = useState(initial);

    const getInfo = async (generalId, vin) => {
        console.log(generalId, vin);
        await ValuacionesServices.getInfoVehiculo(generalId, vin)
        .then(resp => {
            //resp.data.fechaEntrega = getOnlyDateFormat(resp.data.fechaEntrega);
            setData(resp.data);
            dispatch(setDataContrato(resp.data));

            if(dataCalendar){
                const calendario = {
                    tipoCalendario: calendarioType,
                    fields: {
                        contrato: resp?.data?.numContrato,
                        vin: resp?.data?.vin,
                        modelo: resp?.data?.nombreModelo,
                        ano: resp?.data?.nombreYear,
                        marca: resp?.data?.nombreMarca,
                    }
                }

                dispatch(setFields(calendario));
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    const handleClaves = () => {

    }

    return {
        getInfo,
        data,
        handleClaves
    }

}

export default useInfoVehiculo;