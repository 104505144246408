import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ValuacionRegistroCostoService from '../../Services/Inventario/Valuacion/ValuacionRegistroCostoService';
import { Proceso } from '../../Utils/constan';

const useSeguimientoCierrePasos = (toast) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [activebtncontinuar, setActiveBtnContinuar] = useState(false);
    const [stepe, setStepe] = useState([]);
    const [stepSelected, setStepSelected] = useState();
    const [loading, setLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [codStatus, setCodStatus] = useState(0);
    const [statusCita, setStatusCita] = useState(null);

    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar]);

    useEffect(() => {
        handleGetAllStep();
    }, [statusCita]);

    const handleGetAllStep = async () => {
        setLoading(true);
        try {
            const res = await ValuacionRegistroCostoService.getAllStepe(id, Proceso.SeguimientoCierre);
            let step = res.data.data;
            let selectedstp = null;
            step.forEach(x => {
                if (statusCita !== 'reagendar') {
                    if (x.pasoSubStatus === 2) {
                        selectedstp = x;
                    }
                } else {
                    x.porcentaje = 0;
                    if (x.pasoSubStatus === 3) {
                        x.pasoSubStatus = 2;
                        x.pasoSubStatusDescripcion = 'En Proceso';
                        selectedstp = x;
                    } else if (x.pasoSubStatus === 2) {
                        x.pasoSubStatus = 1;
                        x.pasoSubStatusDescripcion = 'Pendiente';
                    }
                }
            });
            if (selectedstp === null && step.length > 0) {
                selectedstp = step[0];
            }
            if (selectedstp !== null) {
                setStepSelected(selectedstp);
            }
            setStepe(step);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClickTerminar = () => {
        setModalConfirm(true);
    };

    const handleClickTerminarProceso = async () => {
        setModalConfirm(false);
        setLoading(true);
        const data = {
            id,
            paso: stepSelected.paso,
            porcentaje: 100,
            pasoSubStatus: 3,
            procesoId: 4
        };
        try {
            const res = await ValuacionRegistroCostoService.updStep(data);
            if (res.data.data.ban === 1) {
                toast.success(`¡Correcto! ${res.data.data.mensaje}`);
                if (stepSelected.paso !== 2) {
                    navigate(`/procesocierre/seguimientocierre/${id}/${stepSelected.paso + 1}`, {
                        state: { ...state }
                    });
                }else{
                    ///procesocierre/seguimientocierre
                    navigate(`/procesocierre/seguimientocierre`)
                }
            } else {
                toast.error(`¡Operación fallida! ${res.data.data.mensaje}`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatusBtn = (item) => {
        if (item.concluido) {
            setActiveBtnContinuar(true);
        }
        setCodStatus(item?.codStatus);
        setStatusCita(item.statusCita?.toLowerCase());
        handleGetAllStep();
    };

    return {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        codStatus
    };
};

export default useSeguimientoCierrePasos;
