import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import { message } from '../../../Utils/functions';
import { TextAreaField } from '../../../Components/TextAreaField';
import Spinner from '../../../Components/Loadig';
import AutorizarDictamenService from '../../../Services/Seguros/AutorizarDictamenService';

const ModalAprobarRechazarSolicitud = ({ isOpen, setIsOpen, item, handleGetAll }) => {
    const refbtn = useRef();    
    const dataAutorizaDictamen = [
        { value: true, label: "Aprobar" },
        { value: false, label: "Rechazar" }
    ]

    let initial = {
        id: item?.id ?? "",
        citaId:item?.citaId ?? "",
        autorizarCita: "",
        motivoRechazoId: "",
        motivoRechazo: "",
        comentarios: "",
        tipoCitaId: item?.tipoCitaId ?? "",
        contrato: item?.contrato ?? "",
        tipoCita: item?.tipoCita ?? "",
        tipoTramite:item?.tipoTramite ?? "",

        vin: item?.vin ?? "",
        proceso: item?.proceso ?? "",
        nombreMarca: item?.nombreMarca ?? "",
        nombreModelo: item?.nombreModelo ?? "",
        nombreYear: item?.nombreYear ?? ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [MotivoRechazoDictamen, setMotivoRechazoDictamen] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleGetAllMotivoRechazoDictamen();
    }, [])

    const validate = Yup.object({
        comentarios: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        autorizarCita: Yup.boolean().required("El campo es requerido."),
        motivoRechazoId: Yup.string()
            .when('autorizarCita', {
                is: false,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

    });
    const handleGetAllMotivoRechazoDictamen = async () => {
        setLoading(true)
        await Siniestros.GetMotivosById(item?.tipoCitaId, item?.procesoId)
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.CitaMotivoRechazoId,
                        label: item.CitaMotivoRechazo
                    });
                });

                setMotivoRechazoDictamen(newData);
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            });
    }
    const handleClickConfirmar = async (values) => {
        setLoading(true)
        await AutorizarDictamenService.AprobarRechazarSolicitudCita(values)
            .then((res) => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    setIsOpen(false);
                    handleGetAll(1)
                } else {
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleClickConfirmar(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Autorización de solicitudes</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <SelectField label="Resultado" name="autorizarCita" items={dataAutorizaDictamen} onChange={(event) => {
                                            setFieldValue("autorizarCita", event.value);
                                            if (event.value) {
                                                setFieldValue("motivoRechazoId", "");
                                                setFieldValue("motivoRechazo", "");
                                            }
                                        }} />
                                    </div>
                                    {
                                        (values?.autorizarCita === false) &&
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <SelectField label="Motivo" name="motivoRechazoId" items={MotivoRechazoDictamen} onChange={(event) => {
                                                setFieldValue("motivoRechazoId", event.value);
                                                setFieldValue("motivoRechazo", event.label);
                                            }} />
                                        </div>
                                    }
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-12 col-md-6 col-lg-12">
                                        <TextAreaField
                                            id="comentariosDictamen"
                                            label="comentarios"
                                            disabled={false}
                                            className="form-control col-12"
                                            rows="3"
                                            name="comentarios"
                                            type="text"
                                            holder="Escribe" />
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalAprobarRechazarSolicitud