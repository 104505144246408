import { Form, Formik } from 'formik';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DataTable from '../../../Components/datatable/DataTable';
import EditarCobradorModal from './EditarCobradorModal';
import ReasignacionCobradorModal from './ReasignacionCobradorModal';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Spinner from '../../../Components/Loadig';
import HistorialDeEstatusDeSistema from './HistorialDeEstatusDeSistema';
import useCobradoresDetalle from '../../../Hooks/Cobranza/useCobradoresDetalle';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'

const CobradoresDetalle = () => {
   const { state } = useLocation();
   const navigate = useNavigate();
   let {
      loading,
      initialValue,
      setIsOpenModalEditar,
      handleHistorial,
      btnEditConfirm,
      setBtnEditConfirm,
      setIsOpen,
      columns,
      zonasTotalesActuales,
      handleCheck,
      // columnsDetail,
      currentData,
      OpenModalEditar,
      datosUsuario,
      setFiltroMunicipio,
      setFiltroEstado,
      HandleGetMunicpio,
      dataEstado,
      dataMunicipio,
      setStr,
      setActualizar,
      OpenModalReasignacion,
      setIsOpenModalReasignacion,
      openModalHistorial,
      setOpenModalHistorial,
      isOpen,
      handleConfirmar,
      handleCancelar
   } = useCobradoresDetalle(state.data);

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            //validationSchema={validate}
            onSubmit={(values) => {
            }}
         >{
               ({ isValid, values, handleChange, setFieldValue }) => (
                  <>
                     <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a Cobradores {state.page}
                     </header>
                     <ValidatePermission isActive={ValidatePermissionById(4, Section.CobradoresNivel2, SubModule.Cobradores)?.Ver}>
                        <Form className='mx-5' style={{ width: "90rem" }}>
                           <section style={{ height: "14rem" }}>
                              <section className=' py-4'>
                                 <div className='d-flex'>
                                    <span className='fs-5 d-flex' >
                                       <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={"Cobrador Activo"}>
                                          <i className="icon-dark ri-account-circle-line" style={{ fontSize: "30px", marginRight: "5px" }}></i>
                                       </fieldset>
                                       <span style={{ fontSize: "30px", color: "#000", fontWeight: "bold" }}>
                                          <strong>{values.nombrecobrador}</strong>
                                       </span>
                                    </span>
                                    <div className='px-4'>
                                       {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.CobradoresNivel2, SubModule.Cobradores)?.Actualizar}> */}
                                       <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModalEditar(true); }} >
                                          <i className='ri-pencil-fill' style={{ fontSize: "22px", color: "#032fe7" }}></i>
                                       </fieldset>
                                       {/* </ValidatePermission> */}
                                    </div>
                                 </div>
                                 <div className='mt-3' >
                                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>Datos de cobrador:</span>
                                 </div>
                              </section>
                              <div className="mb-3 row" style={{ fontSize: "17px" }}>
                                 <div className='col-2'>
                                    <span style={{ fontWeight: "bold" }}><strong>Usuario</strong></span> <br />
                                    <span >{values.usuariosistema}</span>
                                 </div>
                                 <div className='col-2'>
                                    <span style={{ fontWeight: "bold" }}><strong>Nivel de cobranza</strong></span> <br />
                                    <span >{values.nivel}</span>
                                 </div>
                                 <div className='col-2'>
                                    <span style={{ fontWeight: "bold" }}><strong>Número de teléfono</strong></span> <br />
                                    <span >{values.telefono}</span>
                                 </div>
                                 <div className='col-2'>
                                    <span style={{ fontWeight: "bold" }}><strong>Estatus de sistema</strong></span> <br />
                                    <span style={{ display: 'flex' }}><i className="ri-checkbox-circle-fill" style={{ color: values.statussistem === true ? "#3ed800" : "#b9b9b9" }}></i> {values.estatusdesistema}</span>
                                 </div>
                                 <div className='col-3'>
                                    <span style={{ fontWeight: "bold" }}><strong>Historial Estatus de sistema</strong></span> <br />
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.CobradoresNivel2, SubModule.Cobradores)?.Historial}>
                                       <span style={{ display: 'flex' }} onClick={() => { handleHistorial() }} ><i className="icon-dark ri-file-search-line" style={{ fontSize: "20px" }}></i> Ver historial</span>
                                    </ValidatePermission>
                                 </div>
                              </div>
                           </section>
                           <section>
                              <section className=' py-4'>
                                 <div className='d-flex row'>
                                    <span className='fs-5 col-6'><span style={{ fontSize: "25px", color: "#000", fontWeight: "bold" }}><strong>Zona de cobranza</strong></span></span>
                                    <section className='d-flex justify-content-end row col-6'>
                                       {
                                          btnEditConfirm === 1 && (
                                             <button id="AT_btnReasignar" className='btn btn-outline col-4' type='button' onClick={() => { setBtnEditConfirm(2) }}>Editar</button>
                                          )
                                       }
                                       {
                                          btnEditConfirm === 2 && (
                                             <button id="AT_btnAgregar" className='btn mx-2 col-4' type="button" onClick={() => { setIsOpen(true); }}>Confirmar</button>
                                          )
                                       }
                                    </section>
                                 </div>
                              </section>
                              <div className="mb-3 row">
                                 <div className="col-sm-4 col-xl-3">
                                    <SelectField label="Estado" name="estado" items={dataEstado} onChange={(e) => {
                                       setFiltroMunicipio('');
                                       setFiltroEstado(e.value !== '' ? e.label : "");
                                       setFieldValue("estado", e.value !== 0 ? e.value : "");
                                       HandleGetMunicpio(e.value);
                                    }} />
                                 </div>
                                 <div className="col-sm-4 col-xl-3">
                                    <SelectField label="Municipio" name="municipio" items={dataMunicipio} onChange={(e) => {
                                       setFiltroMunicipio(e.value !== '' ? e.label : "");
                                       setFieldValue("municipio", e.value !== 0 ? e.value : "");
                                    }} />
                                 </div>
                                 <div className="col-sm-4 col-xl-3 inner-addon right-addon mt-4">
                                    <i className="glyphicon fas fa-search"></i>
                                    <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                                 </div>
                              </div>

                              {
                                 btnEditConfirm === 2 && (
                                    <div className='mx-4 table-responsive' >
                                       <DataTable
                                          column={columns}
                                          data={currentData}
                                          checkbox={true}
                                          handleCheck={handleCheck}
                                       />
                                    </div>
                                 )
                              }
                              {
                                 btnEditConfirm === 1 && (
                                    <div className='mx-4 table-responsive' >
                                       <DataTable
                                          column={columns}
                                          data={currentData}
                                       />
                                    </div>
                                 )
                              }

                           </section>
                        </Form>
                     </ValidatePermission>
                     {
                        OpenModalEditar && (
                           <>
                              <EditarCobradorModal
                                 isOpen={OpenModalEditar}
                                 setIsOpen={setIsOpenModalEditar}
                                 item={{
                                    userid: datosUsuario?.usuario?.userid,
                                    nombrecobrador: values?.nombrecobrador,
                                    usuariosistema: values?.usuariosistema,
                                    telefono: values?.telefono,
                                    zona: datosUsuario.zona,
                                    nivel_cobrador: datosUsuario?.usuario?.nivel_cobrador,
                                    contratos: datosUsuario?.usuario?.contratos
                                 }}
                                 nivel={2}
                                 setAction={setActualizar}
                              />
                           </>)
                     }
                     {
                        OpenModalReasignacion && (
                           <>
                              <ReasignacionCobradorModal
                                 isOpen={OpenModalReasignacion}
                                 setIsOpen={setIsOpenModalReasignacion}
                                 item={{ nombrecobrador: values?.nombrecobrador, usuariosistema: values?.usuariosistema, telefono: values?.telefono }}
                              />
                           </>)
                     }
                     {
                        openModalHistorial && (
                           <>
                              <HistorialDeEstatusDeSistema
                                 isOpen={openModalHistorial}
                                 setIsOpen={setOpenModalHistorial}
                                 item={datosUsuario}
                              />
                           </>)
                     }
                     <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-2 mx-4' style={{ width: '35rem' }}>
                           <span className='fs-4' style={{ fontWeight: 'bold', color: "#000" }}><strong>Confirma los cambios</strong></span>
                           <p>¿Estas seguro de los cambios realizados al cobrador:<span style={{ fontWeight: "bold" }}>{datosUsuario.nombrecobrador}</span>?</p>
                           <section className='d-flex justify-content-end mt-5'>
                              <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='button' onClick={() => { handleCancelar() }}>Regresar</button>
                              <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type="button" onClick={() => { handleConfirmar() }}>Confirmar</button>
                           </section>
                        </section>
                     </Modal>
                  </>
               )
            }
         </Formik>
      </>
   )
}

export default CobradoresDetalle