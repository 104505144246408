import React from "react"
import { useState, useRef } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import Modal from "../../../Components/Modal/Modal";
import * as Yup from 'yup';
import { SelectField } from "../../../Components/SelectField";
import PagoUnidad from "../../../Services/PagoUnidad/PagoUnidad";
import { message } from "../../../Utils/functions";
import { TextField } from "../../../Components/TextField";
import { FormatNumberMoney } from "../../../Utils/functions";

const RegistrarPagoUnidadModal = ({
    isOpen,
    setIsOpen,
    handleRegistrarPago,
    state,
    folio,
    metodosPago,
    formaPago,
    setLoading,
    toast,
    folio_big
}) => {

    const refFormik = useRef();

    const montoPagado = state.pagos.reduce((accumulator, item) => {
        return accumulator + item.montopagado;
      }, 0);

    const montoPorPagar = state.montoFinanciar - montoPagado

    const inicial = {
        fechaPago: '',
        montoPagado: 0,
        metodoPago: '',
        formaPago: '',
        numeroFactura: '',
        fechaFactura: '',
        userFactura: '',
        endosado: ''
    }

    const [initialValue, setInitialValue] = useState(inicial)
    const [metodoPagoName, setMetodoPagoName] = useState()
    const [formaPagoName, setFormaPagoName] = useState()
    const [formaPagoCode, setFormaPagoCode] = useState()
    const [numberMontoPagado, setNumberMontoPagado] = useState(0)

    const validationSchema = Yup.object({
        fechaPago: Yup.date()
            .required("El campo es requerido."),

        montoPagado: Yup.string()
            .required("El campo es requerido."),

        metodoPago: Yup.string().required('El campo es requerido'),

        formaPago: Yup.string().required('El campo es requerido'),

        numeroFactura: Yup.string().required('El campo es requerido'),

        fechaFactura: Yup.date()
            .required("El campo es requerido."),

        userFactura: Yup.string().required('El campo es requerido'),

        endosado: Yup.string().required('El campo es requerido'),
    })

   
    const handlerGuardar = async (values) => {
        let data = {
            anyObject:
            {
                pago_id: folio,
                cheque: "-",
                vin: state.vin,
                marca: state.nombreMarca,
                modelo: state.nombreModelo,
                año: state.nombreYear,
                version: "-",
                color: "-",
                placas: "-",
                tipo: state.tipoCompra,
                contrato: state.contrato,
                montoPagar: state.montoFinanciar,
                fechaPago: values.fechaPago,
                montoPagado: numberMontoPagado,
                numeroFactura: values.numeroFactura,
                fechaFactura: values.fechaFactura,
                personaFactura: values.userFactura,
                endosado: values.endosado,
                formadepago: formaPagoName,
                formadepagoid: values.formaPago,
                metodopago: metodoPagoName,
                metodopagoid: values.metodoPago
            }
        }

        let dataLey = {
            sol_id: state.sol_Id,
            contrato: state.contrato,
            vin: state.vin,
            fecha_pago: values.fechaPago,
            codigo_metodo_pago: formaPagoCode,
            desc_metodo_pago: formaPagoName,
            monto_pagado: numberMontoPagado,
            no_factura: values.numeroFactura,
            fecha_factura: values.fechaFactura,
            quien_factura: values.userFactura,
            endosado: values.endosado
        }

        const dataEstatusFinal = { generalId: state.generalId, pagounidadestatus: 3 };
        const dataEstatus = { generalId: state.generalId, pagounidadestatus: 2 };

        setLoading(true);
        try {
            await handleRegistrarPago(numberMontoPagado, state.vin)
            await PagoUnidad.pagoLeyAntiLavado(dataLey)
            await PagoUnidad.upsertSeguimientoPagoUnidad(data)
            await PagoUnidad.UpdateActualizaEstatusPago(dataEstatus)
            montoPorPagar === numberMontoPagado && PagoUnidad.UpdateActualizaEstatusPago(dataEstatusFinal)
            //cambiar estatus a 2
            setIsOpen(false);
            setLoading(false)
            toast.success(message("¡Operación exitosa!"));

        } catch (error) {
            setIsOpen(false);
            setLoading(false)
            toast.error(message("¡Error!", error.message));
        }
    };

    return (
        <>
            <Formik
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handlerGuardar(values)
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={1000}>
                            <Form style={{ maxWidth: '100%' }}>
                                <div className="row">
                                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                                        <div className='px-4'>
                                            <h4><strong>Registrar pago</strong></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 mx-4">
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>1. Confirma el vehículo seleccionado</strong></span></div>
                                    <div className="col-lg-12 col-12 py-2">
                                        <span><strong>Folio:</strong> {folio_big}</span>
                                        <span><strong>  | Número de cheque</strong> {state.numeroPago}</span>
                                        <span><strong>  | VIN</strong> {state.vin}</span>
                                        <span><strong>  | Marca</strong> {state.nombreMarca}</span>
                                        <span><strong>  | Modelo</strong> {state.nombreModelo}</span>
                                        <span><strong>  | Año</strong> {state.nombreYear}</span>
                                        <span><strong>  | Versión</strong> {state.nombreVersion}</span>
                                        <span><strong>  | Color</strong> {state.colorVehiculo} </span>
                                        <span><strong>  | Placas</strong> {state.numPlaca}</span>
                                        <span><strong>  | Tipo</strong> {state.tipoCompra}</span>
                                        <span><strong>  | Contrato</strong> {state.contrato}</span>
                                        <span><strong>  | Monto por pagar</strong> {FormatNumberMoney(montoPorPagar)}</span>
                                    </div>
                                </div>
                                <div className="row mt-2 mx-4">
                                    <div className="col-lg-12 col-12 py-2"> <span><strong>2. Registra el detalle del pago</strong></span></div>

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Fecha de pago:</label>
                                        <Field type="date" name="fechaPago" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="fechaPago" component="div" className="error" />
                                    </div>

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Monto pagado ($):</label>
                                        <TextField
                                            id="montoPagado"
                                            // label="Presupuesto"
                                            name="montoPagado"
                                            holder="Escribir"
                                            isMoney={true}
                                            onChange={(event) => {
                                                setNumberMontoPagado(parseFloat(event.target.value.replace(/,/g, '')))
                                                setFieldValue("montoPagado", FormatNumberMoney(event.target.value));
                                            }}
                                        />
                                        <ErrorMessage name="montoPagado" component="div" className="error" />
                                    </div>


                                    <div className="col-lg-4 col-4">
                                        <SelectField
                                            id="metodoPago"
                                            label="Metodo de pago:"
                                            name="metodoPago"
                                            disabled={false}
                                            items={metodosPago}
                                            onChange={(event) => {
                                                setFieldValue("metodoPago", event.value)
                                                setMetodoPagoName(event.label)
                                            }} />
                                    </div>


                                    <div className="col-lg-4 col-4">
                                        <SelectField
                                            id="formaPago"
                                            label="Forma de pago:"
                                            name="formaPago"
                                            disabled={false}
                                            items={formaPago}
                                            onChange={(event) => {
                                                setFieldValue("formaPago", event.value)
                                                setFormaPagoName(event.label)
                                                setFormaPagoCode(event.code)
                                            }} />
                                    </div>
                                    {/* <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Metodo de pago:</label>
                                        <Field type="text" name="metodoPago" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="metodoPago" component="div" className="error" />
                                    </div> */}

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Número de factura:</label>
                                        <Field type="text" name="numeroFactura" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="numeroFactura" component="div" className="error" />
                                    </div>

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Fecha de factura:</label>
                                        <Field type="date" name="fechaFactura" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="fechaFactura" component="div" className="error" />
                                    </div>

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>¿Quién factura?:</label>
                                        <Field type="text" name="userFactura" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="userFactura" component="div" className="error" />
                                    </div>

                                    <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                        <label className='text'>Endosado:</label>
                                        <Field type="text" name="endosado" placeholder="Escribir"
                                            className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="endosado" component="div" className="error" />
                                    </div>

                                </div>
                                <div className="row justify-content-end mt-4 mx-4">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                setIsOpen(false)
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!(isValid)}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            type="submit"
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    )

}
export default RegistrarPagoUnidadModal