import React, { useEffect, useState } from 'react'
import useValuacionDetalle from '../../../Hooks/SeguimientoDeValuacion/useValuacionDetalle'
import Stepper, { status } from '../../../Components/Stepper/Stepper';
import { useLocation, useParams } from 'react-router-dom';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import { set } from 'date-fns';
const data = {
    step1: status.Active,
    porcentage1: 100,
    step2: status.Inactive,
    porcentage2: 0,
    step3: status.Inactive,
    porcentage3: 0,
    step4: status.Inactive,
    porcentage4: 100,
};
const ValuacionDetalle = ({ handleClickContinuar, dataStep = data, activeBtn = false }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const { navigate } = useValuacionDetalle();
    const location = useLocation();
    let url = location.pathname.split("/");    
    let paso = url[4];

    const [politica_compra, setPolitica_compra] = useState();    
    const [stepSelected, setStepSelected] = useState();
    const handleNavigate = (step) => {              
        navigate(`/inventario/valuacion/${id}/${step.paso}`, {
            state: state
        })
    }
    const handleGetParamById = async () => {
        await CobranzaService.getAllParametrosByField({
            FIELD: "ID",
            VALUE: "politica_compra"
        }).then(res => {
            setPolitica_compra(res.data.responseData[0].valorpublico)
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        handleGetParamById();        
    },[])
    useEffect(() => {                
        dataStep?.map(x => {
            if(x.paso == paso){                
                setStepSelected(x);
            }
        })
    },[activeBtn,dataStep])
    return (
        <>
            <section className='headerTab'>
                <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                    <div>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de seguimiento de valuación
                    </div>
                    {
                        <div className='d-flex justify-content-center align-items-center'>
                            {
                                (state.year <= politica_compra) &&
                                <div className=''>
                                    <div className="d-flex me-2" style={{ color: 'red' }}> <i className='ri-error-warning-line me-1'></i>La unidad no aplica por política de compra.</div>
                                </div>
                            }
                            {
                                state.estatus !== 'Concluido' &&
                                <button disabled={stepSelected?.pasoSubStatus === 3 ? true : !activeBtn} className='btn' onClick={handleClickContinuar}>{paso == 4 ? 'Finalizar' : 'Continuar'}</button>
                            }

                        </div>
                    }
                </header>
                <div className='d-flex align-items-center row'>
                    {
                        dataStep.map(x =>
                            <Stepper
                                key={x.paso}
                                status={x.pasoSubStatus}
                                module={x.pasoDescripcion}
                                nameStep={`Paso ${x.paso}`}
                                percentage={x.porcentaje}
                                item={x}
                                handleClick={() => { handleNavigate(x)}}
                            />
                        )
                    }

                </div>
            </section>
        </>
    )
}

export default ValuacionDetalle