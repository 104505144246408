import { createSlice } from '@reduxjs/toolkit'

const initialState = JSON.parse(sessionStorage.getItem('UnidadesEnRemateSlice')) ||{
   itemUnidadesRemate:{},
   nombreUsuario: "",
   dataCobradores: []
}
export const UnidadesEnRemateSlice = createSlice({
   name: 'UnidadesEnRemateSlice',
   initialState,
   reducers: {
      setItemUnidadesRemate:(state, action)=>{
         state.itemUnidadesRemate = action.payload;
         sessionStorage.setItem('UnidadesEnRemateSlice', JSON.stringify(state));
      },
      setNombreUsuario:(state, action)=>{
         state.nombreUsuario = action.payload;
         sessionStorage.setItem('UnidadesEnRemateSlice', JSON.stringify(state));
      },
      setDataCobradores:(state, action)=>{
         state.dataCobradores = action.payload;
         sessionStorage.setItem('UnidadesEnRemateSlice', JSON.stringify(state));
      },
      resetState: (state) => {
         Object.assign(state, initialState);
         sessionStorage.removeItem('UnidadesEnRemateSlice'); 
      },
      setItemGeneralProperty: (state, action) => {
         const { key, value } = action.payload;
         state.itemGeneral[key] = value;
         sessionStorage.setItem('UnidadesEnRemateSlice', JSON.stringify(state));
      },
   },
})

export const {  
   setItemUnidadesRemate, 
   setNombreUsuario, 
   setDataCobradores,
   resetState,
   setItemGeneralProperty } = UnidadesEnRemateSlice.actions

export default UnidadesEnRemateSlice.reducer


