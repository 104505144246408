import React from 'react'
import Modal from './Modal'
const InfoPagoUnidadModal = ({ data, setIsOpen, isOpen, toast }) => {
    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
                <div className="p-4 wrapper-vehiculo">
                    <div className="col col-12">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className=" col col-auto me-2">
                                <h1 className="fw-bold text-lg">Información de contrato / vehículo</h1>
                            </div>
                            <div>
                                <button className="btn btn-outline " onClick={() =>  setIsOpen(false)}>X</button>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="row gap-2">
                            <div className="col col-12">
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">A nombre de:</strong> {data.poseedorDelVehiculo}</span>
                                    </div>
                                </div>
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">Contrato:</strong> {data.contrato}</span>
                                    </div>
                                </div>
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">VIN:</strong>{data.vin}</span>
                                    </div>
                                </div>
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">Marca:</strong> {data.nombreMarca}</span>
                                    </div>
                                </div>
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">Modelo:</strong> {data.nombreModelo}</span>
                                    </div>
                                </div>
                                <div className="row justify-content-start">
                                    <div className="col col-12 my-2 text-sm">
                                        <span><strong className="mr-2">Año:</strong> {data.nombreYear}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default InfoPagoUnidadModal