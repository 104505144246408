import { Form, Formik, Field } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { TextField } from "../../../../Components/TextField";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HistorialCita from "./HistorialCitas";
import { clearScheduleByTipoCalendario, selectScheduleByTipoCalendario, } from "../../../../Redux/Slice/Calendario/calendarDynamicalSlice";
import { useDispatch, useSelector } from "react-redux";
import { setFields } from "../../../../Redux/Slice/Calendario/calendarDynamicalSlice";
import Llamada from "../../../../Services/Cartera/Llamadas";
import SeguimientoCierre from "../../../../Services/Cierre/SeguimientoCierre";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import { formatearFecha } from '../../../../Utils/functions';

const CitaFirma = ({ handleConcluido }) => {

  const scheduleData = useSelector((state) => selectScheduleByTipoCalendario(state, 6));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const refFormik = useRef();

  let initial = {
    fechaHoraCita: "",
    estatusCita: "Pendiente",
    ubicacionId: "",
    ubicacion: "",
    concluido: false,
    cierreContratoCitaId: "",
    calendarioId: "",
    fechaCita: "",
    horaCita: "",
    bloqueoId: "",
  };

  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const [initialValue, setInitialValue] = useState(initial);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = Yup.object({
    fechaHoraCita: Yup.string()
      .nullable()
      .when('concluido', {
        is: true,
        then: Yup.string()
          .required('La fecha y hora es requerida')
      }),
  });

  useEffect(() => {
    getAll();
    setFormatDate();
  }, []);

  const setFormatDate = () => {
    if (typeof scheduleData === "object" && scheduleData?.day === undefined)
      return false;

    const fechaHoraCita = formatearFecha(`${scheduleData.day}T${scheduleData.hour}:00`);
    // const fechaHoraCita = `${scheduleData.day} ${scheduleData.hour}`;
    setInitialValue({
      ...initialValue,
      fechaHoraCita,
      estatusCita: "Solicitud",
      ubicacionId: scheduleData.sucursalId,
      ubicacion: scheduleData.sucursalName,
      fechaCita: scheduleData.day,
      horaCita: scheduleData.hour,
      calendarioId: scheduleData.calendarioId,
    });
    dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 6 }));
  };

  const getAll = () => {
    SeguimientoCierre.cierreContratoCitaGet(id).then((res) => {
      let fech = res.data.fechaCita;

      if (scheduleData.day !== undefined && scheduleData.hour !== undefined) {
        // const fechaHoraCita = `${scheduleData.day} ${scheduleData.hour}`;
        const fechaHoraCita = formatearFecha(`${scheduleData.day}T${scheduleData.hour}:00`);
        setInitialValue({
          ...res.data,
          fechaHoraCita,
          ubicacionId: scheduleData.sucursalId,
          ubicacion: scheduleData.sucursalName,
          fechaCita: scheduleData.day,
          horaCita: scheduleData.hour,
          calendarioId: scheduleData.calendarioId,
        });
      } else {
        // const fechaHoraCita = `${fech ? fech.split("T")[0] : ""} ${res.data.horaCita ?? ""}`;
        const fechaHoraCita = fech ? formatearFecha(`${fech.split("T")[0]}T${res.data.horaCita}:00`) : "";
        setInitialValue({
          ...initialValue,
          ...res.data,
          fechaHoraCita,
          estatusCita: res.data.cierreContratoCitaStatus
        });
      }

      handleConcluido({ concluido: res.data.concluido, statusCita: res.data.cierreContratoCitaStatus });
    });
  };

  const handleCalendar = () => {
    let calendario = {
      tipoCalendario: 6,
      fields: {
        vin: state?.vin,
        contrato: state?.numContrato,
        modelo: state?.modelo,
        ano: state?.año,
        marca: state?.marca,
      },
    };

    dispatch(setFields(calendario));
    navigate("/procesocierre/seguimientocierre/citas/v2/6", {
      state: { title: "Firma Cierre Contrato", tipoCalendario: 6 },
    });
  };

  const submitForm = async (values) => {
    setLoading(true);
    updateCite(values);
  };

  function convertirAFormato(fechaFormateada) {
    const [fecha, hora, ampm] = fechaFormateada.split(' ');
    const [dia, mes, anio] = fecha.split('-');
    let [horas, minutos, segundos] = hora.split(':');
  
    horas = parseInt(horas, 10);
    if (ampm === 'PM' && horas !== 12) horas += 12;
    if (ampm === 'AM' && horas === 12) horas = 0;
  
    const horasStr = horas.toString().padStart(2, '0');
  
    return `${anio}-${mes}-${dia} ${horasStr}:${minutos}:${segundos}`;
  }

  const updateCite = (values) => {
    let dataPut = {
      cierreContratoCitaId: initialValue.cierreContratoCitaId,
      cierreContratoId: id,
      cierreContratoCitaStatusId: values.concluido ? 30 : 0, //Validacion
      // fechaCita: initialValue.fechaHoraCita.split(" ")[0],
      fechaCita: convertirAFormato(initialValue.fechaHoraCita),
      horaCita: initialValue.horaCita,
      concluido: values.concluido,
      ubicacionId: initialValue.ubicacionId,
      bloqueoId: initialValue.bloqueoId,
      fecha: convertirAFormato(initialValue.fechaHoraCita),
      calendarioid: initialValue.calendarioId,
      calendariotipoid: 6,
      relacionid: id,
      autorizado: 0,
      status: 0,
      //aqui
      // proceso: state.,
      proceso: "Proceso de Cierre",
      subproceso: "Solicitud",
      vin: state.vin,
      marca: state.marca,
      modelo: state.modelo,
      anio: state.año,
      numContrato: state.numContrato,
      ubicacion: initialValue.ubicacion
      // fechaAlta: null
    };

    SeguimientoCierre.UpdateCierreContratoCita(dataPut)
      .then((res) => {
        setInitialValue(values);
        handleConcluido({ concluido: res.data.concluido });
        getAll();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <div className="row mx-md-4 my-4">
        <div className="col-12 px-md-3">
          <div className="p-4 white-wrapper mb-2">
            <div
              className="row mt-2"
              data-bs-toggle="collapse"
              href="#collapseCitaFirma"
              role="button"
              aria-expanded="false"
              aria-controls="collapseCitaFirma"
              onClick={() => setCollpsableGrua(!collpsableGrua)}
            >
              <div className="col-11">
                <h6>
                  <span
                    className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"
                      }`}
                  ></span>
                  <strong>Cita de firma</strong>
                </h6>
              </div>
              <div className="col-11">
                <small>
                  {initialValue.concluido ? " Concluido" : " En Proceso"}
                </small>
              </div>
              <div
                className="col-1 col-md-1 collapsable-icon"
                style={{ textAlign: "right" }}
              >
                <i
                  className={
                    !collpsableGrua
                      ? "icon-blue bx bx-chevron-down x2"
                      : "icon-blue bx bx-chevron-up x2"
                  }
                ></i>
              </div>
            </div>
            <div className="collapse" id="collapseCitaFirma">
              <div className="divider"></div>
              <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
                onSubmit={(values) => {
                  submitForm({ ...values });
                }}
              >
                {({ isValid, values, setFieldValue, errors }) => (
                  <Form>
                    <div className="row mt-2 mb-2">
                      <div className="col-4">
                        <h6>
                          <strong>Detalle de la cita</strong>
                        </h6>
                      </div>
                      <div className="col-8">
                        <div className="row justify-content-end">
                          <div className="col-auto">
                            {initialValue.concluido ? (
                              <fieldset className="btn btn-light secondary d-flex align-items-center mx-2">
                                <i className="icon-dark bx bx-hide mx-1"></i>
                                Modo lectura
                              </fieldset>
                            ) : (
                              <button
                                className="btn"
                                disabled={initialValue.concluido}
                                type="submit"
                              >
                                Guardar
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 mb-2">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="row">
                          <div className="col-6">
                            <TextField
                              label="Fecha y hora de cita"
                              disabled={true}
                              name="fechaHoraCita"
                              type="text"
                              holder="No hay cita seleccionada"
                              onChange={(event) => {
                                setFieldValue(
                                  "fechaHoraCita",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <button
                              className="btn mt-4"
                              disabled={
                                initialValue.concluido || initialValue.bloqueoId
                              }
                              onClick={() => {
                                handleCalendar();
                              }}
                            >
                              Ver calendario
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12">
                        <TextField
                          id="estatusCita"
                          label="Estatus cita"
                          holder=""
                          name="estatusCita"
                          type="text"
                          disabled
                          onChange={(event) => {
                            setFieldValue("estatusCita", event.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12">
                        <TextField
                          id="ubicacion"
                          label="Ubicación"
                          holder=""
                          name="ubicacion"
                          type="text"
                          disabled
                          onChange={(event) => {
                            setFieldValue("ubicacion", event.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <span>Historial</span>
                        <button
                          id="btnVisualizarDocumentos"
                          className="btn btn-outline d-flex align-items-center justify-content-center"
                          type="button"
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "#fff",
                          }}
                        >
                          <i
                            className="icon-dark ri-file-list-2-line"
                            style={{ fontSize: "18px" }}
                          ></i>
                          Ver historial
                        </button>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <Field
                          className="form-check-input"
                          id="concluido"
                          disabled={initialValue.concluido}
                          type="checkbox"
                          name="concluido"
                          onClick={(event) => {
                            setFieldValue("concluido", event.target.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "10px" }}
                        >
                          Cita agendada
                        </label>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <HistorialCita isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default CitaFirma;
