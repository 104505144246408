import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { FormatNumberMoney2, message } from "../../../../Utils/functions";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import AuthLogin from "../../../../Utils/AuthLogin";
import { SelectField } from "../../../../Components/SelectField";
import { TextField } from "../../../../Components/TextField";
import SeguimientoGestoriaService from "../../../../Services/Gestoria/SeguimientoGestoria";
import {
  clearScheduleByTipoCalendario,
  selectScheduleByTipoCalendario,
} from "../../../../Redux/Slice/Calendario/calendarDynamicalSlice";
import { useDispatch, useSelector } from "react-redux";
import Siniestros from "../../../../Services/Siniestro/Siniestro";
import Garantias from "../../../../Services/Cartera/Garantias";

const auth = new AuthLogin();
const GestoriaPreparacionPapeleria = ({ handleConcluido }) => {
  const { refFormik } = useRef();
  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const scheduleDataCita = useSelector((state) =>
    selectScheduleByTipoCalendario(state, 10)
  );

  let initial = {
    gestoriaRegistroInicialId: id,
    gestoriaProcesoTramiteId: "",
    tipoTramiteId: "",
    tipoTramite: "",
    tipoContacto: "",
    fechaRealizacionTramite: "",
    placasDadasDeBaja: "",
    codEstatus: "",
    estatusTramite: "",
    motivoRechazoId: "",
    motivoRechazo: "",
    aNombreDeId: "",
    aNombreDe: "",
    aseguradora: "",
    comentarios: "",
    concluido: false,
    subido: false,
    fechaInicio: "",
    fechaFin: "",
    subidoComprobante: "",
    subidoEvidencia: "",
    //Stepper Update
    paso: 3,
    porcentaje: 100,
    status: 2,
    procesoId: state?.procesoId,
  };
  const opcionesBool = [
    { value: true, label: "Si" },
    { value: false, label: "No" },
  ];
  const [totalAdeudo, setTotalAdeudo] = useState(10);
  const [totalPago, setTotalPago] = useState(0);
  const [initialValue, setInitialValue] = useState(initial);
  //
  const [TotalAdeudos, setTotalAdeudos] = useState(0);
  const [TotalPagos, setTotalPagos] = useState(0);
  const [MontoFinanciar, setMontoFinanciar] = useState(0);
  //
  useEffect(() => {
    getData();
    getPagoTramites();
  }, []);

  const getData = async () => {
    setLoading(true);
    await SeguimientoGestoriaService.GetConcluirTramite(id)
      .then((resp) => {
        let item = resp?.data;
        let newData = {
          gestoriaRegistroInicialId: id,
          gestoriaPreparacionPapeleriaId:
            item?.gestoriaPreparacionPapeleriaId ?? "",
          nombreUbicacion:
            item?.nombreUbicacion ?? scheduleDataCita?.sucursalName,
          fechaHoraCita: item?.fechaHoraCita ?? scheduleDataCita?.day,
          estatus: item?.estatus ?? "",
          aplicaFinanciamiento: item?.aplicaFinanciamiento ?? "",
          codEstatus: item?.codEstatus ?? "",
          montoFinanciar: item?.montoFinanciar ?? "",

          subido: item?.subido ?? "",

          concluido: item?.concluido ?? false,
          subido: item?.subido ?? false,
          fechaInicio: item?.fechaInicio ?? "",
          fechaFin: item?.fechaFin ?? "",
          subidoComprobante: item?.subidoComprobante ?? "",
          subidoEvidencia: item?.subidoEvidencia ?? "",

          clienteLlegoCita: item?.clienteLlegoCita ?? false,
          // AgendarCita
          calendarioId: scheduleDataCita?.calendarioId ?? "",
          day: scheduleDataCita?.day ?? "",
          hour: scheduleDataCita?.hour ?? "",
          sucursalId: scheduleDataCita?.sucursalId ?? "",
          sucursalName: scheduleDataCita?.sucursalName ?? "",
          //Stepper Update
          paso: 4,
          porcentaje: 100,
          status: 2,
          procesoId: state?.procesoId,
        };
        setInitialValue(newData);
        handleConcluido({ concluido: item?.concluido ?? false });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };
  const getPagoTramites = async () => {
    setLoading(true);
    await Siniestros.siniestroPagoTramitesGet(id)
      .then((response) => {
        let pagos = [];
        let adeudos = [];
        let totalPagos = 0;
        let totalAdeudos = 0;
        response.data.pagos.map((item, index) => {
          item.folio = index + 1;
          totalPagos += item.monto;
          pagos.push(item);
        });
        //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
        response.data.adeudos.map((item, index) => {
          item.orden = index + 1;
          item.total = item.subtotal + item.iva;
          totalAdeudos += item.total;
          adeudos.push(item);
        });

        setTotalAdeudos(totalAdeudos);
        setTotalPagos(totalPagos);
        setMontoFinanciar(totalAdeudos - totalPagos);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const validate = Yup.object().shape({
    aplicaFinanciamiento: Yup.string().when(["concluido", "codEstatus"], {
      is: (concluido, codEstatus) => {
        return concluido && codEstatus === 2 && totalAdeudo - totalPago > 0;
      },
      then: Yup.string().required("El campo es requerido."),
    }),
    montoFinanciar: Yup.string().when(["concluido", "aplicaFinanciamiento"], {
      is: (concluido, aplicaFinanciamiento) => {
        return (
          concluido &&
          aplicaFinanciamiento === true &&
          totalAdeudo - totalPago > 0
        );
      },
      then: Yup.string().required("El campo es requerido."),
    }),
  });

  const submitForm = async (values) => {
    if (values.concluido) {
      if (!values.subidoComprobante && !values.subidoEvidencia) {
        toast.custom(
          <div className="warnin-toast">
            {message("¡Alerta.!", "Falta subir evidencia.")}
          </div>
        );
        setTimeout(() => {
          toast.remove();
        }, 3000);
        return;
      }
    }

    setLoading(true);
    await SeguimientoGestoriaService.ActualizarConcluirTramite(values)
      .then((res) => {
        if (res?.data.ban === 1) {
          setLoading(false);
          toast.success(message("Correcto!", res?.data.mensaje));

          if (initialValue?.bloqueoId === null) {
            const titleCorreo = `Solicitud de Cita de Conclusión de Trámite de Gestoría ${
              "#" + scheduleDataCita?.contrato
            } - ${scheduleDataCita?.marca} ${scheduleDataCita?.modelo} ${
              scheduleDataCita?.ano
            }`;
            const contenido = {
              VARIABLE_USUARIO: sessionStorage.getItem("nombreUsuario"),
              VARIABLE_TIPO_CITA: "Gestoría",
              VARIABLE_TIPO_TRAMITE: "Concluir",
              VARIABLE_VIN: scheduleDataCita?.vin ?? "",
              VARIABLE_MARCA: scheduleDataCita?.marca ?? "",
              VARIABLE_MODELO: scheduleDataCita?.modelo ?? "",
              VARIABLE_AÑO: scheduleDataCita?.ano ?? "",
              VARIABLE_UBICACION: scheduleDataCita?.sucursalName ?? "",
              VARIABLE_FECHA: scheduleDataCita?.day ?? "",
              VARIABLE_HORA: scheduleDataCita?.hour ?? "",
            };
            Garantias.creardatosenviar({
              modulo: titleCorreo,
              tipoCorreoId: 116,
              contenido: JSON.stringify(contenido),
            }).catch((err) => {
              console.log(
                "Error al enviar correo Cita de Trámite de Gestoria:",
                err
              );
            });
            dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 10 }));
          }

          getData();
        } else {
          setLoading(false);
          toast.error(message("¡Error!", res?.data.mensaje));
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(message("¡Error!", error.message));
        console.log(error);
      });
  };

  const handleUploadFile = () => {
    navigate(
      `/seguro/siniestros/datadocs/fGestoria/eConcTrm/${state.numContrato}/${initialValue.gestoriaPreparacionPapeleriaId}`,
      {
        state: {
          ...initialValue,
          registroId: initialValue.gestoriaPreparacionPapeleriaId,
          vin: state.vin,
          nombreMarca: state.marca,
          nombreModelo: state.modelo,
          nombreVersion: state?.version,
          nombreYear: state.año,
        },
      }
    );
  };
  const verCalendario = async () => {
    navigate(`/gestoria/seguimiento/Citas/v2/${10}`, {
      state: {
        title: "Termino de Tramite de Gestoria",
        tipoCalendario: 10,
        ...state,
      },
    });
  };
  return (
    <>
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />

      <div
        className="d-flex flex-column background-grey"
        style={{ height: "auto" }}
      >
        <div className="row mx-md-4 my-4">
          <div className="col-12 px-md-3">
            <div className="p-4 white-wrapper mb-2">
              <div
                className="row mt-2 px-4"
                data-bs-toggle="collapse"
                href="#collapsePapeleria"
                role="button"
                aria-expanded="false"
                aria-controls="collapsePapeleria"
                onClick={() => setCollpsableGrua(!collpsableGrua)}
              >
                <div className="col-11">
                  <h6>
                    <span
                      className={`semaforo semaforo-${
                        initialValue?.concluido ? "green" : "default"
                      }`}
                    ></span>{" "}
                    <strong>Preparación de papelería</strong>
                  </h6>
                </div>
                <div
                  className="col-1 col-md-1 collapsable-icon"
                  style={{ textAlign: "right" }}
                >
                  <i
                    className={
                      !collpsableGrua
                        ? "icon-blue bx bx-chevron-down x2"
                        : "icon-blue bx bx-chevron-up x2"
                    }
                  ></i>
                </div>
                <div className="col-12">
                  <small>
                    {initialValue.concluido ? "Concluido" : "En Proceso"}
                  </small>
                </div>
              </div>
              <div className="collapse" id="collapsePapeleria">
                <Formik
                  innerRef={refFormik}
                  enableReinitialize={true}
                  validationSchema={validate}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                    submitForm(values);
                  }}
                >
                  {({ isValid, values, setFieldValue, errors }) => (
                    <Form>
                      <div className="separator"></div>
                      <div className="row mt-2 px-4 mb-2">
                        <div className="col-3">
                          <h6>
                            <strong>Cita de entrega</strong>
                          </h6>
                        </div>
                        <div className="col-9">
                          <div className="row justify-content-end">
                            <div className="col-auto">
                              {initialValue.concluido ? (
                                <fieldset className="btn btn-light secondary d-flex align-items-center mx-2">
                                  <i className="icon-dark bx bx-hide mx-1"></i>
                                  Modo lectura
                                </fieldset>
                              ) : (
                                <button
                                  className="btn"
                                  disabled={
                                    initialValue?.concluido ? true : !isValid
                                  }
                                  type="submit"
                                >
                                  Guardar
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 px-4">
                        <div className="col-12 col-lg-3">
                          <TextField
                            id="utilidad"
                            disabled={true}
                            label="Ubicación"
                            holder="Escribe"
                            name="nombreUbicacion"
                            type="text"
                          />
                        </div>
                        <div className="col-auto d-flex align-items-center">
                          <TextField
                            disabled={true}
                            label="Fecha y hora de cita"
                            holder="Escribe"
                            name="fechaHoraCita"
                            type="text"
                          />
                          <fieldset
                            disabled={initialValue?.concluido}
                            onClick={() => {
                              verCalendario();
                            }}
                            className="btn d-flex align-items-center mx-2 mt-3"
                          >
                            Ver calendario
                          </fieldset>
                        </div>
                        <div className="col-12 col-lg-3">
                          <TextField
                            id="utilidad"
                            disabled={true}
                            label="Autorización de Supervisor"
                            holder="Escribe"
                            name="estatus"
                            type="text"
                          />
                        </div>
                      </div>
                      {values.codEstatus == 2 && (
                        <>
                          <div className="row mt-2 col-lg-12 col-md-12 col-sm-12 mx-3">
                            <div className="col-4">
                              <div className="row">
                                <strong>Balance</strong>
                              </div>
                              <div className="row mt-3 ">
                                <div className="col-12">
                                  <table id="tbl_balance" className="col-12">
                                    <thead>
                                      <tr>
                                        <th>Evidencia</th>
                                        <th>Monto</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Adeudos</td>
                                        <td>
                                          ${FormatNumberMoney2(TotalAdeudos)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Pagos cliente</td>
                                        <td>
                                          ${FormatNumberMoney2(TotalPagos)}
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>Pendiente</td>
                                        {/* <td>$100.00</td> */}
                                        <td>
                                          $
                                          {FormatNumberMoney2(
                                            TotalAdeudos - TotalPagos
                                          )}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {totalAdeudo - totalPago > 0 && (
                            <>
                              <div className="row mt-2 px-4">
                                <div className="col-12 col-lg-3">
                                  <SelectField
                                    id="vehiculoApto"
                                    label="¿Aplica financiamiento?"
                                    name="aplicaFinanciamiento"
                                    disabled={initialValue.concluido}
                                    items={opcionesBool}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "aplicaFinanciamiento",
                                        event.value
                                      );
                                      if (event.value === "") {
                                        setFieldValue("montoFinanciar", "");
                                      } else if (event.value)
                                        setFieldValue(
                                          "montoFinanciar",
                                          TotalAdeudos - TotalPagos
                                        );
                                    }}
                                  />
                                </div>
                                {values.aplicaFinanciamiento && (
                                  <div className="col-12 col-lg-3">
                                    <TextField
                                      isMoney={true}
                                      icon={"bi bi-currency-dollar"}
                                      disabled={true}
                                      label="Monto a financiar"
                                      holder="Monto a Financiar"
                                      name="montoFinanciar"
                                      type="text"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "montoFinanciar",
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          <div className="row mt-4 px-4 mb-2">
                            <div className="col-auto d-flex align-items-center">
                              <h6>
                                <strong>Subir Documentos</strong>
                              </h6>
                              <fieldset
                                disabled={initialValue?.acreditacionConcluido}
                                onClick={handleUploadFile}
                                className="btn btn-gray ms-4"
                              >
                                <i className="icon-light fa fa-file "></i> Subir
                                documento
                              </fieldset>
                            </div>
                          </div>

                          <div className="row mt-4 px-4">
                            <div className="col-12 col-lg-6">
                              <Field
                                className="form-check-input"
                                disabled={initialValue?.concluido}
                                type="checkbox"
                                name="concluido"
                                onClick={(event) => {
                                  setFieldValue(
                                    "concluido",
                                    event.target.value
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                style={{ marginLeft: "10px" }}
                              >
                                Papelería completa para iniciar tramite
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row mt-2 px-4 d-flex justify-content-end">
                        <div className="col-12 col-lg-6">
                          <div className="row flex-column align-items-end">
                            <div className="col-auto">
                              {initialValue?.fechaInicio !== "" && (
                                <small className="text-body-tertiary">
                                  Inicio de proceso: {initialValue?.fechaInicio}
                                </small>
                              )}
                            </div>
                            <div className="col-auto">
                              {initialValue?.concluido && (
                                <small className="text-body-tertiary">
                                  Fin de proceso: {initialValue?.fechaFin}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GestoriaPreparacionPapeleria;
