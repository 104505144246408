import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import PagoUnidad from '../../Services/PagoUnidad/PagoUnidad';
import dataDocsServices from '../../Services/DataDocs/dataDocsServices';
import AuthLogin from "../../Utils/AuthLogin";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { message } from '../../Utils/functions';
import { FormatFecha, FormatNumberMoney } from '../../Utils/functions';

const SeguimientoPagoUnidadesDetalleHook = (value) => {
  const navigate = useNavigate();
  const auth = new AuthLogin();
  let url = auth.UrlDataDocs();

  const columns = [
    { field: 'vin', headerName: 'VIN' },
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'fechaEntregaFormat', headerName: 'Fecha de entrega' },
    { field: 'razonSocial', headerName: 'Razón social' },
    { field: 'valorUnidadFormat', headerName: 'Valor de la unidad' },
    { field: 'nombreMarca', headerName: 'Marca' },
    { field: 'nombreModelo', headerName: 'Modelo' },
    { field: 'nombreVersion', headerName: 'Version' },
    { field: 'nombreYear', headerName: 'Año' },
  ];

  const priceColumns = [
    { field: 'vin', headerName: 'VIN' },
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'fechaEntregaFormat', headerName: 'Fecha de entrega' },
    { field: 'razonSocial', headerName: 'Razón social' },
    { field: 'valorUnidadFormat', headerName: 'Valor de la unidad' },
    { field: 'nombreMarca', headerName: 'Marca' },
    { field: 'nombreModelo', headerName: 'Modelo' },
    { field: 'nombreVersion', headerName: 'Version' },
    { field: 'nombreYear', headerName: 'Año' },
    { field: 'acciones', headerName: 'Ver evidencia' },
  ];

  const concludeColumns = [
    // { field: 'folio', headerName: 'Folio' },
    // { field: 'fechaPago', headerName: 'Fecha de pago' },
    // { field: 'userFactura', headerName: 'Usuario pago' },
    { field: 'vin', headerName: 'VIN' },
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'fechaEntregaFormat', headerName: 'Fecha de entrega' },
    { field: 'valorUnidadFormat', headerName: 'Valor de la unidad' },
    { field: 'nombreMarca', headerName: 'Marca' },
    { field: 'nombreModelo', headerName: 'Modelo' },
    { field: 'nombreVersion', headerName: 'Version' },
    { field: 'nombreYear', headerName: 'Año' },
    { field: 'acciones', headerName: 'Ver evidencia' },
  ];


  const [name, setName] = useState("")
  const [sumaTotal, setSumaTotal] = useState(0)
  const [montoTotalPorPagar, setMontoTotalPorPagar] = useState('')
  const [data, setData] = useState([])

  const [pageRows, setpageRows] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentData, setCurrentData] = useState([]);
  const [filteredData, setFilteredData] = useState([])

  const [loading, setLoading] = useState(false)

  const [selectedItems, setSelectedItems] = useState([]);
  const [pantalla, setPantalla] = useState(value.estatus)
  // "Inicial"
  // "Configuración provisional"
  // "Pendiente por pagar"
  // "Pago en proceso"
  // "Concluido"
  const [DiferenciaTotal, setDiferenciaTotal] = useState(0);

  //sUBIR DOCUMENTOS
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [fileName, setFileName] = useState("")
  const [SelectedDocs, setSelectedDocs] = useState([]);

  //Modal
  const [isOpenModalRegistrarPago, setisOpenModalRegistrarPago] = useState(false)
  const [infoRegistro, setInfoRegistro] = useState()
  const [isOpenModalLimite, setisOpenModalLimite] = useState(false)
  const [isOpenModalDesasignar, setisOpenModalDesasignar] = useState(false)


  //Selects
  const [metodosPago, setMetodosPago] = useState([])
  const [formaPago, setFormaPago] = useState([])

  //Consulta de pagos
  const [unidadesPagadas, setUnidadesPagadas] = useState(0)
  const [unidadesPendientes, setUnidadesPendientes] = useState(0)
  const [montoTotalPagado, setMontoTotalPagado] = useState(0)
  const [MontoTotalPagadoFormat, setMontoTotalPagadoFormat] = useState("0")


  const handleReturnPage = () => {
    navigate(`/inventario/pagoUnidad/seguimiento-pago-unidades`)
  }

  useEffect(() => {
    getAllData()
    // getFilteredData()
    getAllMetodosdePago()
    getAllFormaPago()
  }, [])

  useEffect(() => {
    //valores de entrada
    const newItems = value.items || []
    // Filtra los datos según los filtros seleccionados
    let filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.vin && item.vin.toLowerCase().includes(name.toLowerCase()));
      const contratoMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));
      // const tipoCompraMatches = name === '' || (item.tipoCompra && item.tipoCompra.toLowerCase().includes(name.toLowerCase()));
      const razonSocialMatches = name === '' || (item.razonSocial && item.razonSocial.toLowerCase().includes(name.toLowerCase()));
      const fechaMatches = name === '' || (item.fechaEntregaFormat && item.fechaEntregaFormat.toLowerCase().includes(name.toLowerCase()));
      const valorUnidadMatches = name === '' || (item.valorUnidadFormat && item.valorUnidadFormat.toLowerCase().includes(name.toLowerCase()));
      const valorUnidadMatches2 = name === '' || (item.montoFinanciar && item.montoFinanciar.toString().toLowerCase().includes(name.toLowerCase()));
      let inventario = true

      if (value.tipoCompra === "Inventario") {
        inventario = (item.tipoCompra === "Inventario") || (item.razonSocial === "AUTOS DATAMOVIL")
      }

      return (
        (nameMatches ||
          contratoMatches ||
          // tipoCompraMatches ||
          razonSocialMatches ||
          valorUnidadMatches ||
          valorUnidadMatches2 ||
          fechaMatches
          ) 
          &&
        (inventario)
      )
    })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows

    let newFilteredData = data.filter(data => newItems.some(item => (item.vin === data.vin) && (data.pagounidadestatus !== 0)))

    if (pantalla !== "Inicial") {
      newFilteredData = newFilteredData.filter(item => {
        const nameMatches = name === '' || (item.vin && item.vin.toLowerCase().includes(name.toLowerCase()));
        const contratoMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));
        // const tipoCompraMatches = name === '' || (item.tipoCompra && item.tipoCompra.toLowerCase().includes(name.toLowerCase()));
        const razonSocialMatches = name === '' || (item.razonSocial && item.razonSocial.toLowerCase().includes(name.toLowerCase()));
        const fechaMatches = name === '' || (item.fechaEntregaFormat && item.fechaEntregaFormat.toLowerCase().includes(name.toLowerCase()));
        const valorUnidadMatches = name === '' || (item.valorUnidadFormat && item.valorUnidadFormat.toLowerCase().includes(name.toLowerCase()));
        const valorUnidadMatches2 = name === '' || (item.montoFinanciar && item.montoFinanciar.toString().toLowerCase().includes(name.toLowerCase()));
  
        return (
          nameMatches ||
          contratoMatches ||
          // tipoCompraMatches ||
          razonSocialMatches ||
          valorUnidadMatches ||
          valorUnidadMatches2 ||
          fechaMatches
        )
      })

      const newData = newFilteredData.slice(indexOfFirstItem, indexOfLastItem)
      setFilteredData(newData)
    }

    //Filtramos todos los 0 y 1 que tenga
    let filteredItems = []
    if (value.items !== null) {
      filteredItems = filteredData.filter(item => {
        return (item.pagounidadestatus === 0) || ((item.pagounidadestatus === 1) && value.items.some(value => value.vin === item.vin))
      })
    } else {
      filteredItems = filteredData.filter(item => {
        return (item.pagounidadestatus === 0)
      })
    }

    const newData = filteredItems.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    if (pantalla === "Inicial" && pantalla === "Configuración provisional") {
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
    } else {
      const newTotalPages = Math.ceil(newFilteredData.length / pageRows)
      setPageCount(newTotalPages)
    }
    //Arreglar los checbox?
    let table = document.querySelectorAll('.tbl-custome >tr');

    for (let index = 0; index < table.length; index++) {
      const element = table[index];
      if (newData[index]) {
        if (newData[index].statusCheckBox) {
          element.firstChild.firstChild.checked = true;
        } else {
          element.firstChild.firstChild.checked = false;
        }
      }
    }

  }, [currentPage, name, pageRows, data, pantalla])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows, name])

  useEffect(() => {
    const format = FormatNumberMoney(sumaTotal)
    setMontoTotalPorPagar(`$${format}`)
  }, [sumaTotal])

  const getAllData = async () => {
    let unidadesPagadas = []
    if (pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" || pantalla === "Concluido") {
      //vin - pago_id
      unidadesPagadas = await PagoUnidad.getAllRegistroPagoUnidades()
        .then(res => {
          return res.data.responseData
        })
    }

    setLoading(true)
    let SumaTotal = 0
    let frecuencias = await PagoUnidad.getAllReportePagoUnidad() //Cambiar a servcio q traer todos con estatus 0 0
      .then(res => {
        let response = res.data
          // .filter(item => (item.pagounidadestatus === 1 ))
          .map(item => {
            let isSelected = false
            let filteredArray
            let totalPagado = 0
            if (value.items !== null) {
              isSelected = value.items.some((selectedItem) => selectedItem.vin === item.vin)
            }

            if (isSelected) {
              SumaTotal = SumaTotal + item.montoFinanciar
              setSelectedItems((prevSelected) => [...prevSelected, item]);
            }

            if (pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" || pantalla === "Concluido") {
              const filterConditions = [
                { vin: item.vin, pago_id: value.id }
              ];

              filteredArray = unidadesPagadas.filter(unidadesPagadas => {
                return filterConditions.some(condition => {
                  return condition.vin === unidadesPagadas.vin && condition.pago_id === unidadesPagadas.pago_id
                })
              })

              totalPagado = filteredArray.reduce((acc, monto) => acc + monto.montopagado, 0);
            }

            let visibleColumnMoney = true
            if (totalPagado >= item.montoFinanciar) visibleColumnMoney = false

            let visibleColumnDesasignar = true
            if (totalPagado > 0) visibleColumnDesasignar = false

            const fechaFormat = FormatFecha(item.fechaEntrega)

            const valorUnidadFormat = FormatNumberMoney(item.montoFinanciar)

            return {
              ...item,
              statusCheckBox: isSelected ? true : false,
              pagos: filteredArray,
              totalPagado: totalPagado,
              visibleColumnMoney: visibleColumnMoney,
              visibleColumnDesasignar: visibleColumnDesasignar,
              fechaEntregaFormat: fechaFormat,
              valorUnidadFormat: `$${valorUnidadFormat}`
            }
          })

        setSumaTotal(SumaTotal)
        setLoading(false)
        return response
      }).catch(e => {
        setLoading(false)
      })

    let UnidadesPagadas = 0
    let UnidadesPendientes = 0
    let MontoTotalPagado = 0
    frecuencias.forEach(item => {
      if (item.pagos) {
        if (item.pagos.length > 0) {
          const suma = item.pagos.reduce((acc, monto) => acc + monto.montopagado, 0);
          MontoTotalPagado = MontoTotalPagado + suma
          if (item.totalPagado >= item.montoFinanciar) {
            UnidadesPagadas = UnidadesPagadas + 1
          } else {
            UnidadesPendientes = UnidadesPendientes + 1
          }
        }
      }

    });

    const MontoTotalPagadoFormat = FormatNumberMoney(MontoTotalPagado)
    setUnidadesPagadas(UnidadesPagadas)
    setUnidadesPendientes(UnidadesPendientes)
    setMontoTotalPagado(MontoTotalPagado)
    setMontoTotalPagadoFormat(`$${MontoTotalPagadoFormat}`)
    setData(frecuencias)
    const DiferenciaFormat = FormatNumberMoney(SumaTotal - MontoTotalPagado)
    setDiferenciaTotal(`$${DiferenciaFormat}`)
  }


  const getAllMetodosdePago = async () => {
    setLoading(true);
    await PagoUnidad.getAllMetodosDePago()
      .then(res => {
        let newValue = [{
          value: "",
          label: null
        }]
        res.data.responseData?.map(item => {
          if (item.activo) {
            newValue.push({
              value: item.id,
              label: item.nombre
            });
          }
        });
        setMetodosPago(newValue)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  const getAllFormaPago = async () => {
    setLoading(true);
    await PagoUnidad.getAllFormaDePago()
      .then(res => {
        let newValue = [{
          value: "",
          label: null
        }]
        res.data.responseData?.map(item => {
          if (item.activo) {
            newValue.push({
              value: item.id,
              label: item.nombre,
              code: item.code
            });
          }
        });
        setFormaPago(newValue)
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }


  const handlechekedItem = (check, item, i) => {
    let table = document.querySelectorAll('.tbl-custome >tr');
    const number = parseFloat(value.presupuesto.replace(/,/g, ''));

    item.statusCheckBox = check

    if (check) {
      if ((sumaTotal + item.montoFinanciar) > number) {
        for (let index = 0; index < table.length; index++) {
          const element = table[index];
          if (index === i) {
            item.statusCheckBox = false
            element.firstChild.firstChild.checked = false;
          }
        }
        setisOpenModalLimite(true)
        return null
      }
      setSumaTotal((prevTotal) => parseFloat((prevTotal + item.montoFinanciar).toFixed(2))); // Suma si está seleccionado
      value.numeroitemsxfolio = value.numeroitemsxfolio + 1
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    } else {
      setSumaTotal((prevTotal) => parseFloat((prevTotal - item.montoFinanciar).toFixed(2))); // Resta si se deselecciona
      value.numeroitemsxfolio = value.numeroitemsxfolio - 1
      setSelectedItems((prevSelected) => prevSelected.filter((selectedItem) => selectedItem.vin !== item.vin));
    }
    // check ? dispatch(chekItem({ check, item: { ...item, ventaContado: true } })) : dispatch(chekItem({ check, item: null }));
    // let table = document.querySelectorAll('.tbl-custome >tr');
    // // setCurrentChecked(...currentChecked, item)
    // for (let index = 0; index < table.length; index++) {
    //   const element = table[index];
    // }
  }


  const actualizarSeguimientoPago = async (data) => {
    await PagoUnidad.actualizaSeguimientoPago(data)
  }

  // Comparar arrays con validación para array1
  const compararArrays = async (array1, array2) => {
    // Si array1 es null, lo tratamos como un array vacío
    const array1Valid = array1 || [];
    // 1. Encontrar los elementos que están en el primer arreglo pero no en el segundo
    // await array1Valid.forEach(item1 => {
    for (const item1 of array1Valid) {
      const existeEnSegundo = array2.some(item2 => item2.id === item1.id);
      if (!existeEnSegundo) {
        const dataItem = {
          "vin": item1.vin,
          "folio_pago": value.id
        }
        const dataEstatus = { generalId: item1.generalId, pagounidadestatus: 0 };

        await desasignarVehiculo(dataItem); // Llama a la función Eliminar si no existe en el segundo
        await actualizarEstatusPagoVehiculo(dataEstatus)
      }
      // });
    }

    // 2. Encontrar los elementos que están en el segundo arreglo pero no en el primero
    // await array2.forEach(item2 => {
    for (const item2 of array2) {
      const existeEnPrimero = array1Valid.some(item1 => item1.id === item2.id);
      if (!existeEnPrimero) {
        const dataItem = {
          "vin": item2.vin,
          "folio_pago": value.id
        }
        const dataEstatus = { generalId: item2.generalId, pagounidadestatus: 1 };
        asignarVehiculo(dataItem); // Llama a la función Agregar si no existe en el primero
        actualizarEstatusPagoVehiculo(dataEstatus)
      }
      // });
    }
  };

  const asignarVehiculo = async (data) => {
    await PagoUnidad.asignarVehiculoPago(data)
  }

  const desasignarVehiculo = async (data) => {
    await PagoUnidad.desasignaVehiculoPago(data)
  }


  const actualizarEstatusPagoVehiculo = async (data) => {
    //0 es sin asignar,
    //1 asignado,
    //2 pago parcial, 
    //3 pago total
    await PagoUnidad.UpdateActualizaEstatusPago(data)
  }

  const handleConfiguracionProvisional = async () => {
    const data = {
      id: value.id,
      tipoCompra: value.tipoCompra,
      presupuesto: value.presupuesto,
      estatus: "Configuración provisional"
    }

    try {
      setLoading(true)
      await compararArrays(value.items, selectedItems)
      await actualizarSeguimientoPago(data)
      handleReturnPage()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleEditar = async () => {
    setPantalla("Inicial")
  }

  const handleAutorizarPago = async () => {
    const data = {
      id: value.id,
      tipoCompra: value.tipoCompra,
      presupuesto: value.presupuesto,
      estatus: "Pendiente por pagar"
    }
    try {
      setLoading(true)
      await compararArrays(value.items, selectedItems)
      await actualizarSeguimientoPago(data)
      //Como se llama el servicio de josue xD siempre se me ovlida
      handleReturnPage()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRegistrarPago = async (montoPagado, vin) => {

    const data = {
      id: value.id,
      tipoCompra: value.tipoCompra,
      presupuesto: value.presupuesto,
      estatus: "Pago en proceso"
    }

    try {
      setLoading(true)
      await actualizarSeguimientoPago(data)

      let nuevoMontoTotalPagado = montoTotalPagado + montoPagado
      const MontoTotalPagadoFormat = FormatNumberMoney(nuevoMontoTotalPagado)
      let nuevasUnidadesPendientes = unidadesPendientes
      setMontoTotalPagado(nuevoMontoTotalPagado)
      setMontoTotalPagadoFormat(`$${MontoTotalPagadoFormat}`)
      const DiferenciaFormat = FormatNumberMoney(sumaTotal - nuevoMontoTotalPagado)
      setDiferenciaTotal(`$${DiferenciaFormat}`)
      let newFilter = filteredData.map(item => {
        if (item.pagos) {
          if (item.pagos.length > 0) {
            if (item.vin === vin) {
              let suma = item.pagos.reduce((acc, monto) => acc + monto.montopagado, 0);
              suma = suma + montoPagado
              item.pagos.push({ montopagado: montoPagado })
              item.visibleColumnDesasignar = false
              if (suma >= item.montoFinanciar) {
                //llamar a servcio y actualizar estatus a 2

                setUnidadesPagadas(prevValue => prevValue + 1)
                setUnidadesPendientes(prevValue => prevValue - 1)
                nuevasUnidadesPendientes = nuevasUnidadesPendientes - 1
                item.visibleColumnMoney = false
              }
            }
          }
        }

        return item
      })
      setFilteredData(newFilter)
      if (nuevasUnidadesPendientes <= 0) {
        const data = {
          id: value.id,
          tipoCompra: value.tipoCompra,
          presupuesto: value.presupuesto,
          estatus: "Concluido"
        }

        await actualizarSeguimientoPago(data)
        setPantalla("Concluido")

      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDesasignarUpdate = async (data) => {
    const dataItem = {
      "vin": data.vin,
      "folio_pago": value.id
    }

    const dataEstatus = { generalId: data.generalId, pagounidadestatus: 0 };

    try {
      await desasignarVehiculo(dataItem)
      await actualizarEstatusPagoVehiculo(dataEstatus)
      const updateData = filteredData.filter(item => item.vin !== data.vin)
      if ((unidadesPendientes - 1) <= 0) {
        const data = {
          id: value.id,
          tipoCompra: value.tipoCompra,
          presupuesto: value.presupuesto,
          estatus: "Concluido"
        }

        await actualizarSeguimientoPago(data)
        setPantalla("Concluido")

      }
      setUnidadesPendientes((prevValue) => prevValue - 1)
      let montoTotal = sumaTotal - data.montoFinanciar
      const DiferenciaFormat = FormatNumberMoney(montoTotal)
      setDiferenciaTotal(`$${DiferenciaFormat}`)

      setFilteredData(updateData)
    } catch (error) {
      alert(error)
    }
  }

  const handleDesasignarVehiculo = (value) => {
    setInfoRegistro(value)
    setisOpenModalDesasignar(true)
  }
  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => { })
  }


  const handleViewdocs = async (item) => {
    setLoading(true);
    setFileName(item.nombreDocumento);
    await handleGetTokenDatadocs();
    await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      },
      responseType: "blob"
    })
      .then(res => {

        var file = new File([res.data], res.data.name, { type: res.data.type });
        setSelectedDocs([file])
        setLoading(false);
        setIsOpenDocs(true)
      }).catch(e => {

        setLoading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.errors?.error));
        else
          toast.error(message("Error.!", e.message));
      })
  }

  const handleUploadFile = async (valueFile, subido) => {
    // if (subido) {
    //   await handleViewdocs(value)
    // } else 
    // {
    valueFile.sol_id = valueFile.sol_Id
    valueFile.id = value.id
    valueFile.numIdFolder = valueFile.folderId
    valueFile.productoId = 1
    valueFile.pagoUnidadDetalles = true
    valueFile.tipoCompraId = "539ac831-8ab2-4fa2-af95-02723c17ed25"
    navigate(`/inventario/pagoUnidad/datadocs/${valueFile.generalId}`, {
      state: valueFile,

    });
    // }
  }

  const handleModalRegistroPago = (value) => {
    setInfoRegistro(value)
    setisOpenModalRegistrarPago(true)
  }
  const getUnidadesXFolio = async (value) => {

    setLoading(true);
    const data = { pago_id: value };
    await PagoUnidad.exportarPagoUnidadesxFolio(data)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `PagoUnidadesxFolio-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  };

  return {
    columns,
    priceColumns,
    concludeColumns,
    currentPage,
    pageCount,
    setpageRows,
    setName,
    handleReturnPage,
    loading,
    setLoading,
    currentData,
    filteredData,
    sumaTotal,
    montoTotalPorPagar,
    handlechekedItem,
    handlePageClick,

    handleConfiguracionProvisional,
    handleEditar,
    handleAutorizarPago,
    handleRegistrarPago,

    pantalla,
    setPantalla,
    DiferenciaTotal,
    metodosPago,
    formaPago,
    getUnidadesXFolio,
    handleViewdocs,
    handleUploadFile,
    isOpenDocs,
    setIsOpenDocs,
    fileName,
    SelectedDocs,

    handleModalRegistroPago,
    isOpenModalRegistrarPago,
    setisOpenModalRegistrarPago,
    infoRegistro,
    isOpenModalLimite,
    setisOpenModalLimite,
    handleDesasignarVehiculo,
    isOpenModalDesasignar,
    setisOpenModalDesasignar,

    handleDesasignarUpdate,

    unidadesPagadas,
    unidadesPendientes,
    montoTotalPagado,
    MontoTotalPagadoFormat,
  };

}
export default SeguimientoPagoUnidadesDetalleHook;