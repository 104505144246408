import { Form, Formik, Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { TextField } from "../../../../Components/TextField";
import { SelectField } from "../../../../Components/SelectField";
import InicioDeValuacionService from "../../../../Services/Inventario/Valuacion/InicioDeValuacionService";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import useRegistroDeGastos from "../../../../Hooks/Inventario/Valuacion/useRegistroDeGastos";
import ModalCostoGrua from "./ModalCostoGrua";
import {
  clearScheduleByTipoCalendario,
  selectScheduleByTipoCalendario,
} from "../../../../Redux/Slice/Calendario/calendarDynamicalSlice";
import AuthLogin from "../../../../Utils/AuthLogin";
import {
  formatearFecha,
  getHourFormat,
  IsNullOrEmpty,
  message,
} from "../../../../Utils/functions";
import Garantias from "../../../../Services/Cartera/Garantias";
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from "../../../../Auth/ValidatePermission";
import { Section, SubModule } from "../../../../Auth/Authorization";

const RecoleccionDeUnidad = ({ handleConcluido }) => {
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = new AuthLogin();

  const { handleChangeStatusBtn } = useRegistroDeGastos(toast);

  let initial = {
    valuacionInicio_RecoleccionUnidadId: "",
    valuacionId: "",
    folio: "",
    usuarioProgramo: null,
    grua: null,
    empresaId: "",
    empresa: null,
    gruaAsignada: null,
    referenciaUbicacion: null,
    origenTipoUbicacion: "interno",
    origenUbicacion: null,
    origenFechaRecoleccion: null,
    origenHoraRecoleccion: null,
    destinoTipoUbicacion: null,
    destinoUbiccion: null,
    fechaCita: null,
    horaCita: null,
    estatusCita: null,
    autorizarCita: false,
    concluido: false,
    valuacionStatus_SubStatusId: 0,
    fechaInicio: "",
    fechaFin: null,
    iva: 0,
    subTotal: 0,
    total: 0,
    subido: false,
  };

  const CitaServicioDatamovil = useSelector((state) =>
    selectScheduleByTipoCalendario(state, 23)
  );
  const [loading, setLoading] = useState(false);
  const [collpsableRecoleccion, setCollpsableRecoleccion] = useState(false);
  const [initialValue, setInitialValue] = useState(initial);
  const [OrigenGrua, setOrigenGrua] = useState([]);
  const [Dataempresa, setDataempresa] = useState([]);
  const [tipoUbicacion, setTipoUbicacion] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [ubicacionSelect, setubicacionSelect] = useState([
    { label: "Interno", value: "interno" },
    { label: "Externo", value: "externo" },
  ]);

  const { refFormik } = useRef();

  useEffect(() => {
    HandleGetOrigenGrua();
    handleGetEmpresa();
    handleGetTipoUbicacion();
    getAll();
  }, [id]);

  const HandleGetOrigenGrua = async () => {
    await InicioDeValuacionService.getOrigenGrua().then((res) => {
      let OrigenGruas = [];
      res.data.data?.map((item) => {
        OrigenGruas.push({
          value: item.origenGruaId,
          label: item.origenGrua,
          Mover: item.mover,
        });
      });
      setOrigenGrua(OrigenGruas);
    });
  };

  const handleGetEmpresa = async () => {
    await InicioDeValuacionService.getEmpresa()
      .then((res) => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos",
        });
        res.data.data?.map((item) => {
          NewData.push({
            value: item.empresaId,
            label: item.empresa,
          });
        });
        setDataempresa(NewData);
      })
      .catch((e) => { });
  };

  const handleGetTipoUbicacion = async () => {
    await InicioDeValuacionService.getdataTipoUbicacion()
      .then((res) => {
        let NewData = [];
        res.data.data?.map((item) => {
          NewData.push({
            value: item.ubicacionId,
            label: item.ubicacion,
          });
        });
        setTipoUbicacion(NewData);
      })
      .catch((e) => { });
  };

  const formatDate = (fecha) => {
    const dateParts = fecha.split(" ")[0].split("/");
    if (dateParts.length !== 3) return fecha;
    const [month, day, year] = dateParts;
    return `${year}-${month}-${day}`;
  };

  const getAll = async () => {
    setLoading(true);
    await InicioDeValuacionService.GetRecoleccionVehiculo(id)
      .then((resp) => {
        let resdata = resp.data;

        const _origenFechaRecoleccion = resdata.origenFechaRecoleccion
          ? formatDate(resdata.origenFechaRecoleccion)
          : null;

        if (!resdata.concluido) {
          resdata.destinoUbiccion = "";
          resdata.fechaHoraCita = "";
        }

        //resdata.destinoTipoUbicacion = resdata?.destinoTipoUbicacion ? resdata?.destinoTipoUbicacion : CitaServicioDatamovil?.day ? 1 : "";

        resdata.destinoUbiccion = !IsNullOrEmpty(CitaServicioDatamovil?.day)
          ? CitaServicioDatamovil.sucursalName
          : !IsNullOrEmpty(resdata?.destinoUbiccion)
            ? resdata?.destinoUbiccion
            : ""; //Hizo cesar

        resdata.calendarioId =
          resdata?.calendarioId ?? CitaServicioDatamovil?.calendarioId ?? "";
        resdata.sucursalId =
          resdata.sucursalId ?? CitaServicioDatamovil?.sucursalId ?? "";
        resdata.fechaHoraCita = !IsNullOrEmpty(CitaServicioDatamovil?.day)
          ? formatearFecha(
            `${CitaServicioDatamovil.day}T${CitaServicioDatamovil.hour}`
          )
          : resdata.fechaCita;
        resdata.tipoCalendario = 23;
        resdata.fechaCita = !IsNullOrEmpty(CitaServicioDatamovil?.day)
          ? CitaServicioDatamovil.day
          : !IsNullOrEmpty(resdata?.fechaCita)
            ? resdata?.fechaCita
            : "";
        //resdata.fechaCita = CitaServicioDatamovil?.day ?? resdata.fechaCita ?? "";
        resdata.horaCita = !IsNullOrEmpty(CitaServicioDatamovil?.day)
          ? CitaServicioDatamovil.hour
          : !IsNullOrEmpty(resdata?.horaCita)
            ? resdata?.horaCita
            : "";
        //resdata.horaCita = CitaServicioDatamovil.hour ?? resdata.horaCita ?? "";

        resdata.usuarioProgramo = auth.getNombreUsuario();

        resdata.origenTipoUbicacion = "interno";
        resdata.destinoTipoUbicacion = "interno";

        setInitialValue({
          ...resdata,
          origenFechaRecoleccion: _origenFechaRecoleccion,
        });

        let concluido =
          resdata.concluido && resdata.codEstatus == 2 ? true : false;
        handleConcluido({ concluido: concluido });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const scheduleData = useSelector((state) =>
    selectScheduleByTipoCalendario(state, 23)
  );
  const submitForm = async (values) => {
    if (values.concluido) {
      if (!values.subido) {
        toast.custom(
          <div className="warnin-toast">
            {message("¡Alerta.!", "Falta subir evidencia.")}
          </div>
        );
        setTimeout(() => {
          toast.remove();
        }, 3000);
        return;
      }
    }

    values.procesoId = state.procesoId;
    values.paso = 1;
    values.porcentaje = 100;
    values.status = 2;
    setLoading(true);

    const Proceso = `Valuación`;
    const titleCorreo = `Autorización de cita grúa ${Proceso} ${"#" + CitaServicioDatamovil?.contrato
      } - ${CitaServicioDatamovil?.marca} ${CitaServicioDatamovil?.modelo} ${CitaServicioDatamovil?.ano
      }`;
    const contenido = {
      VARIABLE_USUARIO: sessionStorage.getItem("nombreUsuario"),
      VARIABLE_PROCESO: Proceso,
      VARIABLE_VIN: CitaServicioDatamovil?.vin ?? scheduleData.vin,
      VARIABLE_MARCA: CitaServicioDatamovil?.marca ?? scheduleData.marca,
      VARIABLE_MODELO: CitaServicioDatamovil?.modelo ?? scheduleData.modelo,
      VARIABLE_AÑO: CitaServicioDatamovil?.ano ?? scheduleData.ano,
      VARIABLE_UBICACION:
        CitaServicioDatamovil?.sucursalName ?? values.destinoUbiccion,
      VARIABLE_FECHA: IsNullOrEmpty(CitaServicioDatamovil?.day)
        ? ""
        : CitaServicioDatamovil?.day?.split("-")?.reverse()?.join("-") ??
        values?.fechaCita,
      VARIABLE_HORA: IsNullOrEmpty(CitaServicioDatamovil?.hour)
        ? ""
        : getHourFormat(CitaServicioDatamovil?.hour) ??
        getHourFormat(values?.horaCita),
    };
    await InicioDeValuacionService.updateRecoleccion(values)
      .then((resp) => {
        if (resp.data.ban === 1) {
          toast.success(message("Correcto", resp.data.mensaje));
          getAll(1);
          if (values.concluido) {
            Garantias.creardatosenviar({
              modulo: titleCorreo,
              tipoCorreoId: 113,
              contenido: JSON.stringify(contenido),
            }).catch((err) => {
              console.log(
                "Error al enviar correo Revisión de Unidad Aprobada:",
                err
              );
            });
          }
        } else {
          toast.error(message("Error", resp.data.mensaje));
        }

        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 23 }));
      })
      .catch((err) => {
        //console.error(err);
        toast.error(
          message(
            "¡Error, al intentar actualizar el registro de pago de trámites de unidad!"
          )
        );
      })
      .finally(() => {
        getAll();
        setLoading(false);
      });
  };

  const verCalendario = async (numero, values) => {
    setInitialValue({ ...values });
    navigate(`/datamovil/citas/v2/23`, {
      state: {
        ...state,
        title: "Recoleccion de unidad",
        tipoCalendario: 23,
      },
    });
  };

  const validate = Yup.object({
    grua: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("Selecciona una opción"),
      }),
    empresaId: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("Selecciona una opción"),
      }),
    gruaAsignada: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("El campo es requerido."),
      }),
    total: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("El campo es requerido."),
      }),

    origenFechaRecoleccion: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("El campo es requerido."),
      }),
    origenHoraRecoleccion: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("El campo es requerido."),
      }),
    destinoTipoUbicacion: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("Selecciona una opción"),
      }),
    // destinoUbiccion: Yup.string()
    //     .nullable()
    //     .when('concluido', {
    //         is: true,
    //         then: Yup.string()
    //             .nullable()
    //             .required("El campo es requerido."),
    //     }),
    fechaHoraCita: Yup.string()
      .nullable()
      .when("concluido", {
        is: true,
        then: Yup.string().nullable().required("El campo es requerido."),
      }),
  });

  const handleUploadFile = async (values) => {
    navigate(
      `/datamovil/datadocs/fValuacion/eRecoVal/${state.contrato}/${values.valuacionInicio_RecoleccionUnidadId}`,
      {
        state: {
          ...state,
          registroId: values.valuacionInicio_RecoleccionUnidadId,
          vin: state.vin,
          nombreMarca: state.marca,
          nombreModelo: state.modelo,
          nombreVersion: state.version,
          nombreYear: state.anio,
        },
      }
    );
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <div className="row mx-md-4 my-4">
        <div className="col-12 px-md-3">
          <div className="p-4 white-wrapper mb-2">
            <div
              className="row mt-2"
              data-bs-toggle="collapse"
              href="#collapseRecoleccion"
              role="button"
              aria-expanded="false"
              aria-controls="collapsePagoTramites"
              onClick={() => setCollpsableRecoleccion(!collpsableRecoleccion)}
            >
              <div className="col-11">
                <h6>
                  {" "}
                  <strong>Recolección de Unidad</strong>
                </h6>
              </div>
              <div
                className="col-1 col-md-1 collapsable-icon"
                style={{ textAlign: "right" }}
              >
                <i
                  className={
                    !collpsableRecoleccion
                      ? "icon-blue bx bx-chevron-down x2"
                      : "icon-blue bx bx-chevron-up x2"
                  }
                ></i>
              </div>
              <div className="col-12">
                <small>{initialValue.siniestroPagoTramitesStatus}</small>
              </div>
            </div>
            <div className="collapse" id="collapseRecoleccion">
              <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validationSchema={validate}
                initialValues={initialValue}
                onSubmit={(values) => {
                  submitForm({ ...values });
                }}
              >
                {({ isValid, values, setFieldValue, errors }) => (
                  <>
                    <Form>
                      <div className="separator"></div>
                      <div className="row mt-2 mb-2">
                        <div className="col-4">
                          {/* <h6><strong>Recolección de Unidad</strong></h6> */}
                        </div>
                        <div className="col-8">
                          <div className="row justify-content-end">
                            <div className="col-auto">
                              <button
                                id="btnSubirDocumento"
                                className="btn btn-outline col-12 mw-100 d-flex "
                                type="button"
                                onClick={() => {
                                  handleUploadFile(values);
                                }}
                                style={{
                                  padding: "8px",
                                  lineHeight: "20px",
                                  border: "none",
                                  backgroundColor: "#f0f0f0",
                                }}
                              >
                                <i
                                  className="ri-file-3-fill fs-4"
                                  style={{
                                    color: "#004C97",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                Subir documento
                              </button>
                            </div>
                            <div className="col-auto">
                              {initialValue.concluido &&
                                initialValue.fechaFin ? (
                                <fieldset className="btn btn-light secondary d-flex align-items-center mx-2">
                                  <i className="icon-dark bx bx-hide mx-1"></i>
                                  Modo lectura
                                </fieldset>
                              ) : (
                                <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.SeguimientoInicioDeValuacion, SubModule.SeguimientoValuacion))}>
                                  <button
                                    className="btn"
                                    disabled={
                                      initialValue.concluido &&
                                      initialValue.fechaFin
                                    }
                                    type="submit"
                                  >
                                    Guardar
                                  </button>
                                </ValidatePermission>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2 mb-2">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <TextField
                            label="Folio"
                            name="folio"
                            type="text"
                            holder="Escribe"
                            disabled
                            onChange={(event) => {
                              setFieldValue("folio", event.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <TextField
                            label="Usuario Programó"
                            name="usuarioProgramo"
                            type="text"
                            disabled
                            holder="Escribe"
                            onChange={(event) => {
                              setFieldValue(
                                "usuarioProgramo",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <SelectField
                            id="grua"
                            label="Grua"
                            name="grua"
                            items={OrigenGrua}
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            onChange={(event) => {
                              setFieldValue("grua", event.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-2 mb-2">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <SelectField
                            id="empresaId"
                            label="Empresa"
                            name="empresaId"
                            items={Dataempresa}
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            onChange={(event) => {
                              setFieldValue("empresaId", event.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Grua asignada"
                            name="gruaAsignada"
                            type="text"
                            holder="Escribe"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            onChange={(event) => {
                              setFieldValue("gruaAsignada", event.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          {/* <TextField label="Costo de grua" disabled name="total" type="number" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("total", event.target.value);
                                                    }} /> */}
                          <TextField
                            isMoney={true}
                            icon={"bi bi-currency-dollar"}
                            disabled={true}
                            label="Costo de grúa"
                            name="total"
                            type="text"
                            holder={"Costo de grúa"}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="pt-4"></div>
                          <fieldset
                            className="btn"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            Registrar costo
                          </fieldset>
                        </div>
                      </div>

                      <div className="pt-3">
                        <h6>
                          <strong>Indicar ubicación de origen</strong>
                        </h6>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <SelectField
                            id="origenTipoUbicacion"
                            label="Tipo de ubicación"
                            name="origenTipoUbicacion"
                            disabled
                            items={ubicacionSelect}
                            onChange={(event) => {
                              setFieldValue("origenTipoUbicacion", event.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Ubicación"
                            disabled
                            name="origenUbicacion"
                            type="text"
                            holder=""
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Fecha de recolección"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            name="origenFechaRecoleccion"
                            type="date"
                            holder="Escribe"
                            onChange={(event) => {
                              setFieldValue(
                                "origenFechaRecoleccion",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Hora de recolección"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            name="origenHoraRecoleccion"
                            type="time"
                            holder="Escribe"
                            onChange={(event) => {
                              setFieldValue(
                                "origenHoraRecoleccion",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="pt-3">
                        <h6>
                          <strong>Indicar ubicación de destino</strong>
                        </h6>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <SelectField
                            id="destinoTipoUbicacion"
                            label="Tipo de ubicación"
                            name="destinoTipoUbicacion"
                            items={ubicacionSelect}
                            disabled
                            onChange={(event) => {
                              setFieldValue(
                                "destinoTipoUbicacion",
                                event.value
                              );
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Ubicación"
                            name="destinoUbiccion"
                            type="text"
                            holder="Escribe"
                            disabled
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <TextField
                            label="Fecha y hora de cita"
                            name="fechaHoraCita"
                            type="text"
                            holder="Escribe"
                            disabled
                          />
                        </div>
                        <div className="col-auto">
                          <fieldset
                            className="btn mt-4"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            onClick={(e) => verCalendario(23, values)}
                          >
                            Ver Calendario
                          </fieldset>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-12 col-lg-6">
                          <Field
                            className="form-check-input"
                            disabled={
                              initialValue?.concluido && initialValue.fechaFin
                            }
                            type="checkbox"
                            name="concluido"
                            onClick={(event) => {
                              setFieldValue("concluido", event.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            style={{ marginLeft: "10px" }}
                          >
                            Grua programada
                          </label>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="row justify-content-end">
                            <div className="col-auto">
                              {
                                <small className="text-body-tertiary">
                                  Fecha Inicio: {values.fechaInicio}
                                </small>
                              }
                            </div>
                            <div className="col-auto">
                              {initialValue.concluido && (
                                <small className="text-body-tertiary">
                                  Fecha Fin: {values.fechaFin}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                    {openModal && (
                      <ModalCostoGrua
                        isOpen={openModal}
                        setIsOpen={setOpenModal}
                        item={initialValue}
                        handleGetAll={(item) => {
                          setInitialValue({
                            ...values,
                            total: item?.total,
                            subTotal: item?.subTotal,
                            iva: item?.iva,
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoleccionDeUnidad;
