import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { Toaster, toast } from "react-hot-toast";
import Catalogo from "../../Services/Catalogos/Catalogo";
import Solicitudes from "../../Services/ProcesosCierre/Solicitudes";
import ValuacionesServices from "../../Services/Inventario/ValuacionesService";
import Modal from "./Modal";
import Spinner from "../Loadig";
import { SelectField } from "../SelectField";
import { TextAreaField } from "../TextAreaField";
import { message } from "../../Utils/functions";

const SolicitudAmpliacionExpedienteModal = ({ modalMessage, setModalMessage, selectedData, getListado }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    let initial = {
        cierreContratoExpedienteId: "",
        resultado: '',
        motivoRechazoAmpliacion: '',
        comentariosSolicitudAmpliacion: ''
    }

    const [initialValue, setInitialValue] = useState(initial);

    const resultados = [
        { value: '0', label: 'Rechazar' },
        { value: '1', label: 'Aprobar' },
    ]
    const [motivos, setMotivos] = useState([]);

    useEffect(() => {
        getMotivos();
    }, []);

    const getMotivos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.MotivoRechazoAmpliacion')
            .then(resp => {
                let list = [];
                resp.data.forEach(item => {
                    list.push({
                        value: item.MotivoRechazoAmpliacionId,
                        label: item.MotivoRechazoAmpliacion
                    });
                });
                setMotivos(list);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleRegresar = () => {
        setModalMessage({ isOpen: false });
    }

    const handleGuardar = async (item) => {
        setLoading(true)
        let infovehiculo = await ValuacionesServices.getInfoVehiculo(selectedData.generalId, selectedData.vin)
            .then(resp => {
                const datos = {
                    usuario: selectedData.ejecutivo,
                    contrato: selectedData.contrato,
                    vin: selectedData.vin, // corregido
                    marca: resp.data.nombreMarca, // carro
                    modelo: resp.data.nombreModelo, // carro
                    year: resp.data.nombreYear, // carro
                    motivo: motivos.find(m => m.value === item.motivoRechazoAmpliacion)?.label || ""
                }

                return datos
            })
            .catch(err => {
                setLoading(false)
                toast.error(message('Error en la informacion del vehículo'));
            });

        const data = JSON.stringify(infovehiculo)
        let values = { ...item, data: data, cierreContratoExpedienteId: selectedData.cierreContratoExpedienteId }

        await Solicitudes.aprobarRechazarSolicitud(values)
            .catch(err => {
                toast.error(message('Problemas al aprovar/rechazar la solicitud'));
            })
            .finally(() => {
                getListado()
                setLoading(false);
                setModalMessage({ isOpen: false });
            });
    }

    const validationSchema = Yup.object({
        resultado: Yup.string().required('Resultado es requerido'),
        motivoRechazoAmpliacion: Yup.string()
            .when('resultado', {
                is: "0", // Condition to check
                then: Yup.string().required('Motivo es requerido'),
            }),
        comentariosSolicitudAmpliacion: Yup.string().required('Comentario es requerido')
            .test(
                'min-length-if-not-empty',
                'El comentario debe tener al menos 20 caracteres',
                function (value) {
                    // Si el valor no está vacío, debe tener al menos 20 caracteres
                    return !value || (value && value.length >= 20);
                })
    })

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                isInitialValid={false}
                initialTouched={{
                    concepto: true,
                    metodoPago: true,
                    monto: true,
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="resultado"
                                                label="Resultado"
                                                name="resultado"
                                                items={resultados}
                                                onChange={(event) => {
                                                    setFieldValue("resultado", event.value);
                                                }}
                                            />
                                        </div>
                                        {
                                            values.resultado === '0' &&
                                            <div className="col-lg-6 col-12">
                                                <SelectField
                                                    id="motivo"
                                                    label="Motivo"
                                                    name="motivoRechazoAmpliacion"
                                                    items={motivos}
                                                    onChange={(event) => {
                                                        setFieldValue("motivoRechazoAmpliacion", event.value);
                                                        setFieldValue("motivo", event.label);
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextAreaField
                                                label="Comentarios"
                                                disabled={false}
                                                className="form-control col-12"
                                                rows="3"
                                                name="comentariosSolicitudAmpliacion"
                                                id="Comentarios"
                                                type="text"
                                                holder="Escribe"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handleGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default SolicitudAmpliacionExpedienteModal;