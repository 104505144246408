import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Formik } from 'formik'
import { TextField } from '../../Components/TextField';
import { SelectField } from '../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import Catalogo from '../../Services/Catalogos/Catalogo';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import useTablasSecundariasDinamicas from '../../Hooks/TablasSecundarias/useTablasSecundariasDinamicas';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import BasicModal from '../../Components/Modal/BasicModal';
import DataTableDinamica from '../../Components/datatable/DataTableDinamica';
import { message } from '../../Utils/functions'
import { Buffer } from 'buffer';

const ElementoDinamico = ({ tabla, configuration, handleChangeList }) => {
    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { columns,
        data,
        pageCount,
        dataList,
        // handleChangeList,
        handleChange,
        handlePageClick,
        loading,
        setLoading,
        currentPage,
        EditRow
    } = useTablasSecundariasDinamicas();

    let initial = {}
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 1, Origin: "Listado" });
    const [config, setConfig] = useState({});
    const [templateRows, setTemplateRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [dropdownOptions, setDropdownOptions] = useState({});

    useEffect(() => {
        setRows([]);
        if (configuration.length > 0) {
            let tmp = JSON.parse(configuration);
            setConfig(tmp);
            drawTemplate(tmp);
        }
    }, [configuration]);

    const drawTemplate = (cnf) => {
        let template = [];
        setRows([]);
        Object.keys(cnf).forEach(key => {
            if (cnf[key].primaryKey) {
                template.push({
                    field: key,
                    insert: cnf[key].insert,
                    label: cnf[key].descripcion,
                    input: cnf[key].tipo,
                    table: cnf[key]?.table,
                    option: cnf[key]?.option,
                    text: cnf[key]?.text,
                    autoload: cnf[key]?.autoload,
                    childComponent: cnf[key]?.childComponent,
                    value: 'NEWID()',
                    primaryKey: cnf[key].primaryKey
                });
            } else {
                template.push({
                    field: key,
                    insert: cnf[key].insert,
                    label: cnf[key].descripcion,
                    input: cnf[key].tipo,
                    table: cnf[key]?.table,
                    option: cnf[key]?.option,
                    text: cnf[key]?.text,
                    autoload: cnf[key]?.autoload,
                    childComponent: cnf[key]?.childComponent,
                    value: cnf[key].tipo === 'boolean' || cnf[key].tipo === 'bit' ? false : '',
                    soloactivo: cnf[key]?.soloactivo,
                    nombreColumnaStatus: cnf[key]?.nombreColumnaStatus,
                });
            }
        });

        setRows(template);
        setInitialRows(template)
    }

    const addRow = () => {
        let tmpRows = rows;
        tmpRows.push(templateRows);
        setRows(tmpRows);
        forceUpdate();
    }

    const getInputType = (property) => {
        let type = "";
        switch (property) {
            case 'nvarchar':
            case 'varchar':
            case 'text':
            case 'longtext':
            case 'shorttext':
                type = "text";
                break;
            case 'integer':
            case 'number':
            case 'bigint':
            case 'int':
                type = "number";
                break;
            case 'date':
            case 'datetime':
                type = "date";
                break;
            case 'time':
                type = "time";
                break;
            case 'boolean':
            case 'bit':
                type = "checkbox";
                break;
        }

        return type;
    }

    const handleSelectField = (row, value) => {
        row.value = value;
    }

    const handleSetField = (event, index) => {
        // console.log(value)
        // row.value = value;
        const { value } = event.target
        setRows((prevRow) =>
            prevRow.map((item, i) =>
                i === index ? { ...item, value } : item
            ))
    }

    const handleSetCheckbox = (event, index) => {
        // row.value = !row.value;
        const { checked } = event.target; // `checked` indica si el checkbox está marcado
        setRows((prevRow) =>
            prevRow.map((item, i) =>
                i === index ? { ...item, value: checked } : item
            )
        );
    }

    function formatValue(value, type) {
        if (value === null || value === undefined || value === "" || value === "xxx") return 'NULL'; // Manejo de valores NULL

        if (type === 'uniqueidentifier') {
            if (value === "NEWID()") return "NEWID()"; // No usar comillas en NEWID()
            return `'${value}'`; // Envolver UUIDs en comillas
        }

        if (['nvarchar', 'varchar', 'text', 'longtext', 'shorttext'].includes(type)) {
            return `'${value.replace(/'/g, "''")}'`; // Escapar comillas internas
        }
        if (type === 'datetime') {
            return `'${value}'`; // Fechas también deben ir entre comillas
        }
        return value; // Números y bits no necesitan comillas
    }

    const saveRows = async (e) => {
        e.preventDefault();
        let flag = true;
        setLoading(true);

        if (rows.length > 0) {
            rows?.forEach(row => {
                if ((row?.input !== 'boolean' && row?.input !== 'bit') && row?.value.trim().length === 0 && row?.insert) {
                    flag = false;
                }
            });
        }

        if (flag === false) {
            toast.error(message("¡Operación fallida!", `Los campos son requeridos`));
            setLoading(false);
            return false;
        }

        setLoading(true);
        const cnf = JSON.parse(configuration);
        let fields = [];

        // Object.keys(cnf).forEach(key => {
        //     if (cnf[key].insert || cnf[key].primaryKey) {
        //         if (cnf[key].tipo === 'bigint' && cnf[key].primaryKey) {
        //             // No hacer nada si es bigint y tiene primaryKey
        //         }
        //         else if (cnf[key].tipo === 'int' && cnf[key].primaryKey) {
        //             // No hacer nada si es int y tiene primaryKey
        //         }
        //         else {
        //             fields.push(key);
        //         }
        //     }
        // });

        Object.keys(cnf).forEach(key => {
            const { insert, primaryKey, tipo } = cnf[key];

            // Excluir primaryKey si es de tipo bigint o int
            if ((tipo === 'bigint' || tipo === 'int') && primaryKey) return;

            // Agregar el campo si es insertable o clave primaria válida
            if (insert || primaryKey) {
                fields.push(key);
            }
        });



        let orderFields = fields;
        fields = fields.join(', ');

        let tmp = [];
        const TextFields = ['nvarchar', 'varchar', 'text', 'longtext', 'shorttext'];
        rows.forEach(row => {
            let idx = orderFields.indexOf(row.field);
            if (row.input === 'uniqueidentifier') {
                tmp[idx] = (row.value === "NEWID()" || row.value === "") ? "NEWID()" : `'${row.value}'`;
            } else if (row.input === 'boolean' || row.input === 'bit') {
                tmp[idx] = row.value ? 1 : 0;
            } else if (TextFields.includes(row.input)) {
                tmp[idx] = formatValue(row.value, row.input);
            } else if (row.input === 'bigint' && row.primaryKey) {
                // No hacer nada si es bigint y tiene primaryKey
            } else if (row.input === 'int' && row.primaryKey) {
                // No hacer nada si es int y tiene primaryKey
                console.log('Tipo de dato in, se supone');
                console.log(row.input);
            } else {
                tmp[idx] = row.value;
            }
        });

        let filteredTmp = tmp.filter(item => item !== null && item !== '');
        tmp = filteredTmp.join(', ');

        let query = `INSERT INTO ${tabla} (${fields}) VALUES(${tmp})`;
        query = Buffer.from(query).toString('base64');

        const params = {
            nombreTabla: tabla,
            query
        };

        await Catalogo.mantenimientoCatalogos(params)
            .then(resp => {
                console.log("✅ Respuesta de la API:", resp);

                // Verificamos si la respuesta contiene un mensaje de error
                const responseText = JSON.stringify(resp).toLowerCase();

                if (
                    responseText.includes("duplicate key") ||
                    responseText.includes("cannot insert duplicate key")
                ) {
                    const match = responseText.match(/the duplicate key value is \((.*?)\)/i);
                    const duplicateValue = match ? match[1] : "Valor duplicado";

                    toast.error(message("¡Operación fallida!", `⚠️ El registro "${duplicateValue}" ya existe.`));

                    // toast.warning(message("¡Advertencia!", `El registro "${duplicateValue}" ya existe.`));
                    return; // ⛔ No continuar con éxito
                }

                // ✅ Si no hay errores, continuar con éxito
                toast.success(message("¡Operación exitosa!", "Se insertó correctamente el registro"));
                setRows([]);
                addRow();
            })
            .catch(err => {
                console.error("❌ Error en la inserción:", err);
                toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al intentar crear el registro"));
            })
            .finally(() => {
                setLoading(false);
                setRows(prevRow =>
                    prevRow.map(item => ({
                        ...item,
                        value: item.input === "bit" ? false : "", // Limpiar el valor de `value`
                    }))
                );
            });


        await handleChangeList(tabla);
    };



    const loadCatalog = async (item, value) => {
        const dropdownData = dropdownOptions;
        dropdownData[item.childComponent] = []


        if (item) {
            let childComponent = rows.find(x => x.field === item.childComponent);
            let response;

            if (item.field === 'EstadoId') {
                response = await Catalogo.getMunicipiobyId(value);

            }
            if (item.field === 'MunicipioId') {
                response = await Catalogo.getColoniaById(value);
            }

            try {

                if (response?.data?.data) {
                    response = response.data.data
                } else if (response?.data) {
                    response = response.data
                }

                if (response !== undefined) {
                    response.forEach(element => {
                        dropdownData[item.childComponent].push({
                            value: element[childComponent.option],
                            text: element[childComponent.text]
                        })
                    });
                }

            } catch (error) {
                console.error(`Error fetching data for:`, error);
            }
            setDropdownOptions({
                ...dropdownData
            })
        } else {
            console.log("Sin dato", item)
        }
    }

    useEffect(() => {
        fetchDropdowns();
    }, [initialRows]);

    const fetchDropdowns = async () => {
        const dropdownData = [];
        for (const item of rows) {
            dropdownData[item.field] = []
            if (item.table) {
                if (item.autoload) {
                    dropdownData[item.field].push({ value: "", text: 'Seleccione una opción' })
                    if (item.soloactivo) {
                        try {
                            const response = await Catalogo.getDropdownsByTableAndActive(item.table, item.soloactivo, item.nombreColumnaStatus);
                            response.data.forEach(element => {
                                dropdownData[item.field].push({
                                    value: element[item.option],
                                    text: element[item.text]
                                })
                            });
                        } catch (error) {
                            console.error(`Error fetching data for table ${item.table}:`, error);
                        }
                    }
                    else {
                        try {
                            const response = await Catalogo.getDropdownsByTable(item.table);
                            response.data.forEach(element => {
                                dropdownData[item.field].push({
                                    value: element[item.option],
                                    text: element[item.text]
                                })
                            });
                        } catch (error) {
                            console.error(`Error fetching data for table ${item.table}:`, error);
                        }
                    }
                }

            }
        }
        setDropdownOptions(dropdownData);
    };
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className=''>
                <div className='fs-5 mt-3'>
                    <div className="row">
                        <div className="col-10">
                            Nuevo Elemento
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {
                        rows?.map((item, index) => {
                            return (
                                item.insert &&
                                <div className='col-3' key={'row_' + index}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label style={{ fontSize: '16px' }}>{item.label}</label>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                item.table && dropdownOptions[item.field] ? (
                                                    // Si hay opciones para este campo, muestra un select
                                                    <select
                                                        className="form-control mb-4"
                                                        onChange={(event) => {
                                                            handleSelectField(item, event.target.value)
                                                            loadCatalog(item, event.target.value);
                                                        }}
                                                        value={item.value || ''}
                                                    >
                                                        {dropdownOptions[item.field].map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    // Si no es un dropdown, muestra el input correspondiente
                                                    getInputType(item.input) !== 'checkbox' ? (
                                                        <input
                                                            className="form-control mb-4"
                                                            type={getInputType(item.input)}
                                                            onChange={(event) => handleSetField(event, index)}
                                                            value={item.value}
                                                        />
                                                    ) : (
                                                        <input
                                                            className="mb-4"
                                                            type={getInputType(item.input)}
                                                            onChange={(e) => handleSetCheckbox(e, index)}
                                                            checked={item.value}
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className='row justify-content-end mt-2'>
                    <div className='col-auto'>
                        <button className='btn' onClick={(e) => saveRows(e)}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ElementoDinamico