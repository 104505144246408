import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import handlePhonNumberFormatter, { getDateFormat, message, Normalize, pageRows } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../Components/SelectField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../../Services/Siniestro/Siniestro';

const SiniestroDictamenPRCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();

    let initial = {
        siniestroValuacionAseguradoraId: "",
        siniestroValuacionAseguradoraStatusId: "",
        siniestroId: id,

        subioDictamen: false,
        tipoDictamenId: "",
        fechaDictamen: "",
        dictamenRegistrado: true,
        autorizaDictamen: true,
        comentariosRechazoDictamen: "",
        comentariosDictamen: "",
        status: "",
        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 100,
        status: 2,
        procesoId: state.procesoId,

        contrato: "",
        dictamen: ""
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [tipoDictamen, setTipoDictamen] = useState([]);
    useEffect(() => {
        handleGetAllSelects();
        handleGetAllDictamenById();
    }, []);


    /* CONSULTAS */
    const handleGetAllDictamenById = async () => {
        setLoading(true);
        await Siniestros.siniestroValuacionAseguradoraGet(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroValuacionAseguradoraId: item?.siniestroValuacionAseguradoraId ?? "",
                    siniestroValuacionAseguradoraStatusId: item?.siniestroValuacionAseguradoraStatusId ?? 10,
                    siniestroId: item?.siniestroId ?? id,

                    tipoDictamenId: item?.tipoDictamenId ?? "",
                    fechaDictamen: item?.fechaDictamen?.split("T")[0] ?? "",
                    comentariosDictamen: item?.comentariosDictamen ?? "",
                    roboTotal: item?.roboTotal ?? false,
                    subioDictamen: item?.subioDictamen ?? false,

                    autorizaDictamen: item?.AutorizaDictamen ?? false,

                    dictamenRegistrado: item?.dictamenRegistrado ?? false,
                    concluido: state?.concluido ? true : (item?.concluido ?? false),   
                    color: item?.color ?? "default",
                    fechaInicio: item.fechaInicio,
                    fechaFin: item.fechaConclusion ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state.procesoId,

                    contrato: item?.contrato ?? "",
                    dictamen: item?.dictamen ?? "",

                    //Acendes
                    sol_id: state?.sol_Id ?? "",
                    vin: state?.vin ?? "",
                    poliza: item?.poliza ?? "",
                    folioSiniestro: state?.folio ?? "",
                }
                setInitialValue(newData);
                handleConcluido({ tipo: 5, concluido: item?.autorizaDictamen ? item?.concluido : false, ubicacionvehiculo: false, requiereGrua: false, recoleccionconcluido: false, resguardoConcluido: false, tipoDictamenId: item?.tipoDictamenId, tipoDictamen: item?.dictamen });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetAllDictamen()]).finally(() => {
            setLoading(false)
        });
    }
    const handleGetAllDictamen = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoDictamen')
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.TipoDictamenId,
                        label: item.TipoDictamen
                    });
                });

                setTipoDictamen(newData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    /* TERMINA CATALOGOS */

    const submitForm = async (values) => {
        if (values.concluido) {
            if (!values.subioDictamen) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario cargar la evidencia de Dictamen de siniestro.")}</div>);
                return;
            }
        }
        setLoading(true);
        await Siniestros.siniestroValuacionAseguradoraActualizar(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllDictamenById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        //Dictamen Aseguradora
        tipoDictamenId: Yup.string()
            .when("dictamenRegistrado", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaDictamen: Yup.string()
            .when("dictamenRegistrado", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentariosDictamen: Yup.string()
            .when("dictamenRegistrado", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // tipoDictamenId: Yup.string().required("El campo es requerido."),
        // fechaDictamen: Yup.string().required("El campo es requerido."),
        // comentariosDictamen: Yup.string().required("El campo es requerido."),
    });

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eDictRbo/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroValuacionAseguradoraId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseDictamenPT" role="button" aria-expanded="false" aria-controls="collapseDictamenPT" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Dictamen</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDictamenPT">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        // if (values.dictamenRegistrado)
                                        //     values.codSubStatus = 3;
                                        // else
                                        //     values.codSubStatus = 2;                                        
                                        // values.siniestroValuacionAseguradoraStatusId = 20;
                                        values.concluido = values.dictamenRegistrado;
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2 d-flex justify-content-between">
                                                    <div className='col-6 d-flex'>
                                                        <div className="col-auto me-2">
                                                            <small><strong>Dictamen de aseguradora</strong></small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <fieldset
                                                                disabled={false}
                                                                onClick={handleUploadFile}
                                                                className='btn btn-gray'>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                    </div>

                                                    <div className="col-auto justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="Dictamen"
                                                            disabled={initialValue?.concluido ? true : initialValue.roboTotal}
                                                            name="tipoDictamenId"
                                                            items={tipoDictamen}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoDictamenId", event.value);
                                                                setFieldValue("dictamen", event.label);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="FechadictamenId" disabled={initialValue?.concluido} label="Fecha del dictamen" holder="Fecha del dictamen" name="fechaDictamen" type="date" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue?.concluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosDictamen"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosDictamen", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="dictamenRegistrado" onClick={(event) => {
                                                            setFieldValue("dictamenRegistrado", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Dictamen registrado</label>
                                                    </div>
                                                </div>

                                                <div className="text-end">
                                                    <small className="text-muted">
                                                        {initialValue?.fechaInicio
                                                            ? `Inicio de proceso ${initialValue.fechaInicio}`
                                                            : ""}
                                                        {" | "}
                                                        {initialValue?.fechaFin
                                                            ? `Fin de proceso ${initialValue.fechaFin}`
                                                            : ""}
                                                    </small>

                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SiniestroDictamenPRCard