import { Formik } from "formik";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { TextField } from "../../../Components/TextField";
import DataTable from "../../../Components/datatable/DataTable";
import SeguimientoExpedienteHook from "../../../Hooks/ProcesosCierre/SeguimientoExpedienteHook";
import { Toaster } from "react-hot-toast";
import RowPages from "../../../Components/RowPages";
import { SelectField } from '../../../Components/SelectField';
import ModalBusquedaUnidadesRecuperadas from "../../Cobranza/UnidadesRecuperadas/Cards/ModalBusquedaUnidadesRecuperadas";

const SeguimientoExpedientes = () => {
    const {
        refFormik,
        initialValue,
        loading,
        setLoading,
        columns,
        data,
        page,
        totalRows,
        rows,
        setRows,
        setBuscar,
        buscar,
        listaEstatus,
        setStatus,
        handlePageClick,
        handleDetail,
        openModalAvanzado,
        setOpenModalAvanzado,
        dataFiltro, setDataFiltro,
    } = SeguimientoExpedienteHook();

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                openModalAvanzado &&
                <ModalBusquedaUnidadesRecuperadas
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => {
                        setDataFiltro({ ...dataFiltro, ...value }) 
                        setOpenModalAvanzado(false) 
                    }}
                    dataFiltro={dataFiltro}
                    checkConcluido={true}
                />
            }
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            // validationSchema={validate}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='pb-4 pt-2'>
                            <div className='mx-4 my-4'>
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <strong className='mt-4'>Seguimiento de expedientes</strong>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-3 col-md-3 col-sm-12 inner-addon right-addon">
                                        {/* <div style={{ paddingTop: '21px' }}></div> */}
                                        <i className="glyphicon fas fa-search"></i>
                                        <TextField 
                                        id="buscar" 
                                        label="" 
                                        name="buscar" 
                                        type="text" 
                                        holder="Escribe" 
                                        value={buscar}
                                        onChange={(event) => {
                                            setFieldValue("buscar", event.target.value);
                                            setBuscar(event.target.value)
                                        }} />
                                    </div>
                                    {/* <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'> */}
                                    <div className="col-lg-3 col-md-3 col-sm-12 inner-addon right-addon">
                                        <SelectField
                                            id="status"
                                            name="status"
                                            items={listaEstatus}
                                            className='col-12 mx-1'
                                            placeholder="Tipo de Estatus"
                                            onChange={(option) => {
                                                setFieldValue('status', option.value);
                                                setStatus(option.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 inner-addon right-addon">

                                        <button className='btn btn-blue me-2'
                                            onClick={() => { setOpenModalAvanzado(true) }}>
                                            Búsqueda Avanzada
                                        </button>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 inner-addon right-addon">

                                        <button className='btn btn-blue mx-2' onClick={() => {
                                            setDataFiltro({
                                                strAvanzado: "",
                                                fechaInicio: "",
                                                fechaFin: "",
                                                concluido: false,
                                                busquedaAvanzada: false
                                            })
                                            setStatus('');
                                            setBuscar('');
                                            setFieldValue('status', "");
                                        }}>
                                            Limpiar filtro
                                        </button>
                                    </div>
                                </div>

                                <RowPages setpageRows={setRows} />
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            detailable={true}
                                            handleDetail={handleDetail}
                                            currentPage={page - 1}
                                            paginate={true}
                                            pageCount={totalRows / rows}
                                            handlePageClick={handlePageClick}
                                        />
                                    </div>
                                </div>
                                {/* </section> */}
                            </div>
                        </section>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default SeguimientoExpedientes;