import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../Components/Modal/Modal";
import * as Yup from 'yup';
import Spinner from "../../../Components/Loadig";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { useLocation } from "react-router-dom";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import toast from "react-hot-toast";
import { message } from "../../../Utils/functions";

const SolicitarTramiteModal = ({ modalMessage, setModalMessage, setactualizarCardTramite }) => {
    const { state } = useLocation();
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    let initial = {
        GeneralId: '',
        AreaId: '',
        TipoTramiteId: '',
        OperacionTramiteId: '20',
        Comentarios: '',
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [areas, setAreas] = useState([]);
    const [tramites, setTramites] = useState([]);

    useEffect(() => {
        getAreas();
        getTramites();
    }, []);

    const getAreas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Areas')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.AreaId,
                        label: item.Area
                    }
                });
                setAreas(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const getTramites = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.TipoTramiteId,
                        label: item.TipoTramite
                    }
                });
                setTramites(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleRegresar = () => {
        setModalMessage({ isOpen: false });
    }

    const handleGuardar = async (values) => {
        values.GeneralId = state?.generalId;
        // console.log(values);
        
        await ServicioExpedientes.addTramite(values)
            .then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));
                } else {
                    toast.error(message(resp.data.message));
                }
            })
            .catch(err => {
                toast.error(message('Error al crear el trámite'));
            })
            .finally(() => {
                setModalMessage({ isOpen: false });
                setactualizarCardTramite();
            });
    }

    const validate = Yup.object({
        AreaId: Yup.string()
            .required("El campo es requerido."),
        TipoTramiteId: Yup.string()
            .required("El campo es requerido."),
        Comentarios: Yup.string()
            .required("El campo es requerido.")
            .test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),
    });

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    AreaId: true,
                    TipoTramiteId: true
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-10">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                                <div className="col col-2 d-flex justify-content-end">
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => handleRegresar()}>
                                        <i className="ri-close-fill"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="AreaId"
                                                label="Área"
                                                name="AreaId"
                                                items={areas}
                                                onChange={(event) => {
                                                    setFieldValue("AreaId", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="TipoTramiteId"
                                                label="Trámite"
                                                name="TipoTramiteId"
                                                items={tramites}
                                                onChange={(event) => {
                                                    setFieldValue("TipoTramiteId", event.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextAreaField
                                                id="Comentarios"
                                                label="Comentarios"
                                                className="form-control"
                                                rows="3"
                                                name="Comentarios"
                                                type="text"
                                                holder="Escribe"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='justify-content-between'>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline justify-content-center align-items-center col-12'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue justify-content-center align-items-center col-12'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handleGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default SolicitarTramiteModal;