import React, { useState } from 'react';

const Accordion = ({ title, children, isCross = false, blue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const blueActive = {
    background: '#1351a5',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: isCross ? "" : 'space-between',
    padding: '1rem'
  }

  const blueInactive = {
    background: '#E8EAF6',
    color: '#1A3D7E',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: isCross ? "" : 'space-between',
    padding: '1rem'
  }

  const blueStyle = isOpen ? blueActive: blueInactive

  const defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: isCross ? "" : 'space-between',
    padding: '1rem',
  }

  return (
    <>
      <div
        onClick={toggleAccordion}
        style={blue ? blueStyle : defaultStyle}
      >
        {isCross &&
          <div >
            <i className={`${isOpen ? "bx bx-minus" : "ri-add-line"}`} ></i>
          </div>
        }
        <h4 style={{ margin: 5, fontSize: '0.9rem', fontWeight: 700 }}>{title}</h4>
        {!isCross &&
          <div
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <i class={` bx bx-chevron-down x2`}></i>
            {/* &#9650;  */}
            {/* This is the arrow icon */}
          </div>
        }
      </div >
      {isOpen && (
        <div style={{ padding: '10px', borderTop: '1px solid #ccc' }}>
          {children}
        </div>
      )
      }
    </>
  );
};

export default Accordion;