import { useEffect, useState } from "react";
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import dataDocsServices from "../../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../../Utils/AuthLogin";
import { message } from '../../../Utils/functions'
import DataTable from "../../../Components/datatable/DataTable";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import VisorFile from "../../../Components/VisorFile";

const SolicitudTramiteExpediente = ({actualizarCardTramite, setSolicitudActive}) => {
    const auth = new AuthLogin();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [solicitudes, setSolicitudes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [fileName, setFileName] = useState("");
    const [SelectedDocs, setSelectedDocs] = useState([]);
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    let url = auth.UrlDataDocs();


    const columns = [
        { field: 'area', headerName: 'Área' },
        { field: 'usuarioSolicito', headerName: 'Solicitado por' },
        { field: 'fechaAlta', headerName: 'Fecha de Solicitud' },
        { field: 'promesa_status', headerName: 'Estatus', width: 150 },
        { field: 'acciones', headerName: 'Acciones' },
    ];

    useEffect(() => {
        getSolicitudes();
    }, [actualizarCardTramite]);

    const getSolicitudes = async () => {
        await ServicioExpedientes.getAllTramites(state?.generalId)
            .then(resp => {

                if (resp.data?.data === null) return;

                const data = Array.isArray(resp.data) ? resp.data : [resp.data];

                const items = data.map(item => {
                    item.fechaAlta = getOnlyDateFormat(item.fechaAlta);
                    item.promesa_status = item.operacionTramite;

                    if (item.subido === true) {
                        item.promesa_status = "Entregado";
                    }

                    if (item.subido !== true) setSolicitudActive(true)

                    switch (item.promesa_status) {
                        case "Solicitado":
                            item.colorFondo = "#E8EAF6"
                            item.colorTexto = "#1A3D7E"
                            break;
                        case "Entregado":
                            item.colorFondo = "#D8F2CD"
                            item.colorTexto = "#47A066"
                            break;
                        default:
                            item.colorFondo = "#FFFFFF"
                            item.colorTexto = "#000000"
                            break;
                    }

                    return item;
                })

                setSolicitudes(items);
            })
            .catch(err => {

            });
    }

    const handleDocuments = (obj) => {
        //Si es un solo folder para un tramite se envia el id de la tarjeta
        navigate(`/procesocierre/expediente/datadocs/fExpedient/eTramEx/${state.folio}/${state?.cierreContratoExpedienteId}`, {
            state: { ...state, registroId: obj.tramiteId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }


    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item?.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item?.idFolder}/${item?.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
                     {isOpenDocs &&
            <VisorFile
               isOpen={isOpenDocs}
               setIsOIpen={setIsOpenDocs}
               fileName={fileName}
               doc={SelectedDocs}
            />
         }
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseSolicitudTramite" role="button" aria-expanded="false" aria-controls="collapseSolicitudTramite" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Solicitud de trámite</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseSolicitudTramite">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12">
                                        <DataTable
                                            column={columns}
                                            data={solicitudes}
                                            // handleAction={handleDocuments}
                                            handleAction={(tramite, idx) => {
                                                if (tramite.subido) {
                                                    handleViewdocs(tramite)
                                                }
                                                else {
                                                    handleDocuments(tramite)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SolicitudTramiteExpediente;