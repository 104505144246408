import React from 'react'
import ValuacionDetalle from '../ValuacionDetalle'
import { toast, Toaster } from 'react-hot-toast';
import useValoracionDeUnidad from '../../../../Hooks/Inventario/Valuacion/useValoracionDeUnidad';
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard';
import ContratoHeaderCard from '../../../Seguro/Siniestros/ContratoHeaderCard';
import ModalConfirm from '../ModalConfirm';
import ValoracionInicial from './ValoracionInicial';
import ContratoCard from '../../../../Components/ContratoCard';
import AutorizacionValor from './AutorizacionValor';
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../../Auth/Authorization';

const ValoracionDeUnidad = () => {
  const {
    stepe,
    handleClickTerminar,
    activebtncontinuar,
    state,
    handleClickTerminarProceso,
    setModalConfirm,
    modalConfirm,
    handleChangeStatusBtn,
    handleMostrarTarjetaAutorizacion,
    activetarjetaautorizacion
    // setReaload,
    // reaload
  } = useValoracionDeUnidad(toast);
  return (
    <>
      <ValuacionDetalle
        dataStep={stepe}
        handleClickContinuar={handleClickTerminar}
        activeBtn={activebtncontinuar}
      />
      <div className='d-flex flex-column background-grey' style={{ height: '140vh' }}>
        <ValidatePermission isActive={(ValidatePermissionById(4, Section.SeguimientoValoracionDeUnidadInicial, SubModule.SeguimientoValuacion)?.Ver || ValidatePermissionById(4, Section.SeguimientoValoracionDeUnidadValorDeUnidad, SubModule.SeguimientoValuacion)?.Ver)}>
          <div className='row mx-md-4 my-4'>
            <div className="col-6 px-md-3">
              <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} solicitarClave={false} />
            </div>
            <div className="col-6 px-md-3">
              <ContratoCard vin={state?.vin} />
            </div>
          </div>
        </ValidatePermission>

        <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoValoracionDeUnidadInicial, SubModule.SeguimientoValuacion)?.Ver}>
          <ValoracionInicial
            handleMostrarTarjetaAutorizacion={handleMostrarTarjetaAutorizacion}
          />
        </ValidatePermission>
        {
          activetarjetaautorizacion &&
          <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoValoracionDeUnidadValorDeUnidad, SubModule.SeguimientoValuacion)?.Ver}>
            <AutorizacionValor
              handleConcluido={handleChangeStatusBtn}
            />
          </ValidatePermission>
        }
        {
          modalConfirm &&
          <ModalConfirm
            isOpen={modalConfirm}
            setIsOpen={setModalConfirm}
            title={"Seguimiento de Proceso"}
            handleConfirm={handleClickTerminarProceso}
          />
        }
      </div>
    </>
  )
}

export default ValoracionDeUnidad