import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import AuthLogin from '../../../../../Utils/AuthLogin';
import { SelectField } from '../../../../../Components/SelectField';
import { TextAreaField } from '../../../../../Components/TextAreaField';
import { TextField } from '../../../../../Components/TextField';
import Llamada from '../../../../../Services/Cartera/Llamadas';
import Catalogo from '../../../../../Services/Catalogos/Catalogo';
import SeguroSiniestrosService from '../../../../../Services/Seguros/SeguroSiniestrosService';
import { selectScheduleByTipoCalendario } from '../../../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useSelector } from 'react-redux';
import ModalCostosRecoleccion from '../../Valuacion/ModalCostosRecoleccion';

const auth = new AuthLogin();
const RecoleccionUnidadCard = ({ handleConcluido, stepId }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const scheduleDataCita = useSelector((state) => selectScheduleByTipoCalendario(state, 13));

    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    let initial = {
        siniestroId: id,
        siniestroLiberacionUnidadId: "",
        tipoUbicacion: "",
        nombreUbicacion: "",
        totalAdeudo: "",
        calleNumero: "",
        codigoPostal: "",
        edoMexicoId: "",
        municipioId: "",
        coloniaId: "",

        requiereGrua: false,

        comentarios: "",
        oficioLiberado: false,

        unidadCorralon: false,
        nombreCorralon: "",
        corralonCalleNumero: "",
        corralonCodigoPostal: "",
        corralonEdoMexicoId: "",
        corralonMunicipioId: "",
        corralonColoniaId: "",

        subido: false,

        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: stepId,
        porcentaje: 50,
        status: 2,
        procesoId: state?.procesoId,
        subidoRecoleccion: false
    }
    const tipoUbicaciones = [{ value: 'interno', label: 'Interno' }, { value: 'externo', label: 'Externo' }];
    const TipoAseguradoras = [{ value: 'Taller aseguradora', label: 'Taller aseguradora' }, { value: 'Patio aseguradora', label: 'Patio aseguradora' }];

    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);

    //
    const [Gruas, setGruas] = useState([]);
    const [Empresas, setEmpresas] = useState([]);
    const [ubicacionesInternas, setUbicacionesInternas] = useState([]);

    useEffect(() => {
        getEstados()
        getEstadosDos()
        getGruas();
        getEmpresas();
        getUbicaciones();
        getData();
    }, [])

    const getData = async () => {
        setLoading(true)
        await SeguroSiniestrosService.getRecoleccionVehiculoSiniestros({ id, step: stepId })
            .then(resp => {
                let item = resp?.data;
                let newData = {
                    siniestroId: item?.siniestroId ?? id,
                    valuacionInicio_RecoleccionUnidadId: item?.valuacionInicio_RecoleccionUnidadId ?? "",
                    folio: item?.folio ?? "",
                    usuarioProgramo: item?.usuarioProgramo ?? auth.getNombreUsuario(),
                    grua: item?.grua ?? "",
                    empresaId: item?.empresaId ?? "",
                    gruaAsignada: item?.gruaAsignada ?? "",

                    //UbicacionOrigen
                    origenTipoUbicacion: item?.origenTipoUbicacion ?? "externo",
                    origenNombreUbicacion: item?.origenNombreUbicacion ?? "",
                    origenUbicacion: item?.origenUbicacion ?? "",
                    origenHoraRecoleccion: item?.origenHoraRecoleccion ?? "",
                    origenFechaRecoleccion: item?.origenFechaRecoleccion !== null ? item?.origenFechaRecoleccion.split(" ")[0] : null,

                    edoMexicoId: item?.edoMexicoId ?? "",
                    municipioId: item?.municipioId ?? "",
                    coloniaId: item?.coloniaId ?? "",
                    calle: item?.calle ?? "",
                    numero: item?.numero ?? "",
                    referenciaUbicacion: item?.referenciaUbicacion ?? "",
                    //Costos
                    total: item?.total ?? "",
                    iva: item?.iva ?? "",
                    subTotal: item?.subTotal ?? "",
                    //UbicacionDestino
                    destinoTipoUbicacion: item?.destinoTipoUbicacion ?? "externo",
                    destinoNombreUbicacion: item?.destinoNombreUbicacion ?? "",
                    destinoUbiccion: item?.destinoUbiccion ?? scheduleDataCita?.sucursalName,
                    destinoFechaRecoleccion: item?.destinoFechaRecoleccion ?? "",
                    destinoHoraRecoleccion: item?.destinoHoraRecoleccion ?? "",

                    fechaHoraCita: item?.fechaHoraCita ?? scheduleDataCita?.day,
                    fechaCita: item?.fechaCita ?? "",
                    horaCita: item?.horaCita ?? "",
                    estatus: item?.estatus ?? "",

                    destinoCalleNumero: item?.destinoCalleNumero ?? "",
                    origenTipoAseguradora: item?.origenTipoAseguradora ?? "",
                    destinoCodigoPostal: item?.destinoCodigoPostal ?? "",
                    destinoEdoMexicoId: item?.destinoEdoMexicoId ?? "",
                    destinoMunicipioId: item?.destinoMunicipioId ?? "",
                    destinoColoniaId: item?.destinoColoniaId ?? "",
                    destinoReferenciaUbicacion: item?.destinoReferenciaUbicacion ?? "",

                    subidoRecoleccion: item?.subidoRecoleccion ?? false,

                    concluido: state?.concluido ? true : (item?.concluido ?? false),
                    fechaInicio: item.fechaInicio ?? "",
                    fechaFin: item.fechaFin ?? "",
                    //Stepper Update        
                    paso: stepId,
                    porcentaje: 75,
                    status: 2,
                    procesoId: state?.procesoId,

                    //Calendar
                    calendarioId: scheduleDataCita?.calendarioId ?? "",
                    day: scheduleDataCita?.day ?? "",
                    hour: scheduleDataCita?.hour ?? "",
                    sucursalId: scheduleDataCita?.sucursalId ?? "",
                    sucursalName: scheduleDataCita?.sucursalName ?? "",
                }

                getMunicipios(item?.edoMexicoId);
                getColonias(item?.municipioId);

                getMunicipiosDos(item?.destinoEdoMexicoId);
                getColoniasDos(item?.destinoMunicipioId);


                handleConcluido({ concluido: item.concluido });

                setInitialValue(newData);
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const validate = Yup.object().shape({
        // folio: Yup.string()
        //     .required("El campo es requerido."),
        // usuarioProgramo: Yup.string()
        //     .required("El campo es requerido."),
        // grua: Yup.string()
        //     .required("El campo es requerido."),
        // empresaId: Yup.string()
        //     .required("El campo es requerido."),
        // gruaAsignada: Yup.string()
        //     .required("El campo es requerido."),

        // origenTipoUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        // origenUbicacion: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "interno",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // origenFechaRecoleccion: Yup.string()
        //     .required("El campo es requerido."),
        // origenHoraRecoleccion: Yup.string()
        //     .required("El campo es requerido."),

        // origenNombreUbicacion: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // edoMexicoId: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // municipioId: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // coloniaId: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // calle: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // numero: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // referenciaUbicacion: Yup.string()
        //     .when("origenTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // //DEstino
        // destinoTipoUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        // destinoNombreUbicacion: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // origenTipoAseguradora: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),

        // destinoFechaRecoleccion: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoHoraRecoleccion: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoCalleNumero: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoCodigoPostal: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoEdoMexicoId: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoMunicipioId: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoColoniaId: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
        // destinoReferenciaUbicacion: Yup.string()
        //     .when("destinoTipoUbicacion", {
        //         is: "externo",
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),
    });

    const submitForm = async (values) => {
        
        // values.origenFechaRecoleccion = null
        if (values.destinoTipoUbicacion === "interno" && !scheduleDataCita.calendarioId) {
            toast.error(message("¡Error!", "Es necesario agendar una cita en el calendario."));
            return
        }
        setLoading(true);
        await SeguroSiniestrosService.updateRecoleccionSiniestro(values)
            .then(res => {
                if (res?.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res?.data.mensaje));
                    getData()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res?.data.mensaje));
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error(message("¡Error!", error.message));
                console.log(error)
            })
    }
    const getGruas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.OrigenGrua')
            .then(resp => {
                let listGruas = [];
                resp.data.forEach(item => {
                    listGruas.push({ value: item.OrigenGruaId, label: item.OrigenGrua });
                });
                setGruas(listGruas);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar obtener el catálogo de grúas!"));
            });
    }
    const getEmpresas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Empresa')
            .then(resp => {
                let listEmpresas = [];
                resp.data.forEach(item => {
                    listEmpresas.push({ value: item.EmpresaId.toLowerCase(), label: item.Empresa });
                });
                setEmpresas(listEmpresas);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar obtener el catálogo de empresas!"));
            });
    }
    const getUbicaciones = async () => {
        await SeguroSiniestrosService.getUbicacion(40)
            .then(resp => {
                let listUbicacionesInternas = [];
                resp.data.forEach(item => {
                    listUbicacionesInternas.push({ value: item.nombre, label: item.nombre });
                });
                setUbicacionesInternas(listUbicacionesInternas);
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar obtener el catálogo de ubicaciones!"));
            });
    }
    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }
    const getMunicipios = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }
    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === undefined || value === 0)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCntPRec/${state?.folio}/${initialValue.valuacionInicio_RecoleccionUnidadId}`, {
            state: { ...initialValue, registroId: initialValue.valuacionInicio_RecoleccionUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    const verCalendario = async () => {
        navigate(`/seguro/siniestros/Citas/v2/${13}`, {
            state: { title: 'Recolección de unidad', tipoCalendario: 13, ...state }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRecoleccion" role="button" aria-expanded="false" aria-controls="collapseRecoleccion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Recolección de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseRecoleccion">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-3'>
                                                    <h6>
                                                        <strong>Detalle de recolección</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <fieldset
                                                                // disabled={initialValue?.concluido}
                                                                onClick={handleUploadFile}
                                                                className='btn btn-gray'>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField disabled={true} label="Folio" name="folio" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-3">
                                                    <TextField disabled={true} label="Usuario Programo" name="usuarioProgramo" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Grua"
                                                        disabled={initialValue?.concluido}
                                                        name="grua"
                                                        items={Gruas}
                                                        onChange={(event) => {
                                                            setFieldValue("grua", event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Empresa Grúa"
                                                        disabled={initialValue?.concluido}
                                                        name="empresaId"
                                                        items={Empresas}
                                                        onChange={(event) => {
                                                            setInitialValue({ ...initialValue, ...values })
                                                            setFieldValue("empresaId", event.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField
                                                        disabled={initialValue?.concluido}
                                                        label="Grua asignada"
                                                        name="gruaAsignada"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setInitialValue({ ...initialValue, ...values })
                                                            setFieldValue("gruaAsignada", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-auto d-flex align-items-center">
                                                    <TextField icon={"bi bi-currency-dollar"} isMoney={true} disabled={true} label="Costo para cliente" holder="Escribe" name="total" type="text" />
                                                    <fieldset disabled={initialValue?.concluido} onClick={() => { setOpenModal(true) }} className='btn d-flex align-items-center mx-2 mt-3'>Registrar costo</fieldset>
                                                </div>
                                            </div>
                                            <div className="col-12 px-4 mt-4">
                                                <small><strong>Indicar ubicación de origen</strong></small>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Tipo de ubicación"
                                                        disabled={initialValue?.concluido}
                                                        name="origenTipoUbicacion"
                                                        items={tipoUbicaciones}
                                                        onChange={(event) => {
                                                            setFieldValue("origenTipoUbicacion", event.value);
                                                            setFieldValue("origenUbicacion", "");
                                                            setFieldValue("origenNombreUbicacion", "");
                                                            setFieldValue("edoMexicoId", "");
                                                            setFieldValue("municipioId", "");
                                                            setMunicipios([]);
                                                            setColonias([]);
                                                            setFieldValue("coloniaId", "");
                                                            setFieldValue("calle", "");
                                                            setFieldValue("numero", "");
                                                            setFieldValue("referenciaUbicacion", "");
                                                        }} />
                                                </div>
                                                {
                                                    values.origenTipoUbicacion === "interno" &&
                                                    <div className="col-3">
                                                        <SelectField
                                                            label="Ubicación"
                                                            disabled={initialValue?.concluido}
                                                            name="origenUbicacion"
                                                            items={ubicacionesInternas}
                                                            onChange={(event) => {
                                                                setFieldValue("origenUbicacion", event.value);
                                                            }} />
                                                    </div>
                                                }
                                                {
                                                    values.origenTipoUbicacion === "externo" &&
                                                    <>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Nombre ubicación" name="origenNombreUbicacion" type="text" holder="Escribe" />
                                                        </div>
                                                    </>

                                                }
                                                {
                                                    values.origenTipoUbicacion !== "" &&
                                                    <>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Fecha de recolección" name="origenFechaRecoleccion" type="date" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Hora de recolección" name="origenHoraRecoleccion" type="time" holder="Escribe" />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            {
                                                values.origenTipoUbicacion === "externo" &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField id="edoMexicoId"
                                                                label="Estado"
                                                                disabled={initialValue?.concluido}
                                                                name="edoMexicoId"
                                                                items={estados}
                                                                onChange={(event) => {
                                                                    setFieldValue("edoMexicoId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("municipioId", "");
                                                                        setFieldValue("coloniaId", "");
                                                                        setMunicipios([]);
                                                                        setColonias([]);
                                                                    } else
                                                                        getMunicipios(event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="municipioId"
                                                                label="Municipio"
                                                                disabled={initialValue?.concluido}
                                                                name="municipioId"
                                                                items={municipios}
                                                                onChange={(event) => {
                                                                    setFieldValue("municipioId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("coloniaId", "");
                                                                        setColonias([]);
                                                                    } else
                                                                        getColonias(event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField
                                                                disabled={initialValue?.concluido}
                                                                label="Colonia"
                                                                name="coloniaId"
                                                                items={colonias}
                                                                onChange={(event) => {
                                                                    setFieldValue("coloniaId", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Calle y numero" name="calle" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Codigo postal" name="numero" type="text" holder="Escribe" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <TextAreaField
                                                                label="Referecia de ubicación"
                                                                disabled={initialValue?.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="referenciaUbicacion"
                                                                id="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-12 px-4 mt-4">
                                                <small><strong>Indicar ubicación destino</strong></small>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        label="Tipo de ubicación"
                                                        disabled={initialValue?.concluido}
                                                        name="destinoTipoUbicacion"
                                                        items={tipoUbicaciones}
                                                        onChange={(event) => {
                                                            setFieldValue("destinoTipoUbicacion", event.value);
                                                            setFieldValue("destinoUbicacion", "");
                                                            setFieldValue("destinoNombreUbicacion", "");
                                                            setFieldValue("destinoFechaRecoleccion", "");
                                                            setFieldValue("destinoHoraRecoleccion", "");
                                                            setFieldValue("destinoCalleNumero", "");
                                                            setFieldValue("destinoCodigoPostal", "");
                                                            setFieldValue("destinoEdoMexicoId", "");
                                                            setFieldValue("destinoMunicipioId", "");
                                                            setMunicipiosDos([]);
                                                            setColoniasDos([]);
                                                            setFieldValue("destinoColoniaId", "");
                                                            setFieldValue("calle", "");
                                                            setFieldValue("numero", "");
                                                            setFieldValue("destinoReferenciaUbicacion", "");
                                                        }} />
                                                </div>
                                                {
                                                    values.destinoTipoUbicacion === "interno" &&
                                                    <>
                                                        <div className="col-3">
                                                            <TextField disabled={true} label="Ubicación" name="destinoUbiccion" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-auto d-flex align-items-center">
                                                            <TextField disabled={true} label="Fecha y hora de cita" holder="Escribe" name="fechaHoraCita" type="text" />
                                                            <fieldset disabled={initialValue?.concluido} onClick={verCalendario} className='btn d-flex align-items-center mx-2 mt-3'>Ver calendario</fieldset>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    values.destinoTipoUbicacion === "externo" &&
                                                    <>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="origenTipoAseguradora"
                                                                label="Tipo aseguradora"
                                                                name="origenTipoAseguradora"
                                                                disabled={initialValue.concluido}
                                                                items={TipoAseguradoras}
                                                                onChange={(event) => {
                                                                    setFieldValue("origenTipoAseguradora", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Nombre ubicación" name="destinoNombreUbicacion" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Fecha de entrega" name="destinoFechaRecoleccion" type="date" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Hora de entrega" name="destinoHoraRecoleccion" type="time" holder="Escribe" />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            {
                                                values.destinoTipoUbicacion === "externo" &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Calle y numero" name="destinoCalleNumero" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <TextField disabled={initialValue?.concluido} label="Codigo postal" name="destinoCodigoPostal" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="destinoEdoMexicoId"
                                                                label="Estado"
                                                                disabled={initialValue?.concluido}
                                                                name="destinoEdoMexicoId"
                                                                items={estadosDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("destinoEdoMexicoId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("destinoMunicipioId", "");
                                                                        setFieldValue("destinoColoniaId", "");
                                                                        setMunicipiosDos([]);
                                                                        setColoniasDos([]);
                                                                    } else
                                                                        getMunicipiosDos(event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <SelectField id="destinoMunicipioId"
                                                                label="Municipio"
                                                                disabled={initialValue?.concluido}
                                                                name="destinoMunicipioId"
                                                                items={municipiosDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("destinoMunicipioId", event.value);
                                                                    if (event.value === "") {
                                                                        setFieldValue("destinoColoniaId", event.value);
                                                                        setColoniasDos([]);
                                                                    } else
                                                                        getColoniasDos(event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-3">
                                                            <SelectField
                                                                disabled={initialValue?.concluido}
                                                                label="Colonia"
                                                                name="destinoColoniaId"
                                                                items={coloniasDos}
                                                                onChange={(event) => {
                                                                    setFieldValue("destinoColoniaId", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 px-4">
                                                        <div className="col-8">
                                                            <TextAreaField
                                                                label="comentarios"
                                                                disabled={initialValue?.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="destinoReferenciaUbicacion"
                                                                id="destinoReferenciaUbicacions"
                                                                type="text"
                                                                holder="Escribe"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido || initialValue.subidoRecoleccion === false} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                                                </div>
                                                {
                                                    initialValue.subidoRecoleccion === false &&
                                                    <label className='d-flex align-items-center' style={{ fontSize: '13px', color: 'red' }}>
                                                        <i className='icon-red ri-error-warning-fill' style={{ fontSize: "15px" }}></i>
                                                        Para programar la grua, es necesario subir los documentos requeridos.
                                                    </label>
                                                }
                                            </div>
                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-12 col-lg-6">
                                                    <div className="row flex-column align-items-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaInicio !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.concluido &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                openModal &&
                <ModalCostosRecoleccion
                    isOpen={openModal}
                    setIsOpen={setOpenModal}
                    item={initialValue}
                    handleGetAll={(item) => {
                        setInitialValue({ ...initialValue, total: item?.total, subTotal: item?.subTotal, iva: item?.iva })
                        console.log("new", initialValue, item)
                    }}
                />
            }
        </>
    )
}
export default RecoleccionUnidadCard