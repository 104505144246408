import axios from '../../Utils/ApiService';

const SeguimientoCierre = {
    getSeguimientoCierre: (data) => {
        return axios.post(`/cartera/getSeguimientoCierreContrato`, data);
    },
    getContratoByVIN: (vin) => {
        return axios.get(`/cartera/ContratoByVIN?vin=${vin}`);
    },
    postCierreContratoCrear: (data) => {
        return axios.post(`/cartera/CierreContratoCrear`, data);
    },
    putCierreContratoCitaActualizar: (data) => {
        return axios.put(`/cartera/cierreContratoCitaActualizar`, data);
    },
    UpdateCierreContratoCita: (data) => {
        return axios.put(`/cartera/UpdateCierreContratoCita`, data);
    },
    cierreContratoCitaGet: (id) => {
        return axios.get(`/cartera/cierreContratoCitaGet?cierreContratoId=${id}`);
    },
    getCierreContratoCitaHistorial: (id) => {
        return axios.get(`/cartera/getCierreContratoCitaHistorial?CierreContratoId=${id}`);
    },
    getCierreContratoFirmaGet: (id) => {
        return axios.get(`/cartera/cierreContratoFirmaGet?CierreContratoId=${id}`);
    },
    postCierreContratoFirmaCrear: (data) => {
        return axios.put(`/cartera/cierreContratoFirmaActualizar`, data);
    },
    exportCierreContrato: (data) => {
        return axios.post(`/cartera/ExportCierreContrato`, data, { responseType: 'blob' });
    },
    getAllCierreContratoCita: (data) => {
        return axios.post(`/cartera/GetAllCierreContratoCita`, data);
    },
    getCierreContratoCitaHistorial: (id) => {
        return axios.get(`/cartera/getCierreContratoCitaHistorial?CierreContratoId=${id}`);
    },
}
export default SeguimientoCierre