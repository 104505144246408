import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../Components/datatable/DataTable";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import toast, { Toaster } from "react-hot-toast";
import { message } from "../../../Utils/functions";
import HistorialDetalleExpedienteModal from"./HistorialDetalleExpedienteModal";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";



const HistorialExpediente = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
      
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const[isOpenModalHistorialDetalle,setIsOpenModalHistorialDetalle]=useState(false);

    
    useEffect(() => {
        getHistorial();
    }, []);
    const handleHistorial = (obj) => {
        setIsOpenModalHistorialDetalle(true);
    }
    const handleDocuments = (obj) => {
        navigate(`/procesocierre/expediente/datadocs/fExpedient/eTramEx/${obj.tramiteId}/${state?.generalId}`, {
            state: { ...state, registroId: obj.tramiteId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }
    const getHistorial = async () => {
        await ServicioExpedientes.getHistorial(state.generalId)
            .then(resp => {
            
    
                // Verificar si `resp.data` es un array antes de continuar
                if (!Array.isArray(resp.data)) {
                    toast.error('Error: la respuesta no es válida.');
                    return;
                }
    
                // Mapear los datos correctamente
                const items = resp.data.map(item => ({
                    ...item,
                    fechaSolicitud: getOnlyDateFormat(item.fechaSolicitud),
                    selectFieldPlaceholder: item.cierreContratoExpedienteStatus,
                    EstatusUI: {
                        text: item.cierreContratoExpedienteStatus,
                        backgroundColor: item.fondoHex,
                        textColor: item.textoHex
                    },
                }));
    
                setData(items);
            })
            .catch(err => {
                console.error(err);
                toast.error('Error al obtener el detalle del expediente');
            });
    };
    
    const columns = [
        { field: 'folio', headerName: 'Folio' },
        { field: 'usuarioSolicito', headerName: 'Solicitado por' },
        { field: 'fechaSolicitud', headerName: 'Fecha de Solicitud' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'fechaRecuperacion', headerName: 'Fecha de Resguardo' },
        { field: 'acciones', headerName: 'Acciones' },
        { field: 'historialdesistema', headerName: 'Historial' },
    ];
    
    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    return (
        <>
          <Modal isOpen={isOpenModalHistorialDetalle} color='#fff' width={520}>
                    <HistorialDetalleExpedienteModal
                        generalid={state?.generalId}
                        isOpen={isOpenModalHistorialDetalle}
                        setIsOpen={setIsOpenModalHistorialDetalle}
                    />
            </Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseHistorialExpediente" role="button" aria-expanded="false" aria-controls="collapseHistorialExpediente" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Historial de préstamos de expedientes</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseHistorialExpediente">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4 mb-2">
                                    <div className="col-12">
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            handleHistorial={handleHistorial}
                                            handleAction={handleDocuments}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HistorialExpediente;