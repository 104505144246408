import React from 'react'
import SubmenuLogs from './SubMenuLogs'
import DataTable from '../../Components/datatable/DataTable'
import useInterfaces from '../../Hooks/Logs/useInterfaces'
import Modal from '../../Components/Modal/Modal'
import ErrorModal from '../../Components/Modal/ErrorModal'
import SuccessModal from '../../Components/Modal/SuccessModal'
import Spinner from '../../Components/Loadig'
import Select from 'react-select'
import { getDateFormat, getDateFormatNoUTC, pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'

const Interfaces = () => {

    let { columns,
        data,
        loading,
        modalMessage,
        setModalMessage,
        handleCheck,
        pageCount,
        handlePageClick,
        dataModulo,
        dataProceso,
        dataCategoria,
        setStr,
        setDateTime,
        setCategoriaId,
        setProcesoId,
        handleDetails,
        item,
        setIsOpen,
        isOpen,
        columnsDetail,
        dataDetail,
        handlepageDetail,
        isenablebtn,
        handleDownloadFile,
        initialPage,
        refBtn,
        refBtnExp,
        handleExportFile,
        setpageRows } = useInterfaces();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            {
                isOpen &&
                <Modal isOpen={isOpen} color='#fff'>
                    <div style={{ width: '60rem' }}>
                        <section className='d-flex align-items-center'>
                            <i className="bi bi-chevron-compact-left fs-5" style={{ color: '#004c97', fontWeight: 'bold' }} onClick={() => { setIsOpen(false) }}></i>
                            <span className=''><strong>ID {item.logInterfazId} - Interfaz de {item.proceso} {item.contenido}</strong></span>
                        </section>
                        <section className='d-flex flex-column mx-4'>
                            {
                                item?.fechaFin !== null &&
                                <>
                                    <span style={{ fontSize: '14px' }}> <strong>Fecha y hora</strong></span>
                                    <span style={{ fontSize: '14px' }}>{ getDateFormatNoUTC(item?.fechaFin, '') }</span>
                                </>
                            }
                        </section>
                        <section className='my-4 table-responsive tbl-scroll'>
                            <DataTable
                                column={columnsDetail}
                                data={dataDetail}
                                // pageCount={pageCount}
                                paginate={false}
                            // handlePageClick={handlePageClick}                
                            />
                        </section>
                        <section className='mt-5 d-flex justify-content-end'>
                            <button className='btn' onClick={() => { setIsOpen(false) }}>Aceptar</button>
                        </section>
                    </div>
                </Modal>
            }
            <SubmenuLogs />
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Registro Interfaces</strong></span>
                    <div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Interfaces, SubModule.Logs).Historial}>
                            <button className='icon btn btn-outline mx-2' onClick={handlepageDetail}><i className="bi bi-trash-fill" style={{ color: '#004c97' }}></i></button>
                        </ValidatePermission>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Interfaces, SubModule.Logs).Descarga}>
                            <button className='btn px-4 me-1' ref={refBtnExp} onClick={handleExportFile}>Exportar</button>
                        </ValidatePermission>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.Interfaces, SubModule.Logs).Ver}>
                            <button className='btn px-4 ms-1' ref={refBtn} onClick={handleDownloadFile}>Eliminar</button>
                        </ValidatePermission>
                    </div>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los registros de movimientos de las interacciones con Acendes, Intelimotor y Datadocs.</span>
            </section>
            <ValidatePermission
                isActive={ValidatePermissionById(4, Section.Interfaces, SubModule.Logs).Ver}
            >
                <section className='mt-5 mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <input type="date" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDateTime(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <Select options={dataProceso} className='col-12' onChange={(e) => { setProcesoId(e.value) }} placeholder="Todos los procesos" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <Select options={dataCategoria} className='col-12' onChange={(e) => { setCategoriaId(e.value) }} placeholder="Todas las categorias" />
                        </div>
                    </div>

                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handleCheck={handleCheck}
                        detailable={ValidatePermissionById(4, Section.Interfaces, SubModule.Logs).Historial}
                        handleDetail={handleDetails}
                        paginate={true}
                        handlePageClick={handlePageClick}
                        checkbox={true}
                        currentPage={initialPage}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default Interfaces