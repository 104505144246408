import React from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select';
import SubMenuSupervisionDeCarga from './SubMenuSupervisionDeCarga';
import useSupervisionCargaNivel2 from '../../../Hooks/Cobranza/useSupervisionCargaNivel2';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../Utils/functions'
import BusquedaAvanzadaModal from './BusquedaAvanzadaModal';
import RowPages from '../../../Components/RowPages';
import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'

const SupervisionDeCargaNivel2 = () => {
  let {
    columns,
    columnsContratosSinAsignar,
    contratosSinAsignar,
    columnsDetail,
    currentData,
    dataCobradores,
    setCobradorSeleccionado,
    setStr,
    str,
    loading,
    dataFiltro,
    setDataFiltro,
    handleDetails,
    distribucionDeCarga,
    openModalAvanzado,
    setOpenModalAvanzado,
    handleDownloadFile,
    pageCount,
    handlePageClick,
    page,
    limpiarFiltros,
    selectRefStatus,
    setpageRows,
    pageCountSinAsignar,
    handlePageClickSinAsignar,
    pageSinAsignar,
    setPageRowsSinAsignar,
    handleDownloadFileContratosSinAsignar,
    setTextoBusqueda,
  } = useSupervisionCargaNivel2(toast);

  const handlerDistribucion = () => {
    distribucionDeCarga().then(resp => {
      toast.success(message("¡Operación exitosa!", "Distribución de carga completada"));
    }).catch(error => {
      toast.error(message("¡Operación fallida!", "Error al procesar"));
      console.log(error);
    })
  }

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <SubMenuSupervisionDeCarga />
      <section className='mx-5 py-4'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Supervisión de carga</strong></span>
          <div>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.SupervisionDeCargaNivel2, SubModule.SupervisiónDeCarga)?.Actualizar}>
              <button id="AT_btnDistribucion" className='btn mx-2' type="button" onClick={() => { handlerDistribucion(); }} >Distribución manual de cartera</button>
            </ValidatePermission>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.SupervisionDeCargaNivel2, SubModule.SupervisiónDeCarga)?.Descarga}>
              <button id="AT_btnExportar" className='btn mx-2' type="button" onClick={() => { handleDownloadFile() }} >Exportar Excel</button>
            </ValidatePermission>
          </div>
        </div>
      </section>
      <ValidatePermission isActive={ValidatePermissionById(4, Section.SupervisionDeCargaNivel2, SubModule.SupervisiónDeCarga)?.Ver}>
        <section className='mt-3 mx-4'>
          <div className='col-lg-4 col-sm-12 d-flex'>
            <div className="col-6 mx-1  inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" value={str} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
            </div>
            <div className='col-6 d-flex'>
              <Select ref={(el) => (selectRefStatus.current.select1 = el)} options={dataCobradores} className='col-12 mx-1' onChange={(e) => { setCobradorSeleccionado(e.label) }} placeholder="Seleccionar" />
            </div>
          </div>
        </section>
        <div className='mx-4 table-responsive' >
          <DataTable
            column={columns}
            data={currentData}
            masterDetail={true}
            detailable={ValidatePermissionById(4, Section.SupervisionDeCargaNivel2, SubModule.SupervisiónDeCarga)?.Actualizar}
            handleDetail={handleDetails}
            columnDetail={columnsDetail}
            extrainfopromedio={true}
            paginate={true}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={page - 1}
          />
        </div>

        <br></br>
        <section className='mx-5 py-4'>
          <div className='d-flex justify-content-between'>
            <span className='fs-5'><strong>Contratos sin asignar</strong></span>
            <div>
              <ValidatePermission isActive={ValidatePermissionById(4, Section.SupervisionDeCargaNivel2, SubModule.SupervisiónDeCarga)?.Descarga}>
                <button id="AT_btnExportar" className='btn mx-2' type="button" onClick={() => { handleDownloadFileContratosSinAsignar() }} >Exportar Excel</button>
              </ValidatePermission>
            </div>
          </div>
        </section>
        <section className='mt-3 mx-4'>
          <div className='col-lg-4 col-sm-12 d-flex'>
            <div className="col-6 mx-1 inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
            </div>
          </div>
        </section>
        <RowPages
          setpageRows={setPageRowsSinAsignar}
        />
        <div className='mx-4 table-responsive' >
          <DataTable
            column={columnsContratosSinAsignar}
            data={contratosSinAsignar}
            paginate={true}
            pageCount={pageCountSinAsignar}
            handlePageClick={handlePageClickSinAsignar}
            currentPage={pageSinAsignar - 1}
          />
        </div>
      </ValidatePermission>
      {
        openModalAvanzado &&
        <BusquedaAvanzadaModal
          isOpenModal={openModalAvanzado}
          setIsOpenModal={setOpenModalAvanzado}
          handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
        />
      }
    </>
  );
}

export default SupervisionDeCargaNivel2;