import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import SeguimientoCierre from '../../../../Services/Cierre/SeguimientoCierre';
import Spinner from "../../../../Components/Loadig";
import Modal from "../../../../Components/Modal/Modal";
import Garantias from '../../../../Services/Cartera/Garantias';
import documentsHelper from '../../../../Utils/documents';

const ProcesoFirmaDetalle = ({ handleConcluido }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const refFormik = useRef();

    let initial = {
        cierreContratoFirmaId: "",
        cierreContratoId: id,
        entregaPapeleria: false,
        comentarios: "",
        facturaEndosada: false,
        concluido: false,
        contratoDescargado: false,
        terminacionDescargado: false,
    };

    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [isOpen, setIsOpen] = useState(false)
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
    const validate = Yup.object({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        setLoading(true);
        SeguimientoCierre.getCierreContratoFirmaGet(id)
            .then(res => {
                let resData = res.data;
                setInitialValue(resData)
                handleConcluido({ concluido: resData.concluido })
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false);
            })

    };

    const submitForm = (values) => {
        // Lógica de submit
        setLoading(true);
        SeguimientoCierre.postCierreContratoFirmaCrear(values)
            .then(value => {
                handleConcluido({ concluido: values.concluido })
                getAll();
                //setInitialValue({...initialValue,concluido : values.concluido})
            })
            .catch(reason => {

            })
            .finally(() => {
                setLoading(false);
            })

    };

    const downloadContratoDescargado = (values) => {
        const requestBody = {
            nombrePlantilla: "ConvenioTransaccionTerminacionContrato",
            parametros: {
                "<<VARIABLE_FECHA>>": "a",
                "<<VARIABLE_CLIENTE>>": "b",
                "<<VARIABLE_DOMICILIO_REPRESENTADA>>": "c",
                "<<VARIABLE_DOMICILIO_CLIENTE>>": "d",
                "<<VARIABLE_FECHA_ENDOSO>>": "e",
                "<<VARIABLE_MARCA>>": "f",
                "<<VARIABLE_TIPO>>": "g",
                "<<VARIABLE_MODELO>>": "h",
                "<<VARIABLE_SERIE>>": "i",
                "<<VARIABLE_MOTOR>>": "j",
                "<<VARIABLE_COLOR>>": "k",
                "<<VARIABLE_FECHA_CONTRATO>>": "l",
                "<<VARIABLE_ANTICIPO>>": "m",
                "<<VARIABLE_RESTANTE>>": "n",
                "<<VARIABLE_RESTANTE_LETRA>>": "o",
                "<<VARIABLE_DOMICILIO_REPRESENTADA>>": "p",
                "<<VARIABLE_DOMICILIO_CLIENTE>>": "q",
                "<<VARIABLE_FECHA_CONTRATO_LETRA>>": "r",
            }
        };

        Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            console.log(resp);
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            values.contratoDescargado = true
            submitForm(values);
        }).catch(err => {
            console.error(err);
        });
    }

    const downloadTerminacionDescargado = (values) => {
        const requestBody = {
            nombrePlantilla: "ConvenioTransaccionTerminacionContrato",
            parametros: {
                "<<VARIABLE_FECHA>>": "",
                "<<VARIABLE_CLIENTE>>": "",
                "<<VARIABLE_DOMICILIO_REPRESENTADA>>": "",
                "<<VARIABLE_DOMICILIO_CLIENTE>>": "",
                "<<VARIABLE_FECHA_ENDOSO>>": "",
                "<<VARIABLE_MARCA>>": "",
                "<<VARIABLE_TIPO>>": "",
                "<<VARIABLE_MODELO>>": "",
                "<<VARIABLE_SERIE>>": "",
                "<<VARIABLE_MOTOR>>": "",
                "<<VARIABLE_COLOR>>": "",
                "<<VARIABLE_FECHA_CONTRATO>>": "",
                "<<VARIABLE_ANTICIPO>>": "",
                "<<VARIABLE_RESTANTE>>": "",
                "<<VARIABLE_RESTANTE_LETRA>>": "",
                "<<VARIABLE_DOMICILIO_REPRESENTADA>>": "",
                "<<VARIABLE_DOMICILIO_CLIENTE>>": "",
                "<<VARIABLE_FECHA_CONTRATO_LETRA>>": "",
            }
        };

        Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
            console.log(resp);
            documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            values.terminacionDescargado = true
            submitForm(values);
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div className="row mt-2" data-bs-toggle="collapse" href="#collapseProcesoFirmaDetalle" role="button" aria-expanded="false" aria-controls="collapseProcesoFirmaDetalle" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                            <div className="col-11">
                                <h6><strong>Proceso de firma</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: 'right' }}>
                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseProcesoFirmaDetalle">
                            <div className="divider"></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    submitForm({ ...values });
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table" style={{ "border": "solid thin", "borderColor": "#E0E0E0", "width": "60%" }}>
                                                    <thead style={{ "backgroundColor": "#E0E0E0" }}>
                                                        <tr>
                                                            <th colSpan={2}>
                                                                Generar documentos
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ "border": "solid thin", "borderColor": "#E0E0E0" }}>
                                                                Terminación de contrato
                                                            </td>
                                                            <td style={{ "border": "solid thin", "borderColor": "#E0E0E0" }}>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-gray'
                                                                    disabled={
                                                                        initialValue?.concluido
                                                                    }
                                                                    onClick={(e) => { downloadTerminacionDescargado(values) }}>
                                                                    <i className="icon-blue bx bx-download"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "border": "solid thin", "borderColor": "#E0E0E0" }}>
                                                                Contrato de transacción
                                                            </td>
                                                            <td style={{ "border": "solid thin", "borderColor": "#E0E0E0" }}>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-gray'
                                                                    disabled={
                                                                        initialValue?.concluido
                                                                    }
                                                                    onClick={(e) => { downloadContratoDescargado(values) }}>
                                                                    <i className="icon-blue bx bx-download"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-2 text-end">
                                                <button
                                                    className='btn btn-light'
                                                    disabled={
                                                        initialValue?.concluido ||
                                                        initialValue.contratoDescargado === false ||
                                                        initialValue.contratoDescargado === null ||
                                                        initialValue.terminacionDescargado === false ||
                                                        initialValue.terminacionDescargado === null
                                                    }
                                                    type='submit'>
                                                    Subir documento
                                                </button>
                                            </div>
                                            <div className="col-2 text-end">
                                                {initialValue.concluido ? (
                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                        <i className="icon-dark bx bx-hide mx-1"></i>Modo lectura
                                                    </fieldset>
                                                ) : (
                                                    <button
                                                        className='btn'
                                                        disabled={
                                                            initialValue.concluido
                                                        }
                                                        type='submit'>
                                                        Guardar
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mt-2 mb-2 col'>
                                            <strong>
                                                Proceso de firma y entrega de papelería
                                            </strong>
                                        </div>
                                        <div className='row mt-2 mb-2'>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <SelectField id="entregaPapeleria"
                                                    label="Entregó papelería completa"
                                                    name="entregaPapeleria"
                                                    items={opcionesBool}
                                                    disabled={
                                                        initialValue.concluido ||
                                                        initialValue.contratoDescargado === false ||
                                                        initialValue.contratoDescargado === null ||
                                                        initialValue.terminacionDescargado === false ||
                                                        initialValue.terminacionDescargado === null
                                                    }
                                                    onChange={(event) => {
                                                        setFieldValue("entregaPapeleria", event.value);
                                                    }} />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <SelectField id="facturaEndosada"
                                                    label="Factura endosada"
                                                    name="facturaEndosada"
                                                    items={opcionesBool}
                                                    disabled={
                                                        initialValue.concluido ||
                                                        initialValue.contratoDescargado === false ||
                                                        initialValue.contratoDescargado === null ||
                                                        initialValue.terminacionDescargado === false ||
                                                        initialValue.terminacionDescargado === null
                                                    }
                                                    onChange={(event) => {
                                                        setFieldValue("facturaEndosada", event.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row col-6">
                                            <TextAreaField
                                                id="comentarios"
                                                label="Comentarios"
                                                className="form-control"
                                                rows="3"
                                                name="comentarios"
                                                type="text"
                                                holder="Escribe"
                                                disabled={
                                                    initialValue.concluido ||
                                                    initialValue.contratoDescargado === false ||
                                                    initialValue.contratoDescargado === null ||
                                                    initialValue.terminacionDescargado === false ||
                                                    initialValue.terminacionDescargado === null
                                                }
                                                onChange={(event) => {
                                                    setFieldValue("comentarios", event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="row mt-2 mb-2">
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <Field className="form-check-input"
                                                    id="concluido"
                                                    disabled={
                                                        initialValue.concluido ||
                                                        initialValue.contratoDescargado === false ||
                                                        initialValue.contratoDescargado === null ||
                                                        initialValue.terminacionDescargado === false ||
                                                        initialValue.terminacionDescargado === null
                                                    }
                                                    type="checkbox" name="concluido"
                                                    onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Firma realizada</label>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcesoFirmaDetalle;
