import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoGestoriaService from '../../../Services/Gestoria/SeguimientoGestoria';
import { message } from '../../../Utils/functions';
import { clearScheduleByTipoCalendario, selectScheduleByTipoCalendario } from '../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useDispatch, useSelector } from 'react-redux';
import Garantias from '../../../Services/Cartera/Garantias';

const usePreparacionPapeleria = ({ toast, handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const CitaServicioDatamovil = useSelector((state) => selectScheduleByTipoCalendario(state, 9));

    let initial = {
        gestoriaPreparacionPapeleriaId: "",
        gestoriaRegistroInicialId: id,
        paso: 2,
        estimadoAdeudos: null,
        clienteDeseaContinuarTramite: null,
        clienteNotificacionAdeudo: null,
        aplicaFinanciamiento: null,
        montoFinanciar: null,
        papeleriaCompleta: null,
        comentarios: null,
        concluido: false,
        fechaInicio: "",
        fechaFin: null,
        codEstatus: null,
        estatus: "No hay cita",
        ubicacionId: null,
        nombreUbicacion: null,
        bloqueoId: null,
        fechaHoraCita: null,
        fechaCita: null,
        horaCita: null
    }

    const [loading, setloading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

    useEffect(() => {
        getData();
    }, []);

    const getFormatHourCalendar = (hourDate) => {
      if(hourDate === undefined || hourDate === null) return false;
        let [hour, minutes] = hourDate.split(':');
        let meridian = 'AM';
        if(hour > 12){
            hour = hour - 12;
            meridian = 'PM';
        }

        return `${("0"+hour).slice(-2)}:${minutes}:00 ${meridian}`;
    }


    const getData = async () => {
        setloading(true)
        await SeguimientoGestoriaService.EstimacionCostosGet({id : id , paso : 2})
            .then(resp => {

                resp.data.concluido = resp.data.concluido ? true : false
                resp.data.clienteDeseaContinuarTramite = resp.data.clienteDeseaContinuarTramite === null ? null : resp.data.clienteDeseaContinuarTramite ? 1 : 0 
                resp.data.nombreUbicacion = resp.data.nombreUbicacion ?? CitaServicioDatamovil.sucursalName;
                resp.data.fechaHoraCita = resp.data.fechaHoraCita ?? (CitaServicioDatamovil?.day ? CitaServicioDatamovil.day+" "+getFormatHourCalendar(CitaServicioDatamovil?.hour) : "");
                resp.data.calendarioId = resp.data.calendarioId ?? CitaServicioDatamovil.calendarioId;
                resp.data.sucursalId = resp.data.sucursalId ?? CitaServicioDatamovil.sucursalId;
                resp.data.fechaCita = resp.data.fechaCita ?? CitaServicioDatamovil.day;
                resp.data.horaCita = resp.data.horaCita ?? CitaServicioDatamovil.hour;
                resp.data.day = resp.data.day ?? CitaServicioDatamovil.day;
                resp.data.hour = resp.data.hour ?? CitaServicioDatamovil.hour;
                
                setInitialValue({ ...initialValue, ...resp.data });
                handleConcluido({ concluido: resp.data.concluido })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setloading(false)
            })
    };

    const submitForm = async (values) => {
        if (values.concluido) {
          if (!values.subido) {
            toast.custom(
              <div className="warnin-toast">
                {message("¡Alerta.!", "Falta subir evidencia.")}
              </div>
            );
            setTimeout(() => {
              toast.remove();
            }, 3000);
            return;
          }
        }
        setloading(true)
        await SeguimientoGestoriaService.EstimacionCostosActualizar(values)
            .then(resp => {
                toast.success(message("Se Actualizó el Registro Inicial correctamente"));
                getData()

                if(values.concluido && resp.data.ban === 1){
                    const titleCorreo = `Solicitud de Cita de Trámite de Gestoría ${
                      "#" + CitaServicioDatamovil?.contrato
                    } - ${CitaServicioDatamovil?.marca} ${CitaServicioDatamovil?.modelo} ${
                      CitaServicioDatamovil?.ano
                    }`;
                    const contenido = {
                        VARIABLE_USUARIO: sessionStorage.getItem("nombreUsuario"),
                        VARIABLE_TIPO_CITA: 'Gestoría',
                        VARIABLE_TIPO_TRAMITE: 'Iniciar',
                        VARIABLE_VIN: CitaServicioDatamovil?.vin ?? "",
                        VARIABLE_MARCA: CitaServicioDatamovil?.marca ?? "",
                        VARIABLE_MODELO: CitaServicioDatamovil?.modelo ?? "",
                        VARIABLE_AÑO: CitaServicioDatamovil?.ano ?? "",
                        VARIABLE_UBICACION: CitaServicioDatamovil?.sucursalName ?? "",
                        VARIABLE_FECHA: CitaServicioDatamovil?.day ?? "",
                        VARIABLE_HORA: CitaServicioDatamovil?.hour ?? "",
                      };
                      Garantias.creardatosenviar({
                        modulo: titleCorreo,
                        tipoCorreoId: 116,
                        contenido: JSON.stringify(contenido),
                      }).catch((err) => {
                        console.log(
                          "Error al enviar correo Cita de Trámite de Gestoria:",
                          err
                        );
                      });
                    dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 9 }));
                }
                
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar Actualizar el Registro Inicial!"));
            })
            .finally(() => {
                setloading(false);
            });
    }

    const handleViewCalendar = () => {
        navigate('/gestoria/seguimiento/Citas/v2/9', {state: {...state, title: 'Inicio de Tramite de Gestoria', tipoCalendario: 9} });
    }

    
  const handleUploadFile = async (values) => {
    navigate(
      `/gestoria/seguimiento/datadocs/fGestoria/ePrePapl/${state.numContrato}/${values.gestoriaPreparacionPapeleriaId}`,
      {
        state: {
          ...state,
          registroId: values.gestoriaPreparacionPapeleriaId,
          vin: state.vin,
          nombreMarca: state.marca,
          nombreModelo: state.modelo,
          nombreVersion: state.version,
          nombreYear: state.anio,
        },
      }
    );
  };

    return {
        loading,
        initialValue,
        submitForm,
        opcionesBool,
        handleViewCalendar,
        handleUploadFile
    };
};

export default usePreparacionPapeleria;
