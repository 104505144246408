import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import handlePhonNumberFormatter, { getDateFormat, message } from '../../../../../Utils/functions';
import Siniestros from '../../../../../Services/Siniestro/Siniestro';
// import Catalogo from '../../../../../Services/Catalogos/Catalogo';
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
// import ErrorModal from '../../../../../Components/Modal/ErrorModal';
// import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../../Components/SelectField';
import { TextField } from '../../../../../Components/TextField';
import { TextAreaField } from '../../../../../Components/TextAreaField';
// import ModalMessaje from '../../../../../Components/Message/ModalMessage';
// import ModalMessage from '../../../../../Components/Message/ModalMessage';
import { setAcreditacion } from '../../../../../Redux/Slice/Siniestros/acreditacionSlice';


const RegistroSiniestroRoboCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const { refFormik } = useRef();
    // let { state } = props;

    let initial = {
        siniestroId: id,
        reportaNombre: "",
        telefonoMovil: "",
        telefonoFijo: "",
        telefonoTrabajo: "",
        correoElectronico: "",
        siniestroTipoId: "",
        siniestroProcesoId: "",
        // unidadDetenida: "",
        cuentaFolioAseguradora: "",
        folioAseguradora: "",
        // folio: "",
        comentarios: "",

        reporteAutoridades: false,
        fechaReporte: "",
        reporteAseguradora: "",
        denunciaMinisterioPublico: "",
        numeroAveriguacion: "",
        fechaDenuncia: "",
        denunciaAcreditacion: "",
        folioDenuncia: "",

        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 1,
        porcentaje: 100,
        status: 2,
        procesoId: state.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const dataBoolean = [
        { value: true, label: 'Si' },
        { value: false, label: "No" }
    ]


    useEffect(() => {
        handleGetAllSiniestroById();
    }, []);


    /* CONSULTAS */
    const handleGetAllSiniestroById = async () => {
        setLoading(true);
        await Siniestros.GetSiniestroById(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroId: id,
                    reportaNombre: item?.reportaNombre ?? "",
                    telefonoMovil: item?.telefonoMovil ?? "",
                    telefonoFijo: item?.telefonoFijo ?? "",
                    telefonoTrabajo: item?.telefonoTrabajo ?? "",
                    correoElectronico: item?.correoElectronico ?? "",
                    siniestroTipoId: item?.siniestroTipoId ?? "",
                    siniestroTipo: item?.siniestroTipo ?? "",
                    siniestroProcesoId: item?.siniestroProcesoId ?? "",
                    // unidadDetenida: "",                        
                    cuentaFolioAseguradora: item?.cuentaFolioAseguradora ?? false,
                    folioAseguradora: item?.folioAseguradora ?? "",
                    // folio: "",
                    comentarios: item?.comentarios ?? "",
                    fechaReporte: item?.fechaReporte !== null ? item?.fechaReporte.split("T")[0] : "",
                    reporteAseguradora: item?.reporteAseguradora ?? "",
                    denunciaMinisterioPublico: item?.denunciaMinisterioPublico ?? "",
                    numeroAveriguacion: item?.numeroAveriguacion ?? "",
                    fechaDenuncia: item?.fechaDenuncia !== null ? item?.fechaDenuncia.split("T")[0] : "",
                    denunciaAcreditacion: item?.denunciaAcreditacion ?? "",
                    folioDenuncia: item?.folioDenuncia ?? "",
                    subido: item?.subido ?? "",

                    concluido: item?.concluido ?? false,
                    acreditacionConcluido: false,
                    color: "default",
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    //Stepper Update        
                    paso: 1,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state.procesoId,

                    //Pasa usar en el paso 2
                    reporteAutoridades: item?.reporteAutoridades ?? false
                }
                setInitialValue(newData);
                dispatch(setAcreditacion({
                    reporteAutoridades: item?.reporteAutoridades ?? false,
                    subido: item?.subido ?? false
                  }));
                handleConcluido({ concluido: item?.concluido });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([]).finally(() => {
            setLoading(false)
        });
    }


    const submitForm = async (values) => {
        // ✅ Validación de evidencia SOLO si marcó "concluido" Y SÍ hizo reporte
        if (values.concluido && values.reporteAutoridades === true) {
            if (!values.subido) {
                toast.custom(<div className='warnin-toast'>
                    {message("¡Alerta!", "Debe subir el documento de acreditación porque sí se realizó el reporte ante autoridades.")}
                </div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }
        setLoading(true);
        await Siniestros.UpdateSiniestroRobo(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllSiniestroById()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        //Detalle de contacto
        reportaNombre: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        telefonoMovil: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // telefonoFijo: Yup.string()
        //     .required("El campo es requerido."),
        // telefonoTrabajo: Yup.string()
        //     .required("El campo es requerido."),
        correoElectronico: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .email("ingrese un correo valido")
                    .required("El campo es requerido."),
            }),

        //Detalle de siniestro
        reporteAutoridades: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        fechaReporte: Yup.string()
            .when(["concluido", "reporteAutoridades"], {
                is: (concluido, reporteAutoridades) => {
                    return (concluido && reporteAutoridades);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        denunciaMinisterioPublico: Yup.bool()
            .when(["concluido", "reporteAutoridades"], {
                is: (concluido, reporteAutoridades) => {
                    return (concluido && reporteAutoridades);
                },
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        numeroAveriguacion: Yup.string()
            .when(["concluido", "denunciaMinisterioPublico"], {
                is: (concluido, denunciaMinisterioPublico) => {
                    return (concluido && denunciaMinisterioPublico);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaDenuncia: Yup.string()
            .when(["concluido", "denunciaMinisterioPublico"], {
                is: (concluido, denunciaMinisterioPublico) => {
                    return (concluido && denunciaMinisterioPublico);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        denunciaAcreditacion: Yup.bool()
            .when(["concluido", "reporteAutoridades"], {
                is: (concluido, reporteAutoridades) => {
                    return (concluido && reporteAutoridades);
                },
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        folioDenuncia: Yup.string()
            .when(["concluido", "denunciaAcreditacion"], {
                is: (concluido, denunciaAcreditacion) => {
                    return (concluido && denunciaAcreditacion);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        cuentaFolioAseguradora: Yup.bool()
            .when(["concluido"], {
                is: (concluido) => {
                    return (concluido);
                },
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        folioAseguradora: Yup.string()
            .when(["concluido", "cuentaFolioAseguradora"], {
                is: (concluido, cuentaFolioAseguradora) => {
                    return (concluido && cuentaFolioAseguradora);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eLevSinRbo/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {/* {
                modalMessage &&
                <ModalMessage
                    setIsOpen={setModalMessage}
                    isOpen={modalMessage}
                />
            } */}
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRegistrosiniestroRobo" role="button" aria-expanded="false" aria-controls="collapseRegistrosiniestroRobo" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue.concluido ? "green" : "default"}`}></span> <strong>Registro de siniestro</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseRegistrosiniestroRobo">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">

                                                    <div className="col-3">
                                                        <small><strong>Detalle de contacto</strong></small>
                                                    </div>

                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            {/* <fieldset className='btn d-flex align-items-center mx-2 col-auto' onClick={() => { setModalMessage(true) }}><i className="bx bxs-message-alt me-1" ></i>Comentarios</fieldset> */}
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.concluido} label="Nombre de quien reporta" holder="Escribe" name="reportaNombre" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono móvil" holder="Escribe" name="telefonoMovil" type="text" onChange={(event) => {
                                                            setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono fijo" holder="Escribe" name="telefonoFijo" type="text" onChange={(event) => {
                                                            setFieldValue("telefonoFijo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.concluido} label="Teléfono de trabajo" holder="Escribe" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                            setFieldValue("telefonoTrabajo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={initialValue?.concluido} label="Correo electrónico" holder="Escribe" name="correoElectronico" type="text" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small className='fw-bold'>Detalle de siniestro</small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <fieldset
                                                            disabled={false}
                                                            onClick={handleUploadFile}
                                                            className='btn btn-gray'>
                                                            <i className="icon-light fa fa-file "></i> Subir documento
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="utilidad" disabled={true} label="Tipo de Siniestro" holder="Escribe" name="siniestroTipo" type="text" />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Realizo reporte ante las autoridades?"
                                                            disabled={initialValue?.concluido}
                                                            name="reporteAutoridades"
                                                            items={dataBoolean}
                                                            onChange={(event) => {
                                                                setFieldValue("reporteAutoridades", event.value);
                                                                if (!event.value) {
                                                                    setFieldValue("fechaReporte", "");
                                                                    setFieldValue("denunciaMinisterioPublico", "");
                                                                    setFieldValue("denunciaAcreditacion", "");
                                                                }
                                                                setFieldValue("reporteAseguradora", "");
                                                            }} />
                                                    </div>
                                                    {
                                                        values.reporteAutoridades === true &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="FechadictamenId" disabled={initialValue?.concluido} label="Fecha de reporte" holder="Fecha de reporte" name="fechaReporte" type="date" />
                                                        </div>
                                                    }
                                                    {
                                                        values.reporteAutoridades === false &&
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="vehiculoApto"
                                                                label="¿Realizo reporte a la aseguradora?"
                                                                disabled={initialValue?.concluido}
                                                                name="reporteAseguradora"
                                                                items={dataBoolean}
                                                                onChange={(event) => {
                                                                    setFieldValue("reporteAseguradora", event.value);
                                                                }} />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    values.reporteAutoridades &&
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="vehiculoApto"
                                                                label="¿Denuncia ante el ministerio publico?"
                                                                disabled={initialValue?.concluido}
                                                                name="denunciaMinisterioPublico"
                                                                items={dataBoolean}
                                                                onChange={(event) => {
                                                                    setFieldValue("denunciaMinisterioPublico", event.value);
                                                                    if (!event.value) {
                                                                        setFieldValue("numeroAveriguacion", "");
                                                                        setFieldValue("fechaDenuncia", "");
                                                                    }

                                                                }} />
                                                        </div>
                                                        {
                                                            values.denunciaMinisterioPublico === true &&
                                                            <>
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Número de averiguación" holder="Escribe" name="numeroAveriguacion" type="text" />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField id="utilidad" disabled={initialValue?.concluido} label="Fecha de la denuncia" holder="Escribe" name="fechaDenuncia" type="date" />
                                                                </div>
                                                            </>
                                                        }

                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="vehiculoApto"
                                                                label="¿Denuncia cuenta con acreditación?"
                                                                disabled={initialValue?.concluido}
                                                                name="denunciaAcreditacion"
                                                                items={dataBoolean}
                                                                onChange={(event) => {
                                                                    setFieldValue("denunciaAcreditacion", event.value);
                                                                    if (!event.value)
                                                                        setFieldValue("folioDenuncia", "");
                                                                }} />
                                                        </div>
                                                        {
                                                            values.denunciaAcreditacion === true &&
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="utilidad" disabled={initialValue?.concluido} label="Folio de denuncia" holder="Escribe" name="folioDenuncia" type="text" />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {/* {
                                                    values.reporteAseguradora &&
                                                    <> */}
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-auto">
                                                        <small className='fw-bold'>Detalle de la aseguradora</small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Cuentas con folio aseguradora?"
                                                            disabled={initialValue?.concluido}
                                                            name="cuentaFolioAseguradora"
                                                            items={dataBoolean}
                                                            onChange={(event) => {
                                                                setFieldValue("cuentaFolioAseguradora", event.value);
                                                                if (!event.value)
                                                                    setFieldValue("folioAseguradora", "");
                                                            }} />
                                                    </div>
                                                    {
                                                        values.cuentaFolioAseguradora === true &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="utilidad" disabled={initialValue?.concluido} label="Folio siniestro aseguradora" holder="Escribe" name="folioAseguradora" type="text" />
                                                        </div>
                                                    }

                                                </div>
                                                {/* </>
                                                } */}

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue?.concluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4 d-flex justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio !== "" &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido &&
                                                            <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                        }
                                                    </div>
                                                </div>

                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegistroSiniestroRoboCard