import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import Llamada from '../../../../Services/Cartera/Llamadas';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';
import Modal from '../../../../Components/Modal/Modal';
import { message, getDateNow, formatearFecha, FechaParaSQL } from '../../../../Utils/functions';
import Spinner from '../../../../Components/Loadig';
import * as Yup from 'yup';
import { selectScheduleByTipoCalendario, setFields, clearScheduleByTipoCalendario } from '../../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CitaEntregaUnidad = ({
    dataEstado,
    toast,
    state,
    vehiculo
}) => {
    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scheduleDataTarjeta = useSelector((state) => selectScheduleByTipoCalendario(state, 13));
    const userId = sessionStorage.getItem('userId')
    const usuario = sessionStorage.getItem('usuario')

    // const today = new Date
    const today = FechaParaSQL(new Date)

    let initial = {
        contrato: state.contrato,
        ubicacion: "",
        sucursal: "",
        estatus: "",
        fechacita: "",
        horacita: "",
        callenumero: "",
        codigop: "",
        pais: "México",
        estado: "",
        municipio: "",
        colonia: "",
        latitud: "",
        longitud: "",
        bloqueoid: false,
        concluido: false
    }

    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [ubicacionSelect, setUbicacionSelect] = useState([{ label: "Datamovil", value: "Datamovil" }, { label: "Externo", value: "Externo" }]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);

    useEffect(() => {
        getAllData()
    }, [])


    const getAllData = async () => {
        setLoading(true)
        try {
            let fechaInicio = await handleGetCita()
            if (fechaInicio === undefined) await crearCita()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const transformToDate = (value) => {
        // Si el valor es null o undefined, devolvemos una cadena vacía
        if (!value) {
            return '';
        }

        // Si el valor es un string, lo convertimos a una fecha
        const date = getDateNow(value);

        // Si la fecha es inválida (NaN), devolvemos una cadena vacía
        if (isNaN(date)) {
            return '';
        }

        // Si la fecha es válida, la retornamos en un formato adecuado (por ejemplo, 'YYYY-MM-DD')
        return date // O puedes usar `.toISOString()` si quieres un formato ISO
    };

    const getFinalDate = (scheduleDataTarjeta, cita) => {
        // 1. Si scheduleDataTarjeta.day existe y tiene un valor válido, usamos ese valor
        if (scheduleDataTarjeta.day) {
            return scheduleDataTarjeta.day
        }

        // 2. Si cita[0]?.fechacita existe, transformamos esa fecha
        if (Array.isArray(cita) && cita[0]?.fechacita) {
            return transformToDate(cita[0]?.fechacita);
        }

        // 3. Si ninguno de los dos tiene un valor, devolvemos una cadena vacía
        return '';
    };

    const handleGetCita = async (id) => {
        let field = {
            "FIELD": "contrato",
            "VALUE": state.contrato
        }

        let fechaInicio = await UnidadesRecuperadasService.getCitaEntregaUnidadByField(field)
            .then(res => {

                let cita = res.data.responseData
                cita.sort((a, b) => new Date(b.fechafinproceso) - new Date(a.fechafinproceso));

                if (cita[0]?.estatus === "Reagendar" && !scheduleDataTarjeta) {
                    cita[0] = { estatus: "Reagendar", ubicacion: "Datamovil" };
                }

                let fechaHoraStr
                let fechaHora = ''
                if (cita[0]?.fechacita && cita[0]?.horacita) {
                    // Crear un nuevo string con el día y la hora combinados
                    fechaHoraStr = `${cita[0]?.fechacita.split('T')[0]}T${cita[0]?.horacita}`; // Asegúrate de que la hora tenga los segundos
                    const formatFecha = new Date(fechaHoraStr);
                    fechaHora = formatearFecha(formatFecha)
                }
                else if (scheduleDataTarjeta?.day) {
                    // Crear un nuevo string con el día y la hora combinados
                    fechaHoraStr = `${scheduleDataTarjeta?.day.split('T')[0]}T${scheduleDataTarjeta?.hour}`; // Asegúrate de que la hora tenga los segundos
                    const formatFecha = new Date(fechaHoraStr);
                    fechaHora = formatearFecha(formatFecha)
                }
                // Crear el objeto Date con la cadena combinada
                setInitialValue((prevValues) => ({
                    ...prevValues,
                    id: cita[0]?.id ?? "",
                    // contrato: cita[0]?.contrato ?? "",
                    ubicacion: scheduleDataTarjeta.sucursalName ? "Datamovil" : cita[0]?.ubicacion ?? "",
                    sucursal: scheduleDataTarjeta.sucursalName ?? cita[0]?.sucursal,
                    estatus: scheduleDataTarjeta.sucursalName ? "Solicitud" : cita[0]?.estatus ?? "Pendiente",
                    fecha: (cita[0]?.fechacita && cita[0]?.fechacita !== "" && cita[0]?.fechacita !== "1900-01-01T00:00:00"&& cita[0]?.fechacita !== "1990-01-01T00:00:00" ) ? getDateNow(cita[0]?.fechacita) : '',
                    fechacita: getFinalDate(scheduleDataTarjeta, cita[0]),
                    horacita: (cita[0]?.horacita && cita[0]?.horacita !== "") ? cita[0]?.horacita : scheduleDataTarjeta.hour ?? '',
                    callenumero: cita[0]?.callenumero ?? "",
                    codigop: cita[0]?.codigop ?? "",
                    pais: cita[0]?.pais ?? "",
                    estado: cita[0]?.estado ?? "",
                    municipio: cita[0]?.municipio ?? "",
                    colonia: cita[0]?.colonia ?? "",
                    latitud: cita[0]?.latitud ?? "",
                    longitud: cita[0]?.longitud ?? "",
                    fechainicioproceso: cita[0]?.fechainicioproceso ?? today,
                    fechafinproceso: cita[0]?.fechafinproceso ?? "",
                    bloqueoid: cita[0]?.bloqueoid === "true" ? true : false,
                    concluido: cita[0]?.bloqueoid === "true" ? true : false,
                    fechayHora: fechaHora
                }));
                return cita[0]?.fechainicioproceso
            })
        return fechaInicio
    }

    const crearCita = async () => {
        const data = {
            anyObject: {
                id: state.id,
                contrato: state.contrato,
                fechainicioproceso: today,
                ubicacion: "",
                sucursal: "",
                estatus: "",
                fechacita: "",
                horacita: "",
                callenumero: "",
                codigop: "",
                pais: "México",
                estado: "",
                municipio: "",
                colonia: "",
                latitud: "",
                longitud: "",
                bloqueoid: false,
                concluido: false
            }
        }

        await UnidadesRecuperadasService.upsertCitasEntregaUnidad(data)
    }

    const HandleGetMunicpio = async (id) => {
        setDataMunicipio([]);
        await Llamada.GetMunicipio(id).then(res => {
            let Municipios = []
            res.data?.data?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setDataMunicipio(Municipios);
        })
    }

    const HandleGetColonia = async (id) => {
        setDataColonia([])
        await Llamada.GetColonia(id).then(res => {
            let Colonias = []
            res.data?.data?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setDataColonia(Colonias);
        })
    }


    const sendCalendarioGruas = () => {
        let calendario = {
            tipoCalendario: 13,
            fields: {
                contrato: state?.contrato,
                vin: state?.vin,
                modelo: vehiculo?.nombreModelo,
                year: vehiculo?.nombreYear,
                marca: vehiculo?.nombreMarca
            }
        }
        dispatch(setFields(calendario));
        navigate(`/cobranza/citas/v2/13`, {
            state: { 
                ...state, 
                title: 'Cita Entrega De Unidad', 
                tipoCalendario: 13, 
                modelo: vehiculo?.nombreModelo,
                year: vehiculo?.nombreYear,
                marca: vehiculo?.nombreMarca
             }
        });
    }

    const validationSchema = Yup.object({
        ubicacion: Yup.string().required('Ubicacion es requerido'),

        //Solo en Datamovil
        sucursal: Yup.string()
            .when('ubicacion', {
                is: 'Datamovil',
                then: Yup.string().required('Sucursal es requerido'),
            }),
        // estatus: Yup.string()
        //     .when('ubicacion', {
        //         is: 'Datamovil',
        //         then: Yup.string().required('Estatus es requerido'),
        //     }),
        // fechayHora: Yup.string()
        //     .when('ubicacion', {
        //         is: 'Datamovil',
        //         then: Yup.string().required('Fecha es requerido'),
        //     }),

        // Solo en Externo
        horacita: Yup.string().required('Hora es requerido'),
        fechacita: Yup.string().required('Fecha es requerido'),
        callenumero: Yup.string()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.string().required('Calle es requerido'),
            }),
        codigop: Yup.number()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.number().required('C.P. es requerido')
                    .test(
                        'max-length',
                        'El código postal debe tener 6 o menos caracteres',
                        (value) => String(value).length <= 6 // Validar longitud
                    ),
            }),
        pais: Yup.string()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.string().required('Pais es requerido'),
            }),
        estado: Yup.string()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.string().required('Estado es requerido'),
            }),
        municipio: Yup.string()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.string().required('Municipio es requerido'),
            }),
        colonia: Yup.string()
            .when('ubicacion', {
                is: 'Externo',
                then: Yup.string().required('Colonia es requerido'),
            }),
        concluido: Yup.boolean()
            .oneOf([true])
    })

    const submitForm = async (values) => {
        values.id = state.id
        // const fechaHoy = new Date()
        const fechaCita = new Date(`${values?.fechacita}T${values?.horacita}:00`)
        // values.fechafinproceso = state.id
        // if (values.fechainicioproceso) {

        // } else {
        //     values.fechainicioproceso = fechaHoy
        // }
        if (values.concluido) {

            values.bloqueoid = values.concluido
            values.fechafinproceso = today
            values.estatus = "Solicitud"
            //pendiente
            dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 13 }));

        }
        const data = { anyObject: values }

        const dBloqueo = {
            ubicacionid: scheduleDataTarjeta.sucursalId,
            fecha: scheduleDataTarjeta.day,
            calendarioid: scheduleDataTarjeta.calendarioId,
            calendariotipoid: 13,
            relacionid: state.id
        }

        const requestData = {
            anyObject: {
                id_cita: "",
                contrato: values.contrato,
                vin: vehiculo?.vin || "", //carro
                marca: vehiculo?.nombreMarca || "",//carro
                modelo: vehiculo?.nombreModelo || "",//carro
                anio: vehiculo?.nombreYear || "",//carro
                cobradorid: userId, //userId
                // fechacita: fechaHoy, //today //fecha y hora de la cita
                fechacita: fechaCita,
                ubicacion: values.ubicacion,
                sucursal: values.sucursal,
                estatus: 1,
                comentarios: "",
                motivo: "",
                motivoid: "",
                folio_citaentrega: state.id
            }
        }
        const correo_ampliacion = {
            usuario: usuario,
            contrato: values?.contrato,
            vin: vehiculo?.vin || "",
            marca: vehiculo?.nombreMarca || "",//carro
            modelo: vehiculo?.nombreModelo || "",//carro
            year: vehiculo?.nombreYear || "",//carro
            ubicacion: values.sucursal,
            dia: data.anyObject.fechacita,
            hora: values.fechayHora
        }

        const notificacion = {
            anyObject: {
                usuarioid: userId, //El usuario que inicio sesión
                modulo: "pago_unidad", //pantalla origen
                tipocorreo: "Solicitud de Cita Entrega de Unidad",
                data: JSON.stringify(correo_ampliacion), //Objeto JSON que se va a usar en la notificacion 
                enviado: 0 //Cuando se crea va en 0
            }
        }
        try {
            setLoading(true)
            if (values.ubicacion === "Datamovil") {
                if (scheduleDataTarjeta.length > 0) {
                    await Llamada.crearCita(dBloqueo)
                }
                await UnidadesRecuperadasService.upsertCitasEntregaUnidad(data)
            }
            else {
                await UnidadesRecuperadasService.upsertCitasEntregaUnidad(data)
            }
            if (values.concluido) {
                await UnidadesRecuperadasService.upsertCitasEntregaUnidadxVehiculo(requestData)
            }
            await UnidadesRecuperadasService.createNotificacionesCobranza(notificacion)
            setLoading(false)
            toast.success(message("¡Operación exitosa!"));
            await getAllData();
        } catch (error) {
            setLoading(false)
            toast.error(message("¡Error!"));
        }
    }

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={validationSchema}
                initialValues={initialValue}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors }) => {
                    return (<>
                        <Form>
                            <div>
                                <div className="row mb-2">
                                    <div className='col-4'>
                                        <h6><strong>Tipo de ubicación</strong></h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                {
                                                    initialValue.bloqueoid ?
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                        : <button
                                                            className='btn'
                                                            disabled={!(isValid)}
                                                            type='submit'>
                                                            Guardar
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2 '>
                                    <div className='col-lg-3 col-md-3 col-sm-12'  >
                                        <SelectField
                                            id="ubicacion"
                                            label="Ubicación"
                                            name="ubicacion"
                                            items={ubicacionSelect}
                                            disabled={initialValue.bloqueoid}
                                            onChange={(event) => {
                                                setFieldValue("ubicacion", event.value);
                                            }}
                                        />
                                    </div>
                                    {/* {values.ubicacion === "Datamovil" &&
                                        // <div className='col-lg-3 col-md-3 col-sm-12'>
                                        //     <SelectField
                                        //         id="sucursal"
                                        //         label="Sucursal"
                                        //         name="sucursal"
                                        //         items={ubicacionSelect}
                                        //         onChange={(event) => {
                                        //             setFieldValue("sucursal", event.value);
                                        //         }}
                                        //     />
                                        // </div>
                                        <div className='col-lg-2 col-md-2 col-sm-12'>
                                            <TextField label="Sucursal" name="sucursal" type="text" disabled={true} holder="-"
                                                value={scheduleDataTarjeta.sucursalName || ""}
                                                onChange={(event) => {
                                                    setFieldValue("sucursal",);
                                                }} />
                                        </div>
                                    } */}
                                </div>
                                {values.ubicacion === "Datamovil" &&
                                    <>
                                        <div className='col-4'>
                                            <h6><strong>Agenda de cita</strong></h6>
                                        </div>
                                        {/* <div className="col-6">
                                            datos: <pre>{JSON.stringify(scheduleDataTarjeta, null, 2)}</pre>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-9'>
                                                        <TextField label="Fecha y hora de cita" name="fechayHora" type="text" disabled={true} holder="No hay cita seleccionada"
                                                            // value={scheduleDataTarjeta.sucursalName ? `${scheduleDataTarjeta.day} ${scheduleDataTarjeta.hour}` : 'No hay cita seleccionada '}
                                                            onChange={(event) => {
                                                                setFieldValue("fechayHora", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-2" style={{ alignSelf: "flex-end", paddingBottom: "0.5rem" }}>
                                                        <button className='btn'
                                                            disabled={initialValue.bloqueoid}
                                                            onClick={() => {
                                                                sendCalendarioGruas();
                                                            }}>Ver calendario</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-12'>
                                                <TextField label="Ubicación" name="sucursal" type="text" disabled={true} holder="-"
                                                    // value={scheduleDataTarjeta.sucursalName || ""}
                                                    onChange={(event) => {
                                                        setFieldValue("sucursal",);
                                                    }} />
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-12'>
                                                <TextField label="Estatus cita" name="estatus" type="text" disabled={true} holder="Pendiente"
                                                    // value={scheduleDataTarjeta.estatus || ""}
                                                    onChange={(event) => {
                                                        setFieldValue("estatus",);
                                                    }} />
                                            </div>
                                            {/* <div className='col-lg-2 col-md-2 col-sm-12'>
                                                <TextField label="Estatus de cita" name="estatus" type="text" disabled={true} holder="No hay cita" onChange={(event) => {
                                                    setFieldValue("estatus", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-2" style={{ alignSelf: "flex-end", paddingBottom: "0.5rem" }}>
                                                <button className='btn' onClick={() => {
                                                }}>Autorizar Cita</button>
                                            </div> */}
                                        </div>
                                    </>
                                }
                                {values.ubicacion === "Externo" &&
                                    <>
                                        <div className='col-4'>
                                            <h6><strong>Detalle de cita y ubicación</strong></h6>
                                        </div>
                                        <div className='d-flex row'>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="Fecha de cita" name="fecha" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechacita", event.target.value);
                                                    setFieldValue("fecha", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12 px-4'>
                                                <TextField label="Hora de cita" name="horacita" type="time" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("horacita", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                        </div>
                                        <div className='d-flex row'>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="Calle y número" name="callenumero" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("callenumero", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="Código postal" name="codigop" type="number" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("codigop", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="País" name="pais" type="text" holder="Escribe" disabled={true} onChange={(event) => {
                                                    setFieldValue("pais", event.target.value);
                                                }} />
                                            </div>
                                            {initialValue.bloqueoid ?
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <TextField label="Estado" name="estado" type="text" holder="Escribe" disabled={true}
                                                    // onChange={(event) => {
                                                    //     setFieldValue("pais", event.target.value);
                                                    // }} 
                                                    />
                                                </div>
                                                :
                                                <div className="col-lg-3 col-3" style={{ display: 'flex', flexFlow: 'column' }}>
                                                    <SelectField id="estado" label="Estado" name="estado" items={dataEstado} onChange={(event) => {
                                                        setFieldValue("estado", event.label);
                                                        HandleGetMunicpio(event.value);
                                                    }} disabled={initialValue.bloqueoid} />
                                                </div>
                                            }
                                            {/* </div>
                                        <div className='d-flex row'> */}
                                            {initialValue.bloqueoid ?
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <TextField label="Municipio" name="municipio" type="text" holder="Escribe" disabled={true}
                                                    // onChange={(event) => {
                                                    //     setFieldValue("pais", event.target.value);
                                                    // }} 
                                                    />
                                                </div>
                                                :
                                                <div className="col-lg-3 col-3" style={{ display: 'flex', flexFlow: 'column' }}>
                                                    <SelectField id="municipio" label="Municipio" name="municipio" items={dataMunicipio} onChange={(event) => {
                                                        setFieldValue("municipio", event.label);
                                                        HandleGetColonia(event.value);
                                                    }} disabled={initialValue.bloqueoid} />
                                                </div>
                                            }
                                            {initialValue.bloqueoid ?
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <TextField label="Colonia" name="colonia" type="text" holder="Escribe" disabled={true}
                                                    // onChange={(event) => {
                                                    //     setFieldValue("pais", event.target.value);
                                                    // }} 
                                                    />
                                                </div>
                                                :
                                                <div className="col-lg-3 col-3" style={{ display: 'flex', flexFlow: 'column' }}>
                                                    <SelectField id="colonia" label="Colonia" name="colonia" items={dataColonia} onChange={(event) => {
                                                        setFieldValue("colonia", event.label);
                                                    }} disabled={initialValue.bloqueoid} />
                                                </div>
                                            }
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="Latitud" name="latitud" type="text" holder="-" onChange={(event) => {
                                                    setFieldValue("latitud", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <TextField label="Longitud" name="longitud" type="text" holder="-" onChange={(event) => {
                                                    setFieldValue("longitud", event.target.value);
                                                }} disabled={initialValue.bloqueoid} />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="row mt-2 p-4">
                                    <div className="col-12 col-lg-3 col-sm-12">
                                        <div className="d-flex align-items-center" >
                                            <div className="form-check" style={{ paddingLeft: '0', marginLeft: '-20px' }}>
                                                <Field className="form-check-input me-2" type="checkbox" name="concluido" onClick={(event) => {
                                                    setFieldValue("concluido", event.target.value);
                                                }} disabled={initialValue.bloqueoid} style={{ marginLeft: '0', marginRight: '0.5rem' }} />
                                                <label className="form-check-label ms-2" >Cita Agendada</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-9 col-sm-12">
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                {
                                                    values.fechainicioproceso &&
                                                    <small className='text-body-tertiary'>Fecha Inicio: {formatearFecha(values.fechainicioproceso)}</small>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.concluido &&
                                                    <small className='text-body-tertiary'>Fecha Fin: {formatearFecha(values.fechafinproceso)}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                    )
                }}
            </Formik >
        </>
    )

}
export default CitaEntregaUnidad