import { Field, Formik } from "formik";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from 'react';
import ValuacionDetalle from "../ValuacionDetalle";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import useInspeccionRapida from "../../../../Hooks/Inventario/useInspeccionRapida";
import Modal from "../../../../Components/Modal/Modal";
import { toast, Toaster } from "react-hot-toast";
import Spinner from "../../../../Components/Loadig";
import InventarioCostosInspeccionModal from "../../../../Components/Modal/InventarioCostosInspeccionModal";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../../../Components/datatable/DataTable";
import ModalConfirm from "../ModalConfirm";
import ContratoCard from "../../../../Components/ContratoCard";
import { FormatNumberMoney2, message } from "../../../../Utils/functions";
import axios from 'axios';
import AuthLogin from "../../../../Utils/AuthLogin";
import dataDocsServices from "../../../../Services/DataDocs/dataDocsServices";
import VisorFile from "../../../../Components/VisorFile";
import ModalCostos from "./ModalCostos";
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from "../../../../Auth/ValidatePermission";
import { Section, SubModule } from "../../../../Auth/Authorization";

const auth = new AuthLogin();

const InspeccionRapidaValuacion = () => {
    const navigate = useNavigate();
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);
    const { state } = useLocation();
    let url = auth.UrlDataDocs();

    const {
        initialValue,
        refFormik,
        optionsAptoInventario,
        modalMessage,
        setModalMessage,
        loading,
        getCostosInspeccion,
        downloadPDF,
        submitForm,
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        columns,
        valuacionInspeccionUnidadId,
        costos,
        handleEdit,
        handleDelete,
        //handleViewdocs,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        pageCount,
        handlePageClick,
        page,
        totalCosto,
        politicaCompra,
        setLoading,
        isOpenModal,
        setSelectedItem,
        setIsOpenModal,
        selectedItem,
    } = useInspeccionRapida();

    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFileCard = async (value, subido) => {
        navigate(`/seguro/siniestros/datadocs/fResguardo/eResguardo/${state?.contrato}/${value.valuacionInspeccionUnidadId}`, {
            state: { ...value, registroId: value.valuacionInspeccionUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }


    const handleUploadFile = async (value, subido) => {
        if (subido) {
            handleViewdocs(value);
        } else {
            navigate(`/seguro/siniestros/datadocs/fResguardo/eResguardo/${state?.contrato}/${value.valuacionInspeccionUnidadId}`, {
                state: { ...value, registroId: value.valuacionInspeccionUnidadReparacionesId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    }

    const validate = Yup.object({
        observacionesUnidad: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string().nullable().required("Selecciona una opción"),
            }),
        vehiculoApto: Yup.boolean()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.boolean().nullable().required("Selecciona una opción"),
            }),
    });

    return (
        <>
            <Modal isOpen={loading} color="transparent">
                <Spinner message={""} />
            </Modal>
            <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
                {modalMessage.type === 1 && (
                    <InventarioCostosInspeccionModal
                        getCostosInspeccion={getCostosInspeccion}
                        valuacionInspeccionUnidadId={valuacionInspeccionUnidadId}
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                )}
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: "#47a066",
                            color: "#FFFF",
                            borderLeft: "10px solid #2f7246",
                        },
                    },
                    error: {
                        style: {
                            background: "#d53f3f",
                            color: "#FFFF",
                            borderLeft: "10px solid #ac241a",
                        },
                    },
                }}
            />

            <ValuacionDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div
                className="d-flex flex-column background-grey"
                style={{ height: "100vh" }}
            >
                <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoInspeccionDeUnidad, SubModule.SeguimientoValuacion)?.Ver}>
                    <div className="row mx-md-4 my-4">
                        <div className="col-6 px-md-3">
                            <VehiculoInfoCard
                                GeneralId={state?.generalId}
                                verClaves={true}
                                solicitarClave={false}
                            />
                        </div>
                        <div className="col-6 px-md-3">
                            <ContratoCard vin={state?.vin} />
                        </div>
                    </div>

                    <div
                        className="d-flex flex-column background-grey"
                        style={{ height: "auto" }}
                    >
                        <div className="mx-md-4 my-4">
                            <div className="col-12">
                                <div className="p-4 white-wrapper mb-2">
                                    <div className="mt-2 px-4">
                                        <div className="col-11">
                                            <h6>
                                                <strong>Inspección de unidad </strong>
                                            </h6>
                                        </div>
                                    </div>
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        initialValues={initialValue ?? { concluido: false }}
                                        validationSchema={validate}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-6">
                                                        <div className="row flex align-items-center">
                                                            <div className="col-auto">
                                                                <small>
                                                                    Descargar formato de inspección de unidad
                                                                </small>
                                                            </div>
                                                            <div className="col-3">
                                                                <button
                                                                    className="btn btn-gray"
                                                                    onClick={() => downloadPDF()}
                                                                    type="button"
                                                                    disabled={initialValue.concluido}
                                                                >
                                                                    <i className="icon-blue bx bx-download"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="col-auto">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        id="btnSubirDocumento"
                                                                        className="btn btn-outline col-12 mw-100 d-flex"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            handleUploadFileCard(values, false)
                                                                        }
                                                                        style={{
                                                                            padding: "8px",
                                                                            lineHeight: "20px",
                                                                            border: "none",
                                                                            backgroundColor: "#f0f0f0",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="icon-light fa fa-file "
                                                                            style={{
                                                                                color: "#004C97",
                                                                                marginRight: "8px",
                                                                            }}
                                                                        ></i>
                                                                        Subir documento
                                                                    </button>
                                                                </div>

                                                                <div className="col-auto">
                                                                    {initialValue.concluido ? (
                                                                        <fieldset className="btn btn-light secondary d-flex align-items-center mx-2">
                                                                            <i className="icon-dark bx bx-hide mx-1"></i>
                                                                            Modo lectura
                                                                        </fieldset>
                                                                    ) : (
                                                                        <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.SeguimientoInspeccionDeUnidad, SubModule.SeguimientoValuacion))}>
                                                                            <button
                                                                                className="btn"
                                                                                disabled={!isValid}
                                                                                onClick={() => submitForm(values)}
                                                                            >
                                                                                Guardar
                                                                            </button>
                                                                        </ValidatePermission>

                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4 px-4">
                                                    <div className="col-12">
                                                        <div className="row d-flex align-items-center">
                                                            <div className="col-auto">
                                                                <strong>Registro de costos de reparación</strong>
                                                            </div>
                                                            <div className="col-auto">
                                                                {!initialValue.concluido && (
                                                                    <button
                                                                        className="btn"
                                                                        onClick={(e) => {
                                                                            setModalMessage({
                                                                                isOpen: true,
                                                                                type: 1,
                                                                                title: "Registrar costo",
                                                                                valuacionInspeccionUnidadId:
                                                                                    initialValue.valuacionInspeccionUnidadId,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Agregar
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <DataTable
                                                            id="tablaPagos"
                                                            name="pagos"
                                                            column={columns}
                                                            data={costos}
                                                            editable={!initialValue.concluido}
                                                            handleEdit={handleEdit}
                                                            deleteable={!initialValue.concluido}
                                                            handleDelete={handleDelete}
                                                            handleDocuments={handleViewdocs}
                                                            handleAction={(value, idx) => {
                                                                handleUploadFile(value, idx);
                                                            }}
                                                            paginate={true}
                                                            pageCount={pageCount}
                                                            handlePageClick={handlePageClick}
                                                            currentPage={page - 1}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <div>
                                                                <strong>
                                                                    Total: ${FormatNumberMoney2(totalCosto)}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <SelectField
                                                            id="vehiculoApto"
                                                            label="¿Vehículo apto para inventario?"
                                                            disabled={initialValue.concluido || politicaCompra}
                                                            name="vehiculoApto"
                                                            items={optionsAptoInventario}
                                                            onChange={(event) => {
                                                                setFieldValue("vehiculoApto", event.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-6">
                                                        <TextAreaField
                                                            id="observacionesUnidad"
                                                            label="Observaciones de la unidad"
                                                            disabled={initialValue.concluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="observacionesUnidad"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue(
                                                                    "observacionesUnidad",
                                                                    event.target.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 mx-md-3">
                                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                                        {" "}
                                                        {/* Contenedor principal */}
                                                        <div className="d-flex align-items-center">
                                                            {" "}
                                                            {/* Concluido a la izquierda */}
                                                            <Field
                                                                type="checkbox"
                                                                name="concluido"
                                                                disabled={initialValue.concluido}
                                                                className="form-check-input me-2"
                                                                checked={values.concluido}
                                                                onChange={(event) =>
                                                                    setFieldValue("concluido", event.target.checked)
                                                                }
                                                            />
                                                            <label className="form-check-label">
                                                                Inspección de unidad finalizada
                                                            </label>
                                                        </div>
                                                        <div className="d-flex">
                                                            <small className="text-body-tertiary">
                                                                Inicio de proceso:{" "}
                                                                {initialValue?.fechaInicio ?? "No disponible"}
                                                            </small>
                                                            {Boolean(initialValue?.concluido) && (
                                                                <small className="text-body-tertiary">
                                                                    {" | "}Fin de proceso:{" "}
                                                                    {initialValue?.fechaFin ?? "No disponible"}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidatePermission>

                {modalConfirm && (
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Seguimiento de Proceso"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                )}
                {
                    isOpenModal &&
                    <ModalCostos
                        isOpen={isOpenModal}
                        setIsOpen={setIsOpenModal}
                        item={selectedItem}
                        valuesInit={initialValue}
                        handleGetAll={getCostosInspeccion}
                        toast={toast}
                    />
                }
                {
                    isOpenDocs &&
                    <VisorFile
                        isOpen={isOpenDocs}
                        setIsOIpen={setIsOpenDocs}
                        fileName={fileName}
                        doc={SelectedDocs}
                    />
                }
            </div>
        </>
    );
};

export default InspeccionRapidaValuacion;
