
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { formatearMontoMx, FormatFecha, getFormatDate } from "../../Utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { setVisitaDomiciliariaId } from "../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice";

const useHistorialVisitasDetalle = () => {
    const userId = sessionStorage.getItem('userId').trim()
    const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

    const navigate = useNavigate();
    // Datos contrato
    const [userDetail, setUserDetail] = useState({ names: '' });
    const [promesasRotas, setPromesasRotas] = useState(0)
    //Modal
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const { role } = useSelector((state) => state.loginSlice);

    const dispatch = useDispatch();
    const [initialValue, setInitialValue] = useState({
        num_visita: "",
        entregaste_cliente: "",
        resultado: "",
        encontraste_documento: "",
        tipo_documento: "",
        motivo: "",
        especificacion: "",
        motivo_ingreso: "",
        comentarios: "",
        comentario_ingreso: "",
        reg_concluido_visita: false,
        fecha_inicio: '',
        fecha_fin: ''
    });
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
    const [collapsableVisita, setCollapsableVisita] = useState(false)
    const [collapsableResguardo, setCollapsableResguardo] = useState(false)
    const [collpsableGruaPromesaPago, setCollpsableGruaPromesaPago] = useState(false);
    const [collapsableRecoleccion, setCollapsableRecoleccion] = useState(false)
    const [formularioActual, establecerFormularioActual] = useState(0);
    const [formularioVisitaDom, setFormularioVisitaDom] = useState(false)
    const [usuarioProgramo, setUsuarioProgramo] = useState("")
    const [datosResguardo, setDatosResguardo] = useState({});
    const [datosRecoleccion, setDatosRecoleccion] = useState({})
    const [pageRows, setpageRows] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [currenPage, setCurrenPage] = useState(1);
    const [totalPagos, setTotalPagos] = useState(0);
    const [gruaSeleccionada, setGruaSeleccionada] = useState(0);
    const refFormik = useRef();

    const formattedData = {
        ...contratoActual,
        monto_adeudo: formatearMontoMx(contratoActual.monto_adeudo),
        fecha_ultima_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave),
        saldo_credito: formatearMontoMx(contratoActual.saldo_credito),
        fecha_ultima_act: contratoActual.fecha_ultimo_pago === "1990-01-01T00:00:00" ? "-" : FormatFecha(contratoActual.fecha_ultimo_pago),
        vigencia_clave: contratoActual.vigencia_clave === "1990-01-01T00:00:00" ? '-' : FormatFecha(contratoActual.vigencia_clave)
    };

    useEffect(() => {
        if (itemGeneral?.visitaDoms !== null) {
            setFormularioVisitaDom(true)
            handleCargarDatosVisitas()
        }
        if (itemGeneral?.promesa !== null) {
            handleCargarDatosPromesa();
        }
        if (itemGeneral?.resguardoUnidad !== null && itemGeneral?.promesa?.grua === 0) {
            handleCargarDatosResguardo();
        }
        if (itemGeneral?.recoleccionUnidad !== null && itemGeneral?.promesa?.grua === 1) {
            handleCargarDatosRecoleccion();
        }
    }, [])

    const handleCargarDatosVisitas = () => {
        let NewData = []
        if (itemGeneral?.visitaDoms[0]?.visitaDomDocs !== null) {
            itemGeneral?.visitaDoms[0]?.visitaDomDocs?.map(file => {
                if (file.binario != null) {
                    NewData.push({
                        value: file.binario.documentoId,
                        label: file.binario.nombreDocumento,
                        idFolder: file.binario.idFolder
                    })
                }
            })
        }
        dispatch(setVisitaDomiciliariaId(itemGeneral?.visitaDoms[0]?.id))
        setOpcionSeleccionada(itemGeneral?.visitaDoms[0]?.encontraste_cliente === 1 ? 1 : 2)
        setInitialValue({
            id: itemGeneral?.visitaDoms[0]?.id,
            num_visita: itemGeneral?.visitaDoms[0]?.num_visita ?? 0,
            entregaste_cliente: itemGeneral?.visitaDoms[0]?.encontraste_cliente === 2 ? "No" : "Si",
            resultado: itemGeneral?.visitaDoms[0]?.resultado ?? "",
            encontraste_documento: itemGeneral?.visitaDoms[0]?.entregaste_documento === 2 ? "No" : "Si",
            tipo_documento: itemGeneral?.visitaDoms[0]?.tipo_documento ?? "",
            motivo: itemGeneral?.visitaDoms[0]?.motivo ?? "",
            especificacion: itemGeneral?.visitaDoms[0]?.especificacion ?? "",
            motivo_ingreso: itemGeneral?.visitaDoms[0]?.motivo_ingreso ?? "",
            comentarios: itemGeneral?.visitaDoms[0]?.comentarios ?? "",
            comentario_ingreso: itemGeneral?.visitaDoms[0]?.comentario_ingreso ?? "",
            reg_concluido_visita: itemGeneral?.visitaDoms[0]?.registro_concluido === 1 ? true : false,
            fecha_inicio: itemGeneral?.visitaDoms[0]?.fecha_inicio,
            fecha_fin: itemGeneral?.visitaDoms[0]?.fecha_fin,
            ver_documentos: "fCobranza/eVisitaD"
        });
    }

    const getDiasPromesa = (fecha) => {
        return Math.ceil((new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24));
    }

    const calcularTotal = (fecha) => {
        let frecuencia = 7;
        if (contratoActual?.frecuencia_pago?.toLowerCase() === "quincenal") {
            frecuencia = 15;
        } else if (contratoActual?.frecuencia_pago?.toLowerCase() === "mensual") {
            frecuencia = 30;
        }
        let dias_promesa = getDiasPromesa(fecha);
        // console.log(dias_promesa);
        let result = Math.floor(dias_promesa / frecuencia);
        // console.log(result);

        let pago_periodo = (contratoActual?.pago_periodo !== undefined && contratoActual?.pago_periodo !== null) ? contratoActual?.pago_periodo : 0
        let monto_adeudo = (contratoActual?.monto_adeudo !== undefined && contratoActual?.monto_adeudo !== null) ? contratoActual?.monto_adeudo : 0

        if (result <= 0) {
            result = 1
        }

        let res = Number((result * pago_periodo + monto_adeudo).toFixed(2))

        return res;
    }

    const [datosGarantiaPago, setDatosGarantiaPago] = useState({});
    const [datosPromesaPago, setDatosPromesaPago] = useState({});
    const [datosPromesaEntregaDefinitiva, setDatosPromesaEntregaDefinitiva] = useState({});

    const handleCargarDatosPromesa = () => {
        if (itemGeneral?.promesa?.tipo_promesa === "Garantía de pago") {
            let date = new Date(itemGeneral?.promesa?.fecha_vencimiento);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
            let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
            setDatosGarantiaPago({
                id: itemGeneral?.promesa?.id,
                tipo_promesa: "Garantía de pago",
                folio: `${itemGeneral?.promesa?.folio_big}`,
                proceso: itemGeneral?.promesa?.proceso,
                cobrador_apoyo: itemGeneral?.promesa?.cobrador_apoyo === 1 ? "Si" : "No",
                cobradores: itemGeneral?.promesa?.cobradores,
                grua: itemGeneral?.promesa?.grua === 1 ? "Si" : "No",
                fecha_vencimiento: `${year}-${month}-${day}`,
                dias_de_promesa: itemGeneral?.promesa?.dias_de_promesa,
                monto_vencido: formatearMontoMx(contratoActual?.monto_adeudo),
                pago_periodo: formatearMontoMx(contratoActual?.pago_periodo),
                total: isNaN(itemGeneral?.promesa?.total) || itemGeneral?.promesa?.total === null ? 0 : itemGeneral?.promesa?.total,
                reg_concluido_promesa: itemGeneral?.promesa?.registro_concluido === 1 ? true : false,
                fecha_inicio_promesa: itemGeneral?.promesa?.fecha_inicio,
                fecha_fin_promesa: itemGeneral?.promesa?.fecha_fin,
                ver_documentos: "fDomicilio/eSegVisit"
            })
        }
        if (itemGeneral?.promesa?.tipo_promesa === "Promesa de pago") {
            establecerFormularioActual(1)
            let date = new Date(itemGeneral?.promesa?.fecha_compromiso);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
            let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate()
            let hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
            let minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
            setDatosPromesaPago({
                id: itemGeneral?.promesa?.id,
                tipo_promesa: "Promesa de pago",
                folio: `${itemGeneral?.promesa?.folio_big}`,
                motivo_promesa: itemGeneral?.promesa?.motivo_promesa,
                fecha_compromiso: `${year}-${month}-${day}`,
                horarioCompromiso: `${hours}:${minutes}`,
                monto_prometido: formatearMontoMx(itemGeneral?.promesa?.monto_prometido),
                monto_vencido: formatearMontoMx(contratoActual?.monto_adeudo),
                comentarios: itemGeneral?.promesa?.comentarios,
                reg_concluido_promesa: itemGeneral?.promesa?.registro_concluido === 1 ? true : false,
                fecha_inicio_promesa: itemGeneral?.promesa?.fecha_inicio,
                fecha_fin_promesa: itemGeneral?.promesa?.fecha_fin,
                ver_documentos: "fDomicilio/eSegVisit"
            })
        }

        if (itemGeneral?.promesa?.tipo_promesa === "Promesa de entrega definitiva") {
            establecerFormularioActual(2)
            setDatosPromesaEntregaDefinitiva({
                id: itemGeneral?.promesa?.id,
                tipo_promesa: "Promesa de entrega definitiva",
                folio: `${itemGeneral?.promesa.folio_big}`,
                proceso: itemGeneral?.promesa?.proceso,
                cobrador_apoyo: itemGeneral?.promesa?.cobrador_apoyo === 1 ? "Si" : "No",
                cobradores: itemGeneral?.promesa?.cobradores,
                grua: itemGeneral?.promesa?.grua === 1 ? "Si" : "No",
                reg_concluido_promesa: itemGeneral?.promesa?.registro_concluido === 1 ? true : false,
                fecha_inicio_promesa: itemGeneral?.promesa?.fecha_inicio,
                fecha_fin_promesa: itemGeneral?.promesa?.fecha_fin,
                ver_documentos: "fDomicilio/eSegVisit"
            })
        }
    }

    const handleCargarDatosResguardo = () => {
        const fechahora = itemGeneral?.resguardoUnidad?.fecha_hora_resguardo.split('T') ?? ["", ""];
        setDatosResguardo({
            comentarios: itemGeneral?.resguardoUnidad?.comentarios ?? '',
            forma_arribo: itemGeneral?.resguardoUnidad?.forma_arribo ?? '',
            id: itemGeneral?.resguardoUnidad?.id ?? '',
            motivo_resguardo: itemGeneral?.resguardoUnidad?.motivo_resguardo ?? '',
            quien_entrega: itemGeneral?.resguardoUnidad?.quien_entrega ?? '',
            costosAdicionalesTbl: handleCargarTabla(itemGeneral.resguardoUnidad?.resguardoCostos, itemGeneral?.resguardoUnidad?.registro_concluido) ?? [],
            ruta_item_id: itemGeneral?.resguardoUnidad?.ruta_item_id ?? '',
            ubicacion_entrega: itemGeneral?.resguardoUnidad?.ubicacion_entrega ?? '',
            usuario_recibe: itemGeneral?.resguardoUnidad?.usuario_recibe ?? '',
            fechaResguardo: fechahora[0],
            horaResguardo: fechahora[1],
            registro_concluido: itemGeneral?.resguardoUnidad?.registro_concluido ?? false,
            fecha_inicio_resguardo: itemGeneral?.resguardoUnidad?.fecha_inicio ?? "",
            fecha_fin_resguardo: itemGeneral?.resguardoUnidad?.fecha_fin ?? "",
            ver_documentos: "fResguardo/eResguardo"
        })
        setTotalPagos(handleTotal(itemGeneral?.resguardoUnidad?.resguardoCostos));
    }

    const handleTotal = (array) => {
        let total = 0.0
        if (array !== null && array !== undefined && array.length > 0) {
            total = array?.reduce((previous, current) => {
                return previous + current?.total; // sumar el valor de una propiedad
            }, 0);
        }
        return total;
    }

    const handleCargarTabla = (arreglo, registradoPreviamente) => {
        let lista = []
        let counter = 1;
        arreglo?.map(item => {
            lista.push({
                id: item.id,
                count: counter,
                fechaHora: formatearFecha(new Date(item?.fecha)),
                fecha: item.fecha,
                ejecutivo: sessionStorage.getItem('nombreUsuario').trim(),
                concepto: item?.concepto,
                subtotal: item?.subtotal,
                subtotal_formateado: formatearMontoMx(item?.subtotal),
                iva: item?.iva,
                total: item?.total,
                total_formateado: formatearMontoMx(item?.total),
                acciones: { subido: false },
                subido: registradoPreviamente || item?.resguardoCostoDocs !== null,
                ver_documentos: "fResguardo/eResguardo"
            });
            counter++
        })
        return lista
    }

    function formatearFecha(fechaISO) {
        if (!fechaISO) {
            return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
        }

        const fecha = new Date(fechaISO);

        if (isNaN(fecha.getTime())) {
            return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
        }

        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio = fecha.getFullYear();
        const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
        const minutos = fecha.getMinutes().toString().padStart(2, '0');
        const segundos = fecha.getSeconds().toString().padStart(2, '0');
        const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

        return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
    }

    const columnsResguardoUnidad = [
        { field: 'count', headerName: '#' },
        { field: 'fechaHora', headerName: 'Fecha y hora' },
        { field: 'ejecutivo', headerName: 'Ejecutivo' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subtotal_formateado', headerName: 'Subtotal' },
        { field: 'iva', headerName: 'IVA' },
        { field: 'total_formateado', headerName: 'Total' },
        { field: 'acciones', headerName: 'Acciones', width: 200 },
    ];

    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
    };

    const handleCargarDatosRecoleccion = () => {
        const fechahora = itemGeneral?.recoleccionUnidad?.fecha_hora_recoleccion.split('T') ?? ["", ""];
        setDatosRecoleccion({
            id: itemGeneral?.recoleccionUnidad?.id,
            folio: itemGeneral?.recoleccionUnidad?.folio ?? '',
            tipoGrua: itemGeneral?.recoleccionUnidad?.grua ?? '',
            grua_id: itemGeneral?.recoleccionUnidad?.grua === "Externa" ? 1 : 2,
            empresa_nombre: itemGeneral?.recoleccionUnidad?.empresa_nombre ?? "",
            empresa_id: parseInt(itemGeneral?.recoleccionUnidad?.empresa_id) ?? '',
            grua_asignada: itemGeneral?.recoleccionUnidad?.grua_asignada ?? "",
            costo_grua: itemGeneral?.recoleccionUnidad?.costo_grua ?? "",
            estado: itemGeneral?.recoleccionUnidad?.ubi_origen_estado ?? "",
            municipio: itemGeneral?.recoleccionUnidad?.ubi_origen_municipio ?? "",
            colonia: itemGeneral?.recoleccionUnidad?.ubi_origen_colonia ?? "",
            calle: itemGeneral?.recoleccionUnidad?.ubi_origen_calle ?? "",
            numero: itemGeneral?.recoleccionUnidad?.ubi_origen_numero ?? "",
            ubi_origen_cp: itemGeneral?.recoleccionUnidad?.ubi_origen_cp ?? "",
            ubi_dest_cp: itemGeneral?.recoleccionUnidad?.ubi_dest_cp ?? " ",
            usuario_recibe: itemGeneral.recoleccionUnidad?.usuario_recibe ?? "",
            ubi_origen_comentarios: itemGeneral?.recoleccionUnidad?.ubi_origen_comentarios ?? "",
            ubi_destino: itemGeneral?.recoleccionUnidad?.ubi_destino ?? "",
            quien_entrega: itemGeneral?.recoleccionUnidad?.quien_entrega ?? " ",
            fechaRecoleccion: fechahora[0],
            horaRecoleccion: fechahora[1],
            registro_concluido: itemGeneral?.recoleccionUnidad?.registro_concluido,
            fecha_inicio_recoleccion: itemGeneral?.recoleccionUnidad?.fecha_inicio ?? "",
            fecha_fin_recoleccion: itemGeneral?.recoleccionUnidad?.fecha_fin ?? "",
            ver_documentos: "fRecupera/eRecupera"
        })
        setGruaSeleccionada(itemGeneral?.recoleccionUnidad?.grua === "Externa" ? 1 : 2)
        handleGetUserName();
    }

    const handleGetUserName = async () => {
        let nombre_usuario_programo = ""
        setLoading(true)
        await CobranzaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                console.log(res)
                if (res?.data?.callSucceded) {
                    res?.data?.responseData?.map(item => {
                        if (item?.userid.toLowerCase() === itemGeneral?.recoleccionUnidad?.userid) {
                            setUsuarioProgramo(item?.user_detail?.names)
                        }
                    })
                }
            })
            .catch(e => {
                setLoading(false)
                console.log(`ERROR -- ${e}`)
            })
        setLoading(false)
        return nombre_usuario_programo
    }

    const handleReturnPage = () => {
        navigate(`/cobranza/seguimiento-visita/historial-visitas`)
    }

    useEffect(() => {
        // handleGetSeguimientoContrato();
        // GetUserStats();

        handleGetAllData();
    }, [])

    const handleGetAllData = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetSeguimientoContrato(), handleGetPromesasdePago()]).finally(() => {
            setLoading(false)
        });
    }

    const handleGetSeguimientoContrato = async () => {
        const userDetail = contratoActual?.names ?? null;
        setUserDetail(userDetail ?? { names: '' });
    }

    // const GetUserStats = async () => {
    //     let data = {
    //         "verb": "string",
    //         "id": userId,
    //         "field": "string",
    //         "value": "string",
    //         "level": 0,
    //         "flag": true,
    //         "anyObject": {},
    //         "kvp": {}
    //     }
    //     await CobranzaService.GetUserStats(data)
    //         .then(res => {
    //             const filteredCount = res?.data?.responseData?.filter(item =>
    //                 item.status === "rota" && item.contrato_id === contratoActual?.contrato_id
    //             ).length;
    //             setPromesasRotas(filteredCount);
    //         }).catch(e => {
    //             setPromesasRotas(0);
    //             if (e.response !== undefined)
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
    //             else
    //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
    //         })
    // }

    const handleGetPromesasdePago = async () => {
        const dataQuery = {
            contrato_id: contratoActual.contrato_id,
            periodo: contratoActual.periodo
        }
        await CobranzaService.getAllPromesa_de_PagoxPeriodo(dataQuery)
            .then(res => {
                let promesasrotas = 0
                const today = new Date()
                const ayer = new Date()
                ayer.setDate(ayer.getDate() - 1)
                const update = res.data;

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === contratoActual.contrato_id) || [{}]

                if (promesasUpdate) {
                    //acomodar de vieja a mas nueva
                    promesasUpdate.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));

                    for (let i = 0; i < promesasUpdate.length; i++) {
                        const promesa = promesasUpdate[i];
                
                        // Si la promesa está cumplida, salimos del ciclo
                        if (promesa.promesa_status_nombre === 'Cumplida') {
                            break; 
                        }
                
                        // Si la promesa está rota o está vigente pero su fecha es anterior a hoy, sumamos 1
                        if (promesa.promesa_status_nombre === 'Rota' || promesa.promesa_status_id === "66498f01-9033-4be1-9353-ee0fd4217120" || 
                            (promesa.promesa_status_nombre === "Vigente" && new Date(promesa.fecha_promesa) < today)) {
                                promesasrotas++;
                        }
                    }
                }

                // if (promesasrotas >= 3) {
                //     actualizaVisitabyContrato(contratoActual.contrato_id)
                // }

                setPromesasRotas(promesasrotas)

                // setCurrentDataPromesas(promesasUpdate)

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleVerDocumentos = async (id, area) => {
        navigate(`/cobranza/seguimiento-visita/datadocs/${area}/${contratoActual?.contrato_id}/${id}`, {
            state: { ...contratoActual, registroId: id, vin: contratoActual.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
        });
    }

    return {
        formattedData,
        collapsableVisita,
        setCollapsableVisita,
        initialValue,
        opcionSeleccionada,
        formularioVisitaDom,
        collpsableGruaPromesaPago,
        setCollpsableGruaPromesaPago,
        formularioActual,
        datosGarantiaPago,
        datosPromesaPago,
        refFormik,
        datosPromesaEntregaDefinitiva,
        collapsableResguardo,
        setCollapsableResguardo,
        datosResguardo,
        setpageRows,
        columnsResguardoUnidad,
        pageCount,
        handlePageClick,
        currenPage,
        totalPagos,
        collapsableRecoleccion,
        setCollapsableRecoleccion,
        datosRecoleccion,
        gruaSeleccionada,
        usuarioProgramo,
        userDetail,
        promesasRotas,
        loading,
        handleReturnPage,
        handleVerDocumentos
    }
}
export default useHistorialVisitasDetalle