import React, { useEffect, useRef, useState } from 'react'
import ValuacionDetalle from '../ValuacionDetalle'
import useInicioDeValuacion from '../../../../Hooks/Inventario/Valuacion/useInicioDeValuacion'
import UbicacionDeVehiculo from './UbicacionDeVehiculo'
import ModalConfirm from '../ModalConfirm';

import { toast, Toaster } from 'react-hot-toast';
import ContratoCard from '../../../../Components/ContratoCard'
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard';
import { ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../../Auth/Authorization';

const RegistroDeCostos = () => {
    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useInicioDeValuacion(toast);

    // let {
    //     vehiculo
    // } = useCambioDevolucion();

    return (
        <>
            <ValuacionDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
           <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoInicioDeValuacion, SubModule.SeguimientoValuacion)?.Ver}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} solicitarClave={false} dataCalendar={true} calendarioType={23} />
                        </div>
                        <div className="col-6 px-md-3">
                            <ContratoCard vin={state?.vin} />
                        </div>
                    </div>
                </ValidatePermission>

                <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoInicioDeValuacion, SubModule.SeguimientoValuacion)?.Ver}>
                    <UbicacionDeVehiculo handleConcluido={handleChangeStatusBtn} />
                </ValidatePermission>
                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Seguimiento de Proceso"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }
            </div>
        </>
    )
}

export default RegistroDeCostos