import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import { Toaster } from 'react-hot-toast';
import Gestoria from '../../Services/Gestoria/Gestoria';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import ConsultaClaveService from '../../Services/Cartera/ConsultaClaves';
import ClavesDemoService from '../../Services/Inventario/ClavesDemoService';

const ClavesModal = ({ modalMessage, setModalMessage, vin }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);

    const initial = {}

    const [initialValue, setInitialValue] = useState(initial)
    // const { id, generalid, valuacionid } = useParams()
    const [claves, setClaves] = useState([])
    const [clavesDemo, setClavesDemo] = useState({});
    const [clavesVehiculo, setClavesVehiculo] = useState([]);




    useEffect(() => {
        // getClaves(); 
        ClavesDemoGet();
        ClavesDemo();
    }, []);

    // const getClaves = async () => {
    //     let params = { vin: vin }
    //     await Valuaciones.getReporteClaves(params)
    //         .then(resp => {
    //             let items = []
    //             resp.data.forEach(item => {
    //                 const vencimiento = item.vencimiento;
    //                 let date;
    //                 if (typeof vencimiento === 'object') {
    //                     date = ("0" + vencimiento.day).slice(-2) + '-' + ("0" + (vencimiento.month - 2)).slice(-2) + '-' + vencimiento.year;
    //                 } else {
    //                     date = vencimiento.split("-").reverse().join("-");
    //                 }
    //                 items.push({ clave: item.clave, vigencia: date, tipoSolicitud: item.tipo_solicitud })
    //             })

    //             setClaves(items)

    //         }
    //         )
    //         .catch(err => {
    //             console.error(err)
    //         })
    // };
    const ClavesDemoGet = async () => {
        const data = { vin: vin };
        try {
            const resp = await ConsultaClaveService.consultarClavesPorVin(data);
            const demo = [];
            const vehiculo = [];

            resp.data.forEach(item => {
                // Convertimos la fecha de vencimiento al formato deseado
                item.vencimiento = item.vencimiento.split("T")[0];

                // Agrega todos los elementos a demo y vehiculo sin filtrar

                vehiculo.push(item);
            });

            //setClavesDemo(demo);       // Establece todas las claves en clavesDemo
            setClavesVehiculo(vehiculo); // Establece todas las claves en clavesVehiculo
        } catch (err) {
            console.error("Error al consultar las claves:", err);
        }
    };
    const ClavesDemo = async () => {
        try {
            const resp = await ClavesDemoService.getClaveDemo();            
            let demo = resp.data;
            if(demo)
                demo.vencimiento = demo?.fechaVencimiento?.split("T")[0] // Formateo de fecha
            setClavesDemo(demo);
        } catch (err) {
            console.error("Error al consultar las claves:", err);
        }
    };




    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const validate = {}

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const cveReq = {
        background: '#E8EAF6',
        color: '#1A3D7E',
        width: '35px !important',
        minWidth: '35px !important',
        height: '35px !important',
        borderRadius: '4px',
        fontWeight: 'bold',
        padding: '8px',
        margin: '5px 3px'
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                initialErrors={{}}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start px-3 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5>Consulta de claves</h5>
                                </div>
                            </div>
                            <div className="row mt-1 mb-2">
                                <div className="col col-12">
                                    <Tabs>
                                        <Tab title={'Claves de Vehículo'}>
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    A continuación se muestra la clave, tipo de solicitud y la Vigencia
                                                </small>
                                            </div>
                                            {clavesVehiculo.map((clave, idx) => (
                                                <div className="row mt-2" key={idx}>
                                                    <div className="col-lg-4 col-12 mt-4">
                                                        <span>Clave</span>
                                                        {clave.clave?.split('').map((item, i) => (
                                                            <span style={cveReq} key={i}>{item}</span>
                                                        ))}
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <span>Vigencia</span>
                                                        <div style={containerStyle}>
                                                            {clave.vencimiento.split('-').reverse().join('-')}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <span>Tipo de solicitud</span>
                                                        <div style={containerStyle}>
                                                            {clave.tipoSolicitud}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Tab>
                                        <Tab title={'Clave Demo'}>
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    A continuación se muestra la clave y la Vigencia
                                                </small>
                                            </div>
                                            <div className="row mt-2">
                                                {
                                                    clavesDemo?.claveDemo && <>
                                                        <div className="col-lg-4 col-12 mt-4">
                                                            <span>Clave</span>
                                                            {clavesDemo?.claveDemo?.split('').map((item, i) => (
                                                                <span style={cveReq} key={i}>{item}</span>
                                                            ))}
                                                        </div>
                                                        <div className="col-lg-4 col-12">
                                                            <span>Vigencia</span>
                                                            <div style={containerStyle}>
                                                                {clavesDemo?.vencimiento?.split('-').reverse().join('-')}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>

        </>
    );
}

export default ClavesModal;