import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ValuacionRegistroCostoService from '../../../Services/Inventario/Valuacion/ValuacionRegistroCostoService';
import { message } from '../../../Utils/functions';
import useCambioDevolucion from '../../Garantias/useCambioDevolucion';
import { useStateManager } from 'react-select';

const useRegistroDeGastos = (toast) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    let initial = {
        valuacionId: "",
        valuacionRegistroGastosId: "",
        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        registroCostos: []
    }
    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    const [stepe, setStepe] = useState([])
    const [stepSelected, setstepSelected] = useState();
    const [loading, setloading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);

    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar])

    const handleGetAllStep = async () => {
        setloading(true)
        await ValuacionRegistroCostoService.getAllStepe(id, state.procesoId)
            .then((res) => {
                let step = res.data.data;
                step.map(x => {
                    if (x.pasoSubStatus === 2) {
                        setstepSelected(x);
                        return;
                    }
                })
                setStepe(step);
                setloading(false)
            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            })
    }
    const handleClickTerminar = async () => {      
        setModalConfirm(true);
    }
    const handleClickTerminarProceso = async () => {
        setModalConfirm(false)
        setloading(true)
        let data = {
            id: id,
            paso: stepSelected.paso,
            porcentaje: stepSelected.porcentaje,
            pasoSubStatus: 3,
            procesoId: state.procesoId
        }
        await ValuacionRegistroCostoService.updStep(data)
            .then((res) => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    //navegar al siguiente step                                 
                    if (stepSelected.paso !== 4 && stepSelected.avanzar){
                        navigate(`/inventario/valuacion/${id}/${stepSelected.paso + 1}`, {
                            state: state
                        })
                    } else {
                        navigate(`/inventario/valuacion`)
                    }
                } else {
                    toast.error(message("¡Operación fallida!", res.data.data.mensaje));
                }
                setloading(false);
            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            })
    }
    const handleChangeStatusBtn = (item) => {
        if (item.concluido) {
            setactivebtncontinuar(true)
        }
    }

    return {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    }
}

export default useRegistroDeGastos