import React,  { useRef }from 'react'
import { Formik } from 'formik';
import { SelectField } from '../../../Components/SelectField'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CitaEnregaUnidadHook from '../../../Hooks/CitaEntregaUnidad/CitaEntregaUnidadHook'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import '../SeguimientoVisita/SeguimientoVisita/SeguimientoVisita.css'
import SolicitudEntregaUnidadModal from './Cards/SolicitudEntregaUnidadModal'

const CitaEntregaUnidad = () => {
    const {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        setpageRows,
        sucursales,
        setSucursal,
        cobradores,
        setCobrador,
        ubicaciones,
        setUbicacion,
        openModalAvanzado,
        setOpenModalAvanzado,
        modalValue,
        textoBusqueda,
        handleGetAll
    } = CitaEnregaUnidadHook();

    const refFormik = useRef();

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <Formik
                innerRef={refFormik}
                initialValues={{
                    buscar: "",
                    cobradorSeleccionado: "",
                    page: 1,
                    rows: 10,
                }}
                onSubmit={(values) => {
                    // Handle form submission logic here
                }}
            >
                {({ setFieldValue }) => (
                    <>
                        <section className='mx-3 py-4 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className='fs-5'><strong>Cita entrega de unidad</strong></span>
                            </div>
                        </section>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas_CitaEntregaUnidad, SubModule.UnidadesRecuperadas).Ver}>
                            <section className=''>
                                <div className='row col-12 px-4'>
                                    <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <input type="text" className="form-control mb-4" placeholder="Buscar" /*onKeyUp={(e) => { setTextoBusqueda(e.target.value) }}*/ value={textoBusqueda} onChange={(e) => setTextoBusqueda(e.target.value)} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                        {/* <Select options={sucursales} className='col-12' onChange={(e) => { setSucursal(e.label) }} placeholder="Ubicación" /> */}
                                        <SelectField
                                            id="ubicacion"
                                            name="ubicacion"
                                            placeholder="Tipo de ubicación"
                                            items={ubicaciones}
                                            onChange={(e) => {
                                                setFieldValue('ubicacion', e.label);
                                                setUbicacion(e.label)
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                        {/* <Select options={sucursales} className='col-12' onChange={(e) => { setSucursal(e.label) }} placeholder="Ubicación" /> */}
                                        <SelectField
                                            id="sucursal"
                                            name="sucursal"
                                            placeholder="Sucursal"
                                            items={sucursales}
                                            onChange={(e) => {
                                                setFieldValue('sucursal', e.label);
                                                setSucursal(e.label)
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                        {/* <Select options={cobradores} className='col-12' onChange={(e) => { setCobrador(e.label) }} placeholder="Cobrador" /> */}
                                        <SelectField
                                            id="cobrador"
                                            name="cobrador"
                                            placeholder="Cobrador"
                                            items={cobradores}
                                            onChange={(e) => {
                                                setFieldValue('cobrador', e.label);
                                                setCobrador(e.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </section>
                            <RowPages setpageRows={setpageRows} />
                            <div className='mx-4 table-responsive'>
                                <DataTable
                                    column={columns}
                                    data={currentData}
                                    detailable={ValidatePermissionById(4, Section.UnidadesRecuperadas_CitaEntregaUnidad, SubModule.UnidadesRecuperadas).Ver}
                                    handleDetail={handleDetails}
                                    paginate={true}
                                    pageCount={pageCount}
                                    handlePageClick={handlePageClick}
                                    currentPage={currentPage}
                                />
                            </div>
                        </ValidatePermission>
                    </>
                )}
            </Formik >
            {openModalAvanzado && (
                <SolicitudEntregaUnidadModal
                    data={modalValue}  // Aquí debes pasar el objeto `selectedData`
                    setIsOpen={setOpenModalAvanzado}
                    isOpen={openModalAvanzado}
                    toast={toast}
                    handleGetAll={handleGetAll}
                />
            )}
        </>
    )
}

export default CitaEntregaUnidad