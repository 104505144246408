import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../Modal/Modal';
import Modal2 from '../Modal/Modal2';
import { TextField } from '../TextField';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { useParams } from 'react-router-dom';
import { DateFormatDateStr, message } from '../../Utils/functions';

const ModalMessage = ({ setIsOpen, isOpen, modalTitle }) => {
    const refFormik = useRef();
    const endOfMessagesRef = useRef();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const initial = {
        siniestroId: id,
        comentarios: ""
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [dataMessage, setDataMessage] = useState([]);

    const validate = Yup.object().shape({
        comentarios: Yup.string()
            .required('Campo requerido')
    });
    useEffect(() => {
        getAllMessageById();
    }, [])
    useEffect(() => {
        scrollToBottom();
    }, [dataMessage])

    const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const getAllMessageById = async () => {
        setLoading(true)
        await Siniestros.GetMenssageById(1, 10, id)
            .then(resp => {
                console.log("mensajes", resp.data.data);
                setLoading(false);
                const groupedChats = groupChatsByDate(resp.data.data)
                setDataMessage(groupedChats);

                console.log("chats agruapdos", groupedChats);
            })
            .catch(err => {
                setLoading(false)
                console.error(err);
            });
    };
    const groupChatsByDate = (chats) => {
        return chats.reduce((acc, chat) => {
            const fecha = DateFormatDateStr(chat.fechaAlta); // Usamos strFechaAlta para la agrupación                   
            // Buscar si la fecha ya existe en el acumulador
            const existingGroup = acc.find(group => group.fechaAlta === fecha);

            if (existingGroup) {
                // Si existe, añadir el nuevo comentario
                existingGroup.data.push(chat);
            } else {
                // Si no existe, crear un nuevo grupo
                acc.push({
                    fechaAlta: fecha,
                    data: [chat]
                });
            }

            return acc;
        }, []);
    };
    const handleSaveMessage = async (values) => {
        await Siniestros.AddMessageSiniestro(values)
            .then(resp => {
                if (resp.data.data.ban === 1) {
                    toast.success(message("Correcto!", resp.data.data.mensaje));
                    getAllMessageById();
                } else {
                    toast.error(message("¡Error!", resp.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
            });
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleSaveMessage({ ...values, siniestroId: id })
                }}
            >
                {({ isValid, setFieldValue }) => (
                    <>
                        <Modal2 isOpen={isOpen} color='#fff' width={340}>
                            <div className='justify-content-start p-2 align-items-center'>
                                <Form>
                                    <div className="row d-flex justify-content-around align-items-end px-2">
                                        <div className="col-auto">
                                            <h5><strong>{modalTitle || "Comentarios de Siniestro"}</strong></h5>
                                        </div>
                                        <fieldset onClick={() => { setIsOpen(false) }} className='col-auto btn btn-light d-flex align-items-center'><i className="icon fs-6 ri-close-line" ></i></fieldset>
                                    </div>
                                    <div className="row mt-2 mb-2 tbl-scroll" style={{ height: '60vh', overflowY: 'scroll' }}>
                                        {
                                            dataMessage?.map((item, idx) => {
                                                return <section className='mt-2 px-1 h-auto d-flex flex-column justify-content-end' key={idx} ref={endOfMessagesRef}>
                                                    <div className='px-2 d-flex align-items-center' style={{ backgroundColor: '#F7F7F7', height: '35px' }}>
                                                        <label className='' style={{ fontSize: '13px' }}>{item.fechaAlta}</label>
                                                    </div>
                                                    {
                                                        item.data.map((com, index) => {
                                                            return <div className='py-2' key={index} style={{ borderTop: '1.8px solid #F7F7F7',borderBottom:'1.8px solid #F7F7F7' }}>
                                                                <div className='d-flex align-items-center px-2'>
                                                                    <span className='rounded-circle text-white' style={{ backgroundColor: '#1A3D7E' }}>{com.usuario.charAt(0)}</span>
                                                                    <span className="d-none d-md-block dropdown-toggle ps-2"><strong>{com.usuario}</strong>  ID {com.folio}</span>
                                                                </div>
                                                                <div>
                                                                    <div className='mt-2 ms-4 px-2'>                                                                        
                                                                        <label style={{fontSize:'14px'}}>{com.comentarios}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </section>
                                            })
                                        }

                                    </div>
                                    <section className='d-flex px-2 pt-4' style={{ borderTop: '2px solid #F7F7F7' }}>
                                        <div className="row d-flex justify-content-around">
                                            <div className="col-12 col-lg-10">
                                                <TextField id="utilidad" disabled={initialValue?.concluido} label="" holder="Escribe" name="comentarios" type="text" />
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    type='submit'
                                                    id="AT_BtnCloseModal"
                                                    disabled={!isValid}
                                                    className='btn' onClick={() => {
                                                        setTimeout(() => {
                                                            setFieldValue("comentarios", "")
                                                        }, 1000);
                                                    }}
                                                >
                                                    <i className="ri-send-plane-fill"></i>
                                                </button>
                                            </div>

                                        </div>
                                    </section>
                                </Form>
                            </div>
                        </Modal2>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    </>

                )}
            </Formik>
        </>
    );
}

export default ModalMessage;
