import { useEffect, useRef, useState } from "react";
import ServicioExpedientes from "../../Services/ProcesosCierre/Expedientes";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import toast from "react-hot-toast";
import { getOnlyDateFormat, message } from "../../Utils/functions";
import { useNavigate } from "react-router-dom";
import Catalogo from "../../Services/Catalogos/Catalogo";

const SeguimientoExpedienteHook = () => {
    const navigate = useNavigate();
    const refFormik = useRef();
    const initial = {
        buscar: '',
        status: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState("");
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [status, setStatus] = useState('')
    const [listaEstatus, setListaEstatus] = useState([])

    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    //Filtro Avanzado
    const [dataFiltro, setDataFiltro] = useState({
        //Filtor vanzado
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    const columns = [
        { field: 'folio', headerName: 'Folio' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'usuarioSolicito', headerName: 'Usuario Solicito' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'fechaSolicitud', headerName: 'Fecha Solicitud' },
        { field: 'fechaRecuperacion', headerName: 'Fecha Recuperación' },
        { field: 'vigencia', headerName: 'Días de Vigencia' },
        { field: 'EstatusUI', headerName: 'Estatus' },
        { field: 'fechaActualiza', headerName: 'Último Movimiento' },
    ];

    useEffect(() => {
        getData();
        getEstatus();
    }, []);

    useEffect(() => {
        getData();
    }, [page, rows, buscar, status, dataFiltro]);

    const getEstatus = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoExpedienteStatus')
        .then(resp => {
            const items = resp.data.map(item => {
                return {
                    value: item.CierreContratoExpedienteStatus,
                    label: item.CierreContratoExpedienteStatus
                }
            });
            setListaEstatus(items);
        })
        .catch(err => {
            toast.error(message('Error al obtener el listado'));
        });
    }

    const getData = async (values) => {
        const params = {
            buscar,
            page,
            rows,
            status,
            concluido : false
        }
        if (dataFiltro.busquedaAvanzada) {
            params.fechaInicio = dataFiltro.fechaInicio
            params.fechaFin = dataFiltro.fechaFin
            params.concluido = dataFiltro.concluido
            params.buscar = dataFiltro.strAvanzado
            setBuscar(dataFiltro.strAvanzado)
        }

        setLoading(true);

        const promesasRotasField =
        {
            FIELD: "ID",
            VALUE: "prestamo_expediente"
        }

        const responseLlamadasConsecutivas = await CobranzaService.getAllParametrosByField(promesasRotasField)


        await ServicioExpedientes.getSeguimientoExpedientes(params)
            .then(resp => {
                const items = resp.data.data.map(item => {
                    item.EstatusUI = {
                        text: item.cierreContratoExpedienteStatus,
                        backgroundColor: item.fondoHex,
                        textColor: item.textoHex
                    }
                    let fechaSolicitud = item.fechaSolicitud

                    item.fechaSolicitud = item.fechaSolicitud ? getOnlyDateFormat(item.fechaSolicitud) : '-';

                    let fechaRecuperacion = "-"
                    let vigenciaDias = 0

                    let dias = Number(responseLlamadasConsecutivas?.data?.responseData[0]?.valorpublico ?? 0)
                    if (item.fechaSolicitud) {
                        const fecha = new Date(fechaSolicitud);
                        fecha.setDate(fecha.getDate() + dias);
                        fechaRecuperacion = fecha.toISOString()

                        const today =  new Date()
                        const diferenciaDias = new Date(fechaRecuperacion) - (today)
                        vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
                    }

                    item.fechaRecuperacion = getOnlyDateFormat(fechaRecuperacion);

                    item.fechaActualiza = item.fechaActualiza ? getOnlyDateFormat(item.fechaActualiza) : getOnlyDateFormat(item.fechaAlta);

                    item.vigencia =  vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0;

                    return item;
                });
                setData(items);
                setTotalRows(resp.data.totalRows);
            })
            .catch(err => {
                toast.error(message('Error al obtener el listado'));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    };

    const handlePageClick = (numPage) => {
        setPage(numPage.selected + 1);
    }

    const handleDetail = (item) => {
        navigate(`/procesocierre/expediente/${item.cierreContratoExpedienteId}`, { state: item });
    }

    return {
        refFormik,
        initialValue,
        loading,
        setLoading,
        columns,
        data,
        page,
        totalRows,
        rows,
        setRows,
        buscar,
        setBuscar,
        listaEstatus,
        setStatus,
        handlePageClick,
        handleDetail,
        openModalAvanzado,
        setOpenModalAvanzado,
        dataFiltro, setDataFiltro,
    }
}

export default SeguimientoExpedienteHook;