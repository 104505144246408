import Tab from "../../../Components/Tabs/Tab";
import Tabs from "../../../Components/Tabs/Tabs";
import ListadoAmpliacionesExpediente from "./ListadoAmpliacionesExpediente";
import ListadoMontoADevolver from "./ListadoMontoADevolver";
import ListadoCitaFirma from "./ListadoCitaFirma";

const ListadoPrincipalSolicitudes = () => {

    return (
        <>
            <Tabs>
                <Tab title={'Solicitudes de ampliación'}>
                    <ListadoAmpliacionesExpediente />
                </Tab>
                <Tab title={'Monto a devolver'}>
                    <ListadoMontoADevolver />
                </Tab>
                <Tab title={'Cita de firma'}>
                    <ListadoCitaFirma />
                </Tab>
            </Tabs>
        </>
    );
}

export default ListadoPrincipalSolicitudes;