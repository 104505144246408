import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SeguimientoCierre from '../../Services/Cierre/SeguimientoCierre';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { FormatFecha } from '../../Utils/functions';

const useSeguimientoCierre = () => {
    const { id } = useParams();
    const { state } = useLocation();

    let initial =
    {
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    }


    const [dataValue, setDataValue] = useState(initial);
    const [loading, setloading] = useState(false);
    const [cierreContrato, setCierreContrato] = useState([])

    const [buscar, setBuscar] = useState("");
    const [cierreContratoProcesoId, setCierreContratoProcesoId] = useState(null);
    const [pageRows, setpageRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [concluido, setConcluido] = useState(false);

    const columns = [
        { field: 'vin', headerName: 'VIN', width: '150' },
        { field: 'numContrato', headerName: 'Contrato', width: '150' },
        { field: 'cierreContratoProceso', headerName: 'Proceso', width: '200' },
        { field: 'subproceso', headerName: 'SubProceso', width: '200' },
        { field: 'fecha', headerName: 'Fecha', width: '200' },
        { field: 'estatus', headerName: 'Estatus', width: '200' },
        { field: 'subestatus', headerName: 'Subestatus', width: '150' },
        { field: 'ultimaActualizacion', headerName: 'Últ. Actualización', width: '200' },
    ];

    useEffect(() => {
        getCierreContratoProceso();
    }, [])

    useEffect(() => {
        let data = {
            "buscar": buscar,
            "cierreContratoProcesoId": cierreContratoProcesoId,
            "page": currentPage,
            "rows": pageRows
        }
        ejecutarBusqueda(data);
    }, [pageRows])

    const ejecutarBusqueda = (values) => {
        let data = {
            "buscar": values.buscar ?? buscar,
            "cierreContratoProcesoId": values.cierreContratoProcesoId ?? null,
            "fechaInicio": values.fechaInicio ?? null,
            "fechaFin": values.fechaFin ?? null,
            "concluido": values.concluido ?? false,
            "page": values.page ?? currentPage,
            "rows": values.rows ?? pageRows
        }
        setFechaInicio(data.fechaInicio);
        setFechaFin(data.fechaFin);
        setConcluido(data.concluido);
        getData(data);
    };

    const getData = async (data) => {
        setloading(true)
        await SeguimientoCierre.getSeguimientoCierre(data) //state.procesoId
            .then((res) => {
                let items = [];
                res.data.data.forEach(item => {
                    item.fecha = FormatFecha(item?.fecha);
                    item.ultimaActualizacion = FormatFecha(item?.ultimaActualizacion);
                    items.push(item);
                });
                res.data.data = items;
                setDataValue(res.data)
            })
            .catch((error) => {
            }).finally(() => {
                setloading(false)
            })
    }

    const handleEdit = () => {

    }

    const downloadExcel = async () => {
        setloading(true);
        let data = {
            "buscar": buscar,
            "cierreContratoProcesoId": cierreContratoProcesoId,
            "fechaInicio": fechaInicio,
            "fechaFin": fechaFin,
            "concluido": concluido,
            "page": null,
            "rows": null
        }
        await SeguimientoCierre.exportCierreContrato(data)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `CierreContratos-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                // habdleGetAll(1);
                setloading(false);
            }).catch(e => {
                setloading(false);
            })
    }

    const getCierreContratoProceso = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoProceso')
            .then(res => {
                const cierreContratoArray = res.data.map(item => ({
                    value: item.CierreContratoProcesoId,
                    label: item.CierreContratoProceso.trim()  // Usamos trim para eliminar espacios extras si es necesario
                }));
                setCierreContrato(cierreContratoArray);
            })
            .catch(error => {
            });
    };

    const handlePageClickH = (event) => {
        setCurrentPage(event.selected + 1);
        let data = {
            "buscar": buscar,
            "cierreContratoProcesoId": cierreContratoProcesoId,
            "page": event.selected + 1,
            "rows": pageRows
        }
        ejecutarBusqueda(data);
    };

    return {
        columns,
        dataValue,
        setDataValue,
        handleEdit,
        downloadExcel,
        loading,
        cierreContrato,
        setBuscar,
        setCierreContratoProcesoId,
        setpageRows,
        currentPage,
        handlePageClickH,
        setCurrentPage,
        setpageRows,
        cierreContratoProcesoId,
        buscar,
        ejecutarBusqueda,
        setFechaInicio,
        setFechaFin,
        setConcluido
    }
}

export default useSeguimientoCierre