import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Modal from '../../../../Components/Modal/Modal'
import DataTable from '../../../../Components/datatable/DataTable';
import SeguimientoCierre from '../../../../Services/Cierre/SeguimientoCierre';
import { formatearFecha } from '../../../../Utils/functions';

const HistorialCita = ({ isOpen, setIsOpen }) => {
    const { state } = useLocation();
    const { id } = useParams();

    const [data, setData] = useState([])

    const columns = [
        { field: 'folioCita', headerName: 'Folio de cita' },
        { field: 'ubicacion', headerName: 'Ubicación' },
        { field: 'fechaRespuesta', headerName: 'Fecha y hora de cita' },
        { field: 'usuario', headerName: 'Usuario' },
        { field: 'estatus', headerName: 'Estatus' }
    ];
    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        SeguimientoCierre.getCierreContratoCitaHistorial(id)
            .then(res => {
                let items = [];
                console.log(res)
                if (res?.data) {
                    res?.data?.filter(i => i.cierreContratoId === id).forEach(element => {
                        const fecha = new Date(element.fechaCita);
                        items.push({
                            folioCita: element.folioCita,
                            ubicacion: element.ubicacion,
                            fechaRespuesta: formatearFecha(`${fecha.getFullYear()}-${(fecha.getMonth() + 1)}-${fecha.getDate()} ${element.horaCita}`),
                            usuario: element.usuarioAltaNombre,
                            estatus: element.estatus
                        });
                    });
                }
                setData(items)
            })
            .catch(error => {

            })
            .finally(() => {

            })
        // Lógica de obtener datos
    };

    return (
        <>
            <Modal isOpen={isOpen} color='#fff' width={700}>
                <div className="p-4 white-wrapper mb-2">
                    <div className="row">
                        <div className="col-11">
                            <h6>
                                <strong>
                                    Historial de citas
                                </strong>
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <DataTable
                                column={columns}
                                data={data}
                                editable={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <button id="AT_btnGuardar" className='btn' onClick={() => { setIsOpen(false) }}> Cerrar</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default HistorialCita;
