import { Form, Formik, Field } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import SolicitarTramiteModal from "./SolicitarTramiteModal";
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import Siniestros from "../../../Services/Siniestro/Siniestro";
import { message, FormatFecha, IsNullOrEmpty , getOnlyDateFormat } from "../../../Utils/functions";
import * as Yup from 'yup';
import GeneracionExpedientesService from '../../../Services/Cartera/GeneracionExpedientesService';
const AutorizarSolicitudExpediente = ({actualizarCardTramite, setactualizarCardTramite, solicitudActive = false}) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const refbtnDownload = useRef();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    
    const initial = {
        TramiteId: '',
        OperacionTramiteid: '',
        Comentarios: '',
        bloqueoid: false,
        // concluido: false
    };


    const [initialValue, setInitialValue] = useState(initial);
    const [tipoOperaciones, setTipoOperaciones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, width: '' });
    const [openmodalMessage, setOpenModalMessage] = useState(false);
    const [tramite, setTramite] = useState([]);
    const [solicitud, setSolicitud] = useState([]);
    const [tipoOperacionNombre, setTipoOperacionNombre] = useState();

    const validationSchema = Yup.object({
        OperacionTramiteid: Yup.string().required('Ubicacion es requerido'),
        Comentarios: Yup.string().required('Comentario es requerido')
            .test(
                'min-length-if-not-empty',
                'El comentario debe tener al menos 20 caracteres',
                function (value) {
                    // Si el valor no está vacío, debe tener al menos 20 caracteres
                    return !value || (value && value.length >= 20);
                }),
        // concluido: Yup.boolean()
            // .oneOf([true])
    })


    useEffect(() => {
        getDetalle()
        getTipoOperaciones();
        getTramite();
    }, [actualizarCardTramite]);

    const getDetalle = async () => {
        await ServicioExpedientes.getDetalleExpediente(id)
            .then(resp => {
                const items = [];
                items.push({
                    ...resp.data,
                    fechaSolicitud: getOnlyDateFormat(resp.data.fechaSolicitud),
                    selectFieldPlaceholder: resp.data.cierreContratoExpedienteStatus,
                    EstatusUI: {
                        text: resp.data.cierreContratoExpedienteStatus,
                        backgroundColor: resp.data.fondoHex,
                        textColor: resp.data.textoHex
                    },
                });
                setSolicitud(items);
            })
            .catch(err => {
                toast.error(message('Erro al obtener el detalle del expediente'));
            });
    };

    const getTipoOperaciones = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.OperacionesExpediente')
            .then(resp => {
                const items = resp.data
                    .map(item => {
                        return {
                            value: item.OperacionesExpedienteId.toString(),
                            label: item.OperacionesExpediente,
                            orden: item.Orden 
                        };
                    })
                    .sort((a, b) => a.orden - b.orden); // Ordenar por el campo "Orden"
                setTipoOperaciones(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const ActualizarEstatusRegistroExpediente = async (obj) => {
        let data = {
            CierreContratoExpedienteId: obj.CierreContratoExpedienteId,
            OperacionExpedienteId: obj.OperacionExpedienteId,
            OperacionExpedienteNombre: obj.OperacionExpedienteNombre
        }

        setLoading(true)
        await ServicioExpedientes.ActualizarEstatusCierreContratoByOperacion(data)
            .then(resp => {
            })
            .catch(err => {
                setLoading(false)
                toast.error(message('Error al actualizar estatus'));
            });
            await getDetalle()
        setLoading(false)
    }

    const submitForm = async (values) => {
        // // if (values.concluido && solicitud[0]?.subido !== true){
        //     toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir documentos.")}</div>);
        //     setTimeout(() => {
        //         toast.remove();
        //     }, 3000);
        //     return false;
        // }

        // if (values.OperacionTramiteid === '50' && solicitud[0]?.entregado != true) {
        //     toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir Evidencia de Entrega de Expediente.")}</div>);
        //     setTimeout(() => {
        //         toast.remove();
        //     }, 3000);
        //     return false;
        // }

        // if (values.OperacionTramiteid === '90' && solicitud[0]?.resguardo != true) {
        //     toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir Evidencia de Recepción de Expediente.")}</div>);
        //     setTimeout(() => {
        //         toast.remove();
        //     }, 3000);
        //     return false;
        // }

        values.TramiteId = tramite.tramiteId;
        // values.concluido = values.concluido ? 1 : 0
        

        if (solicitudActive) {
            toast.error(message('El trámite está en estatus Solicitado.'));
            return false;
        }

        if (tramite?.tramiteId === undefined) {
            toast.error(message('Aún no hay un trámite iniciado, inicia uno para poder crear una solicitud'));
            return false;
        }

        setLoading(true);
        await ServicioExpedientes.addSolicitud(values)
            .then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));

                    //Actualizar el estatus del registro principal                    
                    let data = {
                        CierreContratoExpedienteId: state?.cierreContratoExpedienteId,
                        OperacionExpedienteId: values.OperacionTramiteid,
                        OperacionExpedienteNombre: tipoOperacionNombre
                    }
                    //metodo para actualizar el estatus principal
                    ActualizarEstatusRegistroExpediente(data);
                } else {
                    toast.error(message(resp.data.message));
                }
            })
            .catch(err => {
                toast.error(message('Error al intentar crear la solicitud'));
            })
            .finally(() => {
                setactualizarCardTramite();
                getDetalle()
                setLoading(false);
            });
    };

    const downloadPDF = async() => {
            setLoading(true)
            const resp = await Siniestros.siniestroByVinGet(state?.vin);
            const sol_id = resp?.data?.infoAscendes?.sol_id ?? null; // Si no existe, asigna null

          let value = {
                        "FIELD": "nombreDocumentoKey",
                        "VALUE": "checklist_expediente_cierre"
                    }
        
                    let tipoDocumentoValue = await GeneracionExpedientesService.GetDocumentoVisitaByField(value).then(res => {
                        return res.data.responseData[0].id
                    }).catch(err => {
                        toast.error(message('Error.!", "Algo ocurrio al intentar generar el PDF.'));
                        setLoading(false)
                    });
        
                    let IdsDescargar = [
                        {
                            "sol_id": sol_id,
                            "contrato": state?.contrato,
                            "vin": state?.vin
                        }
                    ]
                    
                    if (!IsNullOrEmpty(tipoDocumentoValue)) {
                        
                        await GeneracionExpedientesService.GenerarPlantillaExpediente({
                            contratos: IdsDescargar,
                            tipodocumentos: tipoDocumentoValue,
                            comentarios: '',
                            numero_telefono: '',
                            nombre_cobrador: '',
                        })
                            .then(res => {
                                
                                var FileSaver = require('file-saver');
                                let today = FormatFecha(new Date())
                                FileSaver.saveAs(res.data, `Checklist de Expediente_${today}.pdf`);
                                setLoading(false);
                               
                                
                            }).catch(e => {
                                setLoading(false);
                              
                                toast.custom(<div className='warnin-toast'>{message("Error.!", "Algo ocurrio al intentar generar el PDF.")}</div>);
                            })
                    } else {
                        toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No se encuentra el id del documento.")}</div>);
                        setTimeout(() => {
                            toast.remove();
                        }, 3000);
                        setLoading(false);
                        refbtnDownload.current.disabled = false;
                    }
    }

    const handleDocuments = (obj) => {
        
        navigate(`/procesocierre/expediente/datadocs/fExpedient/eExpedient/${state.folio}/${state?.cierreContratoExpedienteId}`, {
            state: { ...state, registroId: state?.cierreContratoExpedienteId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }

    const getTramite = async () => {
        if (state.generalId === undefined) return false;

        setLoading(true);
        await ServicioExpedientes.getTramite(state?.generalId)
            .then(resp => {
                setTramite(resp.data);
            })
            .catch(err => {
                toast.error(message('Error al intentar obtener el trámite'));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={520}>
                {
                    modalMessage.type === 1 &&
                    <SolicitarTramiteModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        getTramite={getTramite}
                        setactualizarCardTramite={setactualizarCardTramite}
                    />
                }
            </Modal>
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseAutorizarSolicitud" role="button" aria-expanded="false" aria-controls="collapseAutorizarSolicitud" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Autorizar solicitud</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAutorizarSolicitud">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    initialValues={initialValue}
                                    validateOnBlur={true}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({ isValid, values, setFieldValue, errors, resetForm }) => {
                                        return (
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 justify-content-between">
                                                    <div className="col-3">
                                                        <SelectField
                                                            label="Operación"
                                                            disabled={false}
                                                            name="OperacionTramiteid"
                                                            items={tipoOperaciones}
                                                            onChange={(event) => {
                                                                setFieldValue("OperacionTramiteid", event.value);
                                                                setTipoOperacionNombre(event.label);
                                                            }} />
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button className="btn" disabled={values.OperacionTramiteid !== '10'}
                                                                    onClick={() => {
                                                                        setModalMessage({ isOpen: true, type: 1, title: 'Solicitar Trámite', data: state });
                                                                    }}
                                                                >
                                                                    <i className="icon-light fa fa-file me-2"></i>
                                                                    Solicitar trámite
                                                                </button>
                                                            </div>
                                                            {/* <div className="col-auto">
                                                                <button className="btn btn-secondary"
                                                                    onClick={handleDocuments}>
                                                                    <i className="icon-light fa fa-file me-2"></i>
                                                                    Ver documento
                                                                </button>
                                                            </div> */}
                                                            <div className="col-auto">
                                                                <button className="btn"
                                                                    disabled={!isValid}
                                                                    onClick={() => {
                                                                        submitForm(values);
                                                                        resetForm();
                                                                    }}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small>Checklist de expediente</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className='btn btn-gray' onClick={() => downloadPDF(1)}>
                                                            <i className="icon-blue bx bx-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-8">
                                                        <TextAreaField
                                                            label="Comentarios"
                                                            disabled={false}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="Comentarios"
                                                            id="Comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-2 px-4">
                                                    <div className="col-8">
                                                        <div className="form-check" style={{ paddingLeft: '0' }}>
                                                            <Field className="form-check-input me-2" type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} disabled={initialValue.bloqueoid} style={{ marginLeft: '0', marginRight: '0.5rem' }} />
                                                            <label className="form-check-label ms-2" >Concluido</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </Form>
                                        )
                                    }}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AutorizarSolicitudExpediente;