import React from 'react'
import Modal from './Modal'
import { getOnlyDateFormat } from '../../Utils/functions'

const DatosPromesaPagoModal = ({ isOpen, setIsOpen, data }) => {

    const esFechaValida = !isNaN(Date.parse(data.fecha_promesa));

    const handleRechazar = () => {
        setIsOpen(false)
    }

    return (
        <Modal isOpen={isOpen} color='#fff' width={600}>
        <div>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                        <div className='px-4'>
                            <h4><strong>Promesa de pago - {data.folio_promesa}</strong></h4>
                        </div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col col-12">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Donde se generó:</strong> {data.generado_en}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col col-6">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Persona:</strong> {data.persona}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Nombre:</strong> {data.nombre_persona}</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="row my-4">
                    <div className="col col-6">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Medio de contacto:</strong> {data.medio_contacto}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Valor:</strong> {data.valor}</span>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="row my-4">
                    <div className="col col-12">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <span><strong className="mr-2">Fecha de la promesa:</strong> {esFechaValida ? getOnlyDateFormat(data.fecha_promesa): data.fecha_promesa}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col col-12">
                        <div className="row justify-content-start">
                            <div className="col col-12 text-sm">
                                <p><strong className="mr-2">Comentarios:</strong> {data.comentarios}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal"
                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => { handleRechazar() }}>
                                Cerrar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </Modal>
    )
}

export default DatosPromesaPagoModal