import React, { useEffect, useState } from "react";
import SiniestroDetalle from "../../../Seguro/Siniestros/Detalle/SiniestroDetalle";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "../ContratoHeaderCard";
import InformacionSeguroCard from "../InformacionSeguroCard";
import SegumientoProcesoDatamovil from "../Detalle/SeguimientoProcesoDatamovil";
import RecoleccionUnidadCard from "../Detalle/Registro/RecoleccionUnidadCard";
import ResguardoUnidadCard from "../Detalle/Registro/ResguardoUnidadCard";
import { useLocation, useParams } from "react-router-dom";
import { Normalize } from "../../../../Utils/functions";
import RegistroSiniestroRoboCard from "../Detalle/Registro/RegitroSiniestroRoboCard";
import useRegistroSiniestro from "../../../../Hooks/Inventario/Siniestro/useRegistroSiniestro";
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from '../Detalle/ModalConfirm'
import CardLiberacionDeUnidad from "./CardLiberacionDeUnidad";
import SiniestroCitaAcreditacionRoboCard from "./SiniestroCitaAcreditacionRoboCard";
import SiniestroAcreditacionRoboCard from "./SiniestroAcreditacionRoboCard";
import ContratoCard from "../../../../Components/ContratoCard";
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import { useSelector } from "react-redux";
 

const DetalleRegistroSiniestro = React.forwardRef(({ ...props }, ref) => {

    const { id } = useParams();

    const reporteAutoridadesRedux = useSelector((state) => state.AcreditacionSlice.reporteAutoridades);
    const subidoRedux = useSelector((state) => state.AcreditacionSlice.subido); 
    const [reporteAutoridadesLocal, setReporteAutoridadesLocal] = useState(null);
    
    console.log("📋 Valor desde Redux - reporteAutoridades:", reporteAutoridadesLocal?.reporteAutoridades);
    console.log("📋 Valor desde Redux - subido:", reporteAutoridadesLocal?.subido);
    
      let initial = {
        reporteAutoridades: ""
    }

    const [initialValue, setInitialValue] = useState(initial);
    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        codStatus,
        requiereCards,
        setRequiereCards
    } = useRegistroSiniestro(toast);

    useEffect(() => {
        if (typeof reporteAutoridadesRedux === "boolean") {
            // ingreso directo al paso 1
          setReporteAutoridadesLocal({
            reporteAutoridades: reporteAutoridadesRedux,
            subido: subidoRedux,
          });
        } else {
          handleGetAllSiniestroById(); // ingreso directo al paso 2
        }
      }, []);
   
    /* Consulta Siniestros.GetSiniestroById(id) */
    const handleGetAllSiniestroById = async () => {
        try {
          const resp = await Siniestros.GetSiniestroById(id);
          const item = resp.data;
          setReporteAutoridadesLocal({
            reporteAutoridades: item?.reporteAutoridades ?? false,
            subido: item?.subido ?? false,
          });
        } catch (err) {
          console.error(err);
          setReporteAutoridadesLocal({ reporteAutoridades: false, subido: false });
        }
      };
  
    const propietario = state?.aNombreDe?.toLowerCase() ?? "";
    const esCliente = propietario.includes("cliente");
    const hizoReporte = state?.reporteAutoridades;

    // Mostrar Cita de Acreditación SOLO si NO es cliente
    const mostrarCita = !esCliente;

    // Mostrar Acreditación si:
    // - es cliente
    // - o no es cliente PERO hizo reporte
    const mostrarAcreditacion = esCliente || (!esCliente && hizoReporte);

    return (
        <>
            <SiniestroDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />

            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoCard vin={state?.vin} />
                    </div>
                </div>
                <InformacionSeguroCard generalId={state?.generalId} />
                <SegumientoProcesoDatamovil Id={state?.siniestroId} />
                {
                    Normalize(state?.siniestroTipo) === "percance" &&
                    <>
                        <CardLiberacionDeUnidad requiereCards={setRequiereCards} />

                        {
                            requiereCards.recoleccion &&
                            <RecoleccionUnidadCard handleConcluido={handleChangeStatusBtn} stepId={2} />
                        }
                        {
                            requiereCards.resguardo &&
                            <ResguardoUnidadCard handleConcluido={handleChangeStatusBtn} stepId={2} />
                        }
                    </>

                }

                {
                Normalize(state?.siniestroTipo) === "robo" &&
                (
                    esCliente || (!esCliente && reporteAutoridadesLocal?.reporteAutoridades && reporteAutoridadesLocal?.subido) ? (
                    <SiniestroAcreditacionRoboCard
                        handleConcluido={handleChangeStatusBtn}
                        state={state}
                    />
                    ) : (
                    <SiniestroCitaAcreditacionRoboCard
                        handleConcluido={handleChangeStatusBtn}
                        state={state}
                    />
                    )
                )
                }

                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Registro de gastos"}
                        handleConfirm={handleClickTerminarProceso}
                    />
                }



            </div>
        </>
    )
});

export default DetalleRegistroSiniestro;
