    import { Formik, Form, Field, ErrorMessage } from 'formik'
    import { useEffect, useRef, useState } from "react";
    import { SelectField } from "../../../Components/SelectField";
    import { TextAreaField } from "../../../Components/TextAreaField";
    import Spinner from "../../../Components/Loadig";
    import Modal from "../../../Components/Modal/Modal";
    import Catalogo from "../../../Services/Catalogos/Catalogo";
    import * as Yup from 'yup';
    import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
    import toast from "react-hot-toast";
    import { message, getDateNow, FormatFecha, formatearMontoMx, TextMoneyToNumber  } from "../../../Utils/functions";
    import ValuacionesServices from "../../../Services/Inventario/ValuacionesService";

    const AmpliarSolicitudExpedienteModal = ({ modalMessage, setModalMessage,info }) => {
        const refFormik = useRef();
        const [loading, setLoading] = useState(false);
      
        const [motivos, setMotivos] = useState([]);
        const [showDias, setShowDias] = useState(false);
        let initial = {
            CierreContratoExpedienteId: modalMessage.data.cierreContratoExpedienteId,
            MotivoAmpliacion: '',
            ComentariosAmpliacion: '',
        }
    
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        };
        
        const finalDate = new Date();
        finalDate.setDate(finalDate.getDate() + 15);
        const formattedDate = formatDate(finalDate);

        const [initialValue, setInitialValue] = useState(initial);
        const usuario = sessionStorage.getItem('usuario')
        useEffect(() => {
            getMotivosAmpliacion();
        }, []);

        const getMotivosAmpliacion = async () => {
            await Catalogo.getDropdownsByTableAndActive('Catalogos.Motivo_Ampliacion_Expediente',true, 'activo')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.Id,
                        label: item.nombre
                    }
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            });
        }

        const handleRegresar = () => {
            setModalMessage({ isOpen: false });
        }

        const handleGuardar = async (values) => {
            let vin=info[0].vin
            ValuacionesServices.getInfoVehiculo('', vin)
            .then(response => {
                const datos = {
                    usuario: usuario,
                    contrato: info[0].contrato,
                    vin: info[0].vin, // corregido
                    marca: response.data.nombreMarca, // carro
                    modelo: response.data.nombreModelo, // carro
                    year: response.data.nombreYear, // carro
                }
                
                const data = JSON.stringify(datos)
                let _values = { ...values,  data: JSON.stringify(datos) }
               
                 ServicioExpedientes.ampliarExpediente(_values)
                .then(resp => {
                    if(resp.data.ban == 1){
                        toast.success(message('Ampliación de solicitud realizada'));
                    }else{
                        toast.error(message('Error al crear la ampliación de solicitud de expediente'));
                    }
                })
                .catch(err => {
                    toast.error(message('Error al crear la ampliación de solicitud de expediente'));
                })
                .finally(() => {
                    setModalMessage({ isOpen: false });
                });





            })
            
           
            
        }
        const validationSchema = Yup.object({
                ComentariosAmpliacion: Yup.string()
                .required("Comentario es requerido")
                .test(
                    'min-length-if-not-empty',
                    'El comentario debe tener al menos 20 caracteres',
                    function (value) {
                        // Si el valor no está vacío, debe tener al menos 20 caracteres
                        return !value || (value && value.length >= 20);
                    }),
                    MotivoAmpliacion: Yup.string().required('Motivo es requerido')
            })

        return (
            <>
                <Formik
                    enableReinitialize={true}
                    innerRef={refFormik}
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    isInitialValid={false}
                    initialTouched={{
                        MotivoAmpliacion: true,
                        ComentariosAmpliacion: true
                    }}

                >
                    {({ isValid, values, setFieldValue, errors, resetForm }) => (
                        <div>
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                            <div className='justify-content-start p-2 align-items-center'>
                                <div className="row">
                                    <div className="col col-10">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                    <div className="col col-2 d-flex justify-content-end">
                                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                            onClick={() => handleRegresar()}>
                                            <i className="ri-close-fill"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-2">
                                    <div className="col col-12">
                                        <div className="row mt-2">
                                            <div className="col-lg-6 col-12">
                                                <SelectField
                                                    id="MotivoAmpliacion"
                                                    label="Motivo ampliación"
                                                    name="MotivoAmpliacion"
                                                    items={motivos}
                                                    onChange={(event) => {
                                                    const selectedValue = event.value;
                                                    setFieldValue("MotivoAmpliacion", selectedValue);
                                                    const motivoSeleccionado = motivos.find(m => m.value === selectedValue);
                                                    const mostrarFecha = motivoSeleccionado?.label === "Tramite en Proceso";
                                                    setShowDias(mostrarFecha);
                                                    }}
                                                />
                                            </div>
                                            {showDias && (
                                                <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                                                    <label className='text'>Fecha ampliación:</label>
                                                    <Field 
                                                        type="text" 
                                                        name="FechaAmpliacion" 
                                                        placeholder="Escribir" 
                                                        disabled={true} 
                                                        value={formattedDate}
                                                        className='programacionJobsCarteraModalSelect' 
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextAreaField
                                                    id="ComentariosAmpliacion"
                                                    label="Comentarios"
                                                    className="form-control"
                                                    rows="3"
                                                    name="ComentariosAmpliacion"
                                                    type="text"
                                                    holder="Escribe"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='justify-content-between'>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline justify-content-center align-items-center col-12'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleRegresar()}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <button
                                                id="AT_BtnCloseModal"
                                                className='btn btn-blue justify-content-center align-items-center col-12'
                                                disabled={!isValid}
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => {
                                                    handleGuardar(values);
                                                    resetForm();
                                                }}
                                            >
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        );
    }

    export default AmpliarSolicitudExpedienteModal;