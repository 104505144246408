import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useEffect, useRef, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Spinner from "../../../Components/Loadig";
import Modal from "../../../Components/Modal/Modal";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import * as Yup from 'yup';
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import toast from "react-hot-toast";
import { message, getDateNow, FormatFecha, formatearMontoMx, TextMoneyToNumber,get  } from "../../../Utils/functions";
import ValuacionesServices from "../../../Services/Inventario/ValuacionesService";
import DataTable from '../../../Components/datatable/DataTable';



const HistorialDetalleExpedienteModal = ({ isOpen, setIsOpen,generalid }) => {
    const columns = [
        { field: 'folio', headerName: 'Folio' },
        { field: 'usuarioSolicito', headerName: 'Solicitado por' },
        { field: 'fechaSolicitud', headerName: 'Fecha de Solicitud' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'vigencia', headerName: 'Días de Vigencia' },
        { field: 'EstatusUI', headerName: 'Estatus' },
        { field: 'acciones', headerName: 'Acciones' }
    ];
    const { id } = useParams();
    const [currentData, setCurrentData] = useState([])

    const handleRechazar = () => {
        setIsOpen(false)
    }
    const getDetalle = async () => {
        await ServicioExpedientes.getDetalleExpediente(id)
            .then(resp => {
                const items = [];
                items.push({
                    ...resp.data,
                    fechaSolicitud: getOnlyDateFormat(resp.data.fechaSolicitud),
                    selectFieldPlaceholder: resp.data.cierreContratoExpedienteStatus,
                    EstatusUI: {
                        text: resp.data.cierreContratoExpedienteStatus,
                        backgroundColor: resp.data.fondoHex,
                        textColor: resp.data.textoHex
                    },
                });
                setCurrentData(items);
            })
            .catch(err => {
                toast.error(message('Erro al obtener el detalle del expediente'));
            });
    };

    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }
    useEffect(() => {
        getDetalle();
    }, [])

    return (
        <Modal isOpen={isOpen} color='#fff' width={1000}>
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                    onClick={() => handleRechazar()}><i className="ri-close-fill"></i></button>
            </section>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h5><strong>Detalle solicitud expediente</strong></h5>
                    </div>
                </div>
              
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <DataTable
                            column={columns}
                            data={currentData}
                            
                        />
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button
                                type='reset'
                                id="AT_BtnCloseModal"
                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => handleRechazar()}
                            >
                                Cerrar
                            </button>
                        </div>

                    </div>
                </section>
            </div>
        </Modal>
    )
}
export default HistorialDetalleExpedienteModal;