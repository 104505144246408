import { Formik, Form } from 'formik'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Cobranza from '../../../Services/Cobranza/Cobranza';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import DataTable from '../../../Components/datatable/DataTable';
import ReasignarColoniasDeZonaModal from './ReasignarColoniasDeZonaModal';
import useConfigurarZonaCobranza from '../../../Hooks/Cobranza/useConfigurarZonaCobranza';
import useAreasCobranza from '../../../Hooks/Cobranza/useAreasCobranza';
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField';
import { TextField } from '../../../Components/TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, handleRadioCheckItem } from '../../../Utils/functions';
import { v4 as uuidv4 } from 'uuid';
import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'

const RegistrarZonaCobranzaModal = ({ isOpen, setIsOpen, item, setDataUpdated, setReasignarColonias, datosZonas, _zonasExcluirEdicion, nombreZonaCrear }) => {
    const refbtn = useRef();
    const dispatch = useDispatch();
    // Configurar los valores iniciales usando el objeto item
    let initial = {
        areaactual: item?.areaactual || '',
        idEstado: item?.item.estado || '',
        idMunicipio: item?.item.municipio || '',
        zona: !setReasignarColonias ? nombreZonaCrear : item?.item.zona || '',
        item: item
    };
    const [initialValue, setInitialValue] = useState(initial);
    const {
        columns,
        dataEstado,
        dataMunicipio,
        defaultValueMunicipio,
        handleGetMunucipios,
        handleChangeMunicipio,
        handleChangeMunicipio2,
        handleChangeStatus,
        currentdataZonas,
        openModal,
        setOpenModal,
        zonaValue,
        loading,
        setLoading,
        sinZonasSeleccionadas,
        setSinZonasSeleccionadas,
        setzonaValue,
        todasZonas,
        coloniaAsignada,
        msjColoniaAsignada,
        setColoniaAsignada,
        setMsjColoniaAsignada,
        setTextoBusqueda,
        setDataMunicipio,
        dataZonas
    } = useConfigurarZonaCobranza();

    // setzonaValue(initial.zona);
    const [nombreestado, setnombreestado] = useState(initial.idEstado);

    const [zonasExcluirEdicion, setZonasExcluirEdicion] = useState(_zonasExcluirEdicion);

    const [nombrezonaseleccionada, setnombrezonaseleccionada] = useState(initial.zona);

    const handleAdd = async () => {
        // const dataZona = currentdataZonas.filter(x => x.statusCheckBox == true).map(x => x.item);
        const dataZona = dataZonas.filter(x => x.statusCheckBox == true).map(x => x.item);

        if (dataZona.length <= 0) {
            // toast.success(message("¡Operación exitosa!", "Selecciona zonas a asignar"));
            setSinZonasSeleccionadas(true);
        }
        else {
            setLoading(true);
            let bandera = coloniaAsignada ? true : false;
            const nuevoId = uuidv4();
            let idregistro = item?.item?.id != null ? item?.item?.id : nuevoId;
            let zonaval = nombrezonaseleccionada;
            let nombreest = item?.item?.id != null ? item?.item?.estado : nombreestado;
            let data = {
                "verb": zonaval,
                "id": idregistro,
                "field": nombreest,
                "value": defaultValueMunicipio.label,
                "flag": bandera,
                "anyObject": dataZona
            }
            await Cobranza.postCreateZonaItemBatch(data)
                .then(res => {
                    if (res.data.callSucceded) {
                        // setCurentState(idEstado);
                        // setselectedState(idEstado);
                        setIsOpen(false);
                        setDataUpdated(true);
                        setLoading(false);
                        toast.success(message("¡Operación exitosa!", res.data.message));
                    }
                    else if (!res.data.callSucceded && res.data.message != '') {
                        //mostrar modal confirmacion reasignar
                        setLoading(false);
                        // setIsOpen(false);
                        setColoniaAsignada(true);
                        setMsjColoniaAsignada(res.data.message)
                        //setDataUpdated(false);
                        toast.error(message("¡Error!", res.data.message));
                    }
                    else {
                        setIsOpen(false);
                        setLoading(false);
                        setDataUpdated(false);
                        if (res.data !== undefined)
                            toast.error(message("¡Error!", res.data.message));
                        else
                            toast.error(message("¡Error!", res.message));
                    }
                }).catch(e => {
                    setLoading(false);
                    setDataUpdated(false);
                    console.log(e)
                    if (e.response !== undefined)
                        toast.error(message("¡Error!", e.response.data.message));
                    else
                        toast.error(message("¡Error!", e.message));
                })
        }
    }

    const handleCloseModal = async () => {
        setIsOpen(false);
        setDataUpdated(true);
        setLoading(false);
    }

    const itemReasignar = {
        nombreEstado: nombreestado,
        nombreMunicipio: defaultValueMunicipio.label,
        // zonas: zonas != 0 ? todasZonas.filter(x => x.label != zonaValue) : todasZonas,
        zonas: todasZonas.filter(x => x.label != zonaValue),
        colonias: currentdataZonas.filter(x => x.status),
        zonaOrigen: zonaValue,
        todasZonas: datosZonas
    };

    useEffect(() => {
        if (initial.idEstado) {
            handleGetMunucipios(initial.idEstado);
        }
    }, [initial.idEstado]); // Cambiar si el valor del estado cambia

    useEffect(() => {
        if (initial.idMunicipio) {
            handleChangeMunicipio({ value: initial.idEstado, label: initial.idMunicipio }, item.item.zonaItems, zonasExcluirEdicion);
            setzonaValue(initial.zona);
        }
    }, [initial.idMunicipio]);

    const validate = Yup.object({
        idEstado: Yup.string().required("El campo es requerido."),
        idMunicipio: Yup.string().required("El campo es requerido."),
        zona: Yup.string().required("El campo es requerido.")
    });

    const cambiarNombreZona = (nuevosValores) => {
        const partes = nombrezonaseleccionada.split(":");
        if (nuevosValores.estado) {
            partes[1] = nuevosValores.estado;
        }
        if (nuevosValores.municipio) {
            partes[2] = nuevosValores.municipio;
        }
        setnombrezonaseleccionada(partes.join(": "));
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => {
                handleAdd();
            }}
        >{
                ({ isValid, values, setFieldValue }) => (
                    <>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-2 mx-4'>
                                <Form>
                                    <div className="mb-3 row" style={{ marginTop: 20 }}>
                                        <div className="col-sm-6">
                                            <span className='fs-4' style={{ fontWeight: 'bold', color: "#000" }}><strong>Configurar zona de cobranza </strong></span>
                                        </div>
                                        <div className="col-sm-6 d-flex justify-content-end ">
                                            {/* {setReasignarColonias && (
                                                <button id="AT_btnCancelar" className='btn btn-outline col-8 mw-100' type='reset' onClick={() => { setOpenModal(true) }}>Reasignar colonias</button>
                                            )} */}
                                        </div>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ZonasDeCobranzaDetalle, SubModule.ZonaDeCobranza)?.Ver}>
                                        <div className='mb-3 row mt-5'>
                                            <div className="col-sm-6">
                                                {setReasignarColonias && (
                                                    <>
                                                        Estado
                                                        <Select options={dataEstado} isDisabled={true} className='col-12' value={dataEstado.find(e => e.label === values.idEstado)} onChange={(e) => { handleGetMunucipios(e.label); setFieldValue('idEstado', e.value) }} placeholder="Seleccionar" />
                                                    </>
                                                )}
                                                {!setReasignarColonias && (
                                                    <SelectField label="Estado" name="idEstado" items={dataEstado}
                                                        onChange={(event) => {
                                                            if (event.value == "") {
                                                                setFieldValue("idEstado", "");
                                                                setnombreestado("");
                                                                // cambiarNombreZona("Estado", "Estado");
                                                                // cambiarNombreZona("Municipio", "Municipio");
                                                                cambiarNombreZona({ estado: "Estado", municipio: "Municipio" });
                                                                setDataMunicipio([]);
                                                                setFieldValue("idMunicipio", "");
                                                            }
                                                            else {
                                                                handleGetMunucipios(event.label);
                                                                setFieldValue("idEstado", event.value);
                                                                setnombreestado(event.label);
                                                                // cambiarNombreZona("Estado", event.label);
                                                                // cambiarNombreZona("Municipio", "Municipio");
                                                                cambiarNombreZona({ estado: event.label, municipio: "Municipio" });
                                                                setFieldValue("idMunicipio", "");
                                                            }
                                                        }} />
                                                )}
                                            </div>
                                            <div className="col-sm-6">
                                                {setReasignarColonias && (
                                                    <>
                                                        Municipio
                                                        <Select options={dataMunicipio} isDisabled={true} value={dataMunicipio.find(e => e.label === values.idMunicipio)} className='col-12' onChange={(e) => { handleChangeMunicipio(e); setFieldValue('idMunicipio', e.value) }} placeholder="Seleccionar" />
                                                    </>
                                                )}
                                                {!setReasignarColonias && (
                                                    <SelectField id="idMunicipio" label="Municipio" name="idMunicipio" items={dataMunicipio}
                                                        onChange={(event) => {
                                                            if (event.value == "") {
                                                                setFieldValue("idMunicipio", "");
                                                                // cambiarNombreZona("Municipio", "Municipio");
                                                                cambiarNombreZona({ estado: nombreestado, municipio: "Municipio" });
                                                            }
                                                            else {
                                                                handleChangeMunicipio2(nombreestado, event, [], zonasExcluirEdicion);
                                                                setFieldValue("idMunicipio", event.value);
                                                                // cambiarNombreZona("Municipio", event.label);
                                                                cambiarNombreZona({ estado: nombreestado, municipio: event.label });
                                                            }
                                                        }} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-3 row mt-4">
                                            <div className="col-sm-6">
                                                {/* <TextField label="Nombre de zona" name="zona" type="text" holder="" value={nombrezonaseleccionada} disabled={true} onChange={(e) => { */}
                                                <TextField label="Nombre de zona" name="zona" type="text" holder="" value={nombrezonaseleccionada} onChange={(e) => {
                                                    setFieldValue('zona', e.target.value)
                                                    setnombrezonaseleccionada(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row mt-4">
                                            <div className="inner-addon right-addon">
                                                <i className="glyphicon fas fa-search"></i>
                                                <input type="text" className="form-control mb-4" placeholder="Buscar por colonia o código postal" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                                            </div>
                                        </div>
                                        <section className='my-2'>
                                            {sinZonasSeleccionadas &&
                                                <span className="error">Se debe seleccionar al menos 1 colonia.</span>
                                            }
                                            {coloniaAsignada &&
                                                <span className="error">{msjColoniaAsignada}</span>
                                            }

                                            <div className='mx-4 table-responsive' style={{ height: '20rem', overflowY: "scroll" }}>
                                                <DataTable
                                                    id={'tblCodigosPostales'}
                                                    column={columns}
                                                    data={currentdataZonas}
                                                    checkbox={true}
                                                    handleCheck={handleChangeStatus}
                                                />
                                            </div>
                                        </section>
                                        <section className='my-4 d-flex justify-content-end'>
                                            <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                            <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                                        </section>
                                    </ValidatePermission>
                                </Form>
                            </section>
                        </Modal>
                        {loading && <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>}
                        {openModal && (
                            <ReasignarColoniasDeZonaModal isOpen={openModal} setIsOpen={setOpenModal} item={itemReasignar} handleCloseModal={handleCloseModal} />
                        )}
                    </>
                )
            }
        </Formik>
    )
}

export default RegistrarZonaCobranzaModal