import React from 'react'
import useHistorialVisitasDetalle from "../../../Hooks/SeguimientoVisita/HistorialVisitasDetalle"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard"
import CarteraInfoCard from "../../../Components/CarteraInfoCard"
import Spinner from "../../../Components/Loadig"
import Modal from "../../../Components/Modal/Modal"
import { Formik, Form, Field } from 'formik'
import { useSelector } from 'react-redux';
import { formatearFecha, FormatNumberMoney2 } from '../../../Utils/functions';
import { TextAreaField } from '../../../Components/TextAreaField';
import { TextField } from '../../../Components/TextField';
import ContratoCard from '../../../Components/ContratoCard'
import RowPages from '../../../Components/RowPages'
import DataTable from '../../../Components/datatable/DataTable'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'

const HistorialVisitasDetalle = () => {
    const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

    const {
        formattedData,
        collapsableVisita,
        setCollapsableVisita,
        initialValue,
        opcionSeleccionada,
        formularioVisitaDom,
        collpsableGruaPromesaPago,
        setCollpsableGruaPromesaPago,
        formularioActual,
        datosGarantiaPago,
        datosPromesaPago,
        refFormik,
        datosPromesaEntregaDefinitiva,
        collapsableResguardo,
        setCollapsableResguardo,
        datosResguardo,
        setpageRows,
        columnsResguardoUnidad,
        pageCount,
        handlePageClick,
        currenPage,
        totalPagos,
        collapsableRecoleccion,
        setCollapsableRecoleccion,
        datosRecoleccion,
        gruaSeleccionada,
        usuarioProgramo,
        userDetail,
        promesasRotas,
        loading,
        handleReturnPage,
        handleVerDocumentos
    } = useHistorialVisitasDetalle();


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto' }}>
                <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                        <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                            <i className="bx bxs-chevron-left mx-2"></i>
                            Regresar a listado de seguimiento de visita
                        </div>
                    </header>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.HistorialVisitasDetalle, SubModule.SeguimientoVisita)?.Ver}>
                        <div className="col-11 col-sm-12 col-md-12 d-flex">
                            <div className="col-6 px-4 py-2">
                                <VehiculoInfoCard
                                    GeneralId={contratoActual?.vehiculo?.generalId}
                                    VIN={contratoActual?.vin}
                                    verClaves={true}
                                    solicitarClave={true} />
                            </div>
                            <div className="col-6 px-4 py-2">
                                <ContratoCard vin={contratoActual?.vin} />
                            </div>
                        </div>
                        <div className="col-12 px-4 py-2">
                            <CarteraInfoCard
                                datos={formattedData}
                                user={userDetail}
                                promesasRotas={promesasRotas} />
                        </div>
                        {/* VISITA DOMICILIARIA */}
                        <div className="col-12 px-4 py-2">
                            <div className="row wrapper-vehiculo">
                                <div className="col col-12">
                                    <div className="row mx-md-4 my-4">
                                        <div className="col-12 px-md-3">
                                            <div className="p-4 white-wrapper mb-2">
                                                <div
                                                    className="row mt-2 px-4"
                                                    data-bs-toggle="collapse"
                                                    href="#collapseVisitaDomiciliaria"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="collapseRegistroTramite"
                                                    onClick={() => setCollapsableVisita(!collapsableVisita)}
                                                >
                                                    <div className="col-11">
                                                        <h6><span className={`semaforo semaforo-${initialValue.color}`} ></span>{" "} <strong>Visita Domiciliaria</strong></h6>
                                                    </div>
                                                    <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                                        <i className={!collapsableVisita ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseVisitaDomiciliaria">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={initialValue}
                                                    >
                                                        {({ isValid, values, setFieldValue, errors, actions }) => (
                                                            <>
                                                                <Form>
                                                                    <div className="separator"></div>
                                                                    <div className="row mt-2 mb-2 justify-content-between">
                                                                        <div className="col-sm-6 col-xl-3">
                                                                            <p className="text-base"><strong>Detalle de visita</strong></p>
                                                                        </div>
                                                                        <div className="col-sm-6 col-xl-3">
                                                                            <div className="col-auto">
                                                                                <fieldset
                                                                                    onClick={() => {
                                                                                        handleVerDocumentos(values.id, values.ver_documentos)
                                                                                    }}
                                                                                    className='btn btn-gray'>
                                                                                    <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row mt-2 mb-2">
                                                                <div className="col-3">
                                                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle de visita</strong></span></p>
                                                                </div>
                                                            </div> */}
                                                                    <div className="mb-3 row px-4" >
                                                                        <div className="col-sm-6 col-xl-3">
                                                                            <TextField label="Número de Visita" name="num_visita" id="num_visita" type="text" disabled={true} />
                                                                        </div>
                                                                        <div className="col-sm-6 col-xl-3">
                                                                            <TextField label="¿Encontraste al Cliente?" name="entregaste_cliente" id="entregaste_cliente" type="text" disabled={true} />
                                                                        </div>
                                                                        {
                                                                            opcionSeleccionada === 1 && (
                                                                                <>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <TextField label="Resultado" name="resultado" type="text" holder={values.resultado} disabled={true} />
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <TextField label="¿Entregaste documentos?" name="encontraste_documento" type="text" holder={values.encontraste_documento} disabled={true} />
                                                                                    </div>
                                                                                    {
                                                                                        values.encontraste_documento === "Si" && (
                                                                                            <div className="col-sm-6 col-xl-3">
                                                                                                <TextField label="Tipo de documento" name="tipo_documento" type="text" holder={values.tipo_documento} disabled={true} />
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </>
                                                                            )}
                                                                        {
                                                                            opcionSeleccionada === 2 && (
                                                                                <>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <TextField label="Motivo" name="motivo" type="text" holder={values.motivo} disabled={true} />
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <TextField label="Especificación" name="especificacion" type="text" holder={values.especificacion} disabled={true} />
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                    {
                                                                        opcionSeleccionada === 2 && (
                                                                            <>
                                                                                <div className="mb-3 row px-4" >
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <TextField label="¿Entregaste Documentos?" name="encontraste_documento" type="text" holder={values.encontraste_documento} disabled={true} />
                                                                                    </div>
                                                                                    {
                                                                                        values.encontraste_documento === "Si" && (
                                                                                            <div className="col-sm-6 col-xl-3">
                                                                                                <TextField label="Tipo de documento" name="tipo_documento" type="text" holder={values.tipo_documento} disabled={true} />
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                                                        <div className="col-12">
                                                                            <TextAreaField
                                                                                label="Comentarios:"
                                                                                className="col-sm-8 col-xl-6"
                                                                                rows="4"
                                                                                name="comentarios"
                                                                                type="text"
                                                                                holder={values.comentarios}
                                                                                disabled={true}
                                                                                style={{
                                                                                    border: "none",
                                                                                    backgroundColor: "#f0f0f0",
                                                                                    resize: "none",
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2 mb-2">
                                                                        <div className="col-4">
                                                                            <p><span style={{ fontSize: "14px", fontWeight: "bold" }} >Ingreso a cartera</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3 px-4">
                                                                        <div className="col-sm-6 col-xl-3">
                                                                            <TextField label="Motivo de ingreso" name="motivo_ingreso" type="text" holder={values.motivo_ingreso} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                                                        <div className="col-12">
                                                                            <TextAreaField
                                                                                label="Comentarios de ingreso a cartera:"
                                                                                className="col-sm-8 col-xl-6"
                                                                                rows="4"
                                                                                name="comentario_ingreso"
                                                                                type="text"
                                                                                holder={values.comentario_ingreso}
                                                                                disabled={true}
                                                                                style={{
                                                                                    border: "none",
                                                                                    backgroundColor: "#f0f0f0",
                                                                                    resize: "none",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='my-2 mt-4 px-4'>
                                                                        <div className="mb-3 row">
                                                                            <div className="col-sm-6 col-xl-3">
                                                                                <Field className="form-check-input" type="checkbox" value={values.reg_concluido_visita} checked={values.reg_concluido_visita} disabled={true} name="reg_concluido_visita" />
                                                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                            </div>
                                                                            {
                                                                                formularioVisitaDom &&
                                                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                    {
                                                                                        values.fecha_inicio !== "" &&
                                                                                        <label>
                                                                                            Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                                                                        </label>
                                                                                    }
                                                                                    {
                                                                                        values.reg_concluido_visita &&
                                                                                        <label style={{ whiteSpace: 'pre' }}>
                                                                                            {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                                                                        </label>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* PROMESAS DE PAGO */}
                        {
                            itemGeneral?.promesa !== null && (
                                <div className="col-12 px-4 py-2">
                                    <div className="row wrapper-vehiculo">
                                        <div className="col col-12">
                                            <div className="row mx-md-4 my-4">
                                                <div className="col-12 px-md-3">
                                                    <div className="p-4 white-wrapper mb-2">
                                                        <div
                                                            className="row mt-2 px-4"
                                                            data-bs-toggle="collapse"
                                                            href="#collapsePromesaPago"
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-controls="collapseRegistroTramite"
                                                            onClick={() => setCollpsableGruaPromesaPago(!collpsableGruaPromesaPago)}
                                                        >
                                                            <div className="col-11"> <h6> <span className={`semaforo semaforo-`} ></span>{" "} <strong>Promesa de Pago</strong> </h6> </div>
                                                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                                                <i className={!collpsableGruaPromesaPago ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                            </div>
                                                        </div>
                                                        <div className="collapse" id="collapsePromesaPago">
                                                            <div className="separator"></div>
                                                            <div className="row mt-2 px-4">
                                                                {/* registro de promesa */}
                                                                {/* tipo promesa: garantia de pago */}
                                                                {formularioActual === 0 && (
                                                                    <Formik
                                                                        enableReinitialize={true}
                                                                        initialValues={datosGarantiaPago}
                                                                    >
                                                                        {({ isValid, values, setFieldValue }) => (
                                                                            <Form>
                                                                                <div className="row mt-2 mb-2 justify-content-between">
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <div className="col-auto">
                                                                                            <fieldset
                                                                                                onClick={() => {
                                                                                                    handleVerDocumentos(values.id, values.ver_documentos)
                                                                                                }}
                                                                                                className='btn btn-gray'>
                                                                                                <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                            </fieldset>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="row mt-2 mb-2">
                                                                        <div className="col-4">
                                                                            <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                        </div>
                                                                    </div> */}
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Tipo de promesa" name="tipo_promesa" holder={values.tipo_promesa} type="text" disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Folio" name="folio" type="text" holder={values.folio} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Proceso" name="proceso" type="text" holder={values.proceso} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" holder={values.cobrador_apoyo} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        {
                                                                                            itemGeneral?.promesa?.cobrador_apoyo === 1 && (
                                                                                                <div className="col-sm-6 col-xl-3">
                                                                                                    <TextField id="cobradores" label="Cobradores" name="cobradores" holder={values.cobradores} disabled={true} />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="grua" label="Grua" name="grua" holder={values.grua} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text-base"><strong>Fecha de promesa</strong></p>
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="fecha_vencimiento" label="Fecha de vencimiento" name="fecha_vencimiento" type="date" holder={values.fecha_vencimiento} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Días de promesa" name="dias_de_promesa" type="text" holder={values.dias_de_promesa} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text-base"><strong>Monto por cubrir</strong></p>
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3 d-flex align-items-center">
                                                                                            <TextField label="Monto vencido" name="monto_vencido" type="text" holder={values.monto_vencido} disabled={true} />
                                                                                            <div className="mt-3 ms-3">
                                                                                                <i className="icon-light fa fa-plus ms-2" style={{ color: '#1351A5' }}></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3 d-flex align-items-center">
                                                                                            <TextField label="Pago de periodo" name="pago_periodo" type="text" holder={values.pago_periodo} disabled={true} />
                                                                                            <div className="mt-3 ms-3">
                                                                                                <i className="icon-light fa fa-equals ms-2" style={{ color: '#1351A5' }}></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField icon={"bi bi-currency-dollar"} label="Total" name="total" type="text" holder={values.total} isMoney={true} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <Field className="form-check-input" type="checkbox" value={values.reg_concluido_promesa} checked={values.reg_concluido_promesa} disabled={true} name="reg_concluido_promesa" />
                                                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                            {
                                                                                                values.fecha_inicio_promesa !== "" &&
                                                                                                <label>
                                                                                                    Inicio de proceso: {formatearFecha(values.fecha_inicio_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                            {
                                                                                                itemGeneral?.promesa?.registro_concluido &&
                                                                                                <label style={{ whiteSpace: 'pre' }}>
                                                                                                    {" | "}Fin de proceso: {formatearFecha(values.fecha_fin_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik >
                                                                )}
                                                                {/* tipo promesa: promesa de pago */}
                                                                {formularioActual === 1 && (
                                                                    <Formik
                                                                        enableReinitialize={true}
                                                                        initialValues={datosPromesaPago}
                                                                    >
                                                                        {({ isValid, values, setFieldValue }) => (
                                                                            <Form>
                                                                                <div className="row mt-2 mb-2 justify-content-between">
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <div className="col-auto">
                                                                                            <fieldset
                                                                                                onClick={() => {
                                                                                                    handleVerDocumentos(values.id, values.ver_documentos)
                                                                                                }}
                                                                                                className='btn btn-gray'>
                                                                                                <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                            </fieldset>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="row mt-2 mb-2">
                                                                        <div className="col-4">
                                                                            <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                        </div>
                                                                    </div> */}
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Tipo de promesa" name="tipo_promesa" type="text" holder={values.tipo_promesa} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Motivo promesa" name="motivo_promesa" type="text" holder={values.motivo_promesa} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="fecha_compromiso" label="Fecha compromiso" name="fecha_compromiso" holder={values.fecha_compromiso} type="date" disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="horarioCompromiso" label="Horario compromiso" name="horarioCompromiso" holder={values.horarioCompromiso} type="time" disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Monto prometido" name="monto_prometido" type="text" holder={values.monto_prometido} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Monto vencido" name="monto_vencido" type="text" holder={values.monto_vencido} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-12">
                                                                                            <TextAreaField
                                                                                                label="Comentarios promesa de pago"
                                                                                                className="col-sm-8 col-xl-6"
                                                                                                rows="4"
                                                                                                name="comentarios"
                                                                                                holder={values.comentarios}
                                                                                                type="text"
                                                                                                disabled={true}
                                                                                                style={{
                                                                                                    border: "none",
                                                                                                    backgroundColor: "#f0f0f0",
                                                                                                    resize: "none",
                                                                                                }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <Field className="form-check-input" type="checkbox" value={values.reg_concluido_promesa} checked={values.reg_concluido_promesa} disabled={true} name="reg_concluido_promesa" />
                                                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                            {
                                                                                                values.fecha_inicio_promesa !== "" &&
                                                                                                <label>
                                                                                                    Inicio de proceso: {formatearFecha(values.fecha_inicio_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                            {
                                                                                                itemGeneral?.promesa?.registro_concluido &&
                                                                                                <label style={{ whiteSpace: 'pre' }}>
                                                                                                    {" | "}Fin de proceso: {formatearFecha(values.fecha_fin_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik >
                                                                )}
                                                                {/* tipo promesa: promesa de entrega definitiva */}
                                                                {formularioActual === 2 && (
                                                                    <Formik innerRef={refFormik}
                                                                        enableReinitialize={true}
                                                                        initialValues={datosPromesaEntregaDefinitiva}
                                                                    >
                                                                        {({ isValid, values, setFieldValue }) => (
                                                                            <Form>
                                                                                <div className="row mt-2 mb-2 justify-content-between">
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-xl-3">
                                                                                        <div className="col-auto">
                                                                                            <fieldset
                                                                                                onClick={() => {
                                                                                                    handleVerDocumentos(values.id, values.ver_documentos)
                                                                                                }}
                                                                                                className='btn btn-gray'>
                                                                                                <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                            </fieldset>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="row mt-2 mb-2">
                                                                        <div className="col-4">
                                                                            <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                        </div>
                                                                    </div> */}
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Tipo de promesa" name="tipo_promesa" type="text" holder={values.tipo_promesa} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Folio" name="folio" type="text" holder={values.folio} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Proceso" name="proceso" type="text" holder={values.proceso} disabled={true} />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" holder={values.cobrador_apoyo} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        {
                                                                                            itemGeneral?.promesa?.cobrador_apoyo === 1 && (
                                                                                                <div className="col-sm-6 col-xl-3">
                                                                                                    <TextField id="cobradores" label="Cobradores" name="cobradores" holder={values.cobradores} disabled={true} />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="grua" label="Grua" name="grua" holder={values.grua} disabled={true} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='my-2 mt-4'>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <Field className="form-check-input" type="checkbox" value={values.reg_concluido_promesa} checked={values.reg_concluido_promesa} disabled={true} name="reg_concluido_promesa" />
                                                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                            {
                                                                                                values.fecha_inicio_promesa !== "" &&
                                                                                                <label>
                                                                                                    Inicio de proceso: {formatearFecha(values.fecha_inicio_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                            {
                                                                                                itemGeneral?.promesa?.registro_concluido &&
                                                                                                <label style={{ whiteSpace: 'pre' }}>
                                                                                                    {" | "}Fin de proceso: {formatearFecha(values.fecha_fin_promesa)}
                                                                                                </label>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik >
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {/** RESGUARDO UNIDAD */}
                        {
                            (itemGeneral?.resguardoUnidad !== null && itemGeneral?.promesa?.grua === 0) &&
                            (
                                <div className="col-12 px-4 py-2">
                                    <div className="row wrapper-vehiculo">
                                        <div className="col col-12">
                                            <div className="row mx-md-4 my-4">
                                                <div className="col-12 px-md-3">
                                                    <div className="p-4 white-wrapper mb-2">
                                                        <div
                                                            className="row mt-2 px-4"
                                                            data-bs-toggle="collapse"
                                                            href="#collapseResguardoUnidad"
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-controls="collapseResguardoUnidad"
                                                            onClick={() => setCollapsableResguardo(!collapsableResguardo)}
                                                        >
                                                            <div className="col-11">
                                                                <h6>
                                                                    <span className={`semaforo semaforo-`} ></span>{" "}
                                                                    <strong>Resguardo de unidad</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                                                <i className={!collapsableResguardo ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                            </div>
                                                        </div>
                                                        <div className="collapse" id="collapseResguardoUnidad">
                                                            <Formik
                                                                enableReinitialize={true}
                                                                initialValues={datosResguardo}
                                                            >
                                                                {({ isValid, values, setFieldValue }) => (
                                                                    <Form>
                                                                        <div className="separator"></div>
                                                                        <div className="row mt-2 px-4">
                                                                            <div className="row mt-2 mb-2 justify-content-between">
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <p className="text-base"><strong>Detalle del resguardo</strong></p>
                                                                                </div>
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <div className="col-auto">
                                                                                        <fieldset
                                                                                            onClick={() => {
                                                                                                handleVerDocumentos(values.id, values.ver_documentos)
                                                                                            }}
                                                                                            className='btn btn-gray'>
                                                                                            <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="row mt-2 mb-2 justify-content-between">
                                                                    <div className="col-3">
                                                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle del resguardo</strong></span></p>
                                                                    </div>
                                                                </div> */}
                                                                            <div className='my-2 mt-4'>
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField id="motivo_resguardo" label="Motivo resguardo" holder={values.motivo_resguardo} name="motivo_resguardo" disabled />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField id="forma_arribo" label="Forma de arribo" holder={values.forma_arribo} name="forma_arribo" disabled />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-2 mb-2">
                                                                                <div className="col-auto">
                                                                                    <strong>Costos adicionales</strong>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                </div>
                                                                            </div>
                                                                            <RowPages setpageRows={setpageRows} />
                                                                            <div className="row mt-2 px-4 mb-2">
                                                                                <div className="col-12">
                                                                                    <DataTable
                                                                                        id="tablaPagos"
                                                                                        name="pagos"
                                                                                        column={columnsResguardoUnidad}
                                                                                        data={datosResguardo.costosAdicionalesTbl}
                                                                                        paginate={true}
                                                                                        pageCount={pageCount}
                                                                                        handleAction={(value, idx) => { handleVerDocumentos(value.id, value.ver_documentos) }}
                                                                                        handlePageClick={handlePageClick}
                                                                                        currentPage={currenPage - 1}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-2 px-4 mb-2 justify-content-end">
                                                                                <div className="col-auto">
                                                                                    <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                                                                                </div>
                                                                            </div>
                                                                            {/* aqui registrar costos y tabla */}
                                                                            <p className="text-base"><strong>Detalle de la entrega</strong></p>
                                                                            <div className='my-2 mt-4'>
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField id="fechaResguardo" label="Fecha de resguardo" holder={values.fechaResguardo} name="fechaResguardo" type="date" disabled />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField id="horaResguardo" label="Hora de resguardo" holder={values.horaResguardo} name="horaResguardo" type="time" disabled />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField label="Quien entrega" name="quien_entrega" holder={values.quien_entrega} type="text" disabled />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField id="ubicacion_entrega" label="Ubicación" holder={values.ubicacion_entrega} name="ubicacion_entrega" disabled />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <TextField label="¿Quién Recibe?" name="usuario_recibe" holder={values.usuario_recibe} type="text" disabled={true} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='my-2 mt-4'>
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                                                        <TextAreaField label="Comentarios" className="form-control col-12" rows="4" holder={values.comentarios} name="comentarios" type="text" disabled />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='my-2 mt-4'>
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                                        <Field className="form-check-input" type="checkbox" value={values.registro_concluido} checked={values.registro_concluido} disabled name="registro_concluido" />
                                                                                        {/* <Field className="form-check-input" type="checkbox" name="registroConcluido" onClick={(event) => { setFieldValue("registroConcluido", event.target.value); }} /> */}
                                                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                        {
                                                                                            values.fecha_inicio_resguardo !== "" &&
                                                                                            <label>
                                                                                                Inicio de proceso: {formatearFecha(values.fecha_inicio_resguardo)}
                                                                                            </label>
                                                                                        }
                                                                                        {
                                                                                            itemGeneral?.resguardoUnidad?.registro_concluido &&
                                                                                            <label style={{ whiteSpace: 'pre' }}>
                                                                                                {" | "}Fin de proceso: {formatearFecha(values.fecha_fin_resguardo)}
                                                                                            </label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {/** RECOLEECION UNIDAD */}
                        {
                            (itemGeneral?.recoleccionUnidad !== null && itemGeneral?.promesa?.grua === 1) &&
                            (
                                <div className="col-12 px-4 py-2">
                                    <div className="row wrapper-vehiculo">
                                        <div className="col col-12">
                                            <div className="row mx-md-4 my-4">
                                                <div className="col-12 px-md-3">
                                                    <div className="p-4 white-wrapper mb-2">
                                                        <div
                                                            className="row mt-2 px-4"
                                                            data-bs-toggle="collapse"
                                                            href="#collapseRecoleccionDeUnidadCard"
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-controls="collapseRegistroTramite"
                                                            onClick={() => setCollapsableRecoleccion(!collapsableRecoleccion)}
                                                        >
                                                            <div className="col-11">
                                                                <h6>
                                                                    <span
                                                                        className={`semaforo semaforo-${initialValue.color}`}
                                                                    ></span>{" "}
                                                                    <strong>Recolección de unidad</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                                                <i className={
                                                                    !collapsableRecoleccion
                                                                        ? "icon-blue bx bx-chevron-down x2"
                                                                        : "icon-blue bx bx-chevron-up x2"
                                                                }
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        <div className="collapse" id="collapseRecoleccionDeUnidadCard">
                                                            <Formik
                                                                enableReinitialize={true}
                                                                // validationSchema={validate}
                                                                initialValues={datosRecoleccion}
                                                            >
                                                                {({ isValid, values, setFieldValue, errors, actions }) => (
                                                                    <>
                                                                        <Form>
                                                                            <div className="separator"></div>
                                                                            <div className="row mt-2 mb-2 justify-content-between">
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <p className="text-base"><strong>Detalle de Recolección de unidad</strong></p>
                                                                                </div>
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <div className="col-auto">
                                                                                        <fieldset
                                                                                            onClick={() => {
                                                                                                handleVerDocumentos(values.id, values.ver_documentos)
                                                                                            }}
                                                                                            className='btn btn-gray'>
                                                                                            <i className="icon-light fa fa-file "></i> Ver documentos
                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Asignación de grua</strong></span></p>
                                                                            </div>
                                                                            <div className="mb-3 row">
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <TextField label="Folio" name="folio" type="text" holder={values.folio} disabled />
                                                                                </div>
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <TextField label="Usuario Programo" id="usuarioProgramo" holder={values.usuarioProgramo} name="usuarioProgramo" value={usuarioProgramo} type="text" disabled />
                                                                                </div>
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <TextField label="Grua" name="tipoGrua" id="tipoGrua" holder={values.tipoGrua} type="text" disabled />
                                                                                </div>
                                                                            </div>
                                                                            {(gruaSeleccionada === 1 || gruaSeleccionada === 2) && (
                                                                                <>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Empresa Grúa" id="empresa_nombre" name="empresa_nombre" holder={values.empresa_nombre} type="text" disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Grua asignada" name="grua_asignada" id="grua_asignada" holder={values.grua_asignada} type="text" disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Costo de grua" name="costo_grua" holder={values.costo_grua} disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-3">
                                                                                            <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Indicar ubicación de origen</strong></span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Estado" name="estado" holder={values.estado} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Municipio" name="municipio" holder={values.municipio} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Colonia" name="colonia" holder={values.colonia} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Calle" name="calle" holder={values.calle} type="text" disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Número" name="numero" holder={values.numero} type="text" disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Código Postal" name="ubi_origen_cp" holder={values.ubi_origen_cp} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="Quien entrega" name="quien_entrega" holder={values.quien_entrega} id="quien_entrega" disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-6 mb-3">
                                                                                            <TextAreaField label="Referencia de ubicación:" className="col-sm-12 col-xl-8 mt-2 form-control" rows="4" holder={values.ubi_origen_comentarios} name="ubi_origen_comentarios" disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-3">
                                                                                            <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Indicar ubicación destino</strong></span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 row px-4">
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="ubi_destino" label="Ubicación" name="ubi_destino" holder={values.ubi_destino} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField label="¿Quién Recibe?" name="usuario_recibe" type="text" holder={values.usuario_recibe} disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="fechaRecoleccion" label="Fecha de recolección" name="fechaRecoleccion" holder={values.fechaRecoleccion} type="date" disabled />
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-xl-3">
                                                                                            <TextField id="horaRecoleccion" label="Hora de recolección" name="horaRecoleccion" holder={values.horaRecoleccion} type="time" disabled />
                                                                                        </div>
                                                                                        {/* <div className="col-sm-6 col-xl-3">
                                                                        <TextField label="Código Postal" name="ubi_dest_cp" id="ubi_dest_cp" holder={values.ubi_dest_cp} disabled />
                                                                    </div> */}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <div className="mb-3 row">
                                                                                <div className="col-sm-6 col-xl-3">
                                                                                    <Field className="form-check-input" type="checkbox" name="registro_concluido" disabled />
                                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                                    {
                                                                                        values.fecha_inicio_recoleccion !== "" &&
                                                                                        <label>
                                                                                            Inicio de proceso: {formatearFecha(values.fecha_inicio_recoleccion)}
                                                                                        </label>
                                                                                    }
                                                                                    {
                                                                                        itemGeneral?.recoleccionUnidad?.registro_concluido &&
                                                                                        <label style={{ whiteSpace: 'pre' }}>
                                                                                            {" | "}Fin de proceso: {formatearFecha(values.fecha_fin_recoleccion)}
                                                                                        </label>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Form>
                                                                    </>
                                                                )}
                                                            </Formik>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </ValidatePermission>
                </div>
            </div>
        </>
    )
}

export default HistorialVisitasDetalle