import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import RecoleccionDeUnidad from "./RecoleccionDeUnidad";
import InicioDeValuacionService from '../../../../Services/Inventario/Valuacion/InicioDeValuacionService'
import CostosAdicionales from './CostosAdicionales';
import AuthLogin from '../../../../Utils/AuthLogin';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import { message } from '../../../../Utils/functions';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../../Auth/Authorization';

const UbicacionDeVehiculo = ({ handleConcluido }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const auth = new AuthLogin();
    const navigate = useNavigate();

    let initial = {
        valuacionInicioId: "",
        valuacionId: "",
        ubicacionActual: "",
        requiereCambioUbicacion: 0,
        unidadPuedeMoverse: 0,
        ubicacionOrigen: "",
        ubicacionDestino: "",
        quienRecibe: "",
        fechaArribo: "",
        horaArribo: "",
        usuarioInicio: "",
        quienEntrega: "",
        fechaEntrega: "",
        horaEntrega: "",
        total: 0,
        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        usuarioInicio: "",
        txtFechaInicio: "",
        txtHoraInicio: ""
    };

    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [pageRows, setpageRows] = useState(10);
    const [disableCheck, setDisableCheck] = useState(false);
    const [boolSelect, setboolSelect] = useState([{ label: "Sí", value: true }, { label: "No", value: false }]);
    const [tipoUbicacion, setTipoUbicacion] = useState([]);
    const [requiereCambioUbicacion, setRequiereCambioUbicacion] = useState(false)
    const [mostrarRecoleccion, setMostrarRecoleccion] = useState(false)

    const { refFormik } = useRef();

    useEffect(() => {
        getAll();
        handleGetTipoUbicacion();
    }, []);

    const formatDate = (fecha) => {
        const [day, month, year] = fecha.split("-");
        return `${year}-${month}-${day}`;
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed
        return `${year}-${month}-${day}`;
    };

    const handleGetTipoUbicacion = async () => {
        await Catalogo.getAllUbicacion(1, 100)
            .then(res => {
                let NewData = res.data.data
                    ?.filter(item => item.tipoUbicacion === "Valuación")
                    .map(item => ({
                        value: item.ubicacionId,
                        label: item.nombre,
                        responsable: item.responsable
                    }));

                setTipoUbicacion(NewData);
            })
            .catch(e => {
                console.error("Error al obtener ubicaciones:", e);
            });
    };


    const getAll = async () => {
        setLoading(true);
        await InicioDeValuacionService.getUbicacionVehiculo(id)
            .then((resp) => {
                let ubicacionVehiculo = resp.data;
                const fechaEntregaFormateada = ubicacionVehiculo.fechaEntrega
                    ? formatDate(ubicacionVehiculo.fechaEntrega)
                    : getTodayDate();
                let fechaStr = ubicacionVehiculo.fechaInicio ? ubicacionVehiculo.fechaInicio.split(" ") : "   "
                ubicacionVehiculo.usuarioInicio = auth.getNombreUsuario()
                ubicacionVehiculo.txtFechaInicio = fechaStr[0]
                ubicacionVehiculo.txtHoraInicio = fechaStr[1] + ' ' + fechaStr[2]

                //ubicacionVehiculo.concluido = false
                ubicacionVehiculo.requiereCambioUbicacion = ubicacionVehiculo.requiereCambioUbicacion ?? false
                ubicacionVehiculo.unidadPuedeMoverse = ubicacionVehiculo.unidadPuedeMoverse ?? false

                setRequiereCambioUbicacion(ubicacionVehiculo.unidadPuedeMoverse);
                setMostrarRecoleccion(ubicacionVehiculo.requiereCambioUbicacion)
                //setDisableCheck(ubicacionVehiculo.concluido)
                setInitialValue({
                    ...ubicacionVehiculo,
                    fechaEntrega: fechaEntregaFormateada,
                    horaEntrega: ubicacionVehiculo.horaEntrega, // Asigna la fecha convertida
                    concluido: ubicacionVehiculo?.concluido ?? false
                });

                if (ubicacionVehiculo.concluido) {
                    if (!ubicacionVehiculo.requiereCambioUbicacion || (ubicacionVehiculo.requiereCambioUbicacion && ubicacionVehiculo.unidadPuedeMoverse)) {
                        handleConcluido({ concluido: true });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }

    const submitForm = async (values) => {
        setLoading(true);
        values.procesoId = state.procesoId
        values.paso = 1
        values.porcentaje = !values.requiereCambioUbicacion && values.unidadPuedeMoverse ? 50 : 100
        values.status = 2
        await InicioDeValuacionService.updateUbicacion(values)
            .then((resp) => {

                if (resp.data.ban === 1) {
                    toast.success(message("Correcto", resp.data.mensaje));
                    getAll(1);
                } else {
                    toast.error(message("Error", resp.data.mensaje));
                }
            })
            .catch((error) => {
                console.log(error, "Error")
                toast.error(message("Error", "Error al tratar de guardar"));
            })
            .finally(() => {
                setLoading(false)
                getAll()
            });
    };

    const handleUploadFile = async (values) => {
        navigate(`/datamovil/datadocs/fValuacion/eUbicaVal/${state.contrato}/${values.valuacionInicioId}`, {
            state: {
                ...state,
                registroId: state.valuacionInicioId,
                vin: state.vin,
                nombreMarca: state.marca,
                nombreModelo: state.modelo,
                nombreVersion: state.version,
                nombreYear: state.anio
            }
        });
    }

    const validate = Yup.object({
        requiereCambioUbicacion: Yup.boolean()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        unidadPuedeMoverse: Yup.boolean()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion'], {
                is: (concluido, requiereCambioUbicacion) => {
                    return (concluido && requiereCambioUbicacion);
                },
                then: Yup.boolean()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        ubicacionDestino: Yup.string()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion', 'unidadPuedeMoverse'], {
                is: (concluido, requiereCambioUbicacion, unidadPuedeMoverse) => {
                    return (concluido && requiereCambioUbicacion && unidadPuedeMoverse);
                },
                then: Yup.string()
                    .nullable()
                    .required("Selecciona una opción"),
            }),
        quienEntrega: Yup.string()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion', 'unidadPuedeMoverse'], {
                is: (concluido, requiereCambioUbicacion, unidadPuedeMoverse) => {
                    return (concluido && requiereCambioUbicacion && unidadPuedeMoverse);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
        quienRecibe: Yup.string()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion', 'unidadPuedeMoverse'], {
                is: (concluido, requiereCambioUbicacion, unidadPuedeMoverse) => {
                    return (concluido && requiereCambioUbicacion && unidadPuedeMoverse);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
        fechaEntrega: Yup.string()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion', 'unidadPuedeMoverse'], {
                is: (concluido, requiereCambioUbicacion, unidadPuedeMoverse) => {
                    return (concluido && requiereCambioUbicacion && unidadPuedeMoverse);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
        horaEntrega: Yup.string()
            .nullable()
            .when(['concluido', 'requiereCambioUbicacion', 'unidadPuedeMoverse'], {
                is: (concluido, requiereCambioUbicacion, unidadPuedeMoverse) => {
                    return (concluido && requiereCambioUbicacion && unidadPuedeMoverse);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido."),
            }),
    });

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>            
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">

                        <div className="row mt-2" data-bs-toggle="collapse" href="#collapsePagoTramites" role="button" aria-expanded="false" aria-controls="collapsePagoTramites" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                            <div className="col-11">
                                <h6><span></span> <strong>Ubicación de vehículo</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                            <div className='col-12'>
                                <small>{initialValue.siniestroPagoTramitesStatus}</small>
                            </div>
                        </div>
                        <div className="collapse" id="collapsePagoTramites">
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    submitForm(values);
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <>
                                        <Form >
                                            <div className="separator"></div>
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    {/* <h6><strong>Ubicación de vehículo</strong></h6> */}
                                                </div>

                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <button
                                                                id="btnSubirDocumento"
                                                                className="btn btn-outline col-12 mw-100 d-flex "
                                                                type="button"
                                                                onClick={() => {
                                                                    handleUploadFile(values)
                                                                }}
                                                                style={{
                                                                    padding: "8px",
                                                                    lineHeight: "20px",
                                                                    border: "none",
                                                                    backgroundColor: "#f0f0f0",
                                                                }}>
                                                                <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                                                Subir documento
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido == true ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    :
                                                                    <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.SeguimientoInicioDeValuacion, SubModule.SeguimientoValuacion))}>
                                                                        <button
                                                                            className='btn'
                                                                            disabled={initialValue.concluido}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                    </ValidatePermission>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                    <TextField label="Ubicación actual"
                                                        name="ubicacionActual"
                                                        disabled
                                                        type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("ubicacionActual", event.target.value);
                                                        }} />
                                                </div>
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <SelectField
                                                        id="requiereCambioUbicacion"
                                                        label="¿Requiere cambio de ubicación?"
                                                        name="requiereCambioUbicacion"
                                                        items={boolSelect}
                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                        onChange={(event) => {
                                                            setFieldValue("requiereCambioUbicacion", event.value);
                                                            setMostrarRecoleccion(event.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                values.requiereCambioUbicacion &&
                                                <>
                                                    <div className='pt-3'>
                                                        <h6><strong>Cambio de ubicación</strong></h6>
                                                    </div>
                                                    <div className='row mt-2 mb-2'>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <SelectField
                                                                id="unidadPuedeMoverse"
                                                                label="¿La unidad puede moverse?"
                                                                name="unidadPuedeMoverse"
                                                                items={boolSelect}
                                                                disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                onChange={(event) => {
                                                                    setFieldValue("unidadPuedeMoverse", event.value);
                                                                    setRequiereCambioUbicacion(event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            values.unidadPuedeMoverse &&
                                                            <>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <TextField
                                                                        label="Ubicación origen"
                                                                        name="ubicacionActual"
                                                                        type="text"
                                                                        disabled
                                                                        holder="Escribe" onChange={(event) => {
                                                                            setFieldValue("ubicacionActual", event.target.value);
                                                                        }} />
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <SelectField
                                                                        id="ubicacionDestino"
                                                                        label="Ubicación destino"
                                                                        name="ubicacionDestino"
                                                                        items={tipoUbicacion}
                                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                        onChange={(event) => {
                                                                            setFieldValue("ubicacionDestino", event.value);
                                                                            setFieldValue("quienRecibe", event.value === "" ? "" : event.responsable)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <TextField label="¿Quien entrega?" name="quienEntrega" type="text" holder="Escribe"
                                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                        onChange={(event) => {
                                                                            setFieldValue("quienEntrega", event.target.value);

                                                                        }} />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    {
                                                        values.unidadPuedeMoverse &&
                                                        <>
                                                            <div className='row mt-2 mb-2'>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <TextField label="¿Quien recibe?" name="quienRecibe" type="text"
                                                                        holder="Escribe"
                                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                        onChange={(event) => {
                                                                            setFieldValue("quienRecibe", event.target.value);
                                                                        }} />
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <TextField label="Fecha de entrega" name="fechaEntrega" type="date" holder="Escribe"
                                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                        onChange={(event) => {
                                                                            setFieldValue("fechaEntrega", event.target.value);
                                                                        }} />
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                    <TextField label="Hora de entrega" name="horaEntrega" type="time" holder="Escribe"
                                                                        disabled={initialValue.concluido ?? false} // 🔹 Usa `false` si `concluido` es `undefined` o `null`
                                                                        onChange={(event) => {
                                                                            setFieldValue("horaEntrega", event.target.value);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }

                                        </Form>

                                        {

                                            (
                                                (values.requiereCambioUbicacion === true && values.requiereCambioUbicacion !== null) &&
                                                (values.unidadPuedeMoverse && values.unidadPuedeMoverse !== null)
                                            ) &&
                                            <>
                                                <CostosAdicionales
                                                    handleConcluido={initialValue.concluido ?? false}
                                                    valuesInit={initialValue}
                                                />

                                            </>
                                        }

                                        {

                                            (
                                                (values.requiereCambioUbicacion === false && values.requiereCambioUbicacion !== null) ||
                                                (values.unidadPuedeMoverse !== null)
                                            ) &&

                                            <>
                                                <div className='row mt-2 mb-2'>
                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                        <TextField label="Fecha de Inicio"
                                                            name="txtFechaInicio"
                                                            type="text"
                                                            holder="Escribe"
                                                            disabled />
                                                    </div>
                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                        <TextField label="Hora de Inicio"
                                                            name="txtHoraInicio"
                                                            type="text"
                                                            holder="Escribe"
                                                            disabled />
                                                    </div>
                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                        <TextField label="Usuario Inicio"
                                                            name="usuarioInicio"
                                                            type="text"
                                                            holder="Escribe"
                                                            disabled />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className="row mt-2">
                                            <div className="col-12 col-md-6">
                                                <Field type="checkbox"
                                                    disabled={initialValue.concluido}
                                                    name="concluido"
                                                    className="form-check-input"
                                                    onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }}
                                                />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                            </div>


                                            <div className="col-12 col-lg-6">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio &&
                                                            <small className='text-body-tertiary'>Fecha Inicio: {values.fechaInicio}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaFin &&
                                                            <small className='text-body-tertiary'>Fecha Fin: {values.fechaFin}</small>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {
                !requiereCambioUbicacion && mostrarRecoleccion ? (
                    <RecoleccionDeUnidad handleConcluido={handleConcluido} />
                ) : null
            }
        </>
    )
}

export default UbicacionDeVehiculo;