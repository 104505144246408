import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Navigate, useNavigate } from 'react-router-dom'
import { chekItem, isOpenModalCambioProd, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';
import AuthLogin from '../Utils/AuthLogin';
import { useState } from 'react';
import { validateManySubModule, ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { AccionesRapidas, SubModule } from '../Auth/Authorization';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();
const InventarioSidebar = () => {
    const { visible } = useSelector((state) => state.changeMenu);
    const dispatch = useDispatch();
    const role = auth.getSesionValue("role");
    const navigate = useNavigate();
    const { cheked, item, isOpenModalClave, isOpenModalClaveCambioProd } = useSelector((state) => state.quickActionsSlice);
    const [isValid, setIsValid] = useState((role === "Compras" || role === "Administrador") ? true : false)
    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }

    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }
    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div id="lbl_Inventario_Sidebar" className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Inventario"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.Datamovil),
                        ValidatePermissionById(2, SubModule.SocioComercial),
                        ValidatePermissionById(2, SubModule.VehículoUtilitario),
                        ValidatePermissionById(2, SubModule.VentaContado)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "Uds" : "Unidades"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Datamovil)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/vehiculo" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title={`Datamovil`}>
                                <i className="icon-dark fa fa-car"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Datamovil</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SocioComercial)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/sociocomercial" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title={`Socio Comercial`}>
                                <i className="icon-dark bi bi-list-task"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Socio Comercial</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículoUtilitario)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/utilitario" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title={`Vehículo Utilitario`}>
                                <i className="icon-dark ri-shield-user-fill"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículo Utilitario</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VentaContado)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/venta" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Venta de contado">
                                <i className="icon-dark bx bx-purchase-tag-alt"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Venta de contado</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.InvUnidadesEnRemate)}
                    >
                        <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                            <NavLink to="/inventario/unidades-en-remate" className="nav-link collapsed text-dark">
                                <i className="icon-dark ri-roadster-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Unidades en Remate</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.PruebaMeManejo),
                        ValidatePermissionById(2, SubModule.VehículosSeparados),
                        ValidatePermissionById(2, SubModule.VehículosEnEnganche),
                        ValidatePermissionById(2, SubModule.InstalaciónDatamovil),
                        ValidatePermissionById(2, SubModule.FirmaDeContrato),
                        ValidatePermissionById(2, SubModule.GestoríaDeVehículos),
                        ValidatePermissionById(2, SubModule.Endoso),
                        ValidatePermissionById(2, SubModule.Entrega),
                        ValidatePermissionById(2, SubModule.Expediente),
                        ValidatePermissionById(2, SubModule.ClavesDemo)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "UPO" : "Unidades en Proceso de Originación"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PruebaMeManejo)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/pruebamanejo" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title={`Prueba de manejo`}>
                                <i className="icon-dark bi bi-card-checklist"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Prueba de manejo</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosSeparados)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/vehiculoseparado" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos separados">
                                <i className="icon-dark bx bx-notepad"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos separados</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosEnEnganche)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/enganche" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos en enganche">
                                <i className="icon-dark ri-money-dollar-circle-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos en enganche</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.InstalaciónDatamovil)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/instalaciones" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Instalación Datamovil">
                                <i className="icon-dark bi bi-wrench"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Instalación Datamovil</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.FirmaDeContrato)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/firmacontrato" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Firma de contrato">
                                <i className="icon-dark bx bx-list-check"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Firma de contrato</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.GestoríaDeVehículos)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/gestoria" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Gestoría de vehículos">
                                <i className="icon-dark bx bi-list-check"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Gestoría de vehículos</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Endoso)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/endoso" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Endoso">
                                <i className="icon-dark ri-file-text-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Endoso</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Entrega)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/entrega" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Entrega">
                                <i className="icon-dark ri-map-pin-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Entrega</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Expediente)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/expediente" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Expediente">
                                <i className="icon-dark bi bi-folder2"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Expediente</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ClavesDemo)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/clavesdemo" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title={`Claves Demo`}>
                                <i className="icon-dark bi bi-key"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Claves Demo</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.VehículosEnCrédito),
                        ValidatePermissionById(2, SubModule.HistorialDeContratos),
                        ValidatePermissionById(2, SubModule.VehículosConvertidos),
                        ValidatePermissionById(2, SubModule.RevisionUnidadRechazada),
                        ValidatePermissionById(2, SubModule.EliminarExterno),
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "HUM" : "Historial de Unidades/Movimientos"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosEnCrédito)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/vehiculosencredito" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos en crédito">
                                <i className="icon-dark bx bxs-box"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos en crédito</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.HistorialDeContratos)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/historialcontratos" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Historial de contratos">
                                <i className="icon-dark bi bi-file-earmark-text"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Historial de contratos</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.RevisionUnidadRechazada)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/revisionunidadrechazada" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Revision de unidad rechazada">
                                <i className="icon-dark bx bx-x-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Revision de unidad rechazada</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.VehículosConvertidos)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/vehiculosconvertidos" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Vehículos convertidos">
                                <i className="icon-dark bx bx-refresh"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Vehículos convertidos</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.EliminarExterno)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/eliminarexterno" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Eliminar externo">
                                <i className="icon-dark ri-close-circle-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Eliminar externo</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.ComisionesInventario)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "PUC" : "Pago de Unidades/comisiones"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ComisionesInventario)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/comisiones" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Comisiones">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Comisiones</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PagoUnidad)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/pagoUnidad/unidades-pendientes" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Pago de unidad">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Pago de unidad</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.ResguardoUnidad),
                        ValidatePermissionById(2, SubModule.AutorizacionDeSolicitudesInventario),
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "UR" : "Unidades Resguardadas"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission isActive={ValidatePermissionById(2, SubModule.ResguardoUnidad)}>
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/resguardo-unidad" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Resguardo de unidad">
                                <i className="icon-dark bx bxs-box"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Resguardo de unidad</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, SubModule.AutorizacionDeSolicitudesInventario)}>
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/autorizacion-solicitudes" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Autorización de solicitudes">
                                <i className="icon-dark bx bx-file"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Autorización de solicitudes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.SeguimientoValuacion)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "VA" : "Valuación"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.SeguimientoValuacion)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/inventario/valuacion" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Pago de unidad">
                                <i className="icon-dark bx bxs-car"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento de valuaciones</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, AccionesRapidas.CambioDeUbicaciones),
                        ValidatePermissionById(2, AccionesRapidas.NumSerieDatamovil),
                        ValidatePermissionById(2, AccionesRapidas.Responsiva),
                        ValidatePermissionById(2, AccionesRapidas.CambioDeEtapas),
                        ValidatePermissionById(2, AccionesRapidas.CambioAInventario),
                        ValidatePermissionById(2, AccionesRapidas.Costos),
                        ValidatePermissionById(2, AccionesRapidas.ConsultaDeClave),
                        ValidatePermissionById(2, AccionesRapidas.CambioProducto)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "AC" : "Acciones rápidas"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.CambioDeUbicaciones)}>
                        <li className={`nav-item ${cheked ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/cambioubicacion/${item?.generalId}`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cambio de ubicaciones">
                                <i className="icon-dark ri-map-pin-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cambio de ubicaciones</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.NumSerieDatamovil)}>
                        <li className={`nav-item ${item?.numSerieDatamovil ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/numeroserie/${item?.generalId}`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Num. Serie Datamovil">
                                <i className="icon-dark ri-booklet-fill"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Num. Serie Datamovil</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.Responsiva)} >
                        <li className={`nav-item ${item?.tipoCompra == "Inventario" ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/responsiva`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Responsiva">
                                <i className="icon-dark bx bx-file"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Responsiva</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.CambioDeEtapas)}>
                        <li className={`nav-item ${cheked ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/cambioetapa/${item?.generalId}`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cambio de etapas">
                                <i className="icon-dark bx bx-reset"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cambio de etapas</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.CambioAInventario)}>
                        {
                            isValid &&
                            <li className={`nav-item ${(item?.convertir) ? "" : 'disabled'}`}>
                                <Link onClick={() => { dispatch(isOpenModalCambioProd({ check: !isOpenModalClaveCambioProd })) }} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cambio a Inventario">
                                    <i className="icon-dark bi bi-clipboard-check"></i>
                                    <span className={`${visible ? "d-none" : "d-block"}`}>Cambio a Inventario</span>
                                </Link >
                            </li>
                        }
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.Costos)}>
                        <li className={`nav-item ${cheked ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/costos/${item?.generalId}`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Costos">
                                <i className="icon-dark ri-money-dollar-circle-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Costos</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.ConsultaDeClave)}>
                        <li className={`nav-item ${(cheked && item.consultaClave) ? "" : 'disabled'}`}>
                            <Link onClick={() => { dispatch(isOpenModalCheck({ check: !isOpenModalClave })) }} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Consulta de clave">
                                <i className="icon-dark bi bi-key-fill"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Consulta de clave</span>
                            </Link>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission isActive={ValidatePermissionById(2, AccionesRapidas.CambioProducto)}>
                        <li className={`nav-item ${(item?.aplicaCambioProducto) ? "" : 'disabled'}`}>
                            <NavLink to={`/inventario/cambioproducto/${item?.generalId}`} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Cambio producto">
                                <i className="icon-dark bx bx-box"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cambio producto</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                </ul>
            </aside>
        </>
    )
}

export default InventarioSidebar