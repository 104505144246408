import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Select from 'react-select'
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Field, Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import BuscadorGestoriaModal from '../../../Components/Modal/BuscadorGestoriaModal';
import RowPages from '../../../Components/RowPages';
import ModalBusquedaSiniestro from '../../Seguro/Siniestros/modalBusquedaSiniestro';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import ValuacionesServices from '../../../Services/Inventario/ValuacionesService';
import { clearScheduleByTipoCalendario } from '../../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { clearStateProcesoTramite } from '../../../Redux/Slice/reloadSlice';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../Auth/Authorization';


const SeguimientoGestoria = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const selectRef = useRef();
    const selectRef2 = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    let initial = {
        buscar: "",
        estatus: null,
        fechaInicio: "",
        fechaFin: "",
        procesoId: null,
        concluido: false,
        page: 1,
        rows: 10
    }
    const [tabInfo, setTabInfo] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [initialValue, setInitialValue] = useState(initial);
    const [openModalInfo, setOpenModalInfo] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    const [dataTipoTramite, setDataTipoTramite] = useState([]);
    const [filtroStatus, setFiltroStatus] = useState([]);
    const [data, setData] = useState([]);
    const [itemSelected, setItemSelected] = useState();
    const [dataFiltro, setDataFiltro] = useState({
        page: 1,
        rows: 10,
        buscar: "",
        tipoTramite: "",
        tipoSolicitud: "",
        estatus: "",
        //Filtor vanzado
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });
    const columns = [
        { field: 'folioId', headerName: 'Folio gestoría' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'numContrato', headerName: 'Contrato' },
        { field: 'tipoTramite', headerName: 'Tipo trámite' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'aNombreDe', headerName: 'A nombre de' },
        { field: 'tipoSolicitud', headerName: 'Tipo solicitud' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'subProceso', headerName: 'Sub Estatus' },
        { field: 'ultimaActualizacion', headerName: 'Última actualización' },
        { field: 'info', headerName: 'Info' },
    ];
    useEffect(() => {
        seguimientoGestoriaBusqueda();
        // setCurrenPage(1)
    }, [dataFiltro]);
    useEffect(() => {
        getTipoTramite();
        getListadoEstatus();
    }, [])
    const getTipoTramite = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoTramite')
            .then(resp => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.map(item => {
                    NewData.push({ value: item.TipoTramiteId, label: item.TipoTramite });
                });
                setDataTipoTramite(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    };
    const getListadoEstatus = async () => {
        await ValuacionesServices.getEstatus(7)
            .then(resp => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                resp.data.map(item => {
                    NewData.push({
                        value: item.valuacionStatusId,
                        label: item.estatus
                    });
                });
                setFiltroStatus(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleEdit = (item) => {
        dispatch(clearScheduleByTipoCalendario({ tipoCalendario: 10 }));
        dispatch(clearStateProcesoTramite());
        navigate(`/gestoria/seguimiento/${item.gestoriaRegistroInicialId}/${item.paso}`, {
            state: item
        });
    }
    const [pagina, setPagina] = useState(1);


    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setDataFiltro({
            ...dataFiltro,
            page
        })
        setCurrenPage(page);
        setPagina(page);
    };
    const getCounter = (tab) => {
        console.log(tabInfo[tab]);
        return tabInfo[tab]?.length || 0;
    };

    const seguimientoGestoriaBusqueda = async () => {
        setLoading(true);
        await Gestoria.getSeguimientoGestoria(dataFiltro)
            .then(resp => {
                let resdata = resp.data.data
                setData(resdata);
                setPageCount(resp.data.totalPages);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <BuscadorGestoriaModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleGetAll={seguimientoGestoriaBusqueda}
                    />
                }
            </Modal>
            <section>
                <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoGestoriaSection, SubModule.SeguimientoGestoria)?.Ver}>
                    <div className='pb-4'>
                        <section className='mx-4 my-4 d-flex flex-column'>
                            <div className="col-12 mt-2">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <strong className='mt-2'>Seguimiento a gestoría</strong>
                                    </div>
                                    <div className="col-auto">
                                        <button className='btn' onClick={(e) => { setModalMessage({ isOpen: true, type: 3, title: 'Buscador de VIN/CONTRATO' }) }}>
                                            Registrar trámite
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <section className='pb-4'>
                                <div className='row col-12'>
                                    <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <input type="text" value={dataFiltro.buscar} className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setDataFiltro((prev) => ({ ...prev, buscar: e.target.value })) }} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                        <Select ref={selectRef} options={dataTipoTramite} className='col-12' onChange={(e) => { setDataFiltro({ ...dataFiltro, tipoTramite: e.value }) }} placeholder="tipo de tramite" />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                        <Select ref={selectRef2} options={filtroStatus} className='col-12' onChange={(e) => { setDataFiltro({ ...dataFiltro, estatus: e.value }) }} placeholder="Estatus" />
                                    </div>
                                    <div className="col-12 col-md-auto my-1 px-1">
                                        <button className='btn btn-blue me-2' onClick={() => { setOpenModalAvanzado(true) }}>
                                            Búsqueda Avanzada
                                        </button>
                                        <button className='btn btn-blue' onClick={() => {
                                            selectRef.current.setValue("");
                                            selectRef2.current.setValue("");

                                            setDataFiltro({
                                                page: 1,
                                                rows: 10,
                                                buscar: "",
                                                tipoTramite: "",
                                                tipoSolicitud: "",
                                                estatus: "",
                                                //Filtor vanzado
                                                strAvanzado: "",
                                                fechaInicio: "",
                                                fechaFin: "",
                                                concluido: false,
                                                busquedaAvanzada: false
                                            })
                                        }}>
                                            Limpiar filtro
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <RowPages
                                setpageRows={(value) => { setDataFiltro({ ...dataFiltro, rows: value }) }}
                            />
                            <div className="row">
                                <div className='col-12'>
                                    <DataTable
                                        column={columns}
                                        data={data}
                                        detailable={ValidatePermissionById(4, Section.SeguimientoGestoriaSection, SubModule.SeguimientoGestoria)?.Ver}
                                        handleDetail={handleEdit}
                                        handleAction={(item) => { setItemSelected(item); setOpenModalInfo(true) }}
                                        pageCount={pageCount}
                                        currentPage={currenPage - 1}
                                        handlePageClick={handlePageClick}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </ValidatePermission>
            </section>
            {
                openModalAvanzado &&
                <ModalBusquedaSiniestro
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
                />
            }
            {
                openModalInfo &&
                <Modal isOpen={openModalInfo} color='#fff'>
                    <div className='row mx-2' style={{ width: '20rem' }}>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px', outline: 'none !important' }} onClick={() => {
                                setOpenModalInfo(false);
                            }}><i className="ri-close-fill"></i></button>
                        </section>
                        <section>
                            <h6 className=""><strong>Información de contrato/vehículo</strong></h6>
                            <div>
                                <label style={{ fontSize: '13px' }}><strong>A nombre de:</strong> {itemSelected.aNombreDe}</label> <br />
                                <label style={{ fontSize: '13px' }}><strong>Contrato:</strong> {itemSelected.numContrato}</label> <br />
                                <label style={{ fontSize: '13px' }}><strong>VIN:</strong> {itemSelected.vin}</label> <br />
                                <label style={{ fontSize: '13px' }}><strong>Marca:</strong> {itemSelected.nombreMarca}</label> <br />
                                <label style={{ fontSize: '13px' }}><strong>Modelo:</strong> {itemSelected.nombreModelo}</label> <br />
                                <label style={{ fontSize: '13px' }}><strong>Año:</strong> {itemSelected.nombreYear}</label>
                            </div>
                        </section>
                    </div>
                </Modal>
            }
        </>
    )
}
export default SeguimientoGestoria;