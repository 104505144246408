import React from 'react'
import Select from 'react-select';
import DataTable from '../../../Components/datatable/DataTable';
import RowPages from '../../../Components/RowPages';
import AgregarCobradorModal from './AgregarCobradorModal';
import SubMenuCobradores from './SubMenuCobradores';
import useCobradoresNivel2 from '../../../Hooks/Cobranza/useCobradoresNivel2';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'

const CobradoresNivel2 = () => {

   let {
      columns,
      currentData,
      pageCount,
      dataEstadoLaboral,
      dataEstatus,
      dataZonas,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      setActualizar,
      nivelDefault,
      setFiltroEstadoLaboral,
      setFiltroEstatus,
      setFiltroZonas
   } = useCobradoresNivel2();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <SubMenuCobradores />
         <section className='mx-5 py-4'>
            <div className='d-flex justify-content-between'>
               <span className='fs-5'><strong>Cobradores nivel 2</strong></span>
            </div>
         </section>
         <ValidatePermission isActive={ValidatePermissionById(4, Section.CobradoresNivel2, SubModule.Cobradores)?.Ver}>
            <section className='mx-4'>
               <div className='col-lg-4 col-sm-12 d-flex'>
                  <div className="col-6 mx-1  inner-addon right-addon">
                     <i className="glyphicon fas fa-search"></i>
                     <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                  </div>
                  <div className='col-5 d-flex'>
                     <Select options={dataEstatus} className='col-12 mx-1' onChange={(e) => { setFiltroEstatus(e.label) }} placeholder="Estatus" />
                     <Select options={dataEstadoLaboral} className='col-12 mx-1' onChange={(e) => setFiltroEstadoLaboral(e.label)} placeholder="Estado laboral" />
                     <Select options={dataZonas} className='col-12 mx-1' onChange={(e) => setFiltroZonas(e.label)} placeholder="Zonas" />
                  </div>
               </div>
            </section>
            <RowPages
               setpageRows={setpageRows}
            />
            <div className='mx-4 table-responsive'>
               <DataTable
                  column={columns}
                  data={currentData}
                  pageCount={pageCount}
                  detailable={ValidatePermissionById(4, Section.CobradoresNivel2, SubModule.Cobradores)?.Actualizar}
                  handleDetail={handleDetails}
                  paginate={true}
                  handlePageClick={handlePageClick}
                  currentPage={page - 1}
               />
            </div>
         </ValidatePermission>
         {
            isOpenModal && (
               <>
                  <AgregarCobradorModal
                     isOpen={isOpenModal}
                     setIsOpen={setIsOpenModal}
                     setAction={setActualizar}
                     nivelDefault={nivelDefault}
                  />
               </>)
         }
      </>
   )
}

export default CobradoresNivel2