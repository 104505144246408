import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import LiberacionUnidadService from '../../../../Services/Inventario/Valuacion/liberacionUnidadService';
import Llamada from '../../../../Services/Cartera/Llamadas';
import { set } from 'date-fns';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../Services/Catalogos/Catalogo';

const auth = new AuthLogin();
const CardLiberacionDeUnidad = ({ requiereCards }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);
    const [boolSelect, setBoolSelect] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    let initial = {
        siniestroLiberacionUnidadId: "",
        siniestroId: "",
        tipoUbicacion: null,
        nombreUbicacion: null,
        totalAdeudo: 0,
        calleNumero: null,
        codigoPostal: null,
        paisId: "",
        pais: null,
        edoMexicoId: 0,
        estado: null,
        municipioId: 0,
        municipio: null,
        coloniaId: 0,
        colonia: null,
        requiereGrua: false,
        comentarios: null,
        oficioLiberado: false,
        unidadCorralon: "",
        nombreCorralon: null,
        corralonCalleNumero: null,
        corralonCodigoPostal: null,
        corralonPaisId: "",
        corralonPais: null,
        corralonEdoMexicoId: 0,
        corralonEstado: null,
        corralonMunicipioId: 0,
        corralonMunicipio: null,
        corralonColoniaId: 0,
        corralonColonia: null,
        concluido: false,
        fechaInicio: "",
        fechaFin: null,
        subido: false,
        documentoId: null,
        idFolder: null,
        nombreDocumento: null
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const [tipoUbicacion, setTipoUbicacion] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);
    const [totalAdeudos, setTotalAdeudos] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);

    useEffect(() => {
        getPagoTramites();
    }, []);

    useEffect(() => {
        getEstados()
        getEstadosDos()
        handleGetTipoUbicacion();
    }, [])
    const handleGetTipoUbicacion = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoUbicacionLiberacionUnidad')
            .then(resp => {
                let items = resp.data.map(item => {
                    return {
                        value: item.TipoUbicacionId,
                        label: item.TipoUbicacion
                    }
                });
                setTipoUbicacion(items);
            })
            .catch(err => {

            });
    };
    const getData = async (totalAdeudo = null) => {
        await LiberacionUnidadService.getLiberacionunidadSiniestro(id)
            .then(resp => {
                if (resp.data.corralonPaisId === "00000000-0000-0000-0000-000000000000") {
                    resp.data.corralonPaisId = ""
                }
                if (resp.data.paisId === "00000000-0000-0000-0000-000000000000") {
                    resp.data.paisId = ""
                }

                if (resp.data.edoMexicoId !== 0) {
                    getMunicipios(resp.data.edoMexicoId);
                }
                if (resp.data.corralonEdoMexicoId !== 0) {
                    getMunicipiosDos(resp.data.corralonEdoMexicoId);
                }
                if (resp.data.municipioId !== 0) {
                    getColonias(resp.data.municipioId);
                }
                if (resp.data.corralonMunicipioId !== 0) {
                    getColoniasDos(resp.data.corralonMunicipioId);
                }
                resp.data.tipoUbicacion = resp.data.tipoUbicacionId ?? "";
                resp.data.oficioLiberado = resp.data.subido ?? false;
                resp.data.totalAdeudo = totalAdeudo ?? (totalAdeudos - totalPagos);
                resp.data.concluido = state?.concluido ? true : (resp.data.concluido ?? false);
                setInitialValue(resp.data)

                if (resp.data.concluido) {
                    if (resp.data.requiereGrua) {
                        requiereCards({ recoleccion: true, resguardo: false })
                    } else {
                        requiereCards({ recoleccion: false, resguardo: true })
                    }

                }

            })
            .catch(err => {
                toast.error(message('Error al obtener los datos'));
            });
    };

    const getPagoTramites = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesGet(id)
            .then((response) => {
                let adeudos = [];
                let totalAdeudos = 0;
                let totalPagos = 0;
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                response.data.adeudos.map((item, index) => {
                    item.orden = index + 1;
                    item.total = (item.subtotal * item.iva) / 100 + item.subtotal;
                    totalAdeudos += item.total;
                    adeudos.push(item);
                });
                //Actualizar los valores a formato moneda en data.adeudos
                // response.data.adeudos.map((item) => {
                //     item.subtotal = FormatNumberMoney2(item.subtotal);
                //     item.iva = FormatNumberMoney2(item.iva);
                //     item.total = FormatNumberMoney2(item.total);
                //     item.fechaAlta = getDateFormat(item.fechaAlta, "");
                //     item.fechaAdeudo = getDateFormat(item.fechaAdeudo, "");
                // });

                response.data.pagos.map((item, index) => {
                    totalPagos += item.monto;
                });
                setTotalAdeudos(totalAdeudos);
                setTotalPagos(totalPagos);
                let totalAdeudo = totalAdeudos - totalPagos;
                getData(totalAdeudo);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }
    const submitForm = async (values) => {
        setLoading(true)
        values.paso = 2
        values.porcentaje = 33
        values.pasoSubStatus = 3
        values.procesoId = state.procesoId

        await LiberacionUnidadService.updateLiberacionUnidadSiniestro(values)
            .then(res => {
                toast.success(message('Registro actualizado correctamente'));
                getData()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipios = async (value) => {
        if (value === undefined || value === "") {
            setMunicipios([]);
            setColonias([]);
            return;
        }
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined || value === "") {
            setColonias([]);
            return;
        }
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }

    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === "" || value === undefined) {
            setMunicipiosDos([]);
            setColoniasDos([]);
            return;
        }
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data?.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === "")
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }
    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eLibera/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroLiberacionUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    const validate = Yup.object().shape({
        tipoUbicacion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        nombreUbicacion: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        calleNumero: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        //Detalle de contacto
        codigoPostal: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        edoMexicoId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        municipioId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        coloniaId: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),

        requiereGrua: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        //   
        unidadCorralon: Yup.boolean()
            .nullable()
            .when("concluido", {
                is: true,
                then: Yup.boolean()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        nombreCorralon: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        corralonCalleNumero: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        corralonCodigoPostal: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        corralonEdoMexicoId: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        corralonMunicipioId: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
        corralonColoniaId: Yup.string()
            .nullable()
            .when(["concluido", "unidadCorralon"], {
                is: (concluido, unidadCorralon) => {
                    return (concluido && unidadCorralon);
                },
                then: Yup.string()
                    .nullable()
                    .required("El campo es requerido.")
            }),
    });
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseLiberacion" role="button" aria-expanded="false" aria-controls="collapseLiberacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Liberacion de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseLiberacion">
                                <div className="separator"></div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => submitForm(values)}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-4'>
                                                    <h6>
                                                        <strong>Ubicación de Unidad</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-md-6 col-lg-3">
                                                    {/* <TextField label="Tipo de ubicacion" disabled={initialValue?.concluido} name="tipoUbicacion" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("tipoUbicacion", event.target.value);
                                                    }} /> */}
                                                    <SelectField
                                                        label="Tipo de ubicacion"
                                                        name="tipoUbicacion"
                                                        items={tipoUbicacion}
                                                        disabled={initialValue?.concluido}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoUbicacion", event.value);
                                                        }} />
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <TextField label="Nombre de ubicacion" disabled={initialValue?.concluido} name="nombreUbicacion" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("nombreUbicacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <TextField disabled={true} isMoney={true} icon={"bi bi-currency-dollar"} label="Total adeudo" name="totalAdeudo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("totalAdeudo", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-md-6 col-lg-3">
                                                    <TextField disabled={initialValue?.concluido} label="Calle y numero" name="calleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("calleNumero", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <TextField disabled={initialValue?.concluido} label="Codigo postal" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("codigoPostal", event.target.value);
                                                    }} />
                                                </div>
                                                {/* <div className="col-2">
                                                    <TextField label="Pais" name="paisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("paisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                <div className="col-md-6 col-lg-3">

                                                    <SelectField id="edoMexicoId"
                                                        label="Estado"
                                                        name="edoMexicoId"
                                                        items={estados}
                                                        disabled={initialValue?.concluido}
                                                        onChange={(event) => {
                                                            setFieldValue("edoMexicoId", event.value);
                                                            getMunicipios(event.value);
                                                            setFieldValue("municipioId", "");
                                                            setFieldValue("coloniaId", "");
                                                        }} />

                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <SelectField id="municipioId"
                                                        label="Municipio"
                                                        name="municipioId"
                                                        items={municipios}
                                                        disabled={initialValue?.concluido}
                                                        onChange={(event) => {
                                                            setFieldValue("municipioId", event.value);
                                                            getColonias(event.value);
                                                            setFieldValue("coloniaId", "");
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-md-6 col-lg-3">
                                                    <SelectField
                                                        id=""
                                                        label="Colonia"
                                                        name="coloniaId"
                                                        items={colonias}
                                                        disabled={initialValue?.concluido}
                                                        onChange={(event) => {
                                                            setFieldValue("coloniaId", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='ms-4 col-4 my-4'>
                                                <h6>
                                                    <strong>Traslado de unidad</strong>
                                                </h6>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Se requiere Grua?"
                                                        name="requiereGrua"
                                                        disabled={initialValue?.concluido}
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereGrua", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='ms-4 col-4 my-4'>
                                                <h6>
                                                    <strong>Detalle de unidad liberada</strong>
                                                </h6>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12">
                                                    Oficio de liberación
                                                </div>
                                                <div className="col-auto">
                                                    <fieldset
                                                        disabled={initialValue?.concluido}
                                                        onClick={handleUploadFile}
                                                        className='btn btn-gray'>
                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField
                                                        label="comentarios"
                                                        className="form-control col-12"
                                                        rows="3"
                                                        disabled={initialValue?.concluido}
                                                        name="comentarios"
                                                        id="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={true} type="checkbox" name="oficioLiberado" onClick={(event) => {
                                                        setFieldValue("oficioLiberado", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Oficio de liberacion registrado en sistema</label>
                                                </div>
                                            </div>
                                            <div className='ms-4 col-4 my-4'>
                                                <h6>
                                                    <strong>Unidad detenida en corralón</strong>
                                                </h6>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-md-6 col-lg-3">
                                                    <SelectField
                                                        disabled={initialValue?.concluido}
                                                        label="¿Unidad en Corralon?"
                                                        name="unidadCorralon"
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadCorralon", event.value);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    values.unidadCorralon &&
                                                    <div className="col-md-6 col-lg-3">
                                                        <TextField disabled={initialValue?.concluido} label="Nombre del corralon" name="nombreCorralon" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("nombreCorralon", event.target.value);
                                                        }} />
                                                    </div>
                                                }

                                            </div>
                                            {
                                                values.unidadCorralon &&
                                                <>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-md-6 col-lg-3">
                                                            <TextField disabled={initialValue?.concluido} label="Calle y numero" name="corralonCalleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("corralonCalleNumero", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <TextField disabled={initialValue?.concluido} label="Codigo postal" name="corralonCodigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("corralonCodigoPostal", event.target.value);
                                                            }} />
                                                        </div>
                                                        {/* <div className="col-md-6 col-lg-3">
                                                    <TextField label="Pais" name="corralonPaisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("corralonPaisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                        <div className="col-md-6 col-lg-3">
                                                            <SelectField id="corralonEdoMexicoId"
                                                                label="Estado"
                                                                name="corralonEdoMexicoId"
                                                                items={estadosDos}
                                                                disabled={initialValue?.concluido}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonEdoMexicoId", event.value);
                                                                    getMunicipiosDos(event.value);
                                                                    setFieldValue("corralonMunicipioId", "");
                                                                    setFieldValue("corralonColoniaId", "");
                                                                }} />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <SelectField id="corralonMunicipioId"
                                                                label="Municipio"
                                                                name="corralonMunicipioId"
                                                                items={municipiosDos}
                                                                disabled={initialValue?.concluido}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonMunicipioId", event.value);
                                                                    getColoniasDos(event.value);
                                                                    setFieldValue("corralonColoniaId", "");
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-md-6 col-lg-3">
                                                            <SelectField
                                                                id=""
                                                                label="Colonia"
                                                                name="corralonColoniaId"
                                                                items={coloniasDos}
                                                                disabled={initialValue?.concluido}
                                                                onChange={(event) => {
                                                                    setFieldValue("corralonColoniaId", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad liberada</label>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="row flex-column align-items-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.fechaInicio !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default CardLiberacionDeUnidad