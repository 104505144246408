import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { TextField } from "../../../../Components/TextField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { useSelector } from "react-redux";
import { formatearMontoMx, FormatFecha } from "../../../../Utils/functions";

const AutorizacionDeValorDeUnidadProcesosCard = React.forwardRef(({  ...props}, ref) => {
   const {itemUnidadesRemate, nombreUsuario} = useSelector((state)=> state.UnidadesEnRemateSlice)
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const [loading, setLoading] = useState(false);
   const [precioVenta, setPrecioVenta]=useState(0)
   const [precioCompra, setPrecioCompra] = useState(0)
   const [modalMessage, setModalMessage] = useState({
      isOpen: false,
      type: 1,
      title: "",
      message: "",
      data: [],
      authorizationType: 2,
      Origin: "Listado",
   });

   useEffect(()=>{
      itemUnidadesRemate?.etapa?.map(etapa=>{
         if(etapa?.nombreEtapa.toLowerCase() === "enganche" && etapa?.enganche !== null){
            if(etapa?.enganche?.costo !== null){
               setPrecioCompra(etapa?.enganche?.costo)
            }
            if(itemUnidadesRemate?.valoracionUnidad !== null && itemUnidadesRemate?.valoracionUnidad?.valorMercado !== null){
               setPrecioVenta(itemUnidadesRemate?.valoracionUnidad?.valorMercado)
            }
         }
      })
   },[])

   let initial = {
      valor_unidad: itemUnidadesRemate?.valoracionUnidad === null ? 0 : itemUnidadesRemate?.valoracionUnidad?.valorUnidad === null ? 0 : formatearMontoMx(itemUnidadesRemate?.valoracionUnidad?.valorUnidad),
      unidad_inventario: itemUnidadesRemate?.valoracionUnidad === null || itemUnidadesRemate?.valoracionUnidad?.unidadParaInventario === null ? "No" : "Si",
      // usuario: itemUnidadesRemate.valoracionUnidad.usuario,
      fecha_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaOperacion === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaOperacion),
      hora_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion === null ? '--:--' :itemUnidadesRemate?.valoracionUnidad?.horaOperacion,
      comentario: itemUnidadesRemate?.valoracionUnidad === null ? '' : itemUnidadesRemate?.valoracionUnidad?.comentarios !== null ? itemUnidadesRemate?.valoracionUnidad?.comentarios : '',
      numero_solicitud: itemUnidadesRemate?.general?.numSolicitud === null ? " " : itemUnidadesRemate?.general?.numSolicitud,
      fecha_pago: itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaUpd === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaUpd),
   };
   const [initialValue, setInitialValue] = useState(initial);

   return (
      <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
         {modalMessage.type === 1 && (
            <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            />
         )}
         { modalMessage.type === 2 && ( <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} /> )}
      </Modal>
      <Toaster
         position="top-right"
         toastOptions={{
            success: {
            style: {
               background: "#47a066",
               color: "#FFFF",
               borderLeft: "10px solid #2f7246",
            },
            },
            error: {
            style: {
               background: "#d53f3f",
               color: "#FFFF",
               borderLeft: "10px solid #ac241a",
            },
            },
         }}
      />
         <div className="row wrapper-vehiculo">
            <div className="col-12">
               <div className="row mx-2 md-4 my-4">
                  <div className="white-wrapper mb-2">
                     <div
                        className="row my-2 px-4 "
                        data-bs-toggle="collapse"
                        href="#collapseAutorizacionValor"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseRegistroTramite"
                        onClick={() => setCollpsableGrua(!collpsableGrua)}
                     >
                     <div className="col-9">
                        <h6>
                           <strong>Autorización de Valor de Unidad</strong>
                        </h6>
                     </div>
                     <div className="col-3">
                        <div className="col-auto unidades-remate-icon btn btn-light secondary d-flex justify-content-end" style={{ textAlign: "end", alignContent: "end" }} >
                           <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                           <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2 d-flex align-items-center" : "icon-blue bx bx-chevron-up x2 d-flex align-items-center"}></i>
                        </div>
                     </div>
                  </div>
                  <div className="collapse" id="collapseAutorizacionValor">
                  <Formik
                     enableReinitialize={true}
                     initialValues={initialValue}
                     >
                     {({ isValid, values, setFieldValue}) => (
                        <>
                        <Form>
                           <div className="separator"></div>
                           <div className="my-3 row px-4" >
                              <div className="col-12 col-lg-3">
                                 <TextField label="Valoración de la unidad" name="valor_unidad" type="text" disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Unidad para inventario" name="unidad_inventario" type="text" disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Usuario" name="usuario" type="text" holder={nombreUsuario ?? ""} disabled={true} />
                              </div>
                              <div className="col-12 col-lg-3">
                                 <TextField label="Fecha de la operación" name="fecha_operacion" type="text" disabled={true} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4" >
                              <div className="col-12 col-lg-3">
                                 <TextField label="Hora de la operación" name="hora_operacion" type="text" disabled={true} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4">
                              <div className="col-6">
                                 <TextAreaField
                                    label="Comentarios"
                                    className="col-8 mt-2 form-control"
                                    rows="4"
                                    name="comentario"
                                    type="text"
                                    holder=""
                                    disabled={true}
                                 />
                              </div>
                           </div>
                        </Form>
                        </>
                     )}
                  </Formik>
                  </div>
               </div>
            </div>
         </div>
         </div>
      </>
   );
});
export default AutorizacionDeValorDeUnidadProcesosCard;