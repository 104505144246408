import React from 'react';
import Spinner from '../../../Components/Loadig';
import Modal from "../../../Components/Modal/Modal";
//import ErrorModal from '../../../Components/Modal/ErrorModal';
//import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef, useEffect } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux';
import { SelectField } from '../../../Components/SelectField';
import RegistrarZonaCobranzaModal from './RegistrarZonaCobranzaModal';
import ConsultarCodigoPostal from './ConsultarCodigoPostal';
import useAreasCobranza from '../../../Hooks/Cobranza/useAreasCobranza';
import { toast, Toaster } from 'react-hot-toast';
import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'

const AreasCobradores = () => {
    const refFormik = useRef();
    const dispatch = useDispatch();

    const {
        columns,
        data,
        currenPage,
        pageCount,
        loading,
        item,
        estados,
        currentState,
        setCurentState,
        selectedState,
        setselectedState,
        isOpenModalRegistrarZona,
        setIsOpenModalRegistrarZona,
        isOpenModalAsignarCP,
        setIsOpenModalAsignarCP,
        handleEdit,
        handleGetAll,
        dataUpdated,
        setDataUpdated,
        setItem,
        datosZonas,
        zonasExcluirEdicion,
        setZonasExcluirEdicion
    } = useAreasCobranza();

    const handleClickSelectedState = (label, value) => {
        setCurentState(label);
        setselectedState(value);
        // setItem({
        //     "item": {
        //         "estado": label
        //     }
        // });
        handleGetAll(label);

        if (value != "")
            setEstadoSeleccionado(true);
        else
            setEstadoSeleccionado(false);
    }

    useEffect(() => {
        if (!isOpenModalRegistrarZona && dataUpdated) {
            handleGetAll(currentState);
            setDataUpdated(false);
        }
        if (!isOpenModalRegistrarZona) {
            setItem(null);
        }
    }, [isOpenModalRegistrarZona, dataUpdated]);

    const [isOpenModalBusquedaCP, setIsOpenModalBusquedaCP] = useState(false);
    const [estadoSeleccionado, setEstadoSeleccionado] = useState(false);

    const [letraZonaCrear, setLetraZonaCrear] = useState("");

    // Ejemplos de uso
    // console.log(siguienteCombinacionExcel("")); // A
    // console.log(siguienteCombinacionExcel("A")); // B
    // console.log(siguienteCombinacionExcel("Z")); // AA
    // console.log(siguienteCombinacionExcel("AZ")); // BA
    // console.log(siguienteCombinacionExcel("ZZ")); // AAA
    const obtenerSiguienteZonaCrear = (combinacion) => {
        const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        // Si no hay combinación anterior, devuelve "A" como la inicial
        if (!combinacion) {
            return "A";
        }

        let resultado = '';
        let carry = true; // Para manejar el incremento como en una suma

        // Iterar la combinación en orden inverso
        for (let i = combinacion.length - 1; i >= 0; i--) {
            if (carry) {
                let index = letras.indexOf(combinacion[i]) + 1;
                if (index === letras.length) {
                    resultado = letras[0] + resultado; // Reiniciar a 'A' y mantener carry
                } else {
                    resultado = letras[index] + resultado;
                    carry = false;
                }
            } else {
                resultado = combinacion[i] + resultado;
            }
        }

        // Si aún hay carry después del bucle, significa que necesitamos una nueva letra al inicio
        if (carry) {
            resultado = letras[0] + resultado;
        }

        return resultado;
    }

    // Ejemplo de uso
    // const combinaciones = ["Z", "BD", "AB", "CA","ABF"];
    // console.log(obtenerUltimaCombinacion(combinaciones)); // BD
    const obtenerUltimaCombinacion = (lista) => {
        if (lista.length === 0)
            return "";
        // Ordenar la lista de combinaciones alfabéticamente con lógica de Excel
        lista.sort((a, b) => {
            const lenDiff = a.length - b.length; // Primero comparar por longitud
            if (lenDiff !== 0) {
                return lenDiff; // Si son de diferente longitud, el más corto va primero
            }
            return a.localeCompare(b);
        });
        // Obtener el último elemento
        return lista[lista.length - 1];
    }

    // Ejemplo de uso
    // const zonas = [
    //     "Zona A:Nuevo León: Monterrey",
    //     "Zona D: Nuevo León: Monterrey",
    //     "Zona C: Nuevo León: Monterrey",
    //     "Zona B: Nuevo León: Monterrey"
    // ];
    // const resultado = extraerCombinaciones(zonas);
    // console.log(resultado); // ["A", "D", "C", "B"]
    const extraerCombinaciones = (lista) => {
        const combinaciones = lista.map(item => {
            // Usar una expresión regular para encontrar la letra antes de ":"
            const match = item.match(/Zona ([A-Z])/);
            return match ? match[1] : null; // Devuelve la letra o null si no hay coincidencia
        });

        // Filtrar los elementos nulos y devolver el resultado
        return combinaciones.filter(combinacion => combinacion !== null);
    }

    const obtenerLetraZonaCrear = (zonasCreadas) => {
        let zonasExistentes = zonasCreadas.map(item => item.zonadecobranza);
        let letrasZonasCreadas = extraerCombinaciones(zonasExistentes);
        let letraUltimaZonaCreada = obtenerUltimaCombinacion(letrasZonasCreadas);
        let letraUltimaZonaACrear = obtenerSiguienteZonaCrear(letraUltimaZonaCreada);
        letraUltimaZonaACrear = "Zona " + letraUltimaZonaACrear + ": Estado: Municipio"
        setLetraZonaCrear(letraUltimaZonaACrear);
    }

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.ZonasDeCobranza, SubModule.ZonaDeCobranza)?.Ver}>
                            <section className='mx-3 py-4 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className='fs-5'><strong>Zonas de cobranza</strong></span>
                                    <div>
                                        <button className='btn px-4' onClick={() => {
                                            setIsOpenModalBusquedaCP(true);
                                            setItem({
                                                "item": {
                                                    "estado": currentState
                                                }
                                            });
                                        }}>Consultar código postal</button>
                                    </div>
                                </div>
                                <span className='mt-2'>
                                    Selecciona el estado para acceder a la configuración de las áreas de cobranza.
                                </span>
                            </section>
                            <div className="mx-4 my-2">
                                <div className="col-12 col-lg-2">
                                    <SelectField id="estadoSeleccionado" label="Estado" name="estadoSeleccionado" items={estados}
                                        onChange={(event) => {
                                            handleClickSelectedState(event.label, event.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <section className='mx-3 py-4' style={{ width: '900px' }}>
                                {
                                    (selectedState !== "" && selectedState !== 0) && (
                                        <>
                                            <div className='row' style={{ height: '50px' }}>
                                                <div className='col-7' style={{ alignContent: 'center' }}>
                                                    <span className='fs-5'><strong>Zonas de cobranza en: <span style={{ fontWeight: 900, color: "#000", textTransform: 'capitalize' }}>{currentState}</span></strong></span>
                                                </div>
                                                <div className='col-5 mw-100'>
                                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ZonasDeCobranzaDetalle, SubModule.ZonaDeCobranza)?.Agregar}>
                                                        <button className='btn' style={{ width: '100%', height: '100%' }} onClick={() => {
                                                            setIsOpenModalRegistrarZona(true)
                                                            setItem({
                                                                "item": {
                                                                    "estado": currentState
                                                                }
                                                            });
                                                            obtenerLetraZonaCrear(data);
                                                            let zonasExluir = data
                                                                .flatMap(x => x.item.zonaItems);
                                                            setZonasExcluirEdicion(zonasExluir);
                                                        }}>Registrar zona</button>
                                                    </ValidatePermission>
                                                </div>
                                            </div>
                                            <div className='mx-8 table-responsive'>
                                                <DataTable
                                                    column={columns}
                                                    data={data}
                                                    pageCount={pageCount}
                                                    detailable={ValidatePermissionById(4, Section.ZonasDeCobranzaDetalle, SubModule.ZonaDeCobranza)?.Actualizar}
                                                    handleDetail={handleEdit}
                                                    currentPage={currenPage - 1}
                                                />
                                            </div>
                                        </>)
                                }
                                {
                                    isOpenModalRegistrarZona && (
                                        <>
                                            <RegistrarZonaCobranzaModal
                                                isOpen={isOpenModalRegistrarZona}
                                                setIsOpen={setIsOpenModalRegistrarZona}
                                                item={item}
                                                setDataUpdated={setDataUpdated}
                                                setReasignarColonias={false}
                                                _zonasExcluirEdicion={zonasExcluirEdicion}
                                                nombreZonaCrear={letraZonaCrear}
                                            />
                                        </>)
                                }
                                {
                                    isOpenModalAsignarCP && (
                                        <>
                                            <RegistrarZonaCobranzaModal
                                                isOpen={isOpenModalAsignarCP}
                                                setIsOpen={setIsOpenModalAsignarCP}
                                                item={item}
                                                setDataUpdated={setDataUpdated}
                                                setReasignarColonias={true}
                                                datosZonas={datosZonas}
                                                _zonasExcluirEdicion={zonasExcluirEdicion}
                                            />
                                        </>)
                                }
                                {
                                    isOpenModalBusquedaCP && (
                                        <>
                                            <ConsultarCodigoPostal
                                                isOpen={isOpenModalBusquedaCP}
                                                setIsOpen={setIsOpenModalBusquedaCP}
                                                item={item}
                                                estadoSeleccionado={estadoSeleccionado}
                                                setDataUpdated={setDataUpdated}
                                                datosZonas={datosZonas}
                                            />
                                        </>)
                                }
                            </section>
                        </ValidatePermission>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default AreasCobradores;
