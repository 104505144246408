import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { TextField } from "../../../../Components/TextField";
import DataTable from "../../../../Components/datatable/DataTable";
import { useSelector } from "react-redux";
import { formatearFecha, formatearMontoMx, FormatFecha } from "../../../../Utils/functions";

const ValoracionInicialProcesosCard = React.forwardRef(({ ...props }, ref) => {
   const { itemUnidadesRemate, nombreUsuario, dataCobradores } = useSelector((state) => state.UnidadesEnRemateSlice)
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const [loading, setLoading] = useState(false);
   const [dataCostosReparacion, setDataCostosReparacion] = useState([])
   const [dataAdeudos, setDataAdeudos] = useState([])
   const [totalCostosReparacion, setTotalCostosReparacion] = useState(0)
   const [totalAdeudos, setTotalAdeudos] = useState(0)
   const [modalMessage, setModalMessage] = useState({
      isOpen: false,
      type: 1,
      title: "",
      message: "",
      data: [],
      authorizationType: 2,
      Origin: "Listado",
   });

   useEffect(() => {
      handleCR()
      handleAdeudos()
   }, [])

   const handleCR = () => {
      let DataCostosReparacion = []
      let totalCR = 0
      let counterCR = 1
      itemUnidadesRemate?.inspeccionUnidad?.reparaciones?.map(item => {
         let cobrador = dataCobradores?.filter(cob => {
            const match = cob?.id.toLowerCase() === item?.usuarioAlta.toLowerCase()
            return (match)
         })

         let iva = item?.total - item?.subtotal
         DataCostosReparacion.push(
            { numero: counterCR, horario: formatearFecha(item?.fechaAlta), ejecutivo: cobrador[0]?.nombre, concepto: item?.concepto, subtotal: formatearMontoMx(item?.subtotal), iva: `${iva} %`, total: formatearMontoMx(item?.total), subido: true },
         )
         counterCR++
         totalCR = totalCR + item?.total
      })

      setDataCostosReparacion(DataCostosReparacion)
      setTotalCostosReparacion(totalCR)
   }

   const handleAdeudos = () => {
      let DataAdeudos = []
      let totalAD = 0
      let counterAD = 1
      itemUnidadesRemate?.valuacionInicio?.valCostoAdicional?.map(item => {
         let cobrador = dataCobradores?.filter(cob => {
            const match = cob?.id.toLowerCase() === item?.usuarioAlta.toLowerCase()
            return (match)
         })

         let iva = item?.total - item?.subTotal
         DataAdeudos.push(
            { numero: counterAD, horario: formatearFecha(item?.fechaAlta), ejecutivo: cobrador[0]?.nombre, concepto: item?.concepto, subtotal: formatearMontoMx(item?.subTotal), iva: `${iva} %`, total: formatearMontoMx(item?.total), subido: true },
         )
         counterAD++
         totalAD = totalAD + item?.total
      })
      setDataAdeudos(DataAdeudos)
      setTotalAdeudos(totalAD)
   }

   let initial = {
      valor_inicial: itemUnidadesRemate?.valuacionInicio === null ? 0 : itemUnidadesRemate?.valuacionInicio?.total === null ? 0 : formatearMontoMx(itemUnidadesRemate?.valuacionInicio?.total),
      valor_mercado: itemUnidadesRemate?.valoracionUnidad === null ? 0 : itemUnidadesRemate?.valoracionUnidad?.valorMercado === null ? 0 : formatearMontoMx(itemUnidadesRemate?.valoracionUnidad?.valorMercado),
      valor_unidad: itemUnidadesRemate?.valoracionUnidad === null ? 0 : itemUnidadesRemate?.valoracionUnidad?.valorUnidad === null ? 0 : formatearMontoMx(itemUnidadesRemate?.valoracionUnidad?.valorUnidad),
      unidad_inventario: itemUnidadesRemate?.valoracionUnidad?.unidadParaInventario === null ? "No" : "Si",
      // usuario: nombre,
      fecha_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaOperacion === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaOperacion),
      hora_operacion: itemUnidadesRemate?.valoracionUnidad === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion
   };

   const [initialValue, setInitialValue] = useState(initial);

   const columnsCostosReparacion = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'horario', headerName: 'Fecha y hora', width: 200 },
      { field: 'ejecutivo', headerName: 'Ejecutivo', width: 250 },
      { field: 'concepto', headerName: 'Concepto', width: 200 },
      { field: 'subtotal', headerName: 'Subtotal', width: 120 },
      { field: 'iva', headerName: 'Iva', width: 120 },
      { field: 'total', headerName: 'Total', width: 120 },
      { field: 'acciones', headerName: 'Acciones', width: 200 },
   ];

   return (
      <>
         <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
         <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
            {modalMessage.type === 1 && (
               <ErrorModal
                  modalMessage={modalMessage}
                  setModalMessage={setModalMessage}
               />
            )}
            {modalMessage.type === 2 && (<SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} />)}
         </Modal>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: "#47a066",
                     color: "#FFFF",
                     borderLeft: "10px solid #2f7246",
                  },
               },
               error: {
                  style: {
                     background: "#d53f3f",
                     color: "#FFFF",
                     borderLeft: "10px solid #ac241a",
                  },
               },
            }}
         />
         <div className="row wrapper-vehiculo">
            <div className="col-12">
               <div className="row mx-2 md-4 my-4">
                  <div className="white-wrapper mb-2">
                     <div
                        className="row mt-2 px-4"
                        data-bs-toggle="collapse"
                        href="#collapseValoracionInicial"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseRegistroTramite"
                        onClick={() => setCollpsableGrua(!collpsableGrua)}
                     >
                        <div className="col-9">
                           <h6>
                              <strong>Valoración Inicial</strong>
                           </h6>
                        </div>
                        <div className="col-3">
                           <div className="col-auto unidades-remate-icon btn btn-light secondary d-flex justify-content-end" style={{ textAlign: "end", alignContent: "end" }} >
                              <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                              <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2 d-flex align-items-center" : "icon-blue bx bx-chevron-up x2 d-flex align-items-center"}></i>
                           </div>
                        </div>
                     </div>
                     <div className="collapse" id="collapseValoracionInicial">
                        <Formik
                           enableReinitialize={true}
                           initialValues={initialValue}
                        >
                           {({ isValid, values, setFieldValue }) => (
                              <>
                                 <Form>
                                    <div className="separator"></div>
                                    <div className="row mt-2 px-4">
                                       <div className="col-2 mt-4">
                                          <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle de valoración</strong></span></p>
                                       </div>
                                    </div>
                                    <div className="mb-3 row px-4" >
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Valoración inicial" name="valor_inicial" type="text" disabled={true} />
                                       </div>
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Valor de mercado" name="valor_mercado" type="text" disabled={true} />
                                       </div>
                                    </div>
                                    {/* {
                              dataCostosReparacion.length > 0 &&(
                                 <> */}
                                    <div className="row mt-2 px-4">
                                       <div className="col-2 mt-4">
                                          <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Costos de Reparación</strong></span>
                                          </p>
                                       </div>
                                    </div>
                                    <div className="row mt-2 px-4 mb-2">
                                       <div className="col-12">
                                          <DataTable
                                             column={columnsCostosReparacion}
                                             data={dataCostosReparacion}
                                             handleAction={() => { console.log("Por aplicar") }}
                                          />
                                       </div>
                                       <div className="row mt-2 px-4 mb-2 justify-content-end">
                                          <div className="col-auto">
                                             <small><strong>Total: {formatearMontoMx(totalCostosReparacion)}</strong></small>
                                          </div>
                                       </div>
                                    </div>
                                    {/* </>
                              )
                           } */}
                                    {/* {
                              dataAdeudos.length > 0 &&(
                                 <> */}
                                    <div className="row mt-2 px-4">
                                       <div className="col-2 mt-4">
                                          <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Adeudos</strong></span>
                                          </p>
                                       </div>
                                    </div>
                                    <div className="row mt-2 px-4 mb-2">
                                       <div className='mx-4 table-responsive'>
                                          <DataTable
                                             column={columnsCostosReparacion}
                                             data={dataAdeudos}
                                             handleAction={() => { console.log("Por aplicar") }}
                                          />
                                       </div>
                                       <div className="row mt-2 px-4 mb-2 justify-content-end">
                                          <div className="col-auto">
                                             <small><strong>Total: {formatearMontoMx(totalAdeudos)}</strong></small>
                                          </div>
                                       </div>
                                    </div>
                                    {/* </>
                              )
                           } */}
                                    <div className="mb-3 row px-4" >
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Valor final de la unidad" name="valor_unidad" type="text" disabled={true} />
                                       </div>
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Unidad para inventario" name="unidad_inventario" type="text" disabled={true} />
                                       </div>
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Usuario" name="usuario" holder={nombreUsuario ?? ""} type="text" disabled={true} />
                                       </div>
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Fecha de la operación" name="fecha_operacion" type="text" disabled={true} />
                                       </div>
                                    </div>
                                    <div className="mb-3 row px-4" >
                                       <div className="col-12 col-lg-3">
                                          <TextField label="Hora de la operación" name="hora_operacion" type="text" disabled={true} />
                                       </div>
                                    </div>
                                 </Form>
                              </>
                           )}
                        </Formik>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
});
export default ValoracionInicialProcesosCard;
