import React from "react"
import Modal from "../../../Components/Modal/Modal.jsx";
import Spinner from "../../../Components/Loadig.jsx";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard.jsx";
import ContratoCard from "../../../Components/ContratoCard.jsx";
import useUnidadesEnRemateDetalleProcesos from "../../../Hooks/GestionDeRemate/useUnidadesEnRemateDetalleProcesos.js";
import ValoracionInicialProcesosCard from "./Cards/ValoracionInicialCard.jsx";
import AutorizacionDeValorDeUnidadProcesosCard from "./Cards/AutorizacionDeValorDeUnidadCard.jsx";
import CierreDeRemateProcesosCard from "./Cards/CierreDeRemateCard.jsx";
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from "../../../Auth/Authorization";

const UnidadesEnRemateDetalleProcesos = () => {
   const {
      // vehiculo,
      // contrato,
      currentDataSeguimiento,
      loading,
      handleReturnPage
   } = useUnidadesEnRemateDetalleProcesos();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto' }}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <section className='headerTab'>
                  <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                     <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2"></i>
                        Regresar a unidades en remate
                     </div>
                  </header>
               </section>
               <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesEnRemateDetalle, SubModule.UnidadesEnRemate)?.Ver}>
                  <div className="col-11 col-sm-12 col-md-12 d-flex">
                     <div className="col-6 px-4 py-2">
                     <VehiculoInfoCard
                           GeneralId={currentDataSeguimiento?.vehiculo?.generalId}
                           VIN={currentDataSeguimiento?.vehiculo?.vin}
                           verClaves={true}
                           solicitarClave={false} />
                     </div>
                     <div className="col-6 px-4 py-2">
                        <ContratoCard vin={currentDataSeguimiento?.vehiculo?.vin} />
                     </div>
                  </div>
                  <div className="col-12 px-4 py-2">
                     <ValoracionInicialProcesosCard />
                  </div>
                  <div className="col-12 px-4 py-2">
                     <AutorizacionDeValorDeUnidadProcesosCard />
                  </div>
                  <div className="col-12 px-4 py-2">
                     <CierreDeRemateProcesosCard />
                  </div>
               </ValidatePermission>
            </div>
         </div>
      </>
   )
}

export default UnidadesEnRemateDetalleProcesos