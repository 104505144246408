import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useUnidadesEnRemateDetalleInventario = ()  =>{
    const {itemUnidadesRemate} = useSelector((state) => state.UnidadesEnRemateSlice);
    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState(itemUnidadesRemate);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleReturnPage = () => {
        navigate(`/inventario/unidades-en-remate`);
    }

    return{
        currentDataSeguimiento,
        loading,
        handleReturnPage
    }
}

export default useUnidadesEnRemateDetalleInventario;