import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Formik } from 'formik';
import Modal from '../../../../Components/Modal/Modal';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import UnidadesRecuperadasService from '../../../../Services/Cartera/UnidadesRecuperadas';
import * as Yup from 'yup';
import { Proceso } from '../../../../Utils/constan';

const SolicitudEntregaUnidadModal = ({ isOpen, setIsOpen, data, toast, handleGetAll }) => {
  const refFormik = useRef();
  const [resultado, setResultado] = useState('');
  const [motivoId, setMotivoId] = useState('');
  const [motivoItems, setmotivoItems] = useState([]);
  const userId = sessionStorage.getItem('userId')
  const usuario = sessionStorage.getItem('usuario')



  const validationSchema = Yup.object({
    message: Yup.string()
      .required("Comentario es requerido")
      .test(
        'min-length-if-not-empty',
        'El comentario debe tener al menos 20 caracteres',
        function (value) {
          // Si el valor no está vacío, debe tener al menos 20 caracteres
          return !value || (value && value.length >= 20);
        }),
    resultado: Yup.string().required('Resultado es requerido'),
    motivo: Yup.string().when('resultado', {
      is: '2', // Aquí verificas si el valor de 'resultado' es 2
      then: Yup.string().required('Motivo es requerido'), // Si es 2, motivo es requerido
      otherwise: Yup.string().notRequired() // Si no es 2, motivo no es requerido
    })

  })


  const handleRechazar = () => {
    setIsOpen(false);
  };

  const handlerGuardar = async (values) => {
    // Verifica que data no sea undefined
    if (!data) {
      console.error("No se han proporcionado datos para el modal.");
      return;
    }

    let requestData = {
      anyObject: {
        id: data.id,
        id_cita: data.id_cita,
        contrato: data.contrato,
        vin: data.vin,
        marca: data.marca,
        modelo: data.modelo,
        anio: data.anio,
        cobradorid: data.cobradorid,
        fechacita: data.fechacita,
        ubicacion: data.ubicacion,
        estatus: (Number(values.resultado) + 1),
        comentarios: values.message,
        motivo: values.motivo,
        motivoid: motivoId
      }
    }


    const dateObj = new Date(data.fechacita);

    // Obtener la fecha (sin la hora)
    const dateOnly = dateObj.toISOString().split('T')[0];  

    const hours = dateObj.getHours().toString().padStart(2, '0'); 
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const hora = `${hours}:${minutes}`; 

    const intento = 
    {
      id:  data.folio_citaentrega ,
      opcion: values.resultado === '1' ? "Aprobar" : "Rechazar"
    }


    let autnom = values.resultado === '1' ? "APROBADA" : "RECHAZADA";

    const correo_ampliacion = {
      usuario: usuario,
      contrato: data.contrato,
      autorizacion: autnom
    };

    const notificacion = {
      anyObject: {
        usuarioid: userId, //El usuario que inicio sesión
        modulo: "pago_unidad", //pantalla origen
        tipocorreo: "Autorización de Cita Entrega de Unidad",
        data: JSON.stringify(correo_ampliacion), //Objeto JSON que se va a usar en la notificacion 
        enviado: 0 //Cuando se crea va en 0
      }
    }


    await UnidadesRecuperadasService.actualizaCitaEntrega(intento)
    const response = await UnidadesRecuperadasService.upsertCitasEntregaUnidadxVehiculo(requestData)
    await UnidadesRecuperadasService.createNotificacionesCobranza(notificacion)

    if (response.status === 200) {
      const successMessage = response.data?.mensaje || "¡Operación exitosa!";
      toast.success(successMessage);
      setIsOpen(false); // Cierra el modal si todo va bien
      handleGetAll()
    } else {
      console.error('Error al enviar los datos:', response);
    }
  };

  const HandleGetMotivos = async () => {
    await Siniestros.GetMotivosById(13, Proceso.Cobranza)
      .then(resp => {
        let newData = [];
        resp.data.forEach(item => {
          newData.push({
            value: item.CitaMotivoRechazoId,
            label: item.CitaMotivoRechazo
          });
        });

        setmotivoItems(newData);
      })
      .catch(err => {
      });
  };

  // useEffect para llamar a HandleGetMotivos cuando el resultado cambie
  useEffect(() => {
    if (resultado === '2') { // Si se selecciona "Rechazar"
      HandleGetMotivos(); // Carga los motivos
    } else {
      setmotivoItems([]); // Limpiamos los motivos si no es "Rechazar"
    }
  }, [resultado]); // Se ejecuta cuando cambia el valor de resultado

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={refFormik}
        initialValues={{
          resultado: '', // Valor inicial de 'resultado'
          motivo: '', // Agrega motivo al initialValues
          message: '' // Agrega message al initialValues
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlerGuardar(values); // Envia los datos al servicio al confirmar
        }}
        validateOnMount
        validateOnChange={true} // Valida mientras el usuario escribe
        validateOnBlur={true} // Valida al salir del campo
      >
        {({ isValid, setFieldValue, handleSubmit }) => (
          <Modal isOpen={isOpen} color='#fff' width={500}>
            <div>
              <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                  <div className="col col-12 dias-festivos-modal-underline">
                    <div>
                      <h4><strong>Solicitud cita entrega de unidad</strong></h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-6 mt-4">
                    <SelectField
                      id="resultado"
                      label="Resultado"
                      name="resultado"
                      items={[
                        { label: "Aprobar", value: "1" },
                        { label: "Rechazar", value: "2" }
                      ]}
                      onChange={(option) => {
                        setResultado(option.value); // Actualiza el estado de resultado
                        setFieldValue("resultado", option.value);
                      }}
                    />
                  </div>
                  {resultado === "2" && (
                    <div className="col-lg-6 col-6 mt-4">
                      <SelectField
                        id="motivo"
                        label="Motivo"
                        name="motivo"
                        items={motivoItems} // Items cargados desde el servicio
                        onChange={(option) => {
                          setFieldValue("motivo", option.label); // Actualiza el valor del Formik
                          setMotivoId(option.value)
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-12 mt-4" style={{ display: 'flex', flexFlow: 'column' }}>
                  <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribir" />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnCloseModal"
                      className='btn btn-outline btn-block'
                      onClick={handleRechazar}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnConfirmModal"
                      className='btn btn-blue btn-block'
                      onClick={handleSubmit}
                      disabled={!isValid}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default SolicitudEntregaUnidadModal;
