import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useEffect, useRef, useState } from "react";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import Spinner from "../../../Components/Loadig";
import Modal from "../../../Components/Modal/Modal";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import * as Yup from 'yup';
import ServicioExpedientes from "../../../Services/ProcesosCierre/Expedientes";
import toast from "react-hot-toast";
import { message, getDateNow, FormatFecha, formatearMontoMx, TextMoneyToNumber  } from "../../../Utils/functions";
import ValuacionesServices from "../../../Services/Inventario/ValuacionesService";
import DataTable from '../../../Components/datatable/DataTable';

const HistorialDetalleSolicitudesModal = ({ isOpen, setIsOpen,generalid, actualizarCardTramite }) => {
    const columns = [
        { field: 'operacion', headerName: 'Operación' },
        { field: 'usuario', headerName: 'Usuario' },
        { field: 'motivo', headerName: 'Motivo' },
        { field: 'fecha', headerName: 'Fecha' },
    ];

    const [currentData, setCurrentData] = useState([])

    const handleRechazar = () => {
        setIsOpen(false)
    }
    const handleGetDirecciones = async () => {
        try {
            
            
            const res = await ServicioExpedientes.getAllOperacionesExpediente(generalid);
            if (res.data) {
                let datosFormateados = res.data.map(item => ({
                    operacion: item.operacion || "N/A",
                    usuario: item.usuarioSolicito || "N/A",
                    motivo: item.area || "N/A",
                    fecha: FormatFecha(item.fechaSolicito) || "N/A",
                    message: item.comentarios || "-"
                }));
    
                setCurrentData(datosFormateados);
            } else {
                setCurrentData([]); // Manejo de caso sin datos
            }
        } catch (error) {
            console.error("Error al obtener direcciones:", error);
            setCurrentData([]);
        }
    };

    useEffect(() => {
        handleGetDirecciones()
    }, [actualizarCardTramite])

    return (
        <Modal isOpen={isOpen} color='#fff' width={800}>
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                    onClick={() => handleRechazar()}><i className="ri-close-fill"></i></button>
            </section>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h5><strong>Historial de Operaciones</strong></h5>
                    </div>
                </div>
              
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <DataTable
                            column={columns}
                            data={currentData}
                            commentColumn={true}
                        />
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button
                                type='reset'
                                id="AT_BtnCloseModal"
                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => handleRechazar()}
                            >
                                Cerrar
                            </button>
                        </div>

                    </div>
                </section>
            </div>
        </Modal>
    )
}
export default HistorialDetalleSolicitudesModal;