import React, { useState, useRef } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import useUnidadesRecuperadas from '../../../Hooks/UnidadesRecuperadas/UnidadesRecuperadas'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import '../SeguimientoVisita/SeguimientoVisita/SeguimientoVisita.css'
import ModalBusquedaUnidadesRecuperadas from './Cards/ModalBusquedaUnidadesRecuperadas'
import { Formik, Field } from 'formik';

const UnidadesRecuperadas = () => {
    const {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        zonas,
        setpageRows,
        setZonaSeleccionada,
        tipoRecuperacionSelect,
        setTipoRecuperacion,
        estatusSelect,
        setEstatus,
        dataFiltro,
        setDataFiltro,
        openModalAvanzado,
        setOpenModalAvanzado,
        textoBusqueda
    } = useUnidadesRecuperadas();

    const refFormik = useRef();

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {
                openModalAvanzado &&
                <ModalBusquedaUnidadesRecuperadas
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
                    dataFiltro={dataFiltro}
                    checkConcluido={true}
                />
            }
            <Formik
                innerRef={refFormik}
                initialValues={{
                    buscar: "",
                    cobradorSeleccionado: "",
                    page: 1,
                    rows: 10,
                }}
                onSubmit={(values) => {
                    // Handle form submission logic here
                }}
            >
                {({ setFieldValue }) => (
                    <>
                        <section className='mx-3 py-4 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className='fs-5'><strong>Unidades Recuperadas</strong></span>
                            </div>
                        </section>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas)?.Ver}>
                            <section className=''>
                                <div className='row col-12 px-4'>
                                    <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <Field
                                            type="text"
                                            name="buscar"
                                            className="form-control mb-4"
                                            placeholder="Buscar"
                                            value={textoBusqueda}
                                            onChange={(e) => {
                                                setTextoBusqueda(e.target.value)
                                                setFieldValue('buscar', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                    <Select
                                     id="tipoRecuperacion"
                                            name="tipoRecuperacion"
                                            options={tipoRecuperacionSelect} 
                                            className='col-12 mx-1'
                                            placeholder="Tipo de recuperación"
                                            onChange={(option) => {
                                                setFieldValue('tipoRecuperacion', option.value);
                                                setTipoRecuperacion(option.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                                    <Select
                                     id="estatusSelect"
                                            name="estatusSelect"
                                            options={estatusSelect} 
                                            // className='col-12 mx-1'
                                            placeholder="Estatus"
                                            onChange={(option) => {
                                                setFieldValue('estatusSelect', option.value);
                                                setEstatus(option.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-auto my-1 px-1">
                                        <button className='btn btn-blue me-2'
                                            onClick={() => { setOpenModalAvanzado(true) }}>
                                            Búsqueda Avanzada
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-auto my-1 px-1">
                                        <button className='btn btn-blue mx-2' onClick={() => {
                                            setDataFiltro({
                                                strAvanzado: "",
                                                fechaInicio: "",
                                                fechaFin: "",
                                                concluido: false,
                                                busquedaAvanzada: false
                                            })
                                            setTipoRecuperacion('todos');
                                            setEstatus('todos');
                                            setFieldValue('estatusSelect', "");
                                            setFieldValue('tipoRecuperacion', "");
                                        }}>
                                            Limpiar filtro
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <RowPages setpageRows={setpageRows} />
                            <div className='mx-4 table-responsive'>
                                <DataTable
                                    column={columns}
                                    data={currentData}
                                    detailable={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas)?.Ver}
                                    handleDetail={handleDetails}
                                    paginate={true}
                                    pageCount={pageCount}
                                    handlePageClick={handlePageClick}
                                    currentPage={currentPage}
                                />
                            </div>
                        </ValidatePermission >
                    </>
                )}
            </Formik >
        </>
    )
}

export default UnidadesRecuperadas