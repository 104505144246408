export const Proceso = {
    Valuacion:1,
    Siniestro:2,
    CambioPropietario:3,
    SeguimientoCierre:4,
    ServicioDatamovilSinOrden:5,
    ServicioDatamovilConOrden:6,
    Gestoria:7,
    Cobranza:8
}
