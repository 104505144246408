import { useEffect, useRef, useState } from "react";
import Solicitudes from "../../Services/ProcesosCierre/Solicitudes";
import toast from "react-hot-toast";
import { FormatFecha, message } from "../../Utils/functions";

const AmpliacionExpedientesHook = () => {
    const refFormik = useRef();
    let initial = {
        buscar: ''
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const columns = [
        { field: 'folio', headerName: 'Folio Expediente', widht: '150px' },
        { field: 'vin', headerName: 'VIN', widht: '150px' },
        { field: 'contrato', headerName: 'Contrato', widht: '150px' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', widht: '150px' },
        { field: 'motivoAmpliacion', headerName: 'Motivo ampliación', widht: '150px' },
        { field: 'fechaAmpliacionFormat', headerName: 'Fecha ampliación', widht: '150px' },
        { field: 'comentariosUI', headerName: 'Comentarios', widht: '150px' },
    ];

    useEffect(() => {
        getListado({});
    }, []);

    useEffect(() => {
        getListado({});
    }, [buscar, page, rows]);

    const getListado = async () => {
        setLoading(true);

        const params = { buscar, page, rows }
        await Solicitudes.getAmpliacionesExpediente(params)
        .then(resp => {
            if (resp.data.statusCode === 204){
                toast.error(message(resp.data.message));
                return 
            }

            let items = resp.data.data.map(item => {
                const FechaFormat = (item.fechaAmpliacion && item.fechaAmpliacion !== "1900-01-01T00:00:00") ? FormatFecha(item.fechaAmpliacion) : '-'                

                item.comentariosUI = item.comentariosAmpliacion;
                item.fechaAmpliacionFormat = FechaFormat

                return item;
            });

            setData(items);
            setTotalRows(resp.data.totalRows);
        })
        .catch(err => {
            toast.error(message('Error al obtener el listado'));
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleDetail = (item) => {
        setSelectedData(item)
        setModalMessage({ isOpen: true, title: 'Solicitud de ampliación expediente', type: 1 });
    }

    const handlePageClick = (numPage) => {
        setPage(numPage.selected + 1);
    }

    return {
        refFormik,
        initialValue,
        loading,
        setLoading,
        columns,
        data,
        page,
        totalRows,
        rows,
        setRows,
        buscar,
        setBuscar,
        setModalMessage,
        modalMessage,
        handleDetail,
        handlePageClick,
        selectedData,
        getListado
    }
}

export default AmpliacionExpedientesHook;