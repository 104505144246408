import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoGestoriaService from '../../../Services/Gestoria/SeguimientoGestoria';
import { message } from '../../../Utils/functions';
import { toast, Toaster } from "react-hot-toast";

const useProcesoTramite = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    const [stepe, setStepe] = useState([]);
    const [stepSelected, setstepSelected] = useState();
    const [loading, setloading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [reload, setReload] = useState(0);
    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar]);

    const handleGetAllStep = async () => {
        setloading(true);
        await SeguimientoGestoriaService.getAllStepe(id, state?.procesoId)
            .then((res) => {
                let step = res.data.data;
                console.log("paso--z<",step)
                step.forEach(x => {
                    if (x.pasoSubStatus === 2) {
                        setstepSelected(x);
                    }
                });
                setStepe(step);
                setloading(false);
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };

    const handleClickTerminar = () => {
        setModalConfirm(true);
    };

    const handleClickTerminarProceso = async () => {
        setModalConfirm(false);
        setloading(true);
        let data = {
            id,
            paso: stepSelected.paso,
            porcentaje: stepSelected.porcentaje,
            pasoSubStatus: 3,
            procesoId: state.procesoId
        };
       
        await SeguimientoGestoriaService.updStep(data)
            .then((res) => {
                console.log("res",res)
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    console.log("paso actual",stepSelected)
                    if (stepSelected?.paso !== 4)
                        navigate(`/gestoria/seguimiento/${id}/${stepSelected?.paso + 1}`, {
                            state: { ...state }
                        });
                    else
                        navigate(`/gestoria/seguimiento`);
                } else {
                    toast.error(message("¡Operación fallida!", res.data.data.mensaje));
                }
                setloading(false);
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };

    const handleChangeStatusBtn = (item) => {
        console.log(item)
        if (item?.concluido) {
            setactivebtncontinuar(true);
        }
        if(item?.paso === 3)
            setReload(item?.reload + 1);
    };

    return {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state,
        reload
    };
};

export default useProcesoTramite;
