import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { TextField } from "../../../../Components/TextField";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import HistorialCitasProcesosModal from "../HistorialCitasModal";
import CajaDeComentariosProcesosModal from "../CajaDeComentariosModal";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { formatearMontoMx, FormatFecha, message, NumFolio } from "../../../../Utils/functions";
import UnidadesRemateService from "../../../../Services/Inventario/UnidadesEnRemateService";
import { setItemUnidadesRemate } from "../../../../Redux/Slice/UnidadesEnRemateSlice";
import { useNavigate } from "react-router-dom";
import { setIdDataDocs } from "../../../../Redux/Slice/ElementosDataDocsSlice";
import { v4 as uuidv4 } from 'uuid';
import { selectScheduleByTipoCalendario, setFields, clearScheduleByTipoCalendario } from "../../../../Redux/Slice/Calendario/calendarDynamicalSlice";


const CierreDeRemateProcesosCard = React.forwardRef(({  ...props}, ref) => {
   const dispatch = useDispatch();
   const {itemUnidadesRemate, nombreUsuario} = useSelector((state)=> state.UnidadesEnRemateSlice)
   // const { schedules } = useSelector((state)=> state.calendarDynamicalSlice)
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const [loading, setLoading] = useState(false);
   const [modalHistorial, setModalHistorial] = useState(false)
   const [modalComentarios, setModalComentarios] = useState(false)
   const [precioVenta, setPrecioVenta]=useState(0)
   const [precioCompra, setPrecioCompra] = useState(0)
   const [initialValueRemate, setInitialValueRemate] = useState([]);
   const userId = sessionStorage.getItem('userId').trim()
   const [listaComentarios, setListaComentarios]=useState([])
   const [remateId, setRemateId] = useState("")
   const navigate = useNavigate();
   const scheduleDataCita = useSelector((state) => selectScheduleByTipoCalendario(state, 8));
   const tipocalendario = 8; //SOLO ES DATO PARA PRUEBAS, AUN NO SE ESTABLECE UN TIPO DE CALENDARIO

   const [modalMessage, setModalMessage] = useState({
      isOpen: false,
      type: 1,
      title: "",
      message: "",
      data: [],
      authorizationType: 2,
      Origin: "Listado",
   });

   let initialRemate = {
      estatus_cierre: "Pendiente",
      horario_firma: `${itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaOperacion === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaOperacion)}
         ${itemUnidadesRemate?.valoracionUnidad === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion}`,
      // nombre_ejecutivo: itemUnidadesRemate.valoracionUnidad.usuario,
      numero_solicitud: itemUnidadesRemate?.general?.numSolicitud === null ? " " : itemUnidadesRemate?.general?.numSolicitud,
      fecha_pago: `${itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaUpd === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaUpd)}`,
      id_devolucion: "",
      realizar_devolucion: "",
      monto_devolucion: "",
      estatus_devolucion: ""
   };

   let initialCita = {
      fecha_hora_cita: "",
      // horario_cita:"",
      estatus_cita: "",
      ubicacion: "",
      cita_agendada: false,
      nuevo_comentario: "",
      proceso_concluido: false
   };

   useEffect(()=>{
      if(scheduleDataCita.sucursalName){
         setInitialValueCita({
            fecha_hora_cita: `${scheduleDataCita.day} ${scheduleDataCita.hour}`,
            // horario_cita:"",
            estatus_cita: "",
            ubicacion: scheduleDataCita.sucursalName,
            cita_agendada: false,
            nuevo_comentario: "",
            proceso_concluido: false
         })
      }
      else{
         setInitialValueCita(initialCita)
      }
   },[])

   const [initialValueCita, setInitialValueCita] = useState(initialCita);

   useEffect(()=>{
      let precioventa = 0
      let preciocompra = 0
      if(itemUnidadesRemate?.remate !== null){
         setRemateId(itemUnidadesRemate?.remate?.id)
         if(itemUnidadesRemate?.remate?.comments !== null){
            setListaComentarios(itemUnidadesRemate?.remate?.comments)
         }
      }
      
      console.log(itemUnidadesRemate)
      itemUnidadesRemate?.etapa?.map(etapa=>{
         if(etapa?.nombreEtapa.toLowerCase() === "enganche" && etapa?.enganche !== null){
            if(etapa?.enganche?.costo !== null){
               setPrecioCompra(etapa?.enganche?.costo)
               preciocompra = etapa?.enganche?.costo
            }
            if(itemUnidadesRemate?.valoracionUnidad !== null && itemUnidadesRemate?.valoracionUnidad?.valorMercado !== null){
               setPrecioVenta(itemUnidadesRemate?.valoracionUnidad?.valorMercado)
               precioventa = itemUnidadesRemate?.valoracionUnidad?.valorMercado
            }
            // initialRemate.monto_devolucion = precioVenta <= 0 ? precioCompra : precioVenta - precioCompra 
         }
      })

      if(itemUnidadesRemate?.remate !== null){
         setInitialValueRemate({
            estatus_cierre: "Pendiente",
            horario_firma: `${itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaOperacion === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaOperacion)}
            ${itemUnidadesRemate?.valoracionUnidad === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion === null ? '--:--' : itemUnidadesRemate?.valoracionUnidad?.horaOperacion}`,
            numero_solicitud: itemUnidadesRemate?.general?.numSolicitud === null ? " " : itemUnidadesRemate?.general?.numSolicitud,
            fecha_pago:  itemUnidadesRemate?.valoracionUnidad === null ? '----/--/--' : itemUnidadesRemate?.valoracionUnidad?.fechaUpd === null ? '----/--/--' : FormatFecha(itemUnidadesRemate?.valoracionUnidad?.fechaUpd),
            id_devolucion: itemUnidadesRemate?.remate === null || itemUnidadesRemate?.remate?.realizar_devolucion === null || itemUnidadesRemate?.remate?.realizar_devolucion === false ? 2 : 1,
            realizar_devolucion: itemUnidadesRemate?.remate === null || itemUnidadesRemate?.remate?.realizar_devolucion === null || itemUnidadesRemate?.remate?.realizar_devolucion === false ? false : true,
            monto_devolucion: itemUnidadesRemate?.remate === null ? 0 : itemUnidadesRemate?.remate?.monto_a_devolver === null ? 0 : itemUnidadesRemate?.remate?.monto_a_devolver,
            estatus_devolucion: itemUnidadesRemate?.remate === null ? '' : itemUnidadesRemate?.remate?.status === null ? '': itemUnidadesRemate?.remate?.status,
            proceso_concluido: false
         })
      }
      else{

         let montoDevolucion = precioventa <= 0 ? preciocompra : precioventa - preciocompra;
         setInitialValueRemate({...initialRemate, monto_devolucion: montoDevolucion })
      }
   },[itemUnidadesRemate])

   const dataOpcionSiNo = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const validateRemate = Yup.object({
      realizar_devolucion: Yup.string().required("El campo es requerido."),
      monto_devolucion: Yup.number().required("El campo es requerido.").positive("Ingrese un valor mayor a cero."),
      estatus_devolucion: Yup.string().required("El campo es requerido."),
   });

   const validateCita = Yup.object({
      fecha_hora_cita: Yup.string().required("El campo es requerido."),
      // horario_cita: Yup.string().required("El campo es requerido."),
      estatus_cita: Yup.string().required("El campo es requerido."),
      ubicacion: Yup.string().required("El campo es requerido.")
   });

   const handleGuardarRemate = async(values, actions) =>{
      setLoading(true)
      let data = 
         {
            "verb": "string",
            "id": "remates",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject":
            {
               id: itemUnidadesRemate.valuacionId,
               realizar_devolucion: values.realizar_devolucion === 1 ? true : false,
               monto_a_devolver: parseFloat(values.monto_devolucion),
               status: values.estatus_devolucion,
               comentarios: null,
               proceso_concluido: values.proceso_concluido === true ? true : false
            },
            "kvp":{}
         }

      await UnidadesRemateService.CreateValuacionUnidadesRemate(data)
         .then(res=>{
            toast.success(message("Registro realizado correctamente"));
            handleUpdate()
         })
         .catch(e => {
            setLoading(false)
            if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
            else
            toast.error(message("¡Error!", e.message));
         })
   }

   function getSqlServerDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
   }

   const handleGuardarCita = async(values, actions) =>{
      setLoading(true)
      const guidId = uuidv4();
      // let fechaActual = new Date()

      let data = 
         {
            "verb": "string",
            "id": "cita",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject":
            {
               CitaId: guidId,
               Folio: NumFolio(),
               GeneralId: itemUnidadesRemate.generalId,
               Id: itemUnidadesRemate.remate.id,
               TipoCitaId: 13,
               TipoCita: null,
               ProcesoId: 0,
               Proceso: null,
               // FechaHoraCita: `${values.fecha_cita} ${values.horario_cita}`,
               FechaHoraCita: values.fecha_hora_cita,
               BloqueoId: null,
               codEstatus: null,
               Estatus: values.estatus_cita,
               UbicacionId: null,
               Ubicacion: values.ubicacion,
               UsuarioAlta:userId,
               UsuarioUpd: null,
               FechaAlta: getSqlServerDateTime(),
               FechaUpd: null,
               MotivoRechazoId: null,
               motivo: null,
               Comentarios: values.nuevo_comentario
            },
            "kvp":{}
         }

         console.log(data)

      await UnidadesRemateService.CreateValuacionUnidadesRemate(data)
         .then(res=>{
            console.log("Cita", res)
            clearCalendar()
            setInitialValueCita(initialCita)
            if(res.data.callSucceded){
               toast.success(message("Registro realizado correctamente"));
               handleUpdate()
            }
            else{
               setLoading(false)
               toast.error(message("¡Error!", "Falla al registrar la cita"));
            }
            actions.resetForm();
         })
         .catch(e => {
            setLoading(false)
            if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
            else
            toast.error(message("¡Error!", e.message));
         })
   }

   const clearCalendar = ()=>{
      dispatch(dispatch(clearScheduleByTipoCalendario({ tipoCalendario: tipocalendario })))
      let calendario = {
         tipoCalendario: "",
         fields: {
         vin: "",
         contrato: "",
         modelo: "",
         ano: "",
         marca: "",
         day: "",
         hour: "",
         calendarioId: ""
         // day: (formatDate.getDate() + 1)
         }
      }
      dispatch(setFields(calendario));
   }

   const handleUpdate = async() =>{
      // dispatch(setLimpiarItemUnidadesRemate())
      let data = {
         "verb": "string",
         "id": "valuacion",
         "field": "string",
         "value": "string",
         "level": -1,
         "flag": true,
         "anyObject": {},
         "kvp": {
            "valuacionId": itemUnidadesRemate.valuacionId
         }
      }

      await UnidadesRemateService.GetAllValuaciones(data)
      .then(res=>{
         console.log(res)
         dispatch(setItemUnidadesRemate(res.data.responseData[0]))
         setLoading(false)
      })
      .catch(e => {
         setLoading(false)
         if (e.response !== undefined)
         toast.error(message("¡Error!", e.response.data.message));
         else
         toast.error(message("¡Error!", e.message));
      })
   }

   const handleUploadFile = async () => {
      dispatch(setIdDataDocs(remateId))
      const archivoId = uuidv4();
      navigate(`/procesocierre/unidades-en-remate/datadocs/fDevoluc/eCierre/${itemUnidadesRemate?.general?.numContrato}/${archivoId}`, {
         state: { registroId: archivoId, vin: itemUnidadesRemate?.vehiculo?.vin, nombreMarca: itemUnidadesRemate?.vehiculo?.nombreMarca,nombreModelo: itemUnidadesRemate?.vehiculo?.nombreModelo, 
            nombreVersion: itemUnidadesRemate?.vehiculo?.nombreVersion, nombreYear: itemUnidadesRemate?.vehiculo?.nombreYear }
      });
   }

   const handleCalendar = () => {
      // let formatDate = new Date(fecha)
      // console.log(formatDate.getDate())
      let calendario = {
         tipoCalendario: tipocalendario,
         fields: {
            vin: itemUnidadesRemate?.vehiculo?.vin,
            contrato: itemUnidadesRemate?.general?.numContrato,
            modelo: itemUnidadesRemate?.vehiculo?.nombreModelo,
            ano: itemUnidadesRemate?.vehiculo?.nombreYear,
            marca: itemUnidadesRemate?.vehiculo?.nombreMarca,
            day: "",
            hour: "",
            calendarioId: ""
            // day: (formatDate.getDate() + 1)
         }
      }
      dispatch(setFields(calendario));
      navigate('/procesocierre/unidades-en-remate/Citas/v2/8', { state: { title: 'Agenda Unidades en Remate', tipoCalendario: 8 } });
   }

   return (
      <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
         {modalMessage.type === 1 && (
            <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            />
         )}
         { modalMessage.type === 2 && ( <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} /> )}
      </Modal>
      <Toaster
         position="top-right"
         toastOptions={{
            success: {
            style: {
               background: "#47a066",
               color: "#FFFF",
               borderLeft: "10px solid #2f7246",
            },
            },
            error: {
            style: {
               background: "#d53f3f",
               color: "#FFFF",
               borderLeft: "10px solid #ac241a",
            },
            },
         }}
      />
      <div className="row wrapper-vehiculo">
         <div className="col-12">
            <div className="row mx-2 md-4 my-4">
               <div className="white-wrapper mb-2">
                  <div
                     className="row my-2 px-4 "
                     data-bs-toggle="collapse"
                     href="#collapseCierreRemate"
                     role="button"
                     aria-expanded="false"
                     aria-controls="collapseRegistroTramite"
                     onClick={() => setCollpsableGrua(!collpsableGrua)}
                  >
                     <div className="col-11">
                        <h6>
                           <strong>Cierre de Remate</strong>
                        </h6>
                     </div>
                     <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right", marginLeft:"-10px" }} >
                        <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                     </div>
                  </div>
                  <div className="collapse" id="collapseCierreRemate">
                     <Formik
                        enableReinitialize={true}
                        validationSchema={validateRemate}
                        initialValues={initialValueRemate}
                        onSubmit={(values, actions) => {
                           handleGuardarRemate(values, actions);
                     }}>
                        {({ isValid, values, setFieldValue}) => (
                           <>
                           <Form>
                              <div className="separator"></div>
                              <div className="row mt-2 px-4">
                                 <div className="col-2 mt-4">
                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Proceso de cierre</strong></span></p>
                                 </div>
                                 <div className="col-10 row justify-content-end">
                                    <div className="col-12 col-lg-2">
                                       {/* <div className="col-auto"> */}
                                          <button
                                             className="btn col-12"
                                             type="submit"
                                             disabled={!(isValid)}
                                             style={{
                                             padding: "10px",
                                             lineHeight: "25px",
                                          }}>
                                             Guardar Remate
                                          </button>
                                       {/* </div> */}
                                    </div>
                                 </div>
                              </div>
                              <div className="mb-3 row px-4" >
                                 <div className="col-12 col-lg-3">
                                    <TextField label="Estatus" name="estatus_cierre" id="estatus_cierre" type="text" disabled={true} />
                                 </div>
                                 <div className="col-12 col-lg-3">
                                    <TextField label="Fecha y hora de la firma" name="horario_firma" type="text" disabled={true} />
                                 </div>
                                 <div className="col-12 col-lg-3">
                                    <TextField label="Ejecutivo atención a cliente" name="nombre_ejecutivo" holder={nombreUsuario ?? ""} type="text" disabled={true} />
                                 </div>
                              </div>
                              <div className="row mt-2 px-4">
                                 <div className="col-2 mt-4">
                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Datos de compra</strong></span></p>
                                 </div>
                              </div>
                              <div className="mb-3 row px-4" >
                                 <div className="col-12 col-lg-3">
                                    <TextField label="Número de solicitud" name="numero_solicitud" type="text" disabled={true} />
                                 </div>
                                 <div className="col-12 col-lg-3">
                                    <TextField label="Fecha de pago de la unidad" name="fecha_pago" type="text" disabled={true} />
                                 </div>
                                 <div className="my-3 row px-4" >
                                    <table style={{border: "#000 1px solid"}} className="col-12 col-lg-3">
                                       <thead style={{border: "#000 1px solid"}}>
                                          <tr>
                                             <th>Concepto</th>
                                             <th>Monto</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>Precio de venta</td>
                                             <td>{formatearMontoMx(precioVenta)}</td>
                                          </tr>
                                          <tr>
                                             <td>Precio de compra</td>
                                             <td>{formatearMontoMx(precioCompra)}</td>
                                          </tr>
                                       </tbody>
                                       <tfoot style={{border: "#000 1px solid"}}>
                                          <tr>
                                             <td>Direfencia</td>
                                             <td>{precioVenta === 0 ? formatearMontoMx(precioCompra) : formatearMontoMx(precioVenta - precioCompra)}</td>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div className="row mt-2 px-4">
                                 <div className="col-2 mt-4">
                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Saldo a favor</strong></span></p>
                                 </div>
                              </div>
                              <div className="mb-3 row px-4" >
                                 <div className="col-12 col-lg-2">
                                    <SelectField
                                       id="id_devolucion"
                                       label="Realizar devolución"
                                       name="id_devolucion"
                                       items={dataOpcionSiNo}
                                       onChange={(event) => {
                                          setFieldValue("id_devolucion", event.value)
                                          setFieldValue("realizar_devolucion", event.value === "" ? "" : event.value);
                                    }}/>
                                 </div>
                                 <div className="col-12 col-lg-2">
                                    <TextField icon={"bi bi-currency-dollar"} label="Monto a devolver" name="monto_devolucion" type="text" holder="Escribe" isMoney={true} onChange={(event) => {
                                          if (event.target.value < 0 || isNaN(event.target.value)) {
                                             setFieldValue("monto_devolucion", 0);
                                          }
                                          else {
                                             setFieldValue("monto_devolucion", event.target.value)
                                             if (event.target.value === "" || isNaN(event.target.value)) {
                                                setFieldValue("monto_devolucion", 0);
                                             }
                                          }
                                    }} />
                                 </div>
                                 <div className="col-12 col-lg-2">
                                    <TextField label="Estatus" name="estatus_devolucion" type="text" holder="Escribe" 
                                       onChange={(event) => { setFieldValue("estatus_devolucion", event.target.value); }} />
                                 </div>
                              </div>
                              {/* <div className="mb-3 row px-4" >
                                 <div className="col-sm-12 col-md-6 col-lg-3">
                                    <Field className="form-check-input" type="checkbox" value={values.proceso_concluido} checked={values.proceso_concluido} name="proceso_concluido" 
                                       onClick={(event) => { 
                                          setFieldValue("proceso_concluido", event.target.checked); 
                                    }}/>
                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Proceso concluido</label>
                                 </div>
                              </div> */}
                           </Form>
                           </>
                        )}
                     </Formik>
                     <Formik
                        enableReinitialize={true}
                        validationSchema={validateCita}
                        initialValues={initialValueCita}
                        onSubmit={(values, actions) => {
                           handleGuardarCita(values, actions);
                     }}>
                        {({ isValid, values, setFieldValue}) => (
                           <>
                           <Form>
                              {
                                 itemUnidadesRemate.remate !== null &&(
                                    <>
                                       <div className="row mt-2 px-4">
                                          <div className="col-2 mt-4">
                                             <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Agendar cita devolución</strong></span></p>
                                          </div>
                                          <div className="col-10 row justify-content-end">
                                             <div className="col-12 col-lg-2">
                                                {/* <div className="col-auto"> */}
                                                   <button
                                                      className="btn col-12"
                                                      type="submit"
                                                      disabled={!(isValid)}
                                                      style={{
                                                      padding: "10px",
                                                      lineHeight: "25px",
                                                   }}>
                                                      Guardar cita
                                                   </button>
                                                {/* </div> */}
                                             </div>
                                          </div>
                                       </div>
                                       <div className="mb-3 row px-4" >
                                          <div className="col-12 col-lg-3">
                                             <TextField id="fecha_compromiso" label="Fecha y hora de cita" name="fecha_hora_cita" holder="Fecha y hora" type="text"  disabled={true} />
                                                {/* holder={ values.fecha_hora_cita === "" ? "Escribe" : values.fecha_hora_cita} 
                                                onChange={(event) => { setFieldValue("fecha_hora_cita", event.target.value); }} 
                                                /> */}
                                          </div>
                                          {/* <div className="col-12 col-lg-3">
                                             <TextField id="horarioCompromiso" label="Hora de cita" name="horario_cita" type="time" holder="Escribe"
                                                onChange={(event) => { setFieldValue("horario_cita", event.target.value); }} />
                                          </div> */}
                                          <div className="col-12 col-lg-3">
                                             <button className='btn mt-4'
                                                onClick={() => {
                                                   handleCalendar();
                                                }}>
                                                Ver calendario
                                             </button>
                                          </div>
                                       </div>
                                       <div className="mb-3 row px-4" >
                                          <div className="col-12 col-lg-3">
                                             <TextField label="Estatus de cita" name="estatus_cita" type="text" holder="Escribe" 
                                                onChange={(event) => { setFieldValue("estatus_cita", event.target.value); }}/>
                                          </div>
                                          <div className="col-12 col-lg-3">
                                             <TextField label="Ubicación" name="ubicacion" type="text" holder="Ubicación" disabled />
                                                {/* onChange={(event) => { setFieldValue("ubicacion", event.target.value); }}/> */}
                                          </div>
                                          <div className="col-12 col-lg-3">
                                             <div className="col-auto mt-3">
                                                <button
                                                   id="btnVisualizarDocumentos"
                                                   className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                                   type="button"
                                                   onClick={()=>{setModalHistorial(true)}}
                                                   style={{
                                                      marginTop:"20px",
                                                      border: "none",
                                                      backgroundColor: "#f0f0f0",
                                                }}>
                                                   <i className="icon-dark ri-file-list-2-line" style={{ fontSize: "18px" }}></i>
                                                   Ver historial
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="mb-3 row px-4" >
                                          <div className="col-sm-12 col-md-6 col-lg-3">
                                             <Field className="form-check-input" type="checkbox" value={values.cita_agendada} checked={values.cita_agendada} name="cita_agendada" 
                                                onClick={(event) => {
                                                   setFieldValue("cita_agendada", event.target.checked); 
                                             }}/>
                                             <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cita agendada</label>
                                          </div>
                                       </div>
                                       <div className="row mt-2 px-4">
                                          <div className="col-2 mt-4">
                                             <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Devolución a cliente</strong></span></p>
                                          </div>
                                       </div>
                                       {/* <div className="row mt-2 px-4 mb-5">
                                          <span>Devolución de efectivo
                                             <button
                                                className="btn col-auto mx-2"
                                                type="button"
                                                onClick={() => { console.log("descarga") }}
                                             >
                                                <i className="ri-download-line fs-5" style={{ color: "#fff", fontSize:"20px" }} ></i>
                                             </button>
                                          </span>
                                       </div> */}
                                       <div className="row px-4 mt-3 mb-3 justify-content-between" >
                                          <table style={{border: "#000 1px solid"}} className="col-12 col-lg-3">
                                             <thead style={{border: "#000 1px solid"}}>
                                                <tr>
                                                   <th>Evidencia</th>
                                                   <th>Acción</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>Devolución de efectivo</td>
                                                   <td>
                                                      <button
                                                         id="btnSubirDocumento"
                                                         className="btn btn-outline col-12 d-flex align-items-center justify-content-center"
                                                         type="button"
                                                         onClick={handleUploadFile}
                                                         style={{
                                                         border: "none",
                                                         backgroundColor: "#f0f0f0",
                                                      }}>
                                                         <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                                         Subir documento
                                                      </button>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                          <div className="col-2 col-lg-2 d-flex align-items-center justify-content-center">
                                                <button
                                                   className="btn col-10"
                                                   type="button"
                                                   onClick={()=>{setModalComentarios(true)}}
                                                   style={{
                                                      padding: "10px",
                                                      lineHeight: "25px",
                                                   }}
                                                >
                                                <i className="icon-white ri-chat-4-fill" style={{marginRight:"5px"}} ></i>
                                                Comentarios
                                                </button>
                                          </div>
                                       </div>
                                       <div className="col-6 row px-4">
                                          <div className="col-12">
                                             <TextAreaField
                                                label="Comentarios"
                                                className="col-8 mt-2 form-control"
                                                rows="4"
                                                name="nuevo_comentario"
                                                type="text"
                                                holder="Escribe"
                                             />
                                          </div>
                                       </div>
                                       <div className="mb-3 row px-4" >
                                          <div className="col-sm-12 col-md-6 col-lg-3">
                                             <Field className="form-check-input" type="checkbox" value={values.proceso_concluido} checked={values.proceso_concluido} name="proceso_concluido" 
                                                onClick={(event) => { 
                                                   setFieldValue("proceso_concluido", event.target.checked); 
                                             }}/>
                                             <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Proceso concluido</label>
                                          </div>
                                       </div>
                                    </>
                                 )
                              }
                              </Form>
                           </>
                        )}
                     </Formik>
                  </div>
               </div>
            
         </div>
      </div>
      </div>
      {
         <HistorialCitasProcesosModal 
            isOpen={modalHistorial}
            setIsOpen={setModalHistorial}
         />
      }
      {
         <CajaDeComentariosProcesosModal
            isOpen={modalComentarios}
            setIsOpen={setModalComentarios}
            item={listaComentarios}
            id={remateId}
            handleUpdate={handleUpdate}
         />
      }
      </>
   );
});
export default CierreDeRemateProcesosCard;