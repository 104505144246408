import { useEffect, useRef, useState } from "react";
import AutorizarDictamenService from '../../Services/Seguros/AutorizarDictamenService';
import { Proceso } from '../../Utils/constan';

const CitaFirmaHook = () => {
    const columns = [
        { field: 'folio', headerName: 'Folio cita', width: 150 },
        { field: 'contrato', headerName: 'contrato', width: 150 },
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 100 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 100 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: 150 },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', width: 200 },
        { field: 'nombreUbicacion', headerName: 'Ubicación', width: 200 },
    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false); 
    const [pageRows, setpageRows] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState();
    useEffect(() => {
        handleGetAll(currenPage);
        setCurrenPage(1);
    }, [str,pageRows])

    const handleGetAll = async (page) => {
        setLoading(true);
        await AutorizarDictamenService.GetAllAutorizarCitas(page, pageRows, str, Proceso.SeguimientoCierre, "", "", "", "", "6")
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }   
    
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleGetAll(event.selected + 1);
    };

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        setOpenModal,
        openModal,
        handleGetAll,
        setItemSelected,
        itemSelected
    }
}

export default CitaFirmaHook;